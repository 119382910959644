import { Renderer } from "xlsx-renderer";
import { saveAs } from "file-saver";

export const exportCheckingAccount = (viewModel, filename = `cuenta-corriente-${Date.now()}.xlsx`) => {
    return readTemplateThenExport(
        'https://banza-partners-web-static.s3.amazonaws.com/templates-xlsx/cuenta-corriente.xlsx', 
        viewModel,
        filename
    );
}

export const exportHoldingAccount = (viewModel, filename = `tenencia-${Date.now()}.xlsx`) => {
    return readTemplateThenExport(
        'https://banza-partners-web-static.s3.amazonaws.com/templates-xlsx/tenencia.xlsx', 
        viewModel,
        filename
    );
}

export const exportFeeDetails = (viewModel, filename = `fee-${Date.now()}.xlsx`) => {
    return readTemplateThenExport(
        'https://banza-partners-web-static.s3.amazonaws.com/templates-xlsx/fee.xlsx', 
        viewModel,
        filename
    );
}
    
const readTemplateThenExport = (template, viewModel, outputFileName) => {
    return fetch(template)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Renderer().renderFromArrayBuffer(buffer, viewModel))
        .then((report) => report.xlsx.writeBuffer())
        .then((buffer) => saveAs(new Blob([buffer]), outputFileName))
        .catch((err) => console.log(err));
}