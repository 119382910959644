import React from "react";
import "./inputText.scss";

export const InputText = ({
  dataTestId,
  text,
  placeholder,
  onChange,
  type,
  value,
  defaultValue,
  name,
  children,
  inputSize,
  togglePasswordVisibility,
  onToggleVisibility,
  suffixIcon
}) => {

  return (
    <div className="input-container">
      <input
        data-testid={dataTestId}
        name={name}
        className="input-text"
        text={text}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        value={value}
        defaultValue={defaultValue}
      >
        {children}
      </input>
      {togglePasswordVisibility && value && suffixIcon}
    </div> 
  );
};
