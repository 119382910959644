import React from 'react';
import './tab-bar.scss';

import { onClickTapOption } from '../../operate-service';

export const TabBar = ({ tabOptions, setTabOptions, disabled }) => {
  return (
    <div className="tab-bar-general-container">
      <div className="tab-bar-wrapper">
        <div className="tab-bar-options-list-container">
          {tabOptions.map((item, i) => {
             const handleClick = () => {
              if (!disabled) {
                onClickTapOption(i, tabOptions, setTabOptions);
              }
            };
            return item.visible ? (
              <div
                key={i}
                className={`tab-bar-option-container ${
                  item.selected ? 'text-selected' : ''
                }`}
              >
                <p
                  className="tab-bar-option-text"
                  onClick={handleClick}
                >
                  {item.name}
                </p>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
};
