import React from "react";
import { useSelector } from "react-redux";

import "./wallet-right.scss";

const HeaderWallet = ({ selectedClientDashboard }) => {
  const state = useSelector((state) => state["home"]);
  const { partnerInfo } = state;

  return (
    <div className="wallet-dashboard-container-right-header">
      <div data-testid="wallet-dashboard-header-client-name" className="wallet-dashboard-container-right-header-title">
        {selectedClientDashboard ? (
          <>
            <p>{selectedClientDashboard.name}</p>
            <span data-testid="wallet-dashboard-header-num-comitente">
              Cuenta comitente nº {selectedClientDashboard.numComitente}
            </span>
          </>
        ) : (
          "Cartera Global"
        )}
      </div>
      {selectedClientDashboard && (
        <div className="wallet-dashboard-container-right-header-data">
          <div className="data-client">
            <div className="phone-email-container">
              <p data-testid="wallet-dashboard-client-phone" className="client-phone">Cel: {partnerInfo.phone}</p>
              <p data-testid="wallet-dashboard-client-cuit-cuil" className="client-cuit">CUIT/CUIL {selectedClientDashboard.cuit}</p>
            </div>
            <div className="birthdate-email-container">
              <p data-testid="wallet-dashboard-client-email" className="client-email">{selectedClientDashboard.email}</p>
              <p data-testid="wallet-dashboard-client-birth-date" className="client-birthdate">Nacimiento: {partnerInfo.birthDate}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderWallet;
