import React, { useState } from 'react';

import EmptyTabView from './empty-view';

import './tabs-card.scss';

const TabsCard = ({ tabs, views }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const TabView = views[selectedTab];
    return (
        <div className="tabs-card-container">
            <div className="tabs-header">
                {
                    tabs.map((tab, index) => (
                        <div className={(selectedTab === index) ? "tab-button active" : "tab-button"} key={index} onClick={() => setSelectedTab(index)}>
                            {tab}
                        </div>
                    ))
                }
            </div>
            <div className="tabs-card-body">
                {
                    (TabView) ? <TabView /> : <EmptyTabView />
                }
            </div>
        </div>
    )
}

TabsCard.defaultProps = {
    tabs: [],
    views: []
}

export default TabsCard