import React from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from '../../../../components/spinner/spinner';
import { MarketHistoryChart } from '../market-detail-history-chart/market-history-chart';
import {twoDecimalsFormat} from "../../../../common/common-service";


export const DetailQuotationTab = () => {

    
    const { loadingTickerHist, termNumber } = useSelector((state) => state.market);
    const { specieInfo } = useSelector((state) => state.operate);

    return (
        <div className="ticker-detail-body">
            <div className="ticker-detail-type-title">
                {specieInfo?.name}
            </div>
            <div className="ticker-detail-title">
                {specieInfo?.ticker}
            </div>
            {loadingTickerHist ? <Spinner /> :
                <MarketHistoryChart />
            }
            <div className="ticker-detail-list">
                <div className="ticker-detail-list-data-container">
                    <ul>
                        <li key={1} className="ticker-prices-container">
                        <div className="ticker-detail-price ticker-buy-price">
                            P. Compra: {specieInfo?.marketData?.['Plazo' + termNumber]?.BID_PRICE}
                        </div>
                        <div className="ticker-detail-price ticker-sell-price">
                            P. Venta: {specieInfo?.marketData?.['Plazo' + termNumber]?.ASK_PRICE}
                        </div>
                        </li>
                        <li key="currency" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Moneda</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.currency?
                                    specieInfo?.currency
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                        <li key="day_percentage" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Volumen Nominal</p>
                            <p className='ticker-detail-list-item-value'>
                            {twoDecimalsFormat(specieInfo?.marketData?.['Plazo' + termNumber]?.VOLUME ?
                                    parseFloat(specieInfo.marketData?.['Plazo' + termNumber]?.VOLUME).toFixed(2)
                                    :
                                    '-'
                                )}
                            </p>
                        </li>
                        <li key="profile" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Variacion de Precio</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE ?
                                    parseFloat(specieInfo.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE).toFixed(2) + '%'
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                        <li key="worth" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>P. Cierre Del Día Anterior</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.marketData?.['Plazo' + termNumber]?.PREV_CLOSE_PRICE ?
                                    parseFloat(specieInfo.marketData?.['Plazo' + termNumber]?.PREV_CLOSE_PRICE).toFixed(2)
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                        <li key="Plazo_resc" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Última Operacion</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.marketData?.['Plazo' + termNumber]?.LAST_TRADE_DATE ?
                                    specieInfo.marketData?.['Plazo' + termNumber]?.LAST_TRADE_DATE
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                        <li key="minimum" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>
                                Importe Minimo
                            </p>
                            <p className='ticker-detail-list-item-value'>
                                {`${specieInfo?.MINIMO_INVERSION > 0 ?
                                    specieInfo?.MINIMO_INVERSION
                                    :
                                    'Sin minimo'}`
                                }
                            </p>
                        </li>
                        <li key="update_time" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>
                                Hora Actualización
                            </p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.marketData?.['Plazo' + termNumber]?.UPDATE_TIME ?
                                    specieInfo.marketData?.['Plazo' + termNumber]?.UPDATE_TIME.split(' ')[1]
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
