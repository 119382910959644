import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA  from 'react-google-recaptcha';
// actions and services
import { api_sendLoginContactMeEmail, a_resetEmailSend  } from '../../common/common-slice';
import { SetSuccessfully } from './sent-successfully';
// styles
import './form-problem.scss';
import { Subtitle } from '../../components/titles/titles';

export const DataInput = ({
  type,
  name,
  classData,
  onChange,
  placeholder,
  classTitle,
}) => (
  <div className={classData}>
    <input
      className="input-data-user"
      type={type}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required
    />
  </div>
);

export const FormProblem = ({ backToLogin }) => {
  const [sentSuccessfully, setSentSuccessfully] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const dispatch = useDispatch();

  const state = useSelector((state) => ({
    ...state['common'],
  }));

  const { loadingEmailSend, emailSendStatus } = state;

  useEffect(() => {
    setSentSuccessfully(emailSendStatus);
  }, [emailSendStatus]);

  const [userContactData, setUserContactData] = useState({
    name: '',
    surname: '',
    email: '',
    code: '',
    cellphone: '',
    comment: '',
  });

  const closeSentSuccessfully = () => {
    setSentSuccessfully(false);
    backToLogin();
    dispatch(a_resetEmailSend());
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleFormProblemChange = (e) => {
    setUserContactData({
      ...userContactData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailSubmit = () => {
    if (validateEmail(userContactData.email) && recaptchaValue) {
      dispatch(
        api_sendLoginContactMeEmail({
          subject: `Consulta Banza Partners - ${userContactData.name} ${userContactData.surname}`,
          destination: ['partners@banza.com.ar'],
          body: `Los datos son: \n Nombre y Apellido: ${userContactData.name} ${userContactData.surname} \n Telefono: ${userContactData.code}-${userContactData.cellphone} \n Email: ${userContactData.email} \n Mensaje: ${userContactData.comment}`,
        })
      );
    }
  };

  return (
    <>
      <div className="form-problem">
        <div className="login-title-container form-problem-title">
          <Subtitle type="main" text="Enviar Consulta" />
        </div>
        <div className="form-problem-login">
          <div className="container-name-surname-form">
            <DataInput
              classData="container-data-user"
              classTitle="title-data"
              placeholder="Nombre*"
              type="text"
              name="name"
              value={userContactData.name}
              onChange={handleFormProblemChange}
              required
            />
            <DataInput
              classData="container-data-user"
              classTitle="title-data"
              placeholder="Apellido*"
              type="text"
              name="surname"
              value={userContactData.surname}
              onChange={handleFormProblemChange}
              required
            />
          </div>
          <DataInput
            classData="container-email-user"
            classTitle="title-data"
            placeholder="Dirección de Correo Electrónico"
            type="email"
            name="email"
            value={userContactData.email}
            onChange={handleFormProblemChange}
            required
          />
          <div className="container-telephone">
            <div className="container-name-surname-form">
              <DataInput
                classData="container-code-number-user"
                placeholder="Cód. Arg"
                type="number"
                name="code"
                value={userContactData.code}
                onChange={handleFormProblemChange}
                required
              />
              <DataInput
                classData="container-number-user"
                placeholder="Celular"
                type="number"
                name="cellphone"
                value={userContactData.cellphone}
                onChange={handleFormProblemChange}
                required
              />
            </div>
            <p className="description-telephone">(Agregar tu código de área)</p>
          </div>
          <div className="container-textarea-data">
            <textarea
              className="textarea-data-form"
              placeholder="Escribe tu consulta aquí"
              name="comment"
              value={userContactData.comment}
              onChange={handleFormProblemChange}
              required
            />
          </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_ENV_RE_CAPTCHA_KEY}
              onChange={handleRecaptchaChange}
            />
          <div className="container-button-form-problem-login">
            <button className="button-return-login" onClick={backToLogin}>
              Volver al Login
            </button>
            <button
              className="button-send-form"
              disabled={loadingEmailSend}
              onClick={handleEmailSubmit}
            >
              Enviar Consulta
            </button>
          </div>
        </div>
      </div>
      {sentSuccessfully && (
        <SetSuccessfully backToLogin={closeSentSuccessfully} />
        )}
    </>
  );
};
