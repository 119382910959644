import React from 'react'
import { useSelector } from 'react-redux';
import { FilePdfOutlined } from '@ant-design/icons';

export const DetailTab = ({ checkedTicker }) => {

    const state = useSelector((state) => ({
        ...state["marketReducer"],
        ...state["commonReducer"],
        ...state["operateReducer"],
    }));

    const {
        // specieInfo,
    } = state
    const { specieInfo } = useSelector((state) => state.operate)
    return (
        <div className="ticker-detail-body">
            <div className="ticker-detail-title">
                <div className="ticker-detail-type-title">
                    {specieInfo?.ES_FISICO ? `Persona Fisica` : ``}
                </div>
                <div className="ticker-detail-title-text">
                    {specieInfo?.ticker}
                </div>
            </div>
            <div className="ticker-detail-subtype-list-item ticker-detail-modal-bold-text">
                Estrategia
            </div>
            <div className="ticker-detail-modal-text">
                {specieInfo?.ESTRATEGIA}
            </div>
            <div className="ticker-detail-subtype-list-item ticker-detail-modal-bold-text">
                Objetivo
            </div>
            <div className="ticker-detail-modal-text">
                {specieInfo?.OBJETIVO}
            </div>
            <div className="ticker-detail-subtype-list-item ticker-detail-modal-bold-text">
                Depositaria
            </div>
            <div className="ticker-detail-modal-text">
                {specieInfo?.AGENTE_CUSTODIA}
            </div>
          {/*   <div className="ticker-detail-fact-sheet">
                <a href="#" className="fact-sheet-link">
                    <FilePdfOutlined /> Fact sheet
                </a>
            </div> */}
        </div>
    )
}
