import iconHome from "../utils/icon-home.svg";
import iconHomeSelected from "../utils/icon-home1.svg";

import iconMercado from "../utils/icon-mercado.svg";
import iconMercadoSelected from "../utils/icon-mercado1.svg";

import iconOperate from "../utils/icon-operar.svg";
import iconOperateSelected from "../utils/icon-operar1.svg";

import iconTesoreria from "../utils/icon-tesoreria.svg";
import iconTesoreriaSelected from "../utils/icon-tesoreria1.svg";

import iconCartera from "../utils/icon-cartera.svg";
import iconCarteraSelected from "../utils/icon-cartera1.svg";

import iconTeam from "../utils/icon-team.svg";
import iconTeamSelected from "../utils/icon-team1.svg";

import iconAnalytics from "../utils/icon-analytics.svg";
import iconAnalyticsSelected from "../utils/icon-analytics1.svg";

import iconDescargas from "../utils/icon-descargas.svg";
import iconDescargasSelected from "../utils/icon-descargas1.svg";

import iconSoporte from "../utils/icon-soporte.svg";
import iconSoporteSelected from "../utils/icon-soporte1.svg";

/* **************************************** */
import iconCalendary from "../utils/icon-calendario.svg";
import iconEmail from "../utils/icon-mail.svg";
import iconTicket from "../utils/icon-ticket.svg";
import iconFaq from "../utils/icon-faq.svg";
import iconModeNight from "../utils/icon-modenight.svg";
import iconSmallDown from "../utils/small-down.svg";
import iconPerfil from "../utils/icon-perfil.svg";
import iconCarrito from "../utils/icon-carrito.svg";
import iconLogOut from "../utils/icon-logout.svg";

/* ************************************************************ */

export const userLogged = {
  idUser: 0,
  cellPhone: "1159484443",
  phone: "46927189",
  dni: "33501904",
  bornDate: "21-01-1988",
  address: "Av Rivadavia 3500",
  postalCode: "1200",
  location: "CABA",
  province: "Buenos Aires",
  cuit: "27-3668934 - 3",
  rz: "UOLE S.A"
};

/* ************************************************************ */

export const items = [
  {
    id: 0,
    image: iconHome,
    imgSelected: iconHomeSelected,
    url: "/home",
    title: "HOME",
    description: "Home Dashboard",
    enabled: true,
    dataTestId: "home"
  },
  {
    id: 1,
    image: iconMercado,
    imgSelected: iconMercadoSelected,
    url: "/market",
    title: "MERCADO",
    description: "Mercado",
    enabled: true,
    dataTestId: "market"
  },
  {
    id: 2,
    image: iconOperate,
    imgSelected: iconOperateSelected,
    url: "/operate",
    title: "OPERAR",
    description: "Operar Instrumentos",
    enabled: true,
    dataTestId: "operate"
  },
  {
    id: 3,
    image: iconTesoreria,
    imgSelected: iconTesoreriaSelected,
    url: "/treasury",
    title: "TESORERIA",
    description: "Tesorería",
    enabled: false,
    dataTestId: "treasury"
  },
  {
    id: 4,
    image: iconCartera,
    imgSelected: iconCarteraSelected,
    url: "/wallet",
    title: "CARTERA",
    description: "Cartera de clientes",
    enabled: true,
    dataTestId: "wallet"
  },
  {
    id: 5,
    image: iconTeam,
    imgSelected: iconTeamSelected,
    url: "/team",
    title: "TEAM",
    description: "Mi Team",
    enabled: false,
    dataTestId: "team"
  },
  {
    id: 6,
    image: iconAnalytics,
    imgSelected: iconAnalyticsSelected,
    url: "/analytics",
    title: "ANALYTICS",
    description: "Analytics BI",
    enabled: false,
    dataTestId: "analytics"
  },
  {
    id: 7,
    image: iconDescargas,
    imgSelected: iconDescargasSelected,
    url: "/files",
    title: "ARCHIVOS",
    description: "Archivos Descargas",
    enabled: false,
    dataTestId: "files"
  },
  {
    id: 8,
    image: iconSoporte,
    imgSelected: iconSoporteSelected,
    url: "/support",
    title: "SOPORTE",
    description: "Soporte Ad-Cap",
    enabled: false,
    dataTestId: "support"
  }
];

/* ************************************************************ */

export const navbarIcons = [
  { imageIcon: iconCalendary },
  { imageIcon: iconEmail },
  { imageIcon: iconTicket },
  { imageIcon: iconFaq },
  { imageIcon: iconModeNight },
  { imageIcon: iconSmallDown },
  { imageIcon: iconPerfil },
  { imageIcon: iconCarrito },
  { imageIcon: iconLogOut }
];

/* ************************************************************ */

export const movements = [
  {
    id: 0,
    description: "VENTA AY24",
    date: "Lun 10:27 hs",
    name: "Oterio Marina",
    amount: 5000
  },
  {
    id: 1,
    description: "Ingresos - Transf",
    date: "03/12 11:59 hs",
    name: "Gonzales Santiago",
    amount: 5000
  },
  {
    id: 2,
    description: "Compra ALUA",
    date: "02/04 15:05 hs",
    name: "Rodriguez Santiago",
    amount: 5000
  },
  {
    id: 3,
    description: "Extraccion Debin",
    date: "01/04 12:46 hs",
    name: "Otero Marina",
    amount: 5000
  },
  {
    id: 4,
    description: "VENTA AY24",
    date: "Lun 10:27 hs",
    name: "Oterio Marina",
    amount: 5000
  },
  {
    id: 5,
    description: "Ingresos - Transf",
    date: "03/12 11:59 hs",
    name: "Gonzales Santiago",
    amount: 5000
  },
  {
    id: 6,
    description: "Compra ALUA",
    date: "02/04 15:05 hs",
    name: "Rodriguez Santiago",
    amount: 5000
  },
  {
    id: 7,
    description: "Extraccion Debin",
    date: "01/04 12:46 hs",
    name: "Otero Marina",
    amount: 5000
  },
  {
    id: 8,
    description: "VENTA AY24",
    date: "Lun 10:27 hs",
    name: "Oterio Marina",
    amount: 5000
  },
  {
    id: 9,
    description: "Ingresos - Transf",
    date: "03/12 11:59 hs",
    name: "Gonzales Santiago",
    amount: 5000
  },
  {
    id: 10,
    description: "Compra ALUA",
    date: "02/04 15:05 hs",
    name: "Rodriguez Santiago",
    amount: 5000
  },
  {
    id: 11,
    description: "Extraccion Debin",
    date: "01/04 12:46 hs",
    name: "Otero Marina",
    amount: 5000
  },
  {
    id: 12,
    description: "VENTA AY24",
    date: "Lun 10:27 hs",
    name: "Oterio Marina",
    amount: 5000
  },
  {
    id: 13,
    description: "Ingresos - Transf",
    date: "03/12 11:59 hs",
    name: "Gonzales Santiago",
    amount: 5000
  },
  {
    id: 14,
    description: "Compra ALUA",
    date: "02/04 15:05 hs",
    name: "Rodriguez Santiago",
    amount: 5000
  },
  {
    id: 15,
    description: "Extraccion Debin",
    date: "01/04 12:46 hs",
    name: "Otero Marina",
    amount: 5000
  }
];

/* ************************************************************ */

export const provinceOptions = [
  { value: 0, description: "Buenos Aires" },
  { value: 1, description: "Catamarca" },
  { value: 2, description: "Chaco" },
  { value: 3, description: "Chubut" },
  { value: 4, description: "Córdoba" },
  { value: 5, description: "Corrientes" },
  { value: 6, description: "Entre Ríos" },
  { value: 7, description: "Formosa" },
  { value: 8, description: "Jujuy" },
  { value: 9, description: "La Pampa" },
  { value: 10, description: "La Rioja" },
  { value: 11, description: "Mendoza" },
  { value: 12, description: "Misiones" },
  { value: 13, description: "Neuquén" },
  { value: 14, description: "Río Negro" },
  { value: 15, description: "Salta" },
  { value: 16, description: "San Juan" },
  { value: 17, description: "San Luis" },
  { value: 18, description: "Santa Cruz" },
  { value: 19, description: "Santiago Del Estero" },
  { value: 20, description: "Santa Fe" },
  { value: 21, description: "Tierra Del Fuego" },
  { value: 22, description: "Tucumán" }
];
/* ************************************************************ */

export const latestNews = [
  {
    id: -4,
    type: "new",
    title: "Mercados: Rebotan las acciones con grandes consecuencias.",
    content: "Los mercados trepan hasta un 7% como en el año anterior"
  },
  {
    id: -3,
    type: "new",
    title: "Industrias: Rebotan las acciones con grandes consecuencias.",
    content: "Las industrias trepan hasta un 7% como en el año anterior"
  },
  {
    id: -2,
    type: "new",
    title: "Mercados: Rebotan las acciones con grandes consecuencias.",
    content: "Los mercados trepan hasta un 7% como en el año anterior"
  },
  {
    id: -1,
    type: "new",
    title: "Industrias: Rebotan las acciones con grandes consecuencias.",
    content: "Las industrias trepan hasta un 7% como en el año anterior"
  },
  {
    id: 0,
    type: "new",
    title: "Mercados: Rebotan las acciones con grandes consecuencias.",
    content: "Los mercados trepan hasta un 7% como en el año anterior"
  },
  {
    id: 1,
    type: "new",
    title: "Industrias: Rebotan las acciones con grandes consecuencias.",
    content: "Las industrias trepan hasta un 7% como en el año anterior"
  },
  {
    id: 2,
    type: "new",
    title: "Fabricas: Rebotan las acciones con grandes consecuencias.",
    content: "Las fabricas trepan hasta un 7% como en el año anterior"
  },
  {
    id: 3,
    type: "new",
    title: "Aeropuertos: Rebotan las acciones con grandes consecuencias.",
    content: "Los aeropuertos trepan hasta un 7% como en el año anterior"
  },
  {
    id: 4,
    type: "new",
    title: "Finanzas: Rebotan las acciones con grandes consecuencias.",
    content: "Las fianzas trepan hasta un 7% como en el año anterior"
  },
  {
    id: 5,
    type: "new",
    title: "Bancos: Rebotan las acciones con grandes consecuencias.",
    content: "Los bancos trepan hasta un 7% como en el año anterior"
  },

  {
    id: 6,
    type: "payment",
    title: "Fecha de pago de renta.",
    content: "Provincia de La Rioja (PROR3)"
  },
  {
    id: 7,
    type: "birth",
    title: "Cumple de Naty Rodriguez.",
    content: "Envíale una tarjeta"
  },
  {
    id: 8,
    type: "bono",
    title: "Vencimiento Bono PROR3.",
    content: "Provincia de La Rioja"
  },

  {
    id: 9,
    type: "payment",
    title: "Fecha de pago de renta.",
    content: "Provincia de La Rioja (PROR3)"
  },
  {
    id: 10,
    type: "birth",
    title: "Cumple de Naty Rodriguez.",
    content: "Envíale una tarjeta"
  },
  {
    id: 11,
    type: "bono",
    title: "Vencimiento Bono PROR3.",
    content: "Provincia de La Rioja"
  },
  {
    id: 12,
    type: "payment",
    title: "Fecha de pago de renta.",
    content: "Provincia de La Rioja (PROR3)"
  },
  {
    id: 13,
    type: "birth",
    title: "Cumple de Naty Rodriguez.",
    content: "Envíale una tarjeta"
  },
  {
    id: 14,
    type: "bono",
    title: "Vencimiento Bono PROR3.",
    content: "Provincia de La Rioja"
  },

  {
    id: 15,
    type: "payment",
    title: "Fecha de pago de renta.",
    content: "Provincia de La Rioja (PROR3)"
  },
  {
    id: 16,
    type: "birth",
    title: "Cumple de Naty Rodriguez.",
    content: "Envíale una tarjeta"
  },
  {
    id: 17,
    type: "bono",
    title: "Vencimiento Bono PROR3.",
    content: "Provincia de La Rioja"
  }
];

/* ************************************************************ */

export const listMarketIndex = [
  {
    id: 0,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 1,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 2,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 3,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 4,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 5,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 6,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 7,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 8,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 9,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 10,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 11,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 12,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 13,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 14,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 15,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 16,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 17,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 18,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "up",
    percentage1: 0.83,
    percentage2: 0.65
  },
  {
    id: 19,
    nameBTC: "BTC/USD",
    valueBTC: 9778.47,
    type: "down",
    percentage1: 0.83,
    percentage2: 0.65
  }
];

/* ************************************************************ */

export const clients = [
  {
    id: 0,
    fullName: "Pablo Vazquez",
    radioOption: "cl",
    suboption: "conservador",
    cardsBelong: 0,
    valueCard: 29587.86,
    percentageCard: -0.41,
    percentageRestCard: -0.36
  },
  {
    id: 1,
    fullName: "Jackelin Jara",
    radioOption: "48hs",
    suboption: "conservador",
    cardsBelong: 0,
    valueCard: 17587.86,
    percentageCard: 0.45,
    percentageRestCard: 0.37
  },
  {
    id: 2,
    fullName: "Evelyn Ropaldo",
    radioOption: "cl",
    suboption: "conservador",
    cardsBelong: 0,
    valueCard: 35733.24,
    percentageCard: -0.54,
    percentageRestCard: -0.21
  },
  {
    id: 3,
    fullName: "Pablo Juanes",
    radioOption: "24hs",
    suboption: "conservador",
    cardsBelong: 0,
    valueCard: 20010.21,
    percentageCard: -0.63,
    percentageRestCard: -0.45
  },
  {
    id: 4,
    fullName: "Pablo Irusta",
    radioOption: "24hs",
    suboption: "conservador",
    cardsBelong: 0,
    valueCard: 11983.11,
    percentageCard: 0.22,
    percentageRestCard: 0.27
  },
  {
    id: 5,
    fullName: "Franco Caballero",
    radioOption: "24hs",
    suboption: "moderado",
    cardsBelong: 0,
    valueCard: 28773.54,
    percentageCard: 0.35,
    percentageRestCard: 0.31
  },
  {
    id: 6,
    fullName: "Rodrigo Castro",
    radioOption: "24hs",
    suboption: "moderado",
    cardsBelong: 0,
    valueCard: 45321.46,
    percentageCard: 0.21,
    percentageRestCard: 0.66
  },
  {
    id: 7,
    fullName: "Eduardo Toledo",
    radioOption: "24hs",
    suboption: "moderado",
    cardsBelong: 0,
    valueCard: 34245.21,
    percentageCard: 0.11,
    percentageRestCard: 0.26
  },
  {
    id: 8,
    fullName: "Federico Alvarez",
    radioOption: "24hs",
    suboption: "agresivo",
    cardsBelong: 0,
    valueCard: 28736.43,
    percentageCard: 0.41,
    percentageRestCard: 0.45
  },
  {
    id: 9,
    fullName: "Cristian Güemes",
    radioOption: "24hs",
    suboption: "agresivo",
    cardsBelong: 0,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 10,
    fullName: "Fernando Irusta",
    radioOption: "24hs",
    suboption: "moderado",
    cardsBelong: 0,
    valueCard: 34245.21,
    percentageCard: 0.11,
    percentageRestCard: 0.26
  },
  {
    id: 11,
    fullName: "Giovani Irusta",
    radioOption: "24hs",
    suboption: "agresivo",
    cardsBelong: 0,
    valueCard: 28736.43,
    percentageCard: 0.41,
    percentageRestCard: 0.45
  },
  {
    id: 12,
    fullName: "Emanuel Irusta",
    radioOption: "48hs",
    suboption: "agresivo",
    cardsBelong: 0,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 13,
    fullName: "Damaris Irusta",
    radioOption: "48hs",
    suboption: "agresivo",
    cardsBelong: 0,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  /* **************** */
  {
    id: 14,
    fullName: "Marcelo 1 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 15,
    fullName: "Marcelo 2 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 16,
    fullName: "Marcelo 3 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 17,
    fullName: "Marcelo 4 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 18,
    fullName: "Marcelo 5 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 19,
    fullName: "Marcelo 6 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 20,
    fullName: "Pedro Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 21,
    fullName: "Elias 1 Gallardo",
    radioOption: "48hs",
    suboption: "general",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 22,
    fullName: "Elias 2 Gallardo",
    radioOption: "48hs",
    suboption: "general",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 23,
    fullName: "Elias 3 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 24,
    fullName: "Elias 4 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 25,
    fullName: "Elias 5 Gallardo",
    radioOption: "48hs",
    suboption: "general",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 26,
    fullName: "Elias 6 Gallardo",
    radioOption: "48hs",
    suboption: "lider",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },

  {
    id: 27,
    fullName: "Elias 7 Gallardo",
    radioOption: "48hs",
    suboption: "general",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 28,
    fullName: "Elias 8 Gallardo",
    radioOption: "24hs",
    suboption: "general",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  },
  {
    id: 29,
    fullName: "Elias 9 Gallardo",
    radioOption: "24hs",
    suboption: "general",
    cardsBelong: 1,
    valueCard: 23345.12,
    percentageCard: 0.56,
    percentageRestCard: 0.56
  }
];
/* ************************************************************ */

export const cardsMarket = [
  {
    id: 0,
    title: "Fondos",
    options: [
      { label: "Conservativo", option: "Conservador" },
      { label: "Moderado", option: "Moderado" },
      { label: "Agresivo", option: "Agresivo" }
    ],
    type: "FUND"
  },
  {
    id: 1,
    title: "Bonos",
    options: [
      { label: "Soberanos", option: "GOVERNMENT" },
      { label: "Provinciales", option: "PROVINCIAL" },
      { label: "Corporativos", option: "CORPORATE" }
    ],
    type: "BOND"
  },
  {
    id: 3,
    title: "Acciones",

    options: [
      { label: "S&P Merval", option: "BYMA.MERV" },
      //{ label: "S&P Merval Arg", option: "BYMA.M.AR" },
      { label: "S&P/BYMA Índice General", option: "BYMA.IGB" }
    ],
    type: "EQUITY"
  },
  { id: 2, title: "CEDEARs", type: "CERTIFICATE" }
];

export const TERM_C = [
  {
    id: 1,
    term: "C. I.",
    checked: false,
  },
  {
    id: 2,
    term: "24 hs",
    checked: false,
  },
  {
    id: 3,
    term: "48 hs",
    checked: true,
  },
  {
    id: 4,
    term: "Rescate parcial",
    checked: false,
  },
  {
    id: 5,
    term: "Rescate total",
    checked: false,
  },
]

export const INSTRUMENTS_C = [
  {
    id: 1,
    name: "Fondos",
    checked:true,
  },
  {
    id: 2,
    name: "Bonos",
    checked:false,
  },
  {
    id: 3,
    name: "Acciones",
    checked:false,
  },
  {
    id: 4,
    name: "CEDEARs",
    checked:false,
  },
]
