import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  noDecimalFormat,
  thousandFormat,
  regularPriceFormat,
} from "../../operate-service";

import "../common-detail-especie/common-detail-especie.scss";

export const Prices = () => {
  const { specieInfo, liquidityTerm } = useSelector((state) => state.operate);

  const [termData, setTermData] = useState("");

  useEffect(() => {
    if (specieInfo && specieInfo["marketData"] && liquidityTerm && liquidityTerm["term"] !== undefined) {
      const termKey = "Plazo" + liquidityTerm["term"].toString();
      const newTermData = specieInfo["marketData"][termKey];
      setTermData(newTermData);
    }
  }, [specieInfo, liquidityTerm]);
  
  const horizontalList = ["P.Compra", "P.Venta"];

  const dataCompra = [
    {
      c1: thousandFormat(termData?.["BID_SIZE"]),
      c2: regularPriceFormat(termData?.["BID_PRICE"]),
    },
  ];

  const dataVenta = [
    {
      c1: thousandFormat(termData?.["ASK_SIZE"]),
      c2: regularPriceFormat(termData?.["ASK_PRICE"]),
    },
  ];

  const ElementHeader = ({ element }) => {
    return <div>{element}</div>;
  };

  return (
    <div className="detail-especie2-info">
      {/* <div className="statics">
        Aqui debe ir el grafico de velas de precios
      </div> */}
      <div className="data">
        <div className="element-data">
          <p className="element-data-description" tabIndex="0">
            Precio
          </p>
          <p className="element-data-value" tabIndex="0">
            {regularPriceFormat(termData?.["NOW_PRICE"])}
          </p>
        </div>
        <div className="element-data">
          <p className="element-data-description" tabIndex="0">
            Dif del dia
          </p>
          <p
            className={`element-data-value ${
              termData?.["DAY_PERCENTAGE_CHANGE"] < 0 ||
              termData?.["DAY_PERCENTAGE_CHANGE"] === "N/A"
                ? "negative-day-percentage-change"
                : "positive-day-percentage-change"
            }`}
            tabIndex="0"
          >
            {regularPriceFormat(termData?.["DAY_PERCENTAGE_CHANGE"])}
          </p>
        </div>
        <div className="element-data">
          <p className="element-data-description" tabIndex="0">
            Volumen
          </p>
          <p className="element-data-value" tabIndex="0">
            {noDecimalFormat(termData?.["VOLUME"])}
          </p>
        </div>
        <div className="element-data">
          <p className="element-data-description" tabIndex="0">
            Hora
          </p>
          <p className="element-data-value" tabIndex="0">
            {termData?.["UPDATE_TIME"]}
          </p>
        </div>
        
      </div>
      <div className="operate-detail-data-color-element">
          <div className="operate-detail-data-first">
            {dataCompra.map((element, i) => (
              <div className="data-color-element-wrapper" key={i}>
                <div className="data-venta-title">P. Compra</div>
                <div className="data-venta-value">{element.c2}</div>
                {/* <div>{element.c1}</div>
              <div>{element.c2}</div> */}
              </div>
            ))}
          </div>

          <div className="operate-detail-data-second">
            {dataVenta.map((element, i) => (
              <div className="data-color-element-wrapper" key={i}>
                <div className="data-venta-title">P. Venta</div>
                <div className="data-venta-value">{element.c2}</div>
                {/* <div>{element.c1}</div>
              <div>{element.c2}</div> */}
              </div>
            ))}
          </div>
        </div>
      {/* <div className="horizontal-data">
        {horizontalList.map((element, i) => (
          <ElementHeader key={i} element={element} />
        ))}
      </div> */}
    </div>
  );
};
