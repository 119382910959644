import React, { useState } from 'react';
import { DatePicker, Select } from 'antd';
import './full-period-picker.scss';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const periodRanges = {
  month: [moment().subtract(1, 'months'), moment()],
  three: [moment().subtract(3, 'months'), moment()],
  six: [moment().subtract(6, 'months'), moment()],
  year: [moment().subtract(1, 'year'), moment()],
  ytd: [moment().startOf('year'), moment()],
  max: [moment('2021-09-08'), moment()]
};

const periodOptions = {
  month: 'Mes Actual',
  three: '3 meses',
  six: '6 meses',
  year: '1 año',
  ytd: 'YTD',
  max: 'MAX'
};

export const FullPeriodPicker = ({ setPeriod, periodType, setPeriodType }) => {
  const [dateRange, setDateRange] = useState(null);
  
  const setSpecificDatesPeriod = (range) => {
    setDateRange(range);
    setPeriod(range);
  };

  const setTypePeriod = (value) => {
    const range = periodRanges[value];
    setPeriodType(value);
    setPeriod(range.map(m => m.format('YYYY-MM-DD')));
  };

  return (
    <div className="full-period-picker">
      <RangePicker
        placeholder={["Desde", "Hasta"]}
        onChange={setSpecificDatesPeriod}
        value={dateRange}
        className="period-picker-left"
      />
      <Select
        defaultValue={periodType}
        onChange={setTypePeriod}
        className="period-picker-right"
      >
        {Object.keys(periodOptions).map(key => (
          <Option key={key} value={key}>
            {periodOptions[key]}
          </Option>
        ))}
      </Select>
    </div>
  );
};
