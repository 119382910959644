import React from 'react';

const EmptyTabView = (props) => {
    return (
        <div className="empty-view-container">
            <p>Esta sección no tiene vista.</p>
        </div>
    )
}

export default EmptyTabView