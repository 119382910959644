import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCallAsync } from "../../common/common-service";

// get Holdings
export const api_getHolding = createAsyncThunk(
  "wallet/api_getHolding",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/portfolio/partner/holding",
        method: "GET",
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// getHolding por cliente
export const api_getHoldingClient = createAsyncThunk(
  "wallet/api_getHoldingClient",
  async (codComitente, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: `/portfolio/partner/holding?codComitente=${codComitente}`,
        method: "GET",
        data: codComitente,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const api_getHoldingDetail = createAsyncThunk(
    "wallet/api_getHoldingDetail",
    async (params, { rejectWithValue }) => {
      try {
        const response = await axiosCallAsync({
          base: "_PARTNERS",
          path: "/portfolio/partner/holding/detail",
          method: "GET",
          data: params,
        });
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const api_getHoldingClientDetail = createAsyncThunk(
    "wallet/api_getHoldingClientDetail",
    async (codComitente, { rejectWithValue }) => {
      try {
        const response = await axiosCallAsync({
          base: "_PARTNERS",
          path: `/portfolio/partner/holding/detail?codComitente=${codComitente}`,
          method: "GET",
          data: codComitente,
        });
        return response;
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error);
      }
    }
  );


// END CUSTOMERS BY PARTNER
const initialState = {
    currencyOption: "Pesos",
    loadingFeeDetail: false,
    feeDetail: [],
    loadingHolding: false,
    holding: [],
    loadingHoldingCodComitente: false,
    holdingCodComitente: [],
    loadingHoldingDetail: false,
    holdingDetail: [],
    loadingHoldingCodComitenteDetail: false,
    holdingCodComitenteDetail: [],
    loadingCurrentAccount: false,
    currentAccount: [],
    loadingTicketByNum: false,
    ticketByNum: undefined,
    loadingTicket: false,
    ticket: [],
    loadingLiquidation: false,
    liquidation: [],
    errorCode: null,
    errorMessage: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    a_setCurrency(state, action) {
        state.currencyOption = action.payload
    },
    setHoldingEmpty(state) {
      state.holding = []
    }
  },
  extraReducers: (builder) => {
    // HOLDING
    builder.addCase(api_getHolding.pending, (state) => {
      state.loadingHolding = true;
    });
    builder.addCase(api_getHolding.fulfilled, (state, action) => {
      state.loadingHolding = false;
      state.holding = action.payload
    });
    builder.addCase(api_getHolding.rejected, (state, action) => {
      state.loadingHolding = false;
      state.errorCode = action.errorCode;
      state.errorMessage = action.errorMessage;
    });
    // // HOLDING CODCOMITENTE
    builder.addCase(api_getHoldingClient.pending, (state) => {
      state.loadingHoldingCodComitente = true;
    });
    builder.addCase(api_getHoldingClient.fulfilled, (state, action) => {
      state.loadingHoldingCodComitente = false;
      state.holdingCodComitente = action.payload;
    });
    builder.addCase(api_getHoldingClient.rejected, (state) => {
      state.loadingHoldingCodComitente = false;
    });
    // HOLDING DETAIL
    builder.addCase(api_getHoldingDetail.pending, (state) => {
      state.loadingHoldingDetail = true;
    });
    builder.addCase(api_getHoldingDetail.fulfilled, (state, action) => {
      state.loadingHoldingDetail = false;
      state.holdingDetail = action.payload;
    });
    builder.addCase(api_getHoldingDetail.rejected, (state, action) => {
      state.loadingHoldingDetail = false;
      state.error = action.error;
    });
    // // HOLDING CODCOMITENTE DETAIL
    builder.addCase(api_getHoldingClientDetail.pending, (state) => {
      state.loadingHoldingCodComitenteDetail = true;
    });
    builder.addCase(api_getHoldingClientDetail.fulfilled, (state, action) => {
      state.loadingHoldingCodComitenteDetail = false;
      state.holdingCodComitenteDetail = action.payload;
    });
    builder.addCase(api_getHoldingClientDetail.rejected, (state) => {
      state.loadingHoldingCodComitenteDetail = false;
    });
  },
});
export const {
    a_setCurrency,
    setHoldingEmpty,
  } = walletSlice.actions;
export const walletReducer = walletSlice.reducer;
export const walletActions = walletSlice.actions;