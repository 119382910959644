import React from "react";

// ant design
import { Button } from "antd";

// utils
import Check from "../../utils/MDI - check-decagram.svg";

// styles
import "./sent-successfully.scss";
import "./recover-user-pass.scss";

export const SetSuccessfully = ({ backToLogin }) => {
    return (
        <div className="container-full-sent-successfully">
            <div className="sent-successfully">
                <img
                    className="logo-check"
                    src={Check}
                    alt="logo"
                />
                <h1 className="title-sent-successfully">
                    Consulta enviada exitosamente
                </h1>
                <p className="text-sent-successfully">
                    En la brevedad nos pondremos en contacto con usted.
                </p>
                <Button className="btn-to-login" onClick={backToLogin} block="true">
                    Volver al Login
                </Button>
            </div>
        </div>
    );
};
