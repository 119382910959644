import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, DatePicker } from "antd";
import { Spinner } from "../../../../components/spinner/spinner";
import { NoData } from "../../../../components/no-data/no-data";
import iconReload from "../../../../utils/icon-reload-blue.svg";
import "./operate-process-finished-orders.scss";

import {
  twoDecimalsFormat,
  getTermType,
  cantByInst,
  getValueWithCodMoneda,
} from "../../operate-service";

import moment from "moment";
import { api_getOperateFailOrderListFdo, api_getOperateFailOrderListMarket } from "../../operate-slice";

export const OperateProcessOrdersFinalizadas = () => {

  const {selectedClient, loadingFailOrderListFdo,loadingFailOrderListMarket, failOrderListFdo,failOrderListMarket, account } = useSelector((state) => state.operate);
  const dispatch = useDispatch();

  let d = new Date();
  d.setMonth(d.getMonth() + 1);
  let dateEnd = d.toISOString().slice(0, 10);
  d.setMonth(d.getMonth() - 2);
  let dateInit = d.toISOString().slice(0, 10);

  const [startDate, setStartDate] = useState(new Date(dateInit.replace(/-/g, "/")));
  const [endDate, setEndDate] = useState(new Date(dateEnd.replace(/-/g, "/")));
  const [optionItem, setOptionItem] = useState("ORDENES APROBADAS");
  const [combinedFailOrderList, setCombinedOrders] = useState([])


  useEffect( ()=> {
    setCombinedOrders([...failOrderListFdo, ...failOrderListMarket])
}, [selectedClient.codComitente, failOrderListFdo, failOrderListMarket])

  const isLoading = loadingFailOrderListFdo || loadingFailOrderListMarket;

  const tabOptions = [
    { id: 0, option: "ORDENES APROBADAS", state: "COMPLETADA" },
    { id: 1, option: "ORDENES RECHAZADAS", state: "RECHAZADA,EXPIRADA,NOENVIADA" },
    { id: 2, option: "ORDENES CANCELADAS", state: "CANCELADA" },
  ];
  useEffect(() => {
    if (account && account.info && selectedClient.codComitente) {
      dispatch(api_getOperateFailOrderListFdo({
        codComitente: selectedClient.codComitente,
        FechaInicio: startDate.toISOString().slice(0, 10),
        FechaFin: endDate.toISOString().slice(0, 10),
        Estados: tabOptions.find(o => o.option === optionItem).state.split(",")
      }))
      dispatch(api_getOperateFailOrderListMarket({
        codComitente: selectedClient.codComitente,
        FechaInicio: startDate.toISOString().slice(0, 10),
        FechaFin: endDate.toISOString().slice(0, 10),
        Estados: tabOptions.find(o => o.option === optionItem).state.split(",")
      }))
    }
  }, [dispatch, account, startDate, endDate, optionItem, selectedClient.codComitente]);

  const formatDataForTable =  
  combinedFailOrderList.map(order => ({
      ...order,
      fechaConcertacion:  order.fechaConcertacion ? order.fechaConcertacion.substring(0, 10) : 'N/A',
      detalle: order.compraVenta === "C" ? "COMPRA" : "VENTA",
      plazo: getTermType(order.plazo),
      precio: twoDecimalsFormat(order.precio),
      importe: twoDecimalsFormat(order.importe),
      cantByInst: cantByInst(order),
  }))
  
  const getFilteredData = 
  formatDataForTable.filter(order => 
    tabOptions.find(o => o.option === optionItem).state.split(",").includes(order.estado) && 
    new Date(order.fechaConcertacion) >= startDate &&
    new Date(order.fechaConcertacion) <= endDate
  )
  

  const resetDateRange = () => {
    setStartDate(new Date(dateInit.replace(/-/g, "/")));
    setEndDate(new Date(dateEnd.replace(/-/g, "/")));
  };

  const handleTabChange = (option) => {
    setOptionItem(option.option);
    resetDateRange();
  };

  const handleRangeDates = (range) => {
    if (range) {
      if (range[0] && range[1]) {
        setStartDate(moment(range[0]).toDate());
        setEndDate(moment(range[1]).toDate());
      }
    } else {
      resetDateRange();
    }
  };
  

  const columns = [
    {
      title: "Nº",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Concertación",
      dataIndex: "fechaConcertacion",
      key: "fechaConcertacion",
    },
    {
      title: "Cuenta",
      dataIndex: "comitente",
      key: "comitente",
    },
    {
      title: "Detalle",
      dataIndex: "detalle",
      key: "detalle",
    },
    {
      title: "Especie",
      dataIndex: "instrumentoAbrev",
      key: "instrumentoAbrev",
    },
    {
      title: "Precio",
      dataIndex: "",
      key: "",
      render: (data) => getValueWithCodMoneda(data.codMoneda, data.precio),
    },
    {
      title: "Plazo",
      dataIndex: "plazo",
      key: "plazo",
    },
    {
      title: "Cantidad",
      dataIndex: "cantByInst",
      key: "cantByInst",
    },
    {
      title: "Neto",
      dataIndex: "",
      key: "",
      render: (data) => (
        <div className={data.detalle === "COMPRA" ? "compra" : "venta"}>
          {getValueWithCodMoneda(data.codMoneda, data.importe)}
        </div>
      ),
    },
    {
      title: "Lote",
      dataIndex: "lote_id",
      key: "lote_id",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
    },
  ];
  return (
    <div className="ordenes-finalizadas">
      <div className="ordenes-finalizadas-header">
        <div className="operate-dashboard-filter-tabs">
          <div className="operate-sub-items-container">
            {tabOptions.map((option, i) => (
              <div key={i} className={`sub-items ${optionItem === option.option ? "selected-sub-item" : ""}`}
                onClick={() => handleTabChange(option)}>
                {option.option}
              </div>
            ))}
          </div>
        </div>
        <div className="ordenes-finalizadas-header-selections">
          <div className="datepickers">
            <DatePicker.RangePicker placeholder={["Desde", "Hasta"]} onChange={handleRangeDates} />
          </div>
          <div className="operate-process-orders-reload-button-container">
            <img src={iconReload} alt="reload" onClick={() => handleTabChange(tabOptions.find(o => o.option === optionItem))} 
                 className={isLoading ? "operate-process-orders-reload-icon-disabled" : "operate-process-orders-reload-icon"} />
          </div>
        </div>
      </div>
      <div className="ordenes-finalizadas-table-pendientes">
        {isLoading ? (
          <Spinner />
        ) : getFilteredData?.length === 0 ? (
          <NoData text="No hay últimos movimientos disponibles." />
        ) : (
          <Table dataSource={getFilteredData} columns={columns} />
        )}
      </div>
    </div>
  );
};

const OrderDetail = ({ process, activeDetailPendientesElement }) => {
  const getCurrencyOrder = (order) => {
    return order.CodMoneda === 1 ? "ARS" : "USD";
  };

  return (
    <div className="ordenes-finalizadas-table-pendientes-element">
      <div className="data">
        <div className="con">{process.FechaConcertacion.substring(0, 11)}</div>
        <div className="com">{process.Comitente}</div>
        <div
          className="det"
          onClick={() => activeDetailPendientesElement(process)}
        >
          {process.CompraVenta === "C" ? "COMPRA" : "VENTA"}
        </div>
        <div
          className="inst"
          onClick={() => activeDetailPendientesElement(process)}
        >
          {process.InstrumentoAbrev}
        </div>
        <div className="pre">
          {getCurrencyOrder(process)}{" "}
          {parseFloat(
            process.Precio ? process.Precio.replace(",", ".") : 0
          ).toFixed(2)}
        </div>
        <div className="plaz">{getTermType(process?.Plazo)}</div>
        <div className="can">{cantByInst(process)}</div>
        <div className="neto">
          {getCurrencyOrder(process)}{" "}
          {parseFloat(
            process.importeNeto ? process.importeNeto.replace(",", ".") : 0
          ).toFixed(2)}
        </div>
        <div className="neto" style={{ color: "#ff0000" }}>
          {process.Lote ? process.Lote : "ID N/A"}
        </div>
        <div className="estado">{process.Estado}</div>
      </div>
    </div>
  );
};
