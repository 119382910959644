import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LO from "../../utils/LO.png";
import point from "../../utils/Elipse 238.svg";
import { viewInformation } from "../../libs/libsUserProfile";

import "./side-bar.scss";

export const SideBar = () => {
  // const state = useSelector((state) => state["commonReducer"]);
  // const {  loadingUserLogged, userLogged } = state;
  const { routes, currentUrl, loadingUserLogged } = useSelector(
    (state) => state.common
  );
  const {partnerInfo} = useSelector((state) => state.home);    
  
  return (
    <div className={`sidebar-container`}>
      <div className="sidebar-logo">
        <div className="header-logo">
          <div class="circle-partner-letter">{partnerInfo.fullName?.charAt(0).toUpperCase()}</div>
        </div>
      </div>

      <div className="sidebar-items">
        {routes.map((item, i) =>
          !loadingUserLogged &&
          viewInformation(item, partnerInfo)["visibility"] ? (
            <Item
              selectedItem={currentUrl}
              item={item}
              key={i}
             
            />
          ) : undefined
        )}
      </div>
    </div>
  );
};

const Item = ({ item, selectedItem, expanded }) => {
  const isSelected = (item, selected) => item.id === selected;

  return (
    <Link data-testid={item.dataTestId} to={item.url} className="link-router-dom">
      <div className="item-container">
        <div className="item-icon">
          <img
            className="item-icon-image"
            src={isSelected(item, selectedItem) ? item.imgSelected : item.image}
            alt="icon"
          />
          {!expanded && (
            <div className="item-icon-text">
              <div className={isSelected(item, selectedItem) ? "selected" : ""}>
                {item.title}
              </div>
              {isSelected(item, selectedItem) && (
                <div className="selected3">
                  <img src={point} />
                </div>
              )}
            </div>
          )}
        </div>

        {expanded && isSelected(item, selectedItem) && (
          <div className="mask-border" />
        )}
      </div>
    </Link>
  );
};
