import React from 'react';
import './market-ticker-detail-modal.scss';
import { useSelector } from 'react-redux';

import { Tabs } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { codLiquidityTermToName } from '../../market-service';
import BaseModal from '../../../../components/modal/base-modal';
import AlternativeModal from '../../../../components/alternativeModal/alternative-modal';
import { Spinner } from '../../../../components/spinner/spinner';
import { WalletPieChart } from '../modal-wallet-piechart/wallet-pie-chart';
import { MarketHistoryChart } from '../market-detail-history-chart/market-history-chart';
import ModalTickerTitle from './modal-ticker-title';
import QuotationTabInfo from './quotation-tab-info';
import DetailTabInfo from './detail-tab-info';
import { twoDecimalsFormat } from '../../../../common/common-service';

const { TabPane } = Tabs;

export const TickerDetailModal = ({ closeModal }) => {
  const state = useSelector((state) => ({
    ...state['marketReducer'],
    ...state['commonReducer'],
    ...state['operateReducer'],
  }));

  const {
    // specieInfo,
    // loadingSpecieInfo,
    // tickerHist,
  } = state;

  const { specieInfo, loadingSpecieInfo } = useSelector(
    (state) => state.operate
  );
  const { tickerHist } = useSelector((state) => state.market);

  const title = 'Ampliar información';

  return (
    <AlternativeModal onClose={closeModal} title={title}>
      <Tabs defaultActiveKey={1}>
        <TabPane tab="COTIZACIONES" key={1}>
          {loadingSpecieInfo && !specieInfo ? (
            <Spinner />
          ) : (
            <div className="ticker-detail-modal-body">
              <ModalTickerTitle
                tickerTitle={specieInfo.ticker}
                isFisic={specieInfo?.ES_FISICO}
              />
              <MarketHistoryChart width={200} height={60} />
              <QuotationTabInfo
                currency={specieInfo?.CURRENCY}
                profileType={specieInfo?.PROFILE}
                liquidityTerm={codLiquidityTermToName(
                  specieInfo?.PLAZORESC
                )}
                variation={parseFloat(
                  specieInfo?.marketData.DAY_PERCENTAGE_CHANGE
                ).toFixed(2)}
                worth={specieInfo?.WORTH}
                minimum={specieInfo?.MINIMO_INVERSION}
              />
              <div className="ticker-detail-modal-bottom">
                <div className="ticker-detail-modal-bottom-title">
                  <p className="">Precio Anual</p>
                  <p className="ticker-detail-modal-bold-text">
                    {`AR$ ${twoDecimalsFormat(
                      tickerHist?.[0]?.price?.marketData?.NOW_PRICE
                    )} - 
                                        AR$ ${twoDecimalsFormat(
                                          tickerHist?.[
                                            tickerHist.length - 1
                                          ]?.price?.marketData?.NOW_PRICE
                                        )}`}
                  </p>
                </div>
                {/* <button
                                    className="adaptable-button"
                                >
                                    Operar Fondo
                                </button> */}
              </div>
            </div>
          )}
        </TabPane>
        <>
          <TabPane tab="CARTERA" key={2}>
            {loadingSpecieInfo && !specieInfo ? (
              <Spinner />
            ) : (
              <div className="ticker-detail-modal-body">
                <ModalTickerTitle
                  tickerTitle={specieInfo.ticker}
                  isFisic={specieInfo?.ES_FISICO}
                />
                <div className="ticker-detail-data">
                  <WalletPieChart specieInfo={specieInfo} />
                </div>
                <div className="ticker-detail-modal-bottom">
                  <div className="ticker-detail-modal-bottom-title">
                    <p className="">Patrimonio total del fondo</p>
                    <p className="ticker-detail-modal-bold-text">
                      {`${
                        specieInfo?.CURRENCY
                      } ${twoDecimalsFormat(specieInfo?.WORTH)}`}
                    </p>
                  </div>
                  {/* <button className="adaptable-button">Operar Fondo</button> */}
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab="DETALLE" key={3}>
            {loadingSpecieInfo && !specieInfo ? (
              <Spinner />
            ) : (
              <div className="ticker-detail-modal-body">
                <ModalTickerTitle
                  tickerTitle={specieInfo.ticker}
                  isFisic={specieInfo?.ES_FISICO}
                />
                <DetailTabInfo
                  estrategia={specieInfo?.ESTRATEGIA}
                  objetivo={specieInfo?.OBJETIVO}
                  depositaria={specieInfo?.AGENTE_CUSTODIA}
                />
                {/*      <div className="ticker-detail-modal-bottom">
                                    <a href="#" className="fact-sheet-link">
                                        <FilePdfOutlined /> Fact sheet
                                    </a>
                                     <button
                                        className="adaptable-button"
                                    >
                                        Operar Fondo
                                    </button> 
                                </div> */}
              </div>
            )}
          </TabPane>
        </>
      </Tabs>

      {/* <Tabs defaultActiveKey={1}>
                <TabPane
                    tab="COTIZACIONES"
                    key={1}
                >
                    {loadingSpecieInfo && !specieInfo ? <Spinner /> :
                        <div className="ticker-detail-modal-body">
                            <ModalTickerTitle
                                tickerTitle={specieInfo.ticker}
                                isFisic={specieInfo?.referenceData.ES_FISICO}
                            />
                            <MarketHistoryChart
                                width={200}
                                height={60}
                            />
                            <QuotationTabInfo
                                currency={specieInfo?.referenceData.CURRENCY}
                                profileType={specieInfo?.referenceData.PROFILE}
                                liquidityTerm={codLiquidityTermToName(specieInfo?.referenceData.PLAZORESC)}
                                variation={parseFloat(specieInfo?.marketData.DAY_PERCENTAGE_CHANGE).toFixed(2)}
                                worth={specieInfo?.referenceData.WORTH}
                                minimum={specieInfo?.referenceData.MINIMO_INVERSION}
                            />
                            <div className="ticker-detail-modal-bottom">
                                <div className="ticker-detail-modal-bottom-title">
                                    <p className="">Precio Anual</p>
                                    <p className="ticker-detail-modal-bold-text">
                                        {`AR$ ${tickerHist?.message[0]?.price?.marketData?.NOW_PRICE} - 
                                        AR$ ${tickerHist?.message[tickerHist.message.length - 1]?.price?.marketData?.NOW_PRICE}`}
                                    </p>
                                </div>
                                <button
                                    className="adaptable-button"
                                >
                                    Operar Fondo
                                </button>
                            </div>
                        </div>
                    }
                </TabPane>
                <>
                    <TabPane
                        tab="CARTERA"
                        key={2}
                    >
                        {loadingSpecieInfo && !specieInfo ? <Spinner /> :
                            <div className="ticker-detail-modal-body">
                                <ModalTickerTitle
                                    tickerTitle={specieInfo.ticker}
                                    isFisic={specieInfo?.referenceData.ES_FISICO}
                                />
                                <div className="ticker-detail-data">
                                    <WalletPieChart specieInfo={specieInfo} />
                                </div>
                                <div className="ticker-detail-modal-bottom">
                                    <div className="ticker-detail-modal-bottom-title">
                                        <p className="">Patrimonio total del fondo</p>
                                        <p className="ticker-detail-modal-bold-text">
                                            {`${specieInfo?.referenceData.CURRENCY} ${specieInfo?.referenceData.WORTH}`}
                                        </p>
                                    </div>
                                    <button
                                        className="adaptable-button"
                                    >
                                        Operar Fondo
                                    </button>
                                </div>
                            </div>}
                    </TabPane>
                    <TabPane
                        tab="DETALLE"
                        key={3}
                    >
                        {loadingSpecieInfo && !specieInfo ? <Spinner /> :
                            <div className="ticker-detail-modal-body">
                                <ModalTickerTitle
                                    tickerTitle={specieInfo.ticker}
                                    isFisic={specieInfo?.referenceData.ES_FISICO}
                                />
                                <DetailTabInfo
                                    estrategia={specieInfo?.referenceData.ESTRATEGIA}
                                    objetivo={specieInfo?.referenceData.OBJETIVO}
                                    depositaria={specieInfo?.referenceData.AGENTE_CUSTODIA}
                                />
                                <div className="ticker-detail-modal-bottom">
                                    <a href="#" className="fact-sheet-link">
                                        <FilePdfOutlined /> Fact sheet
                                    </a>
                                    <button
                                        className="adaptable-button"
                                    >
                                        Operar Fondo
                                    </button>
                                </div>
                            </div>
                        }
                    </TabPane>
                </>
            </Tabs> */}
    </AlternativeModal>
  );
};
