import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { api_postTeamUploadBill } from '../../team-api';

import Input from '../forms/input';
import InputFile from '../forms/input-file';

import BaseModal from '../../../../components/modal/base-modal';
import NewButton from '../../../../components/new-button/new-button';

import './team-modals.scss';

const AddReceipt = (props) => {

  const [period, setPeriod] = useState('');
  const [encodeFile, setEncodeFile] = useState('');

  const state = useSelector((state) => state["commonReducer"]);

  const { userLogged } = state;

  const dispatch = useDispatch();

  const uploadBill = () => {
    try {
      let params =  {
          'producerName': userLogged.fullName,
          'emailSender': userLogged.email,
          period,
          encodeFile
      };
      if (period !== '' && encodeFile !== '') {
        dispatch(api_postTeamUploadBill(params));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handlePeriod = (event) => {
    const { value } = event.target;
    let dte = value.split('-');
    let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    setPeriod(`${months[dte[1] - 1]} ${dte[0]}`);
  }

  const handleInputFileChanges = async (event) => {
    const { files } = event.target;
    if (files.length > 0 && (files[0].type === 'application/pdf')) {
      try {
        let base64 = await getBase64(files[0]);
        setEncodeFile(base64);
      } catch (e) {
        alert('No se pudo cargar el archivo seleccionado');
      }
    } else if (files[0]?.type !== 'application/pdf') {
      alert('Solo se pueden adjuntar archivos PDF');
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.replace('data:application/pdf;base64,', ''));
      reader.onerror = error => reject(error);
    });
  }

  return (
    <BaseModal onClose={props.onClose}>
        <div className="team-modal-container">
            <h1 className="left-modal-title">Carga tu factura</h1>
            <span className="modal-text">A continuación seleccione el mes y adjunte el documento correspondiente a la factura.</span>
            
            <div className="row">
                <Input type="month" placeholder="Seleccionar mes" className="col-md-6" required onChange={handlePeriod} />
                <InputFile className="col-md-6" onChange={handleInputFileChanges} inputIcon={(encodeFile !== '') ? 'icon-input icon-paperclip' : undefined} inputText={(encodeFile !== '') ? 'Archivo Adjuntado' : undefined} />
            </div>

            <div className="info-container">

            </div>

            <NewButton className="new-btn-primary" onClick={() => uploadBill()} disabled={(encodeFile === '' || period === '')}>
                Guardar Cambios
            </NewButton>

        </div>
    </BaseModal>
  );
};

AddReceipt.defaultProps = {
  onClose: () => console.log('close action: add-receipt')
}

export default AddReceipt;
