import React from "react";

import "./tooltip.scss";

export const Tooltip = ({ text, iconSrc}) => {
  return (
    <div className="tooltip-wrapper">
      <p data-tooltip={text}>
        <img className="tooltip" src={iconSrc } alt="tooltip" />
      </p>
    </div>
  );
};
