import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Form } from 'antd';

import { api_requestPasswordRecovery, setEmailRecoverPassword, setUserReveryPassword } from '../../../common/common-slice';

import '../recover-user-pass.scss';

const VerifyUserEmail = ({ backToLogin }) => {
  const dispatch = useDispatch();
  const { recoverPassVerify, errorMessage } = useSelector((state) => state['common']);
  const handleUserEmail = async ({ user, email }) => {
   await dispatch(api_requestPasswordRecovery({ user, email }));
   await dispatch(setEmailRecoverPassword(email))
   await dispatch(setUserReveryPassword(user))
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <h3 className="subtitle">Recuperar clave de ingreso</h3>
      <p className="recover-password-desrcription">
        Por favor, introduce el correo electrónico registrado en tu cuenta. Te
        enviaremos un código de acceso.
      </p>

      {recoverPassVerify?.statusCode === true && !errorMessage && (
        <div className="msg-success">
          ¡El código de usuario fue enviado a su correo electrónico!
        </div>
      )}

      {recoverPassVerify === null && !errorMessage && (
        <div className="msg-error">
          Ha ocurrido un error, por favor inténtelo nuevamente.
        </div>
      )}

      {errorMessage && (
        <div className="msg-error">
        {errorMessage}
      </div>
      )}

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleUserEmail}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="user"
          className="recover-user-form"
          rules={[
            {
              type: "string",
              required: true,
              message: 'Ingrese nombre de usuario / codigo de referido',
            },
          ]}
        >
          <Input
            placeholder="Nombre de usuario *"
            className="input-text"
            block="true"
          />
        </Form.Item>

        <Form.Item
          name="email"
          className="recover-user-form"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Introduzca una dirección de correo electrónico válida!',
            },
          ]}
        >
          <Input
            placeholder="Dirección de correo electrónico *"
            className="input-text"
            block="true"
          />
        </Form.Item>

        <div className="message-container">
          <p className="message">
            * El correo electrónico ingresado deberá coincidir con el registrado
            en el usuario.
          </p>
        </div>

        <div className="btn-container">
          <Button className="btn-to-login" onClick={backToLogin} block="true">
            VOLVER A LOGIN
          </Button>
          <Button className="btn-recover-submit" htmlType="submit" block="true">
            RECUPERAR
          </Button>
        </div>
      </Form>
    </>
  );
};

VerifyUserEmail.defaultProps = {};

export default VerifyUserEmail;
