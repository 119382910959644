import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";

import { Dashboard } from "./views/dashboard/dashboard";
import { Login } from "./views/login/login";
//import { Maintenance } from "./views/maintenance/maintenance";
// import { Spinner } from "../src/components/spinner/spinner";
import "./scss/reset.scss";
import "./scss/grid.scss";
import "./App.css";
import { setLogIn, setSignInData } from "./common/common-slice";
import { logout, setLogOut } from "./common/common-slice";

export const App = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.common.isLogged);
  const expiresIn = localStorage.getItem("userData");
  const now = Math.floor(new Date().getTime() / 1000);
  const dateLogin = localStorage.getItem("logIn");
  const expirationTime = expiresIn && parseInt(dateLogin, 10) + parseInt(expiresIn, 10); // Suma expiresIn a now en segundos

  useEffect(() => {
    ReactGA.initialize("UA-218087923-300675477");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      dispatch(setSignInData(userData));
      dispatch(setLogIn());
    }
  }, []);

  useEffect(() => {
    if (expirationTime && now >= expirationTime) {
        dispatch(logout());
        dispatch(setLogOut());
    }
  },[now >= expirationTime])

  return (
    <div>
      <Router>
        {isLogged ? <Dashboard /> : <Login />}
      </Router>
    </div>
  );
};
 