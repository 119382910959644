import React from 'react';

import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

// https://pqina.nl/filepond/docs/api/instance/properties/

import './forms.scss';

const InputFile = ({ className, labelIdle }) => {
    return (
        <div className={"form-container " + className}>
            <FilePond
                allowMultiple={true}
                // maxFiles={1}
                labelIdle={labelIdle}
            />
        </div> 
    )
}

InputFile.defaultProps = {
    labelIdle: `Arrastra el archivo aqui <br/>o <span className="filepond--label-action">Buscar Archivo</span>`,
    className: '',
    onChange: () => console.log('onChange input')
}

export default InputFile