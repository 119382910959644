import React from 'react';
import { useSelector } from 'react-redux';
import { twoDecimalsFormat } from '../../../../common/common-service';

import iconAR from '../../../../utils/AR-ICON.svg';
import iconEU from '../../../../utils/EU-ICON.svg';

const EurArsQuotationCard = (props) => {
  const { quotationDollar, loadingQuotationDollar } = useSelector(
    (state) => state.market
  );

  return loadingQuotationDollar ? (
    <div className="container-list">
      <div className="quotation-item-container" />
    </div>
  ) : (
    <div className="container-list">
      <div className="quotation-item-container">
        <div className="quotation-item-title">
          <span className="quotation-icon-container">
            <img className="quotation-icon" src={iconEU} alt="icon price" />
          </span>
          <div className="quotation-text">
            <div className="quotation-symbol">EURARS</div>
            <div className="quotation-description">EURO</div>
          </div>
        </div>
        <div className="quotation-item-price-container">
          <p className="quotation-item-price">${String(quotationDollar.EUR)}</p>
        </div>
      </div>
    </div>
  );
};

EurArsQuotationCard.defaultProps = {};

export default EurArsQuotationCard;
