import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../../components/spinner/spinner";

import { a_setDataModalCancel } from "../../operate-action";

import {
  api_postOperateCancelOrder,
  api_postOperateCancelFund,
  api_getOperateLotList,
} from "../../operate-api";

import iconCerrarIcon from "../../../../utils/icon-close.svg";
import fileIcon from "../../../../utils/file-icon.svg";
import mensajeCancelarIcon from "../../../../utils/icon-mensaje-cancelar.svg";
import mensajeExitoIcon from "../../../../utils/icon-mensaje-exito.svg";

import "./modal-cancel-process.css";

export const ModalCancelProcess = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => ({
    ...state["commonReducer"],
    ...state["operateReducer"],
  }));
  const {
    proccesData,
    loadingOperateCancelOrder,
    operateCancelOrder,
    loadingOperateCancelFund,
    operateCancelFund,
  } = state;

  useEffect(() => {
    if (proccesData) {
      setModalState(true);
    }
  }, [proccesData]);

  const [modalState, setModalState] = useState(false);

  const closeModal = () => {
    setModalState(false);
    dispatch(a_setDataModalCancel(null));
  };

  const [modalCancelingState, setModalCancelingState] = useState(false);

  const cancelProcess = () => {
    if (proccesData.CodFondo) {
      dispatch(
        api_postOperateCancelFund({
          codRequestFCI: proccesData["CodSolicitudFdo"],
        })
      );
    } else {
      dispatch(
        api_postOperateCancelOrder({ CodOrden: proccesData["CodOrden"] })
      );
    }
    setModalCancelingState(true);
  };

  const closeCancelingModal = () => {
    closeModal();
    setModalCancelingState(false);
  };

  return (
    <div
      className={`modal-cancel-general-container ${
        modalState ? "show-modal-cancel" : "hide-modal-cancel"
      }`}
    >
      {!modalCancelingState ? (
        <div className="modal-cancel-general-wrapper">
          <div className="modal-cancel-x-container">
            <div className="modal-cancel-close-container">
              <img
                className="modal-cancel-close-icon"
                src={iconCerrarIcon}
                alt="..."
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
          </div>
          <div className="modal-cancel-general-image-section">
            <div className="modal-cancel-image-container">
              <img
                className="modal-cancel-image-icon"
                src={fileIcon}
                alt="..."
              />
            </div>
          </div>
          <div className="modal-cancel-title-container">
            <div className="modal-cancel-title-text">
              ¿Deseas cancelar esta orden?
            </div>
          </div>
          <div className="modal-cancel-information-container">
            <div className="modal-cancel-information-text">
              Retiraremos esta orden del mercado.
              <br />
              Esta accion no se puede deshacer.
            </div>
          </div>
          <div
            className="modal-cancel-button-container"
            onClick={() => {
              cancelProcess();
            }}
          >
            <div className="modal-cancel-button-box">
              <div className="modal-cancel-button-text">Cancelar la orden</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-cancel-general-wrapper">
          {loadingOperateCancelOrder && loadingOperateCancelFund ? (
            <div className="modal-cancel-loading-container">
              <div className="modal-cancel-loading-image">
                <Spinner />
              </div>
              <div className="modal-cancel-title-container">
                <div className="modal-cancel-title-text">
                  Cancelando la orden
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              {operateCancelOrder?.orderCanceled ||
              operateCancelFund?.cancelled ? (
                <div className="">
                  <div className="modal-cancel-general-image-section">
                    <div className="modal-cancel-image-container">
                      <img
                        className="modal-cancel-image-icon"
                        src={mensajeExitoIcon}
                        alt="..."
                      />
                    </div>
                  </div>
                  <div className="modal-cancel-title-container">
                    <div className="modal-cancel-title-text">
                      Orden cancelada
                    </div>
                  </div>
                  <div className="modal-cancel-information-container">
                    <div className="modal-cancel-information-text">
                      La orden fue cancelada correctamente.
                      <br />
                      En unos minutos la verás en órdenes canceladas.
                    </div>
                  </div>
                  <div
                    className="modal-cancel-button-container"
                    onClick={() => {
                      closeCancelingModal();
                    }}
                  >
                    <div className="modal-cancel-button-box">
                      <div className="modal-cancel-button-text">Aceptar</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className="modal-cancel-general-image-section">
                    <div className="modal-cancel-image-container">
                      <img
                        className="modal-cancel-image-icon"
                        src={mensajeCancelarIcon}
                        alt="..."
                      />
                    </div>
                  </div>
                  <div className="modal-cancel-title-container">
                    <div className="modal-cancel-title-text">
                      La orden no se pudo cancelar
                    </div>
                  </div>
                  <div className="modal-cancel-information-container">
                    <div className="modal-cancel-information-text">
                      Por favor, inténtalo nuevamente.
                    </div>
                  </div>
                  <div
                    className="modal-cancel-button-container"
                    onClick={() => {
                      closeCancelingModal();
                    }}
                  >
                    <div className="modal-cancel-button-box">
                      <div className="modal-cancel-button-text">Aceptar</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
