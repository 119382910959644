import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components actions and services
import { CardHoldings } from '../card-holdings/card-holdings';
import { StaticsProfile } from '../statics-profile/statics-profile';
import { SelectProfileCoin } from '../../../../components/selectProfileCoin/selectProfileCoin';
import { Subtitle, Title } from '../../../../components/titles/titles';
import { Spinner } from '../../../../components/spinner/spinner';
import { Tooltip } from '../../../../components/tooltip/tooltip';
import { NoData } from '../../../../components/no-data/no-data';
import { auth, checkRoleName } from '../../../../common/common-service';
import { formatClientHoldings } from '../../home-service';

// utils
import portIcon from '../../../../utils/portfolio-icon.svg';
import arrowLink from '../../../../utils/arrow-right.svg';

// styles
import './portfolio.scss';
import { TooltipWhite } from '../../../../components/tooltip/tooltip-white';
import tip from "../../../../utils/tip-icon.svg";

export const Portfolio = () => {

  const { loadingUserLogged, userPortfolio } = useSelector((state) => state.home);

  const [selectionCoin, setSelectionCoin] = useState({
    value: 0,
    description: 'ARS',
  });

  const optionsCoin = [
    { value: 0, description: 'ARS' },
    { value: 1, description: 'USD' },
  ];

  const setSelectionCoin2 = (opt) => setSelectionCoin(opt);

  const consolidadaToCard = () => {
    try {
      let consValue;
      const { value } = selectionCoin;
      switch (value) {
        case 0:
          consValue = formatClientHoldings(userPortfolio.consolidatedTenureArs);
          break;
        case 1:
          consValue = formatClientHoldings(userPortfolio.consolidatedTenureUsd);
          break;

        default:
          break;
      }
      return consValue;
    } catch (err) {
      return 0;
    }
  };

  return (
    <div className="portfolio-card">
      <div className="portfolio-card-header">
        <div className="portfolio-header-title">
          <img src={portIcon} alt="portfolio icon" />
          <Subtitle text="Mi Portfolio" type="dark" />
        </div>
      </div>

      {loadingUserLogged ? (
        <Spinner />
      ) : (
        <>
          {userPortfolio ? (
            <>
              <div className="portfolio-content">
                <div className="portfolio-content-tenencia">
                  <div className="title-tooltip-wrapper">
                    <span className="title-tooltip">Tenencia Consolidada</span>
                    <Tooltip iconSrc={tip} text="Es la sumatoria total del dinero disponible más el dinero invertido de todos tus comitentes" />
                  </div>
                  <div className="portfolio-content-tenencia-value-coins">
                    <Title type="purple">{consolidadaToCard()}</Title>
                    <SelectProfileCoin
                      options={optionsCoin}
                      selection={selectionCoin}
                      setSelectionCoin2={setSelectionCoin2}
                    />
                    {selectionCoin.description === 'ARS' ? (
                      <Tooltip iconSrc={tip} text="Suma de los totales invertidos de todos los clientes + Disponible ARS + Disponible USD * MEP" />
                    ) : (
                      selectionCoin.description === 'USD' && (
                        <Tooltip iconSrc={tip} text="(Suma de los totales invertidos de todos los clientes + Disponible ARS + Disponible USD * MEP) / MEP" />
                      )
                    )}
                  </div>
                </div>
                <StaticsProfile />
              </div>
              <div className="portfolio-vertical-separator">&nbsp;</div>
              <div className="portfolio-results">
                <CardHoldings
                  cardStyle="card--footer--portfolio--green"
                  description="Dinero disponible"
                  amountPesos={formatClientHoldings(
                    userPortfolio?.availableMoneyArs
                  )}
                  amountDollars={formatClientHoldings(
                    userPortfolio?.availableMoneyUsd
                  )}
                  dataTooltipARS={'Dinero disponible en pesos'}
                  dataTooltipUSD={'Dinero disponible en dólares'}
                />
                <CardHoldings
                  cardStyle="card--footer--portfolio--blue"
                  description="Total Invertido"
                  amountPesos={formatClientHoldings(
                    userPortfolio?.investedMoneyArs
                  )}
                  amountDollars={formatClientHoldings(
                    userPortfolio?.investedMoneyUsd
                  )}
                  dataTooltipARS={
                    'Suma de los totales invertidos de todos los clientes'
                  }
                  dataTooltipUSD={
                    'Suma de los totales invertidos de todos los clientes convertido a USD al valor del MEP'
                  }
                />
                {/* {auth('ROLE_BANZA', 'PERMISSION_FEE_HOME') &&
                checkRoleName('Director') ? (
                  <div className="card--footer--portfolio portfolio-fee-section">
                    <div className="card-profile">
                      <div className="card-profile-description">
                        Fee del Mes actual
                      </div>
                      <div className="portfolio-fee-info-container">
                        <div className="portfolio-fee-total-container">
                          {`ARS ${formatClientHoldings(
                            partnerInfo?.feeTotalMonth
                          )}`}
                          <TooltipWhite text="Honorarios generados este mes" />
                        </div>
                        <Link className="portfolio-fee-link" to={'/wallet'}>
                          <p className="portfolio-fee-link-text">Ver más</p>
                          <img src={arrowLink} alt="access link" />
                        </Link>
                      </div>
                    </div>
                    <div className="portfolio-fee-wrapper">
                        <div className="card-profile-description-fee">
                          Fee del Mes actual
                        </div>
                        <div className="portfolio-fee-info-wrapper">
                          <div className="portfolio-fee-total">
                            {`ARS ${formatClientHoldings(
                              userPortfolio?.feeTotalMonth
                            )}`}
                            <Tooltip text="Honorarios generados este mes" />
                          </div>
                          <Link className="portfolio-fee-link" to={"/wallet"}>
                            <img src={arrowLink} alt="access link" />
                            <div className="portfolio-fee-link-text">
                              Ver más
                            </div>
                          </Link>
                        </div>
                      </div>
                  </div>
                ) : (
                  undefined
                )} */}
              </div>
            </>
          ) : (
            <NoData text="No hay datos disponibles para mostrar" />
          )}
        </>
      )}
    </div>
  );
};
