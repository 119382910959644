import React from "react";
import iconSearch from "../../../../../utils/icon-search.svg";
import iconClean from "../../../../../utils/close-icon-gray.svg";
import "./input-text-search-clean.scss";

const SIZES = ["input--small--10", "input--medium--10", "input--large--100"];

const InputTextSearchClean = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  setCleanInput,
  inputSize,
}) => {
  const checkInputSize = SIZES.includes(inputSize) ? inputSize : SIZES[0];
  const hasValue = () => value !== undefined;
  const iconToShow = value ? iconClean : iconSearch;
  const iconAltText = value ? "clean" : "search";
  const onIconClick = value ? setCleanInput : null;

  return (
    <div className={`custom--search--clean--container-cartera ${hasValue() ? "no-empty" : ""} ${checkInputSize}`}>
      <input
        data-testid="input-search"
        name={name}
        className={`custom--search--clean--input`}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        value={value || ""}
      />
      <div className={`custom--search--clean--icon`}>
        <img src={iconToShow} alt={iconAltText} onClick={onIconClick} />
      </div>
    </div>
  );
};

export default InputTextSearchClean;
