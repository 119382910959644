import React, { useState, useEffect } from 'react';

import { Spinner } from '../../../../components/spinner/spinner';
import { PieChart } from '../market-pie-chart/pie-chart';
import { dataForPieChart } from '../../market-service';
import { dataForWallet } from '../../market-service';
import {twoDecimalsFormat} from "../../../../common/common-service";


export const DetailPieChart = ({ specieInfo }) => {

    const [detailChartData, setDetailChartData] = useState(false)
    const [dataItems, setDataItems] = useState(false)

    useEffect(() => {
        if (specieInfo?.PORTFOLIO) {
          setDetailChartData(dataForPieChart(specieInfo.PORTFOLIO));
          setDataItems(dataForWallet(specieInfo.PORTFOLIO));
        }
      }, [specieInfo]);

      if (!detailChartData || !dataItems) {
        return <p>No hay datos disponibles</p>;
      }

    return (
        !detailChartData ? <Spinner /> :
            <>
                <PieChart data={detailChartData} />
                {detailChartData && <div className="ticker-detail-list">
                    <div className="ticker-detail-list-small-title">
                        Valor Total Cartera
                    </div>
                    <div className="ticker-detail-ul-container">
                        <ul className="ticker-detail-ul">
                            <li key={1} className="ticker-detail-list-title">
                                {`${specieInfo?.CURRENCY} ${twoDecimalsFormat(specieInfo?.WORTH)}`}
                            </li>
                            {
                                dataItems?.map((item, i) => (
                                    i < 3 &&
                                    <li key={item.name} className="ticker-detail-list-item">
                                        <div className='ticker-detail-list-item-p'>
                                            <div className="colored-type-scuare" style={{ backgroundColor: item.color}} />
                                            {item.name}
                                        </div>
                                        <p className='ticker-detail-list-item-p-value'>{`${item.percentage}%`}</p>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>}
            </>
    );
};