import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { Tabs } from 'antd';
import { Spinner } from '../../../../components/spinner/spinner';
import { DetailTab } from '../ticker-card-detail-tab/detail-tab';
import { DetailWalletTab } from '../market-detail-card-wallet/detail-wallet-tab';
import { DetailQuotationTab } from '../detail-quotation-tab/detail-quotation-tab'
import { DetailCardButton } from '../market-detail-card-button/detail-card-button';
import { DetailFundQuotationTab } from '../market-detail-card-cotization-card/detail-fund-quotation-tab';
import { api_postByTickerHist } from '../../market-slice';

const { TabPane } = Tabs;

export const TickerDetailCard = ({ setModalOpen, instrumentNumber }) => {

    const dispatch = useDispatch();

    const state = useSelector((state) => ({
        ...state["commonReducer"],
        ...state["operateReducer"],
    }));

    const {
        // loadingSpecieInfo,
        // specieInfo,
    } = state;
    const { loadingSpecieInfo, specieInfo } = useSelector((state) => state.operate)
    useEffect(() => {
        const todaysDate = moment().format('YYYY-MM-DD');
        const fromDate = moment().subtract(3, 'months').format('YYYY-MM-DD');

        if (specieInfo.ticker) {
            dispatch(api_postByTickerHist({
                "ticker": specieInfo.ticker,
                "dateFrom": fromDate,
                "dateTo": todaysDate
            }))
        }
    }, [specieInfo])

    return (
        <div
            className="ticker-detail-container"
            
            /* {
                `ticker-detail-container 
                ${instrumentNumber === 0 ?
                    '' :
                    ''}`
            } */

            /* for the conditional */
            /* 'tabs-alignment-center' : 'tabs-alignment-left'} */

        >
            <Tabs defaultActiveKey={1}>
                {loadingSpecieInfo ? <Spinner /> :
                    <>
                        <TabPane tab="COTIZACIÓN" key={1}>
                            {instrumentNumber === 0 ?
                                <DetailFundQuotationTab />
                                :
                                <DetailQuotationTab />
                            }
                        </TabPane>
                        {instrumentNumber === 0 &&
                            <>
                                <TabPane tab="CARTERA" key={2}>
                                    <DetailWalletTab />
                                </TabPane>
                                <TabPane tab="DETALLE" key={3}>
                                    <DetailTab />
                                </TabPane>
                            </>
                        }
                    </>
                }
            </Tabs>
            {!loadingSpecieInfo && instrumentNumber === 0 && <DetailCardButton setModalOpen={setModalOpen} />}
        </div>
    )
};
