import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// constants
import { items } from '../../constants/constants';

// components
import { Navbar } from '../../components/navbar/navbar';
import { ModalInformation } from "../../components/modalInformation/modalInformation";
import { SideBar } from '../../components/sideBar/sideBar';
import { MainViews } from '../mainViews/mainViews';
import { NotificationError } from '../../common/common-components/team-notification-error/notification-error';

// actions and services
import {
  api_getCustomersByPartner,
  setRoutes,
  setSelectedRoute,
} from '../../common/common-slice';
import {
  api_getPartnerInformationExtensive,
  api_getPartnerInformationPersonal,
} from '../home/home-slice';

// styles
import './dashboard.scss';

export const Dashboard = () => {
  const { genFailSer, expanded, openBlur, routes } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(api_getPartnerInformationExtensive());
    dispatch(api_getCustomersByPartner());
    dispatch(setRoutes(items));
  }, [dispatch]);

  useEffect(() => {
    dispatch(api_getPartnerInformationPersonal());
  }, [dispatch]);

  useEffect(() => {
    const route = routes.find((r) => r.url === pathname);
    route && dispatch(setSelectedRoute(route.id));
  }, [routes, pathname, dispatch]);

  return (
    <div className={`main-container ${openBlur ? 'openBlur' : ''}`}>
      <div className={`first-container ${expanded ? 'expanded1' : ''} `}>
        <SideBar />
      </div>
      <div className="second-container">
        <Navbar />
        {/* <ModalInformation /> */}
        {genFailSer ? <NotificationError /> : undefined}
        <MainViews />
      </div>
      <BlurView />
    </div>
  );
};

const BlurView = () => {
  const state = useSelector((state) => state);
  const { openBlur } = state;
  return (
    <div
      className={`blur-view-container ${openBlur ? 'openProtectorBlur' : ''}`}
    ></div>
  );
};
