const mockData = [
    { date: '30/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Venta AY24', currency: 'Pesos', neto: { direction: '+', amount: '5555.53' }, status: 'ACEPTADO' },
    { date: '29/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Depósito', currency: 'Pesos', neto: { direction: '+', amount: '14.23' }, status: 'PENDIENTE' },
    { date: '30/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Extracción', currency: 'Pesos', neto: { direction: '-', amount: '55.53' }, status: 'CANCELADO' },
    { date: '19/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Depósito', currency: 'Dolar', neto: { direction: '-', amount: '1255.53' }, status: 'RECHAZADO' },
    { date: '30/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Extracción', currency: 'Dolar', neto: { direction: '+', amount: '3355' }, status: 'CANCELADO' },
    { date: '01/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Venta AY24', currency: 'Pesos', neto: { direction: '+', amount: '5555.53' }, status: 'ACEPTADO' },
    { date: '10/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Extracción', currency: 'Pesos', neto: { direction: '+', amount: '5555.53' }, status: 'ACEPTADO' },
    { date: '19/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Depósito', currency: 'Dolar', neto: { direction: '-', amount: '1255.53' }, status: 'RECHAZADO' },
    { date: '19/09/2021', client: 'Olivencia Federico Javier', account: '5789', tenencia: '5.679', detail: 'Depósito', currency: 'Pesos', neto: { direction: '+', amount: '1255.53' }, status: 'RECHAZADO' },
];

export default mockData