/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';

import {
  a_setCustomersByPartnerFiltered,
  setEspeciesOperate,
  reloadSearchData,
} from '../../common/common-slice';

import { TabBar } from './operate-components/tab-bar/tab-bar';
import { OperateMainCardView } from './operate-components/operate-main-card-view/operate-main-card-view';
import { OperateConfirmProcedure } from '../../views/operate/operate-components/operate-confirm-procedure/operate-confirm-procedure';
import { OperateInProgressOrders } from './operate-components/operate-inprogress-orders/operate-inprogress-orders';
import { OperateModalFinish } from '../../views/operate/operate-components/operate-modal-finish/operate-modal-finish';
import { OperateProcessOrdersFinalizadas } from '../operate/operate-components/operate-process-finished-orders.js/operate-process-finished-orders';
import { OperateDetailEspecie } from './operate-components/operate-detail-especie/operate-detail-especie';
import { ModalCancelProcess } from './operate-components/modal-cancel-process/modal-cancel-process';
import { ModalTimeOut } from './operate-components/modal-time-out/modal-time-out';

import {
  setCheckList,
  checkElement,
  unCheckElement,
  updateTableFields,
  selectInitialState,
  isTimeToOperate,
  onClickTapOption,
  getNewItem,
  getCurrentTerm,
  getPriceLimit,
  getClientWithAmountToShow,
  filterBySearches,
  getPriceByInstru,
  getSelectedClientQuantity,
  getSelectedClientPrice,
} from './operate-service';

import { getDate } from '../../common/common-service';
import { TERM_C } from '../../constants/constants';

import './operate-style.scss';
import './operate-components/operate-table/operate-table.scss';
import './operate-components/operate-table-edit-group/operate-table-edit-group.scss';
import './operate-components/operate-table-header/operate-table-header.css';
// import { LastOrder } from './operate-components/last-order/last-order';
import OperateSearch from './operate-components/operate-search/operate-search';
import {
  a_resetExpensesState,
  a_setInstrument,
  a_setLiquidityTerm,
  a_setOperateInstruments,
  a_setSpecieInfo,
  a_setTradingOption,
  api_getOperateExpenses,
  api_getOperateInstruments,
  api_getOperateSpecieInfo,
  api_postOperateGetEspecies,
  setSelectedClient,
} from './operate-slice';

export const Operate = () => {
  const dispatch = useDispatch();
  const {
    loadingSpecieInfo,
    specieInfo,
    operateInstruments,
    instrument,
    tradingOption,
    selectedClient,
    account,
  } = useSelector((state) => state.operate);
  const { tabOrderInProgress, customersByPartner } = useSelector(
    (state) => state.common
  );

  const [selectedInstrument, setSelectedInstrument] =
    useState(selectInitialState);
  const [selectedSubTypeInstrument, setSelectedSubTypeInstrument] =
    useState(selectInitialState);
  const [optionItem, setOptionItem] = useState(
    tabOrderInProgress === false ? 'OPERAR' : 'ORDENES EN PROCESO'
  );
  const [subOptionItem, setSubOptionItem] = useState(
    selectedInstrument['description'] === 'Fondos' ? 'SUSCRIBIR' : 'COMPRAR'
  );
  const [search, setSearch] = useState({
    name: '',
    codComitente: '',
  });
  const [listTerms, setListTerms] = useState(TERM_C);
  const [termNumber, setTermNumber] = useState(2);
  const [quantityOperateTable, setQuantityOperateTable] = useState('');
  const [limitOperateTable, setLimitOperateTable] = useState('');
  const [readyCardChange, setReadyCardChange] = useState(false);
  const [openConfirmProcedure, setOpenConfirmProcedure] = useState(false);
  const [selectedPendingProcess, setSelectedPendingProcess] = useState(null);
  const [tickerOrigen, setTickerOrigen] = useState(undefined);
  const [totalQuantity, setTotalQuantity] = useState('');
  const [totalImporte, setTotalImporte] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [limitPrice, setLimitPrice] = useState('');
  const [hasClientBeenSelected, setHasClientBeenSelected] = useState(false);

  const prevCodComitenteRef = useRef();
  const operate = () => {
    setOptionItem('OPERAR');
  };
  const ordersInProcess = () => {
    setOptionItem('ORDENES EN PROCESO');
    cancelProcedure();
  };

  const finishedOrders = () => {
    setOptionItem('ORDENES FINALIZADAS');
    cancelProcedure();
  };

  const [tabOptions, setTabOptions] = useState([
    {
      name: 'OPERAR',
      onClick: operate,
      visible: true,
      selected: true,
    },
    {
      name: 'ORDENES EN PROCESO',
      onClick: ordersInProcess,
      visible: true,
      selected: false,
    },
    {
      name: 'ORDENES FINALIZADAS',
      onClick: finishedOrders,
      visible: true,
      selected: false,
    },
  ]);
  /* ************************************************************************ */
  const resetOperateByTermLimitHours = () => {
    if (operateInstruments) {
      let instSelected = operateInstruments['instruments'].find((x) =>
        x['terms'].find((y) => y['checked'])
      );
      if (instSelected?.hasLimitHours) {
        let term = instSelected['terms'].find((x) => x['checked']);
        if (
          !isTimeToOperate(
            term['openingHour'],
            term['closingHour'],
            instrument === 'Fondos'
          )
        ) {
          chooseInstrument(instSelected);
        }
      }
    }
  };
  useEffect(() => {
    resetOperateByTermLimitHours();
    dispatch(
      a_setLiquidityTerm(Object.assign({ term: termNumber }, listTerms))
    );
    let cotizacion = getPriceLimit(
      specieInfo?.marketData,
      termNumber,
      subOptionItem,
      instrument
    );
    if (cotizacion === '0,0') {
      message.info(
        'No existe cotizacion para esta especie en el plazo seleccionado',
        3
      );
    }
    setLimitOperateTable(cotizacion);
  }, [termNumber])

  /* ************************************************************************ */
  /*°°°*/

  const selectTerm = (radio) => {
    setListTerms(setCheckList(radio, listTerms));
    setTermNumber(radio['id'] - 1);
    updatePriceForSelectedTerm(radio['id'] - 1);
  };

  const updatePriceForSelectedTerm = (termId) => {
    if (!specieInfo?.marketData) return;

    const termKey = `Plazo${termId}`;
    const marketData = specieInfo.marketData[termKey];

    if (marketData && selectedClient) {
      const newPrice = marketData.ASK_PRICE;
      dispatch(setSelectedClient({ ...selectedClient, price: newPrice }));
      setLimitOperateTable(newPrice);
    }
  };

  const onChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const recalculateAmount = () => {
      if (instrument !== 'Fondos') {
        let priceStr = (selectedClient.price || '')?.toString();
        let quantityStr = (selectedClient.quantity || '')?.toString();

        let price = parseFloat(priceStr?.replace(',', '.') || 0);
        let quantity = parseFloat(quantityStr?.replace(',', '.') || 0);

        let newAmount = price * quantity;
        let newAmountStr = newAmount?.toFixed(2)?.replace('.', ',');

        dispatch(
          setSelectedClient({ ...selectedClient, amount: newAmountStr })
        );
        setTotalImporte(newAmountStr);
      }
    };

    recalculateAmount();
  }, [selectedClient.quantity, selectedClient.price, instrument]);

  const onChangeQuantity = (valueQuantityStr) => {
    if (!selectedClient || !specieInfo?.marketData) return;

    let updatedClient = { ...selectedClient };

    let priceStr = selectedClient.price;
    let importeStr = '';

    let price = parseFloat(priceStr?.replace(',', '.'));
    let valueQuantity = parseFloat(valueQuantityStr?.replace(',', '.'));

    if (!isNaN(valueQuantity) && !isNaN(price)) {
      let importe = valueQuantity * price;

      updatedClient = {
        ...updatedClient,
        quantity: valueQuantityStr,
        amount: importe.toFixed(2)?.replace('.', ','),
      };

      importeStr = importe.toFixed(2)?.replace('.', ',');
    } else {
      updatedClient = {
        ...updatedClient,
        quantity: '0',
        amount: '0,00',
      };
    }

    dispatch(setSelectedClient(updatedClient));
    setQuantityOperateTable(valueQuantityStr);
    setTotalImporte(importeStr);
  };

  const calculateRescateTotal = () => {
    if (!selectedClient || !specieInfo?.marketData || !account) return;
  
    let price = parseFloat(getPriceByInstru(selectedInstrument['description'], specieInfo.marketData?.NOW_PRICE));
  
    let updatedClient = { ...selectedClient };
    if (subOptionItem === 'RESCATAR' && listTerms[4].checked) {
      const tenenciaInmediataFondo = account.tenenciaDisponible.find((fondo) => fondo.ticker === specieInfo.ticker)?.tenenciaInmediata;
  
      if (tenenciaInmediataFondo !== undefined) {
        const calculatedQuantity = tenenciaInmediataFondo / price;
        const calculatedAmount = tenenciaInmediataFondo;
        updatedClient = {
          ...updatedClient,
          quantity: calculatedQuantity.toFixed(6).toString()?.replace('.', ','),
          amount: calculatedAmount.toFixed(2).toString()?.replace('.', ','),
          countType: 'Rescate Total',
          price: price.toString()?.replace('.', ','),
        };
      }
    }
  
    dispatch(setSelectedClient(updatedClient));
    setQuantityOperateTable(updatedClient.quantity);
    setTotalImporte(updatedClient.amount);
  };
  

  const onChangeAmount = (valueAmount) => {
    if (!selectedClient || !specieInfo?.marketData || !account) return;
  
    let updatedClient = { ...selectedClient };
  
    let price = parseFloat(getPriceByInstru(selectedInstrument['description'], specieInfo.marketData?.NOW_PRICE));
    price = isNaN(price) ? 0 : price;
  
    if (subOptionItem === 'RESCATAR' && listTerms[4].checked) {
      const tenenciaInmediataFondo = account.tenenciaDisponible.find((fondo) => fondo.ticker === specieInfo.ticker)?.tenenciaInmediata || 0;
  
      const calculatedQuantity = tenenciaInmediataFondo / price;
      const calculatedAmount = tenenciaInmediataFondo;
      updatedClient = {
        ...updatedClient,
        quantity: calculatedQuantity ? '0,0000' : calculatedQuantity.toFixed(6)?.toString()?.replace('.', ','),
        amount: calculatedAmount ? '0,00' : calculatedAmount.toFixed(2)?.toString()?.replace('.', ','),
        countType: 'Rescate Parcial',
      };
    } else {
      valueAmount = parseFloat(valueAmount?.replace(',', '.'));
      const quantity = !isNaN(valueAmount) && valueAmount > 0 ? valueAmount / price : 0;
      updatedClient = {
        ...updatedClient,
        quantity: quantity.toFixed(6)?.toString()?.replace('.', ','),
        amount: !isNaN(valueAmount) ? valueAmount?.toString()?.replace('.', ',') : '0,00',
        countType: '',
        price: price.toFixed(2)?.toString()?.replace('.', ','),
      };
    }
  
    dispatch(setSelectedClient(updatedClient));
    setQuantityOperateTable(updatedClient.quantity);
    setTotalImporte(updatedClient.amount);
  };
  
  

  /* ************************************************************************ */

  const calculateAmount = (quantity, price) => {
    const numericQuantity = parseFloat(quantity);
    const numericPrice = parseFloat(price);
    return numericQuantity * numericPrice;
  };

  const onChangePrice = (valueImporte) => {
    if (!selectedClient || !specieInfo) return;

    let price = parseFloat(
      getPriceByInstru(
        selectedInstrument['description'],
        specieInfo.marketData?.NOW_PRICE
      )
    );
    let quantity = '';
    let amount = '';

    valueImporte = parseFloat(valueImporte?.replace(',', '.'));

    if (!isNaN(valueImporte) && !isNaN(price)) {
      quantity = valueImporte / price;
      amount = calculateAmount(quantity, price);

      dispatch(
        setSelectedClient({
          ...selectedClient,
          quantity: quantity,
          price: price,
          amount: amount,
        })
      );
    } else {
      dispatch(
        setSelectedClient({
          ...selectedClient,
          quantity: '',
          price: '',
          amount: '',
        })
      );
    }

    setLimitOperateTable(price);
    setQuantityOperateTable(quantity);
    setTotalImporte(amount);
  };
  
  
  
  
  /* ************************************************************************ */

  const handlePriceChange = (newPrice) => {
    newPrice = newPrice?.replace('.', ',');
    if (!isNaN(parseFloat(newPrice)) && isFinite(newPrice)) {
      setLimitPrice(newPrice);
      if (selectedClient.priceType === 'limit') {
        let updatedClient = { ...selectedClient, price: newPrice };
        dispatch(setSelectedClient(updatedClient));
      }
    }
  };

  const onChangeLimit = (value) => {
  if (!selectedClient) return;

  value = parseFloat(value?.replace(',', '.'));
  if (!isNaN(value)) {
    let updatedClient = { ...selectedClient, limit: value };
    if (selectedClient.quantityOperateTable) {
      let vpri = value * parseFloat(selectedClient.quantityOperateTable);
      updatedClient.price = parseFloat(vpri.toFixed(2));
    }
    dispatch(setSelectedClient(updatedClient));
    setLimitOperateTable(value);
  }
};

  const cancelProcedure = () => {
    setOpenConfirmProcedure(false);
    dispatch(a_resetExpensesState());
  };

  /*°°°*/
  useEffect(() => {
    dispatch(reloadSearchData({ data: '' }));
  }, [selectedInstrument, selectedSubTypeInstrument]);

  /*°°°*/
  useEffect(() => {
    dispatch(a_setTradingOption(subOptionItem));
  }, [subOptionItem]);

  /*°°°*/
  const changeActionTab = (selectedInstrument) => {
    if (selectedInstrument['description'] === 'Fondos') {
      if (subOptionItem === 'COMPRAR') {
        setSubOptionItem('SUSCRIBIR');
      }
      if (subOptionItem === 'VENDER') {
        setSubOptionItem('RESCATAR');
      }
    } else {
      if (subOptionItem === 'SUSCRIBIR') {
        setSubOptionItem('COMPRAR');
      }
      if (subOptionItem === 'RESCATAR') {
        setSubOptionItem('VENDER');
      }
    }
  };

  /*°°°*/
  const validateChangeListTerm = () => {
    if (selectedInstrument && selectedInstrument['description']) {
      if (termNumber >= 3 && selectedInstrument['description'] !== 'Fondos') {
        setListTerms(setCheckList(listTerms[2], listTerms));
        setTermNumber(listTerms[2]['id'] - 1);
      }
      if (termNumber < 3 && selectedInstrument['description'] === 'Fondos') {
        setListTerms(setCheckList(listTerms[4], listTerms));
        setTermNumber(listTerms[4]['id'] - 1);
      }
    }
  };

  const checkAnInstrumentByTerm = () => {
    const updatedInstruments = operateInstruments['instruments'].map((x) => {
      const updatedTerms = x['terms'].map((y) => unCheckElement(y));
      if (
        x['description'] === selectedInstrument['description'] &&
        x['description'] !== 'Fondos'
      ) {
        if (termNumber <= 3) {
          updatedTerms[termNumber] = checkElement(updatedTerms[termNumber]);
        }
      }
      return { ...x, terms: updatedTerms };
    });
    dispatch(
      a_setOperateInstruments({
        ...operateInstruments,
        instruments: updatedInstruments,
      })
    );
  };

  /*°°°*/
  useEffect(() => {
    checkAnInstrumentByTerm();
  }, [selectedInstrument, termNumber]);
  /*°°°*/
  const resetUI = () => {
    setSelectedSubTypeInstrument(selectInitialState);
    setLimitOperateTable('');
    setQuantityOperateTable('');
    setTotalImporte('');
    setTotalPrice('');
    setTotalQuantity('');
    dispatch(reloadSearchData({ data: '' }));
  };
  useEffect(() => {
    if (
      selectedClient?.codComitente &&
      prevCodComitenteRef.current &&
      prevCodComitenteRef.current !== selectedClient.codComitente
    ) {
      resetUI();
    }
    prevCodComitenteRef.current = selectedClient?.codComitente;
  }, [selectedClient?.codComitente]);

  /*°°°*/
  const resetSubTypeByCedearSelection = () => {
    let termCedears = operateInstruments['instruments'][3]['terms'][termNumber];
    if (termCedears?.['checked']) {
      if (
        isTimeToOperate(
          termCedears['openingHour'],
          termCedears['closingHour'],
          instrument === 'Fondos'
        )
      ) {
        chooseSubTypeInstrument(selectInitialState);
      }
    }
  };

  /*°°°*/
  useEffect(() => {
    dispatch(a_setInstrument(selectedInstrument['description']));

    if (selectedInstrument['description'] === 'Cedears') {
      resetSubTypeByCedearSelection();
    }
    validateChangeListTerm();
  }, [selectedInstrument]);

  /*°°°*/
  //Selection of an instrument
  const chooseInstrument = (inst) => {
    dispatch(setEspeciesOperate([]));
    dispatch(a_setSpecieInfo({}));
    setLimitOperateTable(0);
    setQuantityOperateTable(0);
    setSelectedInstrument(inst);
    setSelectedSubTypeInstrument(selectInitialState);

    changeActionTab(inst);
    validateChangeListTerm();
  };

  /* ******************************************************** */

  const chooseSubTypeInstrument = (opt) => {
    setSelectedSubTypeInstrument(opt);

    switch (selectedInstrument['description']) {
      case 'Fondos':
        dispatch(
          api_postOperateGetEspecies({
            type: 'FUND',
            subType: opt['subType'],
            EsFisico: 'True',
          })
        );
        break;
      case 'Bonos':
        dispatch(
          api_postOperateGetEspecies({
            type: 'BOND',
            subType: opt['subType'],
          })
        );
        break;
      case 'Acciones':
        dispatch(
          api_postOperateGetEspecies({
            type: 'EQUITY',
            subType: opt['subType'],
          })
        );
        break;
      case 'Cedears':
        dispatch(
          api_postOperateGetEspecies({
            type: 'CERTIFICATE',
          })
        );
        break;

      default:
        break;
    }
  };

  /* ******************************************************** */

  /*°°°*/
  const getClientWithSpeciesCountForFonds = (client, tmId) => {
    let speciesCount = 0;
    let codigoCafci = getCodigoCafci();
    if (client['specieList']) {
      let filteredSpecie = client['specieList'].find((specie) => {
        return specie['abbreviation'] === codigoCafci;
      });
      speciesCount = filteredSpecie
        ? getSpeciesCountByTerm(filteredSpecie['availableQuantity'], tmId)
        : 0;
    }
    return { ...client, speciesCount: speciesCount };
  };

  /*°°°*/
  const getSpeciesCountByTerm = (array, tmId) => {
    return array[tmId]['quantity'];
  };

  /*°°°*/
  const getClientSelectedSpeciesCount = (selectedClient, tmId) => {
    let speciesCount = 0;

    if (!selectedClient || !selectedClient['specieList'])
      return { ...selectedClient, speciesCount: 0 };

    let filteredSpecie = selectedClient['specieList'].find(
      (specie) => specie['abbreviation'] === specieInfo['ticker']
    );

    if (instrument === 'Bonos' || instrument === 'Cedears') {
      filteredSpecie = selectedClient['specieList'].find((specie) =>
        specieInfo['ticker'].includes(specie['abbreviation'])
      );
    }

    speciesCount = filteredSpecie
      ? getSpeciesCountByTerm(filteredSpecie['availableQuantity'], tmId)
      : 0;

    return { ...selectedClient, speciesCount: speciesCount };
  };

  /*°°°*/

  /*°°°*/
  const getSelectedClientFilteredBySpecies = (selectedClient) => {
    if (
      !selectedClient ||
      !selectedClient['specieList'] ||
      selectedClient['specieList'].length === 0
    ) {
      return null;
    }

    const matches = selectedClient['specieList'].filter((specie) => {
      const found = specie['abbreviation'] === specieInfo['ticker'];
      if (instrument === 'Bonos' || instrument === 'Cedears') {
        let sameTicker = specieInfo['ticker'].includes(specie['abbreviation']);
        if (sameTicker) {
          setTickerOrigen(specie['abbreviation']);
        }
        return sameTicker;
      }
      return found;
    });

    return matches.length > 0
      ? { ...selectedClient, specieList: matches }
      : null;
  };

  /*°°°*/
  const isAMatchBetweenPersonTypeAndClazz = (isPhysicalPerson, clazz) => {
    if (isPhysicalPerson && (clazz === 'A' || clazz === 'D')) {
      return true;
    }
    return false;
  };

  /*°°°*/
  const getCodigoCafci = () => {
    return specieInfo['referenceData']
      ? specieInfo['referenceData']['COD_CAFCI']
      : undefined;
  };

  /*°°°*/
  const getClientsListFilteredByFunds = () => {
    let codigoCafci = getCodigoCafci();
    if (codigoCafci) {
      let clazz = specieInfo ? specieInfo['referenceData']['CLAZZ'] : undefined;
      return customersByPartner.filter((client) => {
        if (
          isAMatchBetweenPersonTypeAndClazz(client['isPhysicalPerson'], clazz)
        ) {
          if (client['specieList'] && client['specieList'].length > 0) {
            return client['specieList'].find((specie) => {
              if (specie['abbreviation'] && codigoCafci) {
                return specie['abbreviation'] === codigoCafci;
              }
              return false;
            })
              ? true
              : false;
          }
        }
        return false;
      });
    }
    return [];
  };

  /*°°°*/
  const getClientsByCurrencyAndTerm = (
    currency,
    tmId,
    instrument,
    selectedClient
  ) => {
    if (subOptionItem === 'COMPRAR' || subOptionItem === 'SUSCRIBIR') {
      let clientWithAmountToShow = getClientWithAmountToShow(
        selectedClient,
        currency,
        tmId
      );
      if (instrument === 'Fondos') {
        let clazz = specieInfo?.referenceData?.CLAZZ;
        if (
          isAMatchBetweenPersonTypeAndClazz(
            selectedClient.isPhysicalPerson,
            clazz
          )
        ) {
          return [clientWithAmountToShow];
        }
      } else {
        return [clientWithAmountToShow];
      }
    }

    if (subOptionItem === 'VENDER') {
      const filteredSelectedClient =
        getSelectedClientFilteredBySpecies(selectedClient);

      if (filteredSelectedClient) {
        return [getClientSelectedSpeciesCount(filteredSelectedClient, tmId)];
      }

      return [{ ...selectedClient, speciesCount: 0 }];
    }

    if (subOptionItem === 'RESCATAR') {
      const clientWithSpeciesCountForFonds = getClientWithSpeciesCountForFonds(
        selectedClient,
        tmId
      );
      return getNewItem(
        clientWithSpeciesCountForFonds,
        termNumber === 3 ? 'PARCIAL' : 'TOTAL',
        specieInfo?.marketData?.NOW_PRICE
      );
    }
  };

  /*°°°*/
  const filterSelectedClientBySelections = (
    termSelected,
    instrument,
    selectedClient
  ) => {
    let currency = specieInfo ? specieInfo?.CURRENCY : undefined;
    let tmId = termSelected <= 2 ? termSelected : 0;

    if (!selectedClient) return null;

    if (
      currency &&
      currency in selectedClient.tmArs &&
      currency in selectedClient.tmUsd
    ) {
      let amountToShow = getClientWithAmountToShow(
        selectedClient,
        currency,
        tmId
      );
      return { ...selectedClient, amountToShow };
    } else {
      let amountToShow =
        'ARS ' +
        selectedClient.tmArs[tmId].amount +
        ' / ' +
        'USD ' +
        selectedClient.tmUsd[tmId].amount;

      return { ...selectedClient, amountToShow };
    }
  };

  /*°°°*/
  const setNewListOfCustomersByPartner = (customerList) => {
    dispatch(a_setCustomersByPartnerFiltered(customerList));
  };
  /*°°°*/
  const getLimitAndReset = () => {
    if (specieInfo?.marketData) {
      return getPriceLimit(
        specieInfo?.marketData,
        termNumber,
        subOptionItem,
        instrument,
        instrument
      );
    } else {
      setQuantityOperateTable(0);
      // setPriceOperateTable("");
    }
    return 0;
  };

  /*°°°*/
  const calcPrice = (newPrice) => {
    let quantity = parseFloat(
      quantityOperateTable
        ? quantityOperateTable?.toString()?.replace(',', '.')
        : '0'
    );
    let price = parseFloat(newPrice?.replace(',', '.'));
    let calculatedPrice = quantity * price;

    return calculatedPrice.toFixed(2);
  };

  /*°°°*/
  useEffect(() => {
    let instrument = selectedInstrument['description'];
    let currentTerm = getCurrentTerm(termNumber);

    let firstFiltered = filterSelectedClientBySelections(
      termNumber,
      instrument
    );

    let secondFiltered = filterBySearches(firstFiltered, search);

    let price = calcPrice(
      getPriceLimit(
        specieInfo?.marketData,
        termNumber,
        subOptionItem,
        instrument
      )
    );
    setLimitOperateTable(price);

    let finalResult = secondFiltered?.map((x) => {
      if (subOptionItem === 'RESCATAR') {
        return x.countType === 'TOTAL'
          ? x
          : {
              ...x,
              quantity: quantityOperateTable,
              price: price,
              limit: getLimitAndReset(),
            };
      }
      return updateTableFields(
        x,
        quantityOperateTable,
        specieInfo['name'] ? price : 0,
        getLimitAndReset(),
        currentTerm
      );
    });
    setSelectedClient(finalResult);
  }, [
    subOptionItem,
    selectedInstrument,
    specieInfo,
    listTerms,
    search,
    selectedClient,
  ]);

  /*°°°*/
  const chooseEspecie = (especie) => {
    dispatch(api_getOperateSpecieInfo({ ticker: especie.ticker }));
  };

  /*°°°*/
  useEffect(() => {
    if (specieInfo['ticker']) {
      let currentDate = getDate();
      let currency = specieInfo?.currency;
      let market = specieInfo?.market;

      let params = {
        date: currentDate,
        currency: currency,
        market: market,
      };

      if (selectedInstrument?.description === 'Fondos') {
        // api_getOperateHolidayState(params);
        api_getOperateInstruments(params);
      }
      setReadyCardChange(true);
    }
    if (specieInfo['ticker'] && selectedClient.priceType === 'market') {
      let marketPrice = calculateInitialPrice(
        selectedClient,
        specieInfo,
        subOptionItem,
        termNumber,
        instrument
      );
      let marketPriceStr = marketPrice != null ? marketPrice?.replace('.', ',') : '0,00';
  
      if (selectedClient.price !== marketPriceStr) {
        let updatedClient = {
          ...selectedClient,
          price: marketPriceStr,
        };
        dispatch(setSelectedClient(updatedClient));
        setLimitPrice(marketPriceStr);
      }
    }
    if (specieInfo.marketData && specieInfo.marketData.NOW_PRICE) {
      calculateRescateTotal();
    }
  }, [specieInfo, selectedClient.priceType, selectedClient.countType]);

  useEffect(() => {
    if (selectedClient.priceType === 'limit' && selectedClient.price) {
      setLimitOperateTable(selectedClient.price);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (!loadingSpecieInfo && Object.keys(specieInfo)?.length === 0) {
      setLimitOperateTable(0);
      setQuantityOperateTable(0);
    }
    setLimitOperateTable(
      getPriceLimit(
        specieInfo?.marketData,
        termNumber,
        subOptionItem,
        instrument,
        instrument
      )
    );
  }, [loadingSpecieInfo, specieInfo]);

  /* ******************************************************** */
  const activeDetailPendientesElement = (pendingProcess) => {
    setSelectedPendingProcess(pendingProcess);
  };

  const handleDetailProcess = (process) => {
    if (selectedPendingProcess) {
      if (selectedPendingProcess.detalle === process.detalle) {
        setSelectedPendingProcess(null);
      }
    }
  };

  useEffect(() => {
    dispatch(api_getOperateInstruments());
    setReadyCardChange(false);
    dispatch(setEspeciesOperate([]));
    dispatch(a_setSpecieInfo({}));
  }, [dispatch]);

  useEffect(() => {
    setTotalQuantity(getSelectedClientQuantity(selectedClient));
    setTotalPrice(getSelectedClientPrice(selectedClient));
  }, [selectedClient]);

  const getTermForExpensesRequestPayload = () => {
    let plazo;
    switch (subOptionItem) {
      case 'RESCATAR':
        plazo = specieInfo.referenceData['PLAZORESC'] + 1;
        break;

      case 'SUSCRIBIR':
        plazo = specieInfo.referenceData['PLAZORESC'] + 1;
        break;

      case 'COMPRAR':
        plazo = termNumber;
        break;

      case 'VENDER':
        plazo = termNumber;
        break;

      default:
        break;
    }
    return plazo;
};

  /*°°°*/
  const expensesRequestPayload = () => {
    const buySellFunds = tradingOption === 'SUSCRIBIR' ? 'B' : 'S';
    const buySellOrders = tradingOption === 'COMPRAR' ? 'B' : 'S';
  
    let cleanedAmount = selectedClient?.amount?.replace(',', '.');
    let numericAmount = parseFloat(cleanedAmount);
  
    let cleanedQuantity = selectedClient?.quantity?.replace(',', '.');
    let numericQuantity = parseFloat(cleanedQuantity);
  
    let cleanedPrice = selectedClient?.price?.replace(',', '.');
    let numericPrice = parseFloat(cleanedPrice);
  
    let params = {
      denominacion: selectedClient.name,
      codMoneda: specieInfo["CODMONEDA"],
      codComitente: selectedClient.codComitente,
      numComitente: selectedClient.numComitente,
      quantity: numericQuantity,
      price: numericPrice,
      amount: numericAmount,
      term: getTermForExpensesRequestPayload(),
      ticker: specieInfo['ticker'],
      isExpensesInAmount: false,
      isByAmount: false,
    };
  
    if (selectedInstrument['description'] === 'Fondos') {
      params['buySell'] = buySellFunds;
    } else {
      params['buySell'] = buySellOrders;
      params['abreviatura'] = specieInfo['ticker'];
    }
    if (subOptionItem === 'VENDER') {
      params['tickerOrigen'] = tickerOrigen;
    }
  
    return params;
  };
  
  
  

  /*°°°*/
  const confirmProcedure = async () => {
    const payload = expensesRequestPayload();
    dispatch(
      api_getOperateExpenses({
        params: payload,
        codComitente: selectedClient.codComitente,
      })
    );
    setOpenConfirmProcedure(true);
  };

  const resetOperateDashboard = () => {
    onClickTapOption(1, tabOptions, setTabOptions);
    setSubOptionItem('COMPRAR');

    const defaultCountType = 0;

    if (selectedClient) {
      const resetClient = updateTableFields(
        selectedClient,
        0,
        0,
        0,
        defaultCountType
      );
      dispatch(setSelectedClient(resetClient));
    }

    setSelectedInstrument(selectInitialState);
    setSelectedSubTypeInstrument(selectInitialState);
    dispatch(setEspeciesOperate([]));
    dispatch(a_setSpecieInfo({}));

    setLimitOperateTable('');
    // setPriceOperateTable(0);
    setQuantityOperateTable('');
  };

  /*************************************************************************************************/
  /*************************************************************************************************/
  const calculateInitialPrice = (
    selectedClient,
    specieInfo,
    subOptionItem,
    termNumber,
    instrument
  ) => {
    if (!selectedClient || !specieInfo?.marketData) return "0,00";
  
    let price;
  
    if (instrument === 'Fondos') {
      price = getPriceByInstru(instrument, specieInfo.marketData.NOW_PRICE);
    } else {
      const termKey = `Plazo${termNumber}`;
      const marketData = specieInfo.marketData[termKey];
      if (!marketData) {
        console.error('Datos de mercado no disponibles para el término seleccionado.');
        return "0,00";
      }
  
      if (selectedClient.priceType === 'market') {
        if (subOptionItem === 'COMPRAR') {
          price = getPriceByInstru(instrument, marketData.ASK_PRICE);
        } else if (subOptionItem === 'VENDER') {
          price = getPriceByInstru(instrument, marketData.BID_PRICE);
        } else {
          price = getPriceByInstru(instrument, marketData.NOW_PRICE);
        }
      } else {
        price = selectedClient.price;
      }
    }
    if (typeof price === 'number' && !isNaN(price)) {
      return price.toFixed(2)?.replace('.', ',');
    } else {
      return "0,00";
    }
  };
  
  
  const changePriceType = (newPriceType) => {
    let updatedClient = { ...selectedClient, priceType: newPriceType };
    
    if (newPriceType === 'limit') {
      updatedClient.isPriceEditable = true;
    } else {
      const marketPrice = calculateInitialPrice(
        selectedClient,
        specieInfo,
        subOptionItem,
        termNumber,
        instrument
      );
      
      let marketPriceStr = '0,00';
      if (typeof marketPrice === 'number' && !isNaN(marketPrice)) {
        marketPriceStr = marketPrice?.toFixed(2)?.replace('.', ',');
      }
    
      updatedClient.price = marketPriceStr;
      updatedClient.isPriceEditable = false;
      setLimitPrice(marketPriceStr);
    }
  
    dispatch(setSelectedClient(updatedClient));
  };
  
  useEffect(() => {
    const initialPrice = calculateInitialPrice(
      selectedClient,
      specieInfo,
      subOptionItem,
      termNumber,
      instrument
    );
    let formattedPrice = '0,00';
    if (typeof initialPrice === 'number' && !isNaN(initialPrice)) {
      formattedPrice = initialPrice.toFixed(2)?.replace('.', ',');
    }
  
    setLimitOperateTable(formattedPrice);
  }, [specieInfo, selectedInstrument, selectedClient.priceType]);
  

  const filterTabs = !openConfirmProcedure ? (
    <div
      className={` ${
        !hasClientBeenSelected
          ? 'operate-dashboard-filter-tabs-gray'
          : 'operate-dashboard-filter-tabs'
      }`}
    >
      {selectedInstrument.description === 'Fondos' ? (
        <div className="operate-sub-items-container">
          <div
            className={`sub-item ${
              subOptionItem === 'SUSCRIBIR' ? 'selected-sub-item' : ''
            }`}
            onClick={() =>
              selectedClient &&
              Object.keys(selectedClient).length !== 0 &&
              setSubOptionItem('SUSCRIBIR')
            }
          >
            SUSCRIBIR
          </div>
          <div
            className={`sub-item ${
              subOptionItem === 'RESCATAR' ? 'selected-sub-item' : ''
            }`}
            onClick={() =>
              selectedClient &&
              Object.keys(selectedClient).length !== 0 &&
              setSubOptionItem('RESCATAR')
            }
          >
            RESCATAR
          </div>
        </div>
      ) : (
        <div className="operate-sub-items-container">
          <div
            className={`sub-item ${
              subOptionItem === 'COMPRAR' ? 'selected-sub-item' : ''
            }`}
            onClick={() =>
              selectedClient &&
              Object.keys(selectedClient).length !== 0 &&
              setSubOptionItem('COMPRAR')
            }
          >
            COMPRAR
          </div>
          <div
            className={`sub-item ${
              subOptionItem === 'VENDER' ? 'selected-sub-item' : ''
            }`}
            onClick={() =>
              selectedClient &&
              Object.keys(selectedClient).length !== 0 &&
              setSubOptionItem('VENDER')
            }
          >
            VENDER
          </div>
        </div>
      )}
    </div>
  ) : undefined;

  const handleClientSelect = (client) => {
    setHasClientBeenSelected(true);
  };
  const isClientSelectedAndHasComitente =
    hasClientBeenSelected && !!selectedClient?.codComitente;
  return (
    <>
      <div className="operate-dashboard">
        <div className="operate-dashboard-general-container row">
          <OperateSearch
            handleClientSelect={handleClientSelect}
            setselectedClientSearch={setSelectedClient}
          />
          <div className="operate-dashboard-container-right">
            <div className="operate-dashboard-container">
              <div className="operate-dashboard-title-container">
                <p className="operate-dashboard-title-text">Operar</p>
                {isClientSelectedAndHasComitente && (
                  <p className="operate-dashboard-info-details">
                    {selectedClient.name}
                  </p>
                )}
              </div>
              <div
                className={` ${
                  !isClientSelectedAndHasComitente
                    ? 'operate-dashboard-tab-bar-container-gray'
                    : 'operate-dashboard-tab-bar-container'
                }`}
              >
                <TabBar
                  tabOptions={tabOptions}
                  setTabOptions={setTabOptions}
                  disabled={!isClientSelectedAndHasComitente}
                />
              </div>
              <div className="operate-dashboard-selection-container">
                {optionItem === 'OPERAR' ? (
                  <div className="operate-dashboard-filter-container">
                    {!openConfirmProcedure ? (
                      <>
                        <OperateMainCardView
                          termNumber={termNumber}
                          openConfirmProcedure={openConfirmProcedure}
                          onChange={onChange}
                          subOptionItem={subOptionItem}
                          selectedInstrument={selectedInstrument}
                          setSelectedInstrument={chooseInstrument}
                          selectedSubTypeInstrument={selectedSubTypeInstrument}
                          setSelectedSubTypeInstrument={chooseSubTypeInstrument}
                          selectTerm={selectTerm}
                          chooseEspecie={chooseEspecie}
                          search={search}
                          onChangePrice={onChangePrice}
                          onChangeQuantity={onChangeQuantity}
                          onChangeLimit={onChangeLimit}
                          confirmProcedure={confirmProcedure}
                          listTerms={listTerms}
                          totalImporte={totalImporte}
                          totalQuantity={totalQuantity}
                          totalPrice={totalPrice}
                          changePriceType={changePriceType}
                          limitOperateTable={limitOperateTable}
                          limitPrice={limitPrice}
                          handlePriceChange={handlePriceChange}
                          hasClientBeenSelected={hasClientBeenSelected}
                          isClientSelectedAndHasComitente={
                            isClientSelectedAndHasComitente
                          }
                          onChangeAmount={onChangeAmount}
                        >
                          {filterTabs}
                        </OperateMainCardView>
                      </>
                    ) : (
                      <OperateConfirmProcedure
                        cancelProcedure={cancelProcedure}
                      />
                    )}
                  </div>
                ) : optionItem === 'ORDENES EN PROCESO' ? (
                  <OperateInProgressOrders
                    activeDetailPendientesElement={
                      activeDetailPendientesElement
                    }
                    handleDetailProcess={handleDetailProcess}
                  />
                ) : (
                  optionItem === 'ORDENES FINALIZADAS' && (
                    <OperateProcessOrdersFinalizadas
                      activeDetailPendientesElement={
                        activeDetailPendientesElement
                      }
                    />
                  )
                )}
                {/* *********************** */}
                {/* VENTANAS EMERGENTES !!! */}
                <OperateModalFinish
                  setOptionItem={setOptionItem}
                  setOpenConfirmProcedure={setOpenConfirmProcedure}
                  resetOperateDashboard={resetOperateDashboard}
                />
                <ModalCancelProcess />
                {/* <ModalTimeOut /> */}
              </div>
            </div>
            <div className="operate-dashboard-detail">
              {optionItem === 'OPERAR' && readyCardChange && (
                <OperateDetailEspecie />
              )}
            </div>
          </div>
        </div>
        <div className="item-market-separator"></div>
        <div className="item-market-separator"></div>
        <div className="item-market-separator"></div>
      </div>
    </>
  );
};
