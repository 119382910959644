import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import Select from "react-select";
import { ConfigProvider, Table, DatePicker, Button } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import iconExcel from "../../../../utils/downloand-excel-btn.svg";
import locale from "antd/lib/locale/es_ES";
import { getDotColor } from "../../../home/home-components/movement/movement";

import {
  getUniqueOptionsList,
  useFilteredData,
  parseDetalleTpActivo,
  formatDate,
  getMaxDateRange,
  groupMovementsByInstrument,
  // getMovementType,
  // getCategoryNames,
} from "./utils";

import { Spinner } from "../../../../components/spinner/spinner";

import { exportCheckingAccount } from "../../export-xlsx";

import "react-datepicker/dist/react-datepicker.css";
import "./checking-account.scss";

const CheckingAccount = (props) => {
  const [instrumentoSelected, setInstrument] = useState("");
  const [especieSelected, setEspecie] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [monthMovements, setMonthMovements] = useState([]);

  // const { loadingCurrentAccount, currentAccount } = useSelector(
  //   (state) => state["wallet"]
  // );
  const {
    lastMovements,
    loadingLastMovements
  } = useSelector((state) => state["home"]);
  const {
    setDate,
    selectedDate,
    setStartDate,
    startDate,
    selectedClientDashboard,
  } = props;
  const movementsArray = lastMovements && Object.keys(lastMovements).length
  ? Object.values(lastMovements)
  : [];
  const { items, requestFilter } = useFilteredData(movementsArray);


  const especiesBlackList = ["Depósito", "Extracción", "Nota de Débito"];
  const especiesList = getUniqueOptionsList(
    movementsArray,
    "currency"
  ).filter((e) => !especiesBlackList.includes(e));
  const instrumentosList = getUniqueOptionsList(
    movementsArray,
    "currency"
  ).map((i) => ({ value: i, label: i }));
  const options = [
    { value: 'Pesos', label: 'Pesos' },
    { value: 'Dólares', label: 'Dólares' },
  ];

  const optionsSpecies = [
    { value: "Todas", label: "Todas" },
    ...(especiesList || []).map((option, index) => ({ value: option, label: parseDetalleTpActivo(option) })),
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F4F6FC' : 'initial',
      color: state.isSelected ? '#7381F7' : '#7381F7'
    }),
  };

  const columns = [
    {
      title: "Tipo mov.",
      dataIndex: "movementType",
      key: "movementType",
      width: "25%",
      render: (text, record, index) => ({
        children: (
          <div>
            <img
              src={getDotColor(text)}
              alt="decoration"
              className="dot"
            />
            <span>{text}</span>
          </div>
        ),
      }),
    },
    {
      title: "Instrumento",
      dataIndex: "currency",
      key: "currency",
      width: "25%",
    },
    {
      title: "Cantidad",
      dataIndex: "amount",
      key: "amount",
      width: "25%",
    },
    {
      title: "Fecha",
      dataIndex: "agreementDate",
      key: "agreementDate",
      width: "25%",
    }
  ];

  useEffect(() => {
    // on change wallet tabs reset filters values
    initializeState();
  }, []);

  // Set initial dates
  const [startHit, endHit] = [
    moment()
      .add(-20, "days")
      .startOf("month")
      .format("YYYY-MM-DD"),
    moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
  ];

  useEffect(() => {
    if (startHit === startDate && endHit === selectedDate && movementsArray) {
      setMonthMovements(
        movementsArray?.filter((item) => item)
      );
    }
  }, [movementsArray?.length]);

  const initializeState = () => {
    setStartDate(startHit);
    setDate(endHit);
    setInstrument("Pesos");
    setEspecie("");
    setDateRange(null); // clean daterange input
    requestFilter({ value: null }); // reset filters
  };

  const handleFilterBy = (e) => {
    const { name, value } = e.target || e; // El evento puede ser directamente el valor en este caso
    if (name === "currency") {
      setInstrument(value);
      setEspecie("");
    }
    requestFilter({ key: name, value });
  };

  const handleFilterBySpecies = (e) => {
    const { name, value } = e.target || e; // El evento puede ser directamente el valor en este caso
    if (name === "currency") {
      setInstrument("");
      setEspecie(value);
    }
    requestFilter({ key: name, value });
  };

  const handleRangeDates = (range) => {
    setDateRange(range);
    if (range && range.length === 2) {
      setStartDate(range[0]);
      setDate(range[1]);
    } else if (range === null) {
      initializeState();
    }
  };

  const exportMovements = (movements) => {
    exportCheckingAccount({
      items: groupMovementsByInstrument(movements),
      client: selectedClientDashboard,
      startDate: formatDate(startDate),
      endDate: formatDate(selectedDate),
    })
  };

  return (
    <div className="checking-account">
      { 
      /*
      <div className="header-right-block export-btn-container">

        <Button
          type="primary"
          onClick={() => exportMovements(items)}
          //icon={<i className="icon-download" style={{ paddingRight: 10 }} />}
          size="large"
        >
          <span className="export-fee-icon">
            <img
              src={iconExcel}
              alt="Exportar FEE"
              width="auto"
              height="auto"
            ></img>
          </span>
          <span className="export-fee-button-title">Exportar Selección</span> 
        </Button>
      </div>
      */
      }
      <div className="checking-account-tabs-card-container">
        <div className="checking-account-tabs-header">
          <div className="custom-filters">
            <ConfigProvider locale={locale}>
              <DatePicker.RangePicker
                placeholder={["Desde", "Hasta"]}
                onChange={(range) => handleRangeDates(range)}
                disabledDate={() => getMaxDateRange()}
                // ranges={{
                //   "Mes anterior": [
                //     moment()
                //       .add(-1, "month")
                //       .startOf("month"),
                //     moment()
                //       .add(-1, "month")
                //       .endOf("month"),
                //   ],
                //   "Este mes": [
                //     moment().startOf("month"),
                //     moment().endOf("month"),
                //   ],
                // }}
                value={dateRange}
              />
            </ConfigProvider>
          </div>
          <div className="header-right-block">
            <Select
              components={{
                DropdownIndicator: () => (
                  <div style={{ padding: "0px 10px 0px 0px" }}>
                    <SearchOutlined />
                  </div>
                ),
                IndicatorSeparator: () => null,
                NoOptionsMessage: () => (
                  <div style={{ padding: "2px", textAlign: "center" }}>
                    Sin resultados
                  </div>
                ),
              }}
              placeholder="Buscar instrumento"
              options={instrumentosList}
              className="ca-autocomplete-select"
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: 35,
                  border: "1px solid #C4C4C4",
                  borderRadius: 8,
                }),
              }}
              onChange={(e) =>
                handleFilterBy({
                  target: { name: "currency", value: e?.value || null },
                })
              }
            />
          </div>
        </div>
        <div className="checking-account-tabs-card-body">
          {loadingLastMovements ? (
            <div className="loader-container-ca">
              <Spinner />
            </div>
          ) : (
            <div className="table-ca">
              <Table
                columns={columns}
                dataSource={items}
                /*
                footer={() =>
                  monthMovements.length >= 0 && (
                    <Button
                      type="link"
                      onClick={() => exportMovements(monthMovements)}
                      primary="true"
                      icon={<DownloadOutlined />}
                      size="large"
                    >
                      Descargar todos los movimientos del último mes
                    </Button>
                  )
                }
                */
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckingAccount;
