import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Tabs } from 'antd';
import { MarketTime } from '../market-time/market-time';
// import { api_getOperateSpecieInfo } from '../../../operate/operate-api';
import { TickerDetailCard } from '../market-ticker-detail-card/ticker-detail-card';
import { api_getOperateSpecieInfo } from '../../../operate/operate-slice';
// import { OperateScheduleView } from '../../../operate/operate-components/operate-schedule-view/operate-schedule-view';

const { TabPane } = Tabs;

export const TickerCardContainer = ({ setModalOpen }) => {
  const dispatch = useDispatch();

  const [ticker, setTicker] = useState(null);

  const state = useSelector((state) => ({
    ...state['marketReducer'],
    ...state['commonReducer'],
  }));

  const {
    // selectedTicker,
    // termNumber,
    // instrumentNumber,
  } = state;
  const { selectedTicker, termNumber, instrumentNumber } = useSelector(
    (state) => state.market
  );

  useEffect(() => {
    if (selectedTicker.length) {
      setTicker(selectedTicker[selectedTicker.length - 1]);
    } else {
      setTicker(null);
    }
  }, [selectedTicker, instrumentNumber]);

  useEffect(() => {
    if (ticker) {
      dispatch(
        api_getOperateSpecieInfo({
          ticker: ticker?.key,
        })
      );
    }
  }, [ticker]);

  return (
    <>
      {ticker && (
        <div className="instrument-rigth-container">
          <MarketTime
            instrumentNumber={instrumentNumber}
            termNumber={termNumber}
          />
          <TickerDetailCard
            instrumentNumber={instrumentNumber}
            setModalOpen={setModalOpen}
          />
        </div>
      )}
    </>
  );
};
