import React from 'react';

import { Chart } from 'react-chartjs-2';

export const PieChart = ({ data }) => {
    return (
        <div className="ticker-detail-chart">
            <Chart
                type="doughnut"
                data={{
                    labels: data.labels,
                    datasets: [
                        {
                            data: data.amountData,
                            backgroundColor: data.colors,
                        },
                    ],
                }}
                width={200}
                height={200}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }}
            />
        </div>
    );
};
