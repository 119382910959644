export const viewInformation = (item, partnerInfo) => {
  const { title, enabled } = item;
  if (enabled && partnerInfo?.enabledViews) {
    return partnerInfo["enabledViews"].find(view => view["name"] === title);
  }
  return {lockLevel: "NOT_VISIBLE", visibility: false};
}

export const checkDisclaimer = (discAuth, partnerInfo) => {
  return ((discAuth?.status===200)||(partnerInfo?.disclaimers?.includes(1)))
}