import React, { useEffect } from "react";

import Instrument from "./instrument";
import { Spinner } from "../../../../../components/spinner/spinner";

const InstrumentList = ({
  title,
  holding,
  loadingHolding,
  loadingHoldingDetail,
  loadingCustomersByPartner,
  currencyOption,
  selectedClientDashboard,
  funds,
  bonds,
  cedears,
  actions,
  foreignExchange,
  divisas,
  negotiableObligations,
  financialTrusts,
  lets,
  lecaps,
  holdingDetail,
  holdingCodComitente,
}) => {

  let totalInstrumentDetail = [];
  const totalPesos = selectedClientDashboard ? holdingCodComitente?.dataBody && holdingCodComitente?.dataBody[0] : holding?.dataBody && holding?.dataBody[0];
  const totalDolares = selectedClientDashboard ? holdingCodComitente?.dataBody && holdingCodComitente?.dataBody[1] : holding?.dataBody && holding?.dataBody[1];
  const totalDolaresMep = selectedClientDashboard ? holdingCodComitente?.dataBody && holdingCodComitente?.dataBody[2] : holding?.dataBody && holding?.dataBody[2];

  const calculateInstrument = () => {
    switch (currencyOption) {
      case "Pesos":
        return totalPesos["instruments"];
      case "Dólares":
        return totalDolares["instruments"];
      case "Dólares MEP":
        return totalDolaresMep["instruments"];
    }
  };

  let x = [];
  if (totalPesos) {
    x = calculateInstrument();

    x.forEach((element, i) => {
      switch (element.name) {
        case "Acciones":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "ACCION",
          });
          break;

        case "Certificados de depósitos Argentinos":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "CEDEAR",
          });
          break;

        case "Fondo Común de Inversión":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "FCI",
          });
          break;

        case "Letras del Tesoro":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "LETES",
          });
          break;

        case "Obligaciones Negociables":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "OBLNEG",
          });
          break;

        case "Fideicomisos Financieros":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "FIDFIN",
          });
          break;

        case "Pesos":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "Monedas",
          });
          break;

        case "Dolares":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "Monedas",
          });
          break;

        case "Dolares 7000":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "Divisas",
          });
          break;

        case "Dolares Cable":
          totalInstrumentDetail.push({
            total: element.value,
            typeOfInstrument: "Divisas",
          });
          break;

        default:
          break;
      }
    });
  }

  return (
    <div className="wallet-dashboard-container-right-desplegables open-tenencia">
      <div className="wallet-dashboard-container-right-desplegables-header-title">
        {title}
      </div>
      <div className="wallet-dashboard-container-right-desplegables-list visible-table">
        {loadingCustomersByPartner ||
        loadingHolding ||
        loadingHoldingDetail ||
        holdingDetail ? (
          <Spinner />
        ) : (
          <>
            <Instrument
              title="Fondos"
              instrument={funds}
              typeOfInstrument="FCI"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />

            <Instrument
              title="Bonos"
              instrument={bonds}
              typeOfInstrument="TITPUB"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />

            <Instrument
              title="Acciones"
              instrument={actions}
              typeOfInstrument="ACCION"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />

            <Instrument
              title="Cedears"
              instrument={cedears}
              typeOfInstrument="CEDEAR"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />

            <Instrument
              title="Monedas"
              instrument={foreignExchange}
              typeOfInstrument="Monedas"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />

            <Instrument
              title="Divisas"
              instrument={divisas}
              typeOfInstrument="Divisas"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />

            <Instrument
              title="Fideicomisos Financieros"
              instrument={financialTrusts}
              typeOfInstrument="FIDFIN"
              currencyOption={currencyOption}
              selectedClientDashboard={selectedClientDashboard}
              totalInstrumentDetail={totalInstrumentDetail}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InstrumentList;
