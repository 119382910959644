import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Spinner } from '../../../../components/spinner/spinner';
import { CompareChartContainer } from '../compare-chart-container/compare-chart-container';
import { FullPeriodPicker } from '../../full-period-picker/full-period-picker';

export const TickerCompareModalBody = ({ selectedPeriod, setPeriod }) => {
    const [tickerA, setTickerA] = useState(null);
    const [tickerB, setTickerB] = useState(null);
    const [tickerC, setTickerC] = useState(null);
    const [fullData, setFullData] = useState([]);
    const [periodType, setPeriodType] = useState('year');

    const { selectedTicker, firstTickerHist, secondTickerHist, thirdTickerHist } = useSelector((state) => state.market);

    useEffect(() => {
        if (firstTickerHist) {
            setTickerA(firstTickerHist);
        }
        if (secondTickerHist) {
            setTickerB(secondTickerHist);
        }
        if (thirdTickerHist) {
            setTickerC(thirdTickerHist);
        }
    }, [firstTickerHist, secondTickerHist, thirdTickerHist]);

    useEffect(() => {
        const createDataSetForCompareChart = () => {
            const newData = [];

            if (tickerA?.length >= 2) {
                newData.push({ ...tickerA });
            }
            if (tickerB?.length >= 2) {
                newData.push({ ...tickerB });
            }
            if (tickerC?.length >= 2) {
                newData.push({ ...tickerC });
            }

            setFullData(newData);
        };

        createDataSetForCompareChart();
    }, [tickerA, tickerB, tickerC, setFullData]);

    return (
        <div className="compare-modal-body">
            {
                fullData.length < selectedTicker.length ?
                <Spinner /> :
                <>
                    <FullPeriodPicker
                        setPeriod={setPeriod}
                        selectedPeriod={selectedPeriod}
                        periodType={periodType}
                        setPeriodType={setPeriodType}
                    />
                    <CompareChartContainer
                        fullData={fullData}
                        selectedTicker={selectedTicker}
                        periodType={periodType}
                    />

                </>
            }
        </div>
    )
}
