import React from "react";
import { TeamTop } from "../team-top/team-top";
import { TeamSelectPeriod } from "../team-select-period/team-select-period";
import { TeamPartnersFee } from "../team-partners-fee/team-partners-fee";
// import { Datepicker } from "../team-datepicker/team-datepicker";
import { ProfitFee } from "../profit-fee/profit-fee";
import { TeamBusinessIntelligence } from "../team-business-intelligence/teamBusinessIntelligence";
import { PercentageVersus } from "../../../../common/common-components/percentage-versus/percentage-versus";

import Select from "react-select";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/lib/locale/es_ES";

import "./team-right.scss";
import { TeamReportDownload } from "../team-report-download/team-report-download";

export const TeamRight = ({ teamHoldingAccounts, menuOpened }) => {
  return (
    <div
      className={
        menuOpened
          ? "team-dashboard-container-right slide-in-right"
          : "team-dashboard-container-right-full-size"
      }
    >
      <div className="top-dashboard-right-container">
        <div
          className={
            menuOpened ? "title-teams-right" : "title-teams-right-full-size"
          }
        >
          Carteras Totales
        </div>
        <div className="ticket-buttons-container">
          <TeamTop />
          <TeamReportDownload />
        </div>
      </div>
      {/* <TeamSelectPeriod /> */}
      <div className="item-partner-separator">&nbsp;</div>
      {/* <div className="select-period-components-container">
        <div className="inactive-period-button">Anual</div>
        <div className="inactive-period-button">Mensual</div>
        <div className="selected-period-button">Personalizar</div>
        <div>[datepicker]</div>
      </div>
      <div className="item-partner-separator">&nbsp;</div> */}
      <div className="select-period-components-and-fee-profit-top-container">
        <div className="select-period-components-container">
          <div className="inactive-period-button">Anual</div>
          <div className="inactive-period-button">Mensual</div>
          <div className="selected-period-button">Personalizar</div>
          <ConfigProvider locale={locale}>
            <DatePicker.RangePicker placeholder={["Desde", "Hasta"]} />
          </ConfigProvider>
          <div className="team-performance-selector-container">
            <Select placeholder="Ver rendimiento: Equipos"></Select>
          </div>
        </div>
        <div className="fee-profit-top-container">
          <ProfitFee />
        </div>
      </div>
      
      <div className="item-partner-separator">&nbsp;</div>
      <TeamPartnersFee />
      <div className="item-partner-separator">&nbsp;</div>
      <div className="fee-details-plus-team-business-intelligence-container">
        {/* <div className="profit-fee-container">
          <ProfitFee />
        </div> */}
        <div className="team-business-intelligence-container">
          <TeamBusinessIntelligence teamHoldingAccounts={teamHoldingAccounts} />
        </div>
      </div>
      <div className="item-partner-separator">&nbsp;</div>
      <div className="team-counts-information-container">
        <PercentageVersus
          type="accounts"
          teamHoldingAccounts={teamHoldingAccounts}
        />
      </div>
      <div className="item-partner-separator">&nbsp;</div>
      <div className="team-counts-information-container">
        <PercentageVersus
          type="holding"
          teamHoldingAccounts={teamHoldingAccounts}
        />
      </div>
      <div className="item-partner-separator">&nbsp;</div>
      <div className="item-partner-separator">&nbsp;</div>
      <div className="item-partner-separator">&nbsp;</div>
      {/* <div className="team-button-tickets-container">
        <TeamTop />
        <TeamReportDownload />
      </div>
      <div className="team-dashboard-title-container">
        Carteras totales
        <TeamSelectPeriod />
      </div>
      <TeamPartnersFee />
      <TeamBusinessIntelligence teamHoldingAccounts={teamHoldingAccounts} />
      <div className="team-counts-information-container">
        <PercentageVersus
          type="accounts"
          teamHoldingAccounts={teamHoldingAccounts}
        />
      </div>
      <div className="team-counts-information-container mbottom">
        <PercentageVersus
          type="holding"
          teamHoldingAccounts={teamHoldingAccounts}
        />
      </div> */}
    </div>
  );
};
