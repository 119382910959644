import React from "react";
import iconSearch from "../../utils/icon-search.svg";
import "./inputTextClientsWallet.scss";

const SIZES = ["input--small--10", "input--medium", "input--large--10"];

export const InputTextClientsWallet = ({
  text,
  placeholder,
  onChange,
  type,
  value,
  defaultValue,
  name,
  inputSize,
  children,
}) => {
  const checkInputSize = SIZES.includes(inputSize) ? inputSize : SIZES[0];

  return (
    <div className={`input-text-clients-wallet-container`}>
      <input
        text={text}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        value={value}
        defaultValue={defaultValue}
        name={name}
        className={`input-text-clients-wallet ${checkInputSize}`}
      >
        {children}
      </input>
      <img className="img-input-text" src={iconSearch} alt="..." />
    </div>
  );
};
