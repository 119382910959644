import React from "react";

import iconPrecaucion from "../../../../utils/icon-precaución.svg";

//import BaseModal from '../../../../components/modal/base-modal';
import NewButton from '../../../../components/new-button/new-button';
import AlternativeModal from "../../../../components/alternativeModal/alternative-modal";

import './team-modals.scss';

const DisableAccount = (props) => {
  return (
    <AlternativeModal onClose={props.onClose}>
        <div className="team-modal-container">
            <div className="modal-title">Deshabilitar Cuenta</div>

            <img style={{ width: 100, padding: 20, alignSelf: 'center' }} src={iconPrecaucion} alt="Menu Icon" />

            <div className="modal-xl-text text-center modal-important-text">¿Está seguro que quiere deshabilitar la cuenta de {props.account.nombreApellido}?</div>

            <div className="f-row-center">
                <div className="buttons-footer-container">
                    <NewButton className="new-btn-primary footer-cta-button">
                        DESHABILITAR CUENTA
                    </NewButton>
                    <span className="skip-upload-button" onClick={props.onClose}>No deshabilitar por ahora</span>
                </div>
            </div>

        </div>
    </AlternativeModal>
  );
};

DisableAccount.defaultProps = {
  onClose: () => console.log('close modal action: disable-account'),
  account: {
    nombreApellido: 'Nombre y Apellido'
  }
}

export default DisableAccount;
