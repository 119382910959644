import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import jsPDF from "jspdf";
import moment from "moment";

import { balanceTotalByTM } from "../../../../common/common-service";
import { exportHoldingAccount } from "../../export-xlsx";

import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Spinner } from "../../../../components/spinner/spinner";

import iconCerrarIcon from "../../../../utils/icon-close.svg";
import tenenciaForm from "../../../../utils/tenenciaForm.png";

const ModalHoldingPreview = ({
  selectedClientDashboard,
  modalStateHolding,
  closeModal,
  data,
  quotation,
  chartImage,
  holdingDetail,
}) => {
  const [pdfOutput, setPdfOutput] = useState(null);

  const fileName = selectedClientDashboard
    ? `tenencia-comitente-${selectedClientDashboard.numComitente}`
    : "tenencia-global";
  const clientName = selectedClientDashboard
    ? selectedClientDashboard.name
    : "Cartera Global";
  const codComitente = selectedClientDashboard
    ? `Cuenta comitente nº ${selectedClientDashboard.numComitente}`
    : null;

  const { userLogged, customersByPartner } = useSelector(
    (state) => state["home"]
  );


  const generateHoldingForm = (data, quotation) => {
    let imgData = document.createElement("img");
    imgData.src = tenenciaForm; //imagen background comprobante
    let doc = new jsPDF("p", "mm", "a4");
    doc.setProperties({ title: "Tenencia" });
    doc.viewerPreferences({ DisplayDocTitle: true });
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    doc.addImage(imgData, "PNG", 0, 0, width, height);

    if (chartImage) {
      let imgChart = chartImage;
      doc.addImage(imgChart, "PNG", 0, 79, 100, 58);
    }

    doc.setFontSize(9);
    doc.setTextColor(114, 114, 114);

    let dataColors = [
      "#300D69",
      "#9863EC",
      "#6A1CE8",
      "#B1B5EC",
      "#5316B5",
      "#00FFEC",
      "#1F6964",
      "#00B5A9",
      "#300D69",
      "#9863EC",
    ];

    for (let i = 0; i < data?.dataBody[0]?.instruments?.length; i++) {
      let color = dataColors[i];
      doc.setFillColor(color);
      doc.ellipse(97.5, 83 + i * 6, 1.1, 1.1, "F");

      doc.text(100, 84 + i * 6, data?.dataBody[0]?.instruments[i].name);
      doc.text(170, 84 + i * 6, "$" + data?.dataBody[0]?.instruments[i].value);
    }

    doc.setFontSize(11);
    doc.setTextColor(114, 114, 114);
    if (selectedClientDashboard) {
      doc.text(
        6,
        41.5,
        "CUENTA: " +
          selectedClientDashboard.numComitente +
          " - " +
          selectedClientDashboard.name
      );
    } else {
      doc.text(
        6,
        41.5,
        `${selectedClientDashboard ? "N° Ref:" : "" }${selectedClientDashboard ? userLogged?.referredCode : "Cartera"} ${selectedClientDashboard ? userLogged?.fullName : "Global"}`
      );
    }

    doc.setFontSize(12);
    doc.setTextColor(114, 114, 114);
    doc.text(170, 68.3, "$" + data?.dataBody[0]?.total);

    doc.setFontSize(9);
    doc.setTextColor(0, 0, 0);
    for (let i = 0; i < data?.dataBody[0]?.instruments?.length; i++) {
      doc.text(10, 157 + i * 6, data?.dataBody[0]?.instruments[i].name);
      doc.text(70, 157 + i * 6, data?.dataBody[0]?.instruments[i].value);
      doc.text(120, 157 + i * 6, data?.dataBody[1]?.instruments[i].value);
      doc.text(175, 157 + i * 6, data?.dataBody[2]?.instruments[i].value);
    }

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text(74, 57.8, `${moment().format("DD/MM/YYYY")}`);

    // Total CARTERA
    doc.setFontSize(10);
    doc.setTextColor(256, 256, 256);
    doc.text(70, 218, "$" + data?.dataBody[0]?.total);
    doc.text(120, 218, "$" + data?.dataBody[1]?.total);
    doc.text(175, 218, "$" + data?.dataBody[2]?.total);

    // Tipo de Cambio
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(135, 230, "$" + quotation?.dollarBNABuy);
    doc.text(185, 230, "$" + quotation?.dollarBuyMEP);

    doc.setFontSize(8);
    doc.setTextColor(114, 114, 114);
    doc.text(
      6,
      240,
      `La información expuesta corresponde a ${moment().format(
        "DD/MM/YYYY HH:mm"
      )}hs.`
    );
    doc.text(
      6,
      244,
      "La cotización es estimada y calculada en base a la operatoria con AL30/AL30D."
    );
    doc.text(
      6,
      248,
      "El tipo de cambio no es fijo y puede variar entre la fecha de ejecución y liquidación"
    );

    setPdfOutput(doc); // Almaceno para preview y descarga
  };

  useEffect(() => {
    generateHoldingForm(data, quotation);
  }, [data]);
  // Distribución

  let mergeData = [];

  if (data) {
    if (data?.dataBody && data.dataBody[0].instruments) {
      // Utiliza forEach en lugar de map para iterar sobre los elementos sin crear un nuevo array anidado
      data.dataBody[0].instruments.forEach((instrument) => {

        let dolar = instrument?.value || "";
        let mep = instrument?.value || "";
  
        // Utiliza concat o el operador de propagación para agregar elementos individualmente a mergeData
        mergeData.push({
          dolar: dolar,
          mep: mep,
        })
      });
    }
  }

  // Saldo Disponible

  let saldoARS = balanceTotalByTM(customersByPartner, "tmArs", selectedClientDashboard);
  let saldoUSD = balanceTotalByTM(customersByPartner, "tmUsd", selectedClientDashboard);

  // Detalle

  let mergeHoldings = [];

  const { actions, bonds, cedears, foreignExchange, funds } = holdingDetail;

  if (actions.length > 0) {
    mergeHoldings.push({ title: "Acciones", data: actions });
  }
  if (bonds.length > 0) {
    mergeHoldings.push({ title: "Bonos", data: bonds });
  }
  if (cedears.length > 0) {
    mergeHoldings.push({ title: "CEDEARs", data: cedears });
  }
  if (foreignExchange.length > 0) {
    mergeHoldings.push({ title: "Divisas", data: foreignExchange });
  }
  if (funds.length > 0) {
    mergeHoldings.push({ title: "Fondo Común de Inversión", data: funds });
  }

  if (!modalStateHolding) {
    return null;
  }

  return (
    <div className="ticket-preview-modal-container show-modal">
      <div className="ticket-preview-container">
        <div className="ticket-preview-wrapper">
          <div className="container-fluid">
            <div className="row ticket-preview">
              <div className="col-md-12">
                <div className="btn-close">
                  <img
                    className="component-disclaimer-modal-close-icon"
                    src={iconCerrarIcon}
                    alt="..."
                    onClick={closeModal}
                  />
                </div>
              </div>
              <div className="col-md-9">
                {pdfOutput ? (
                  <iframe
                    title="Vista previa del PDF"
                    width="100%"
                    height="500"
                    src={pdfOutput.output("datauristring")}
                  ></iframe>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="col-md-3">
                <Button
                  data-testid="wallet-pdf-download-btn"
                  type="primary"
                  icon={<DownloadOutlined />}
                  size={"large"}
                  onClick={() => pdfOutput.save(`${fileName}.pdf`)}
                  className="download-pdf"
                >
                  Descargar PDF
                </Button>
                <Button
                  data-testid="wallet-xls-download-btn"
                  style={{ backgroundColor: "#52B5B1", marginTop: 10, border: "1px solid #52B5B1" }}
                  type="primary"
                  icon={<DownloadOutlined />}
                  size={"large"}
                  className="download-button"
                  onClick={() =>
                    exportHoldingAccount(
                      {
                        distribution: mergeData,
                        today: new Date(),
                        saldoARS,
                        saldoUSD,
                        mergeHoldings,
                        clientName,
                        codComitente,
                      },
                      `${fileName}.xlsx`
                    )
                  }
                >
                  Descargar Excel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHoldingPreview;
