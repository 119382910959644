import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { a_setTickerFiltered } from '../../../../common/common-action'
import {twoDecimalsFormat} from "../../../../common/common-service";
import { a_setSelectedTicker, a_setTickerListWithFilter } from '../../market-slice';


export const useTableActiveData = (type) => {

    const dispatch = useDispatch();

    // const state = useSelector((state) => ({
    //     ...state["marketReducer"],
    //     ...state["commonReducer"],
    // }));

    // const {
    //     bondTickerFiltered,
    //     equityTickerFiltered,
    //     certTickerFiltered,
    //     tickerListWithFilter,
    //     termNumber,
    // } = state;
    const { bondTickerFiltered, equityTickerFiltered, certTickerFiltered, tickerListWithFilter, termNumber } = useSelector((state) => state.market)

    const columns = [
        {
            key: "selected",
            dataIndex: "selected",
            width: "6%",
            title: '',
            render: (text, record) => (
                <div className="custom-check">
                    <Radio
                        checked={record.selected}
                        onChange={() => selectTicker(record)}
                    />
                </div>
            ),
        },
        {
            title: "TICKER",
            dataIndex: 'ticker',
            key: 'ticker',
            width: '20%',
            align:'left',
            render: (text,record) => (
                <div className="table-custom-record">
                    <div className="ticker-abreviation">
                        {record.key}
                    </div>
                    <div className="ticker-name">
                        {record.ticker}
                    </div>
                </div>
            ),
        },
        {
            title: "PRECIO",
            dataIndex: 'last',
            key: 'last',
            align:'center',
            render: (text, record) => {
                const numero = twoDecimalsFormat(text)
                return numero
            },
        },
        {
            title: "% DIA",
            dataIndex: 'day_percentage',
            key: 'day_percentage',
            align:'center',
            render: (text, record) => {
                const numero = twoDecimalsFormat(text)
                return numero
            },
        },
        {
            title: "MONEDA",
            dataIndex: 'currency',
            key: 'currency',
            align:'center',
        },
        {
            title: 'P.Compra',
            dataIndex: 'buy_price',
            key: 'buy_price',
            align:'center',
            render: (text, record) => {
                const numero = twoDecimalsFormat(text)
                return numero
            },
        },
        {
            title: 'P.Venta',
            dataIndex: 'sell_price',
            key: 'sell_price',
            align:'center',
            render: (text, record) => {
                const numero = twoDecimalsFormat(text)
                return numero
            },
        },
        {
            title: "Vol. Nominal",
            dataIndex: 'vol_nom',
            key: 'vol_nom',
            align:'center',
            render: (text, record) => {
                const numero = twoDecimalsFormat(text)
                return numero
            },
        },
        {
            title: "P. Cierre Dia Anterior",
            dataIndex: 'last_closeure_price',
            key: 'last_closeure_price',
            align:'center',
            render: (text, record) => {
                const numero = twoDecimalsFormat(text)
                return numero
            },
        }/* ,
        {
            title: "ACCION",
            dataIndex: 'action',
            key: 'action',
            render: () => (
                <div className="acciones-active" onClick={() => console.log('SUSCRIBIR')}>
                    SUSCRIBIR
                </div>
            ),
            align:'center',
        }, */
    ]

    const selectTicker = (checkedRecord) => {
        dispatch(
            a_setTickerListWithFilter(
                tickerListWithFilter.map((tickerItem) => {
                    if (checkedRecord.key === tickerItem.key) {
                        if (!tickerItem.checked) {
                            dispatch(a_setSelectedTicker([tickerItem]))
                        }
                        return {
                            ...tickerItem,
                            selected: !tickerItem.checked,
                        };
                    }
                    return {
                        ...tickerItem,
                        selected: false,
                    }
                })
            )
        );
    };


    const [search, setSearch] = useState('')
    const [moneda, setMoneda] = useState({ value: '', description: 'Moneda' })
    const [tickerFiltered, setTickerFiltered] = useState([])

    const handleSearchTextChange = (e) => {
        if (e.target.value === '') {
            setSearch('')
        } else {
            setSearch(e.target.value)
        }
    }

    const handleMonedaChange = (moneda) => {
        setMoneda(moneda)
    }

    useEffect(()=>{
        setSearch('')
        setMoneda({value:'', description:'Moneda'})
    },[termNumber])

    useEffect(() => {
        if (type === 'bond') {
            setTickerFiltered(bondTickerFiltered);
        } else if (type === 'equity') {
            setTickerFiltered(equityTickerFiltered);
        } else if (type === 'cert') {
            setTickerFiltered(certTickerFiltered);
        }
        if (tickerFiltered.length) {
            dispatch(a_setTickerListWithFilter(
                tickerFiltered.filter((tickerItem) => {
                    if (search === "" && moneda.value === '') {
                        return tickerItem
                    } else {
                        if (search !== "" && moneda.value) {
                            if (moneda.value === 'ars') {
                                if (tickerItem.ticker.toLowerCase().includes(search.toLowerCase()) && tickerItem.currency === 'ARS') return tickerItem
                                //tickerItem.selected = false;
                            } else if (moneda.value === 'usd') {
                                if (tickerItem.ticker.toLowerCase().includes(search.toLowerCase()) && tickerItem.currency === 'USD') return tickerItem
                                //tickerItem.selected = false;
                            } else {
                                if (tickerItem.ticker.toLowerCase().includes(search.toLowerCase())) return tickerItem;
                                //tickerItem.selected = false;
                            }
                        } else if (search !== "") {
                            if (tickerItem.ticker.toLowerCase().includes(search.toLowerCase())) return tickerItem
                            //tickerItem.selected = false;
                        } else if (moneda.value) {
                            if (moneda.value === 'ars') {
                                if (tickerItem.currency === 'ARS') return tickerItem
                                //tickerItem.selected = false;
                            } else if (moneda.value === 'usd') {
                                if (tickerItem.currency === 'USD') return tickerItem
                                //tickerItem.selected = false;
                            } else {
                                return tickerItem;
                                //tickerItem.selected = false;
                            }
                        }
                    }
                }
                )
            )
            )
        }
    }, [search, moneda,tickerFiltered])


    return [handleSearchTextChange, search, handleMonedaChange, moneda, columns]

}
