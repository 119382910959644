import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";

// components actions and services
// import {
//   loadAuthenticatedInformation,
//   setLogOut,
//   a_setTabOrderInProgress,
// } from '../../common/common-action';
// import { closeSocketConnections } from '../../common/common-service';
import { BankOutlined } from '@ant-design/icons';
import { UserViewProfile } from '../user-view-profile/user-view-profile';
// import { viewInformation } from '../../libs/libsUserProfile';
import { logout, setLogOut } from "../../common/common-slice";

// utils
import iconSmallDown from "../../utils/small-down.svg";
import iconPerfil from "../../utils/icon-perfil.svg";
import iconLogOut from "../../utils/logout.svg";
// import iconCart from '../../utils/icon-carrito.svg';

// styles
import "./navbar.scss";

export const Navbar = () => {
  const dispatch = useDispatch();
  const { loadingUserLogged, partnerInfo } = useSelector((state) => state.home);
  
  const processLogOut = () => {
    dispatch(logout());
    dispatch(setLogOut());
  };

  // const {  userLogged, tabOrderInProgress } = state;
  const [openModal, setOpenModal] = useState(null);

  const toggleModal = (modalName) => {
    setOpenModal(openModal === modalName ? null : modalName);
  };

  // const setOrderInProgress = () => {
  //   dispatch(a_setTabOrderInProgress(true));
  // };

  return (
    <div className="navbar-main-container">
       {loadingUserLogged ? (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
       ) : ( 
        <div
          onClick={() => toggleModal("personalData")}
          className="navbar-user-full-name"
        >
          <p className="user-full-name">{partnerInfo?.fullName?.toUpperCase()}</p>
          <img
            className={`icon-right-container ${
              openModal === "personalData" ? "open-bank-view" : ""
            }`}
            src={iconSmallDown}
            alt="..."
          />
          <div className="navbar-icon-profile-container">
            <IconUser/>
          </div>
        </div>
      )}

      {/* <div className="navbar-icon-profile-container">
        <Link to={"/operate"}>
          <IconNavbar
            icon={iconCart}
            className={"navbar-cart-icon"}
            onClick={setOrderInProgress}
          />
        </Link>
      </div> */}
      {loadingUserLogged ? (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : ( 
        <>
          <div className="divider-navbar-container">
            <div className="divider-navbar" />
          </div>

          <div
            className="bank-account-information"
            onClick={() => toggleModal("bankData")}
          >
            <BankOutlined className="bank-account-icon" />
            <p className="bank-account-title">DATOS CUENTA</p>
            <img
              className={`icon-right-container ${
                openModal === "bankData" ? "open-bank-view" : ""
              }`}
              src={iconSmallDown}
              alt="..."
            />
          </div>
        </>
       )}
      <div className="divider-navbar-container">
        <div className="divider-navbar" />
      </div>
      <IconNavbar
        icon={iconLogOut}
        onClick={() => processLogOut()}
        className="logout-icon"
      />
      <UserViewProfile openModal={openModal} />
    </div>
  );
};

export const IconUser = () => {
  const { loadingUserLogged, partnerInfo } = useSelector((state) => state.home);

  return (
    <figure className="navbar-icon-container">
      <div class="circle-partner-letter">{partnerInfo?.fullName?.charAt(0).toUpperCase()}</div>
    </figure>
  );
};

const IconNavbar = ({ icon, className, onClick }) => (
  <figure className="navbar-icon-container">
    <img src={icon} alt="..." className={className} onClick={onClick} />
  </figure>
);