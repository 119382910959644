import React from "react";

import "./percentage-versus.scss";

export const PercentageVersus = ({ type, teamHoldingAccounts }) => {
  return (
    <div className="percentage-versus-container">
      <div className="percentage-versus-title">
        {type === "accounts"
          ? "Cuentas Activas vs. Inactivas"
          : "Tenencia liquida vs Dinero invertido"}
      </div>
      <div className="account-values-container">
        <div className="account-value">
          <div className="account-value-title">
            <span className="account-active-value-title-indicator">&nbsp;</span>
            <span className="account-value-title-small">Cuentas Activas</span>
          </div>
          <div className="account-value-number-active">111</div>
        </div>
        <div className="account-value">
          <div className="account-value-title">
            <span className="account-inactive-value-title-indicator">&nbsp;</span>
            <span className="account-value-title-small">Cuentas Activas</span>
          </div>
          <div className="account-value-number-inactive">37</div>
        </div>
      </div>
      <div className="right-total-accounts-container">
        <span className="right-total-accounts">Nuevas cuentas: 148</span>
      </div>
      <div className="percentage-data-container">
        <div className="percentage-data-1" style={{with: teamHoldingAccounts?.percAvailableMoney}}>
          <div className="percentage-data-value">
            {type === "accounts"
              ? teamHoldingAccounts?.openAccounts
              : teamHoldingAccounts?.percAvailableMoney}
            %&nbsp;Activas
          </div>
        </div>
        <div className="percentage-data-2" style={{with: teamHoldingAccounts?.percAvailableMoney}}>
          <div className="percentage-data-value">
          {type === "holding"
              ? teamHoldingAccounts?.openAccounts
              : teamHoldingAccounts?.percAvailableMoney}
            %&nbsp;Inactivas
          </div>
        </div>
      </div>
      
      {/* <div className="percentage-versus-line">
        <div className="percentage-line-full">
          <div
            className="percentage-versus-line-has"
            style={{
              width: `${
                type === "accounts"
                  ? teamHoldingAccounts?.openAccounts
                  : teamHoldingAccounts?.percAvailableMoney
              }%`,
            }}
          ></div>
        </div>
      </div> */}

      {/* <div className="title-bottom">
        <div className="percentage-versus-line-has-title">
          <h2 className="data-title-percentage">
            {type === "accounts" ? "Cuentas abiertas" : "Tenecia liquida"}
          </h2>
        </div>
        <div className="percentage-versus-line-hasnt-title">
          <h2 className="data-title-percentage">
            {type === "accounts" ? "Cuentas activas" : "Dinero invertido"}
          </h2>
        </div>
      </div> */}

    </div>
  );
};