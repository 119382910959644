import React from "react";

import warning from "../../utils/warning.svg";

import "./no-data.scss";

export const NoData = ({ text }) => {
  return (
    <div className="no-statics">
      <img src={warning} alt="warning" />
      <p className="no-movements">
      {text}
      </p>
    </div>
  );
};
