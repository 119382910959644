import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";


import { setSelectedClient } from "../../operate-slice";

import { Spinner } from "../../../../components/spinner/spinner";
import InputTextSearchClean from "../../../wallet/wallet-components/wallet-left/input-text-search-clean/input-text-search-clean";

import iconSearch from "../../../../utils/icon-buscar.svg";
import iconQuestion from "../../../../utils/question.svg";

import "../../../../views/wallet/wallet.scss";
import { Footer } from "../footer/footer";
import { api_getCustomerDetails } from "../../../home/home-slice";

const OperateSearch = ({handleClientSelect }) => {
  const selectedClient = useSelector((state) => state.operate);
  const { loadingCustomersByPartner, customersByPartner} = useSelector((state) => state.common)

  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    filteredClient: "",
  })

  const onChangeSelectedClient = (e) => {
    setSearch({ filteredClient: e.target.value });
  };

  const setCleanInput = () => {
    setSearch({ filteredClient: undefined });
    dispatch(setSelectedClient(null));
  };

  const chooseClient = (client) => {
    dispatch(
      api_getCustomerDetails({ codComitente: Number(client.codComitente) })
    );
    dispatch(setSelectedClient({...client, priceType: 'market'}));
    handleClientSelect(client);
  };

  const filteredClients = () => {
    if (!customersByPartner) return [];
    if (!search.filteredClient) return customersByPartner;
  
    return customersByPartner.filter(
      client =>
        client.name.toLowerCase().includes(search.filteredClient.toLowerCase()) ||
        client.numComitente.includes(search.filteredClient)
    );
  };
  
  
  return (
    <>
      <div
        className="wallet-dashboard-container-left"
      >
        <div className="table-container">
          <h2 className="title-wallet">Buscador de Clientes</h2>
          <div className="item-partner-separator">&nbsp;</div>
          <div className="selections">
            <InputTextSearchClean
              type="text"
              name="name"
              placeholder="Nombre o N° Cuenta Comitente"
              value={search.filteredClient}
              onChange={onChangeSelectedClient}
              setCleanInput={setCleanInput}
              inputSize="100%"
            />
          </div>
          <div className="item-partner-separator">&nbsp;</div>
          <div data-testid="wallet-clients-list" className="table">
            {loadingCustomersByPartner ? (
              <Spinner />
            ) : (
              <>
                {!filteredClients()?.length ? (
                  <>
                    <div className="message-client-not-found">
                      <div className="iconSearch">
                        <img src={iconSearch} alt="icon Search" />
                      </div>
                      <h2 className="message-title">
                        No encontramos ese cliente...
                      </h2>
                      <p>
                        Asegurate que ese cliente exista en tu cartera y prueba
                        escribirlo nuevamente.
                      </p>
                    </div>
                    <div className="message-consult-us">
                      <div className="iconQuestion">
                        <img src={iconQuestion} alt="icon Question" />
                      </div>
                      <p>Envianos tu consulta a partners@ad-cap.com.ar</p>
                    </div>
                  </>
                ) : (
                  filteredClients().map((client, i) => (
                    <Client
                      key={i}
                      client={client}
                      chooseClient={chooseClient}
                      // loadingHolding={loadingHolding}
                      // loadingHoldingDetail={loadingHoldingDetail}
                      isSelected={selectedClient && client.codComitente === selectedClient.selectedClient?.codComitente}
                    />
                  ))
                )}
              </>
            )}
          </div>
        </div>
        <div className="terms-and-conditions-container">
          <Footer />
        </div>
      </div>
    </>
  );
};

const Client = ({
  client,
  chooseClient,
  isSelected 
}) => {

  const enableWaiting = false; 
  const activeClass = isSelected ? "element active" : "element";

  return (
    <div
      className={enableWaiting ? "element-disabled" : activeClass}
      onClick={() => (enableWaiting ? null : chooseClient(client))}
    >
      <div className="item-center">
        <div data-testid="client-name" className="name">
          {client.name}
        </div>
        <div data-testid="client-cuit" className="number">
          CUIT: {client.cuit}
        </div>
      </div>
      <div className="item">
        <p data-testid="num-comitente" className="text">
          Nº {client.numComitente}
        </p>
      </div>
    </div>
  );
};

export default OperateSearch;
