import React from "react";
import closeIcon from "../../utils/icon-close.svg";

import "./base-modal.css";

const BaseModal = ({ title, onClose, children }) => {
  return (
    <div className="base-modal">
    <div className="base-modal-content">
      <span className="modal-close-container">
        <div className="modal-title">{title}</div>
        <img
          className="modal-close-icon"
          src={closeIcon}
          alt="..."
          onClick={onClose}
        />
      </span>
      {/* {(title) && <div className="modal-title">{title}</div>} */}
      {children}
    </div>
  </div>
  );
};

BaseModal.defaultProps = {
  title: null,
  onClose: () => console.log("close action: base-modal"),
};

export default BaseModal;
