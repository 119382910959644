import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ProcedureContainer } from "../common/procedure-container/procedure-container";
import { Button } from "../../../../components/button/button";

import { 
  noDecimalFormat, 
  twoDecimalsFormat 
} from "../../operate-service";

import { a_setInsertLot } from "../../operate-action";

import iconCerrarIcon from "../../../../utils/icon-close.svg";
import warningRedIcon from "../../../../utils/warning-icon.svg";

import "./modal-rejected-orders.scss";

export const ModalRejectedOrders = ({
  rejectedOrders,
  showRejectedOrdersModal,
  setShowRejectedOrdersModal,
  setOpenConfirmProcedure,
  setOptionItem,
  resetOperateDashboard,
  setRejectedOrders
}) => {
  const dispatch = useDispatch();
  
  const [showDetailModal, setShowDetailModal] = useState(false);

  const closeModal = () => {
    setShowRejectedOrdersModal(false);
    dispatch(a_setInsertLot(null));
  };

  const noOrdersSent = rejectedOrders?.[0]?.length === rejectedOrders?.[1]?.length;

  return (
    <>
      <div className={`modal-general-container ${showRejectedOrdersModal ? "open" : ""}`}>
        <div className="modal-container">
          <div className="close-icon-container">
            <img
              className="close-icon"
              src={iconCerrarIcon}
              alt="cerrar"
              onClick={closeModal}
            />
          </div>
          <div className="content">
            <div className="warning-icon-container">
              <img className="warning-icon" src={warningRedIcon} alt="" />
            </div>
            <div className="modal-text">
              <p>
                { 
                  noOrdersSent
                  ? "Ninguna orden fue enviada al mercado"
                  : `${rejectedOrders?.[0].length} de ${rejectedOrders?.[1].length} órdenes no fueron enviadas al mercado`
                }
              </p>
            </div>
            <Button
              buttonStyle="btn--primary"
              buttonSize="btn--medium"
              onClick={ () => {
                setShowDetailModal(true);
                setShowRejectedOrdersModal(false);
                dispatch(a_setInsertLot(null));
                }}>
                VER DETALLE
            </Button>
          </div>
        </div>
      </div>
      <ModalRejectedOrdersDetail
        setShowRejectedOrdersModal={setShowRejectedOrdersModal}
        showDetailModal={showDetailModal}
        setShowDetailModal={setShowDetailModal}
        rejectedOrders={rejectedOrders}
        setOpenConfirmProcedure={setOpenConfirmProcedure}
        setOptionItem={setOptionItem}
        resetOperateDashboard={resetOperateDashboard}
        noOrdersSent={noOrdersSent}
        setRejectedOrders={setRejectedOrders}
      />
    </>
  );
};

const ModalRejectedOrdersDetail = ({
  setShowRejectedOrdersModal,
  showDetailModal,
  setShowDetailModal,
  rejectedOrders,
  setOpenConfirmProcedure,
  setOptionItem,
  resetOperateDashboard,
  noOrdersSent,
  setRejectedOrders
}) => {
  const dispatch = useDispatch();
  
  const state = useSelector((state) => ({
    ...state["commonReducer"],
    ...state["operateReducer"],
  }));
  const { tradingOption, specieInfo, expenses } = state;

  const closeDetailModal = () => {
    setShowDetailModal(false);
  };

  /**
  * @description function to reset all states and close modals
  **/
  const resetAll = () => {
    setShowRejectedOrdersModal(false);
    closeDetailModal();
    setOpenConfirmProcedure(false);
    setOptionItem("ORDENES EN PROCESO");
    resetOperateDashboard();
    setRejectedOrders(null);
    dispatch(a_setInsertLot(null));
  }

  /**
  * @description function to only close modals due to no orders sent succesfully.
  **/
  const dontReset = () => {
    setShowRejectedOrdersModal(false);
    closeDetailModal();
    setRejectedOrders(null);
    dispatch(a_setInsertLot(null));
  }

  return (
    <div className={`modal-general-container ${showDetailModal ? "open" : ""}`}>
      <div className="modal-detail-container">
        <div className="close-icon-container">
          <img
            className="close-icon"
            src={iconCerrarIcon}
            alt="cerrar"
            onClick={closeDetailModal}
          />
        </div>
        <div className="warning-icon-container">
          <img className="warning-icon-detail" src={warningRedIcon} alt="warning" />
        </div>
        <div className="modal-text">
          <p>
            Las órdenes en rojo no fueron enviadas. <br /> Por favor,
            envíalas nuevamente
          </p>
        </div>
        <div className="operate-confirm-procedure-container-data-header">
          <ProcedureContainer
            title="Operación"
            description={tradingOption}
            divisionHide={false}
          />
          <ProcedureContainer
            title="Especie"
            description={specieInfo?.["name"]}
            divisionHide={false}
          />
          <ProcedureContainer
            title="Moneda"
            description={specieInfo?.["referenceData"]?.["CURRENCY"] === "ARS" ? "Pesos" : "Dólares"}
            divisionHide={false}
          />
          <ProcedureContainer
            title="Fecha Concertación"
            description={expenses?.fechaConcertacion}
            divisionHide={false}
          />
          <ProcedureContainer
            title="Fecha Liquidación"
            description={expenses?.fechaLiquidacion}
            divisionHide={true}
          />
          </div>
        <div className="orders-detail-container">
          <div className="orders-detail-container-header">
            <h5>Denominación</h5>
            <h5>Unidades</h5>
            <h5>Importe Neto</h5>
            <h5>Gastos</h5>
            <h5>Total Bruto</h5>
            <h5>Estado</h5>
          </div>
          <div className="orders-detail-container-body">
          {rejectedOrders?.[1]?.map((order, i) => (
            <div
              className={`orders-detail-container-row ${order?.processed === true ? "processed" : ""}`}
              key={i}>
              <p>{order?.nombreComitente}</p>
              <p>{noDecimalFormat(order?.cantidad)}</p>
              <p>{`$ ${twoDecimalsFormat(order?.importe)}`}</p>
              <p>{`$ ${twoDecimalsFormat(order?.gastos)}`}</p>
              <p>{`$ ${twoDecimalsFormat(order?.totalBruto)}`}</p>
              <p>{order?.processed === true ? "Enviada" : "No Enviada"}</p>
            </div>
            ))
          }
          </div>
        </div>
        <div className="button-wrapper">
          <Button
            buttonStyle="btn--primary"
            buttonSize="btn--medium"
            onClick={() => noOrdersSent
              ? dontReset()
              : resetAll()
            }>
              ACEPTAR
          </Button>
        </div>
      </div>
    </div>
  );
};
