import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/es";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import ArrowDown from "../../../../utils/arrow-dark-blue-down.svg";
import "./feeChart.css";

export const FeeChart = ({
  series,
  width,
  height,
  period,
  setDate,
  setMonthSelected,
  monthSelected,
}) => {
  const [periodList, setPeriodList] = useState([]);
  const [dataByPeriod, setDataByPeriod] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);

  const numCuMonth = 11; //moment().month();
  const aYear = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const currentMonth = aYear[numCuMonth] + " (en curso)";
  aYear[numCuMonth] = currentMonth;

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const createDayList = () => {
    let month = monthSelected === undefined ? numCuMonth : monthSelected;
    let yearMonth = moment().format("YYYY") + "-" + zeroPad(month + 1, 2);
    let date = moment(yearMonth + "-01").format("YYYY-MM-DD");
    let dayList = [];
    let daysInMonth = monthSelected
      ? moment(yearMonth).daysInMonth()
      : moment().daysInMonth();
    while (daysInMonth > 0) {
      let day = zeroPad(daysInMonth--, 2);
      let arrD = date.split("-");
      arrD[2] = "" + day;
      date = arrD.join("-");
      dayList.push(
        moment(date)
          .locale("es")
          .format("ddd") +
          " " +
          day
      );
    }
    return dayList.reverse();
  };

  const createMonthList = () => {
    aYear[numCuMonth] = aYear[numCuMonth].split(" ")[0];
    return aYear.map((month) => month.toUpperCase().substring(0, 3));
  };

  const createPeriodList = () => {
    if (period === 1) {
      setPeriodList(createDayList());
    } else {
      setPeriodList(createMonthList());
    }
  };

  const selectMonth = (i) => {
    setOpenSelect((x) => !x);
    let selectedDate =
      moment().format("YYYY") + "-" + zeroPad(i + 1, 2) + "-01";
    setMonthSelected(i);
    setDate(selectedDate);
  };

  useEffect(() => {
    if (series?.team.length > 0) {
      createPeriodList();
    }
  }, [series?.team]);

  const getPeriod = (i, pt) => {
    let n = pt === "DAY" ? 2 : 1;
    return parseInt(i?.fecha.split("-")[n]) - 1;
  };

  const createDataByPeriodType = (s, pt) => {
    let data = [];

    s.forEach((item) => (data[getPeriod(item, pt)] = item["totalFee"]));
    periodList.forEach((item, i) => {
      if (!data[i]) data[i] = 0;
    });

    return data;
  };

  const arrStgToNums = (arrS) => {
    return arrS.map((s) => (s + "")?.replace(".", "").replace(",", "."));
  };

  const createDayData = (s) => {
    let data = createDataByPeriodType(s, "DAY");
    if (monthSelected > numCuMonth) {
      return [];
    }
    return monthSelected < numCuMonth ? data : data.slice(0, moment().date());
  };

  const createMonthData = (s) => {
    let data = createDataByPeriodType(s, "MONTH");
    return data.slice(0, numCuMonth + 1);
  };

  const createDataByPeriod = () => {
    let commData = [];

    series = series?.team;

    series.forEach((s) => {
      // console.log(s.fees);
    });

    series.forEach((s) => {
      if (period === 1) {
        commData.push({
          comm: s.nombre,
          data: arrStgToNums(createDayData(s.fees)),
        });
      } else {
        commData.push({
          comm: s.nombre,
          data: arrStgToNums(createMonthData(s.fees)),
        });
      }
    });

    setDataByPeriod(commData);
  };

  useEffect(() => {
    if (series?.team.length > 0 && periodList.length > 0) createDataByPeriod();
  }, [periodList]);

  const genDataByComm = (dp) => {
    const borderDatacolors = [
      "#943838",
      "#CC2323",
      "#4B2222",
      "#6D385B",
      "#423894",
      "#262248",
      "#813DB4",
      "#386394",
      "#7385DE",
      "#377E7C",
      "#14C2BC",
      "#1ABB6A",
      "#419438",
      "#39CE29",
      "#828700",
      "#889438",
      "#335138",
      "#092A0F",
      "#B77943",
      "#BE6D10",
      "#F1AD26",
    ];

    let data = [];
    dp.forEach((d) =>
      data.push({
        label: "$" + d.comm,
        backgroundColor: "transparent",
        //borderColor: "#"+Math.floor(Math.random()*16777215).toString(16),
        borderColor:
          borderDatacolors[Math.floor(Math.random() * borderDatacolors.length)],
        borderWidth: 4,
        lineTension: 0.0,
        fill: true,
        data: d.data,
      })
    );

    // console.log(data);

    return data;
  };

  const checkinsData = {
    labels: periodList,
    datasets: genDataByComm(dataByPeriod),
  };

  const optionsLine = {
    title: { display: true },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <div className="component-fee-chart-general-container">
      {period === 1 ? (
        <>
          <div className="component-fee-chart-month-select-container">
            <div className="component-fee-chart-month-selection-container">
              {monthSelected >= 0 ? aYear[monthSelected] : aYear[numCuMonth]}
            </div>
            <div
              className="component-fee-chart-month-arrow-container"
              onClick={() => setOpenSelect((x) => !x)}
            >
              <ArrowDirection open={openSelect} />
            </div>
          </div>
          <div
            className={`component-fee-chart-month-option-list-container ${
              !openSelect ? "hide-dropdown" : ""
            }`}
          >
            <ul className="component-fee-chart-month-option-list">
              {aYear.map((month, i) => {
                return (
                  <li
                    className="component-fee-chart-month-item"
                    key={i}
                    onClick={() => selectMonth(i)}
                  >
                    {month}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="item-partner-separator">&nbsp;</div>
        </>
      ) : (
        undefined
      )}

      <div>
        <Line
          data-testid="wallet-fee-chart"
          type="line"
          width={width}
          height={height}
          data={checkinsData}
          options={optionsLine}
        />
      </div>
    </div>
  );
};

const ArrowDirection = ({ open }) => (
  <img className={open ? "arrow-invert" : ""} src={ArrowDown} alt="..." />
);
