import React from "react";

import "./teamBusinessIntelligence.scss";

export const TeamBusinessIntelligence = ({ teamHoldingAccounts }) => {
  return (
    <div className="bi-content">
      <div className="bi-title">Business Intelligence</div>
      <div className="bi-items-content">
        <div className="bi-item">
          <div className="bi-item-title">Nuevas Cuentas</div>
          <div className="bi-item-value">+{teamHoldingAccounts.newAccounts}</div>
        </div>
        <div className="bi-item">
          <div className="bi-item-title">Operaciones Netas</div>
          <div className="bi-item-value">55.591</div>
        </div>
        <div className="bi-item">
          <div className="bi-item-title">Valor Total Cartera</div>
          <div className="bi-item-value">ARS {teamHoldingAccounts.totalPortfolio}</div>
        </div>
        {/* <div className="data-item">
          <h4>Nuevas cuentas</h4>
          <span>+{teamHoldingAccounts.newAccounts}</span>
        </div>
        <div className="data-item">
          <h4>Operaciones netas</h4>
          <span className="green">55.591</span>
        </div>
        <div className="data-item">
          <h4>Valor total cartera</h4>
          <span>ARS {teamHoldingAccounts.totalPortfolio}</span>
        </div> */}
      </div>
    </div>
  );
};
