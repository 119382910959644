import {
  /* ****************************** */
  SET_SUCCESS_SIGN_IN_DATA,
  /* ****************************** */
  LOAD_REQUEST_RECOVER_USER,
  SET_SUCCESS_RECOVER_USER,
  SET_FAILURE_RECOVER_USER,
  /* ****************************** */
  LOAD_REQUEST_RECOVER_PASS_VERIFY,
  SET_SUCCESS_RECOVER_PASS_VERIFY,
  SET_FAILURE_RECOVER_PASS_VERIFY,
  /* ****************************** */
  LOAD_REQUEST_RECOVER_PASS_CONFIRM,
  SET_SUCCESS_RECOVER_PASS_CONFIRM,
  SET_FAILURE_RECOVER_PASS_CONFIRM,
  /* ****************************** */
  LOAD_REQUEST_RECOVER_PASS_RESTORE,
  SET_SUCCESS_RECOVER_PASS_RESTORE,
  SET_FAILURE_RECOVER_PASS_RESTORE,
  /* ****************************** */
  LOAD_REQUEST_USER_DATA,
  SET_SUCCESS_USER_DATA,
  SET_FAILURE_USER_DATA,
  /* ****************************** */
  SET_SUCCESS_ESPECIES_OPERATE,
  /* ****************************** */
  SET_OPEN_BLUR,
  SET_AUTH,
  SET_ROUTES,
  SET_SELECTED_ROUTE,
  SET_EXPANDED,
  LOAD_AUTHENTICATED_INFORMATION,
  SET_LOG_IN,
  SET_LOG_OUT,
  SET_UPDATE_USER,
  LOAD_MOVEMENTS,
  SET_MOVEMENTS,
  LOAD_USER_DATA,
  SET_USER_DATA,
  LOAD_USER_INFORMATION,
  SET_USER_INFORMATION,
  LOAD_PROVINCES_DATA,
  SET_PROVINCES_DATA,
  LOAD_ANALYTICS_DATA,
  SET_ANALYTICS_DATA,
  SET_MODAL_CREATE_GROUP,
  SET_SELECTED_CLIENT_FROM_HOME_TO_WALLET,
  RELOAD_SEARCH_DATA,

  /* ************************************** */
  /* ***************REFACTOR*************** */
  /* ************************************** */
  SET_GENERIC_SERVICE_FAILURE,
  SET_TAB_ORDER_IN_PROGRESS,
  LOAD_REQUEST_CUSTOMERS_BY_PARTNER,
  SET_SUCCESS_CUSTOMERS_BY_PARTNER,
  SET_SUCCESS_CUSTOMERS_BY_PARTNER_FILTERED,
  SET_FAILURE_CUSTOMERS_BY_PARTNER,
  LOAD_REQUEST_WALLET_FEE,
  SET_SUCCESS_WALLET_FEE,
  SET_FAILURE_WALLET_FEE,
  LOAD_REQUEST_WALLET_HOLDING,
  SET_SUCCESS_WALLET_HOLDING,
  SET_FAILURE_WALLET_HOLDING,
  LOAD_REQUEST_QUOTATION,
  SET_SUCCESS_QUOTATION,
  SET_FAILURE_QUOTATION,
  WEB_SOCKET_CONNECTION,
  SET_SUCCESS_TICKER_FILTERED,
  LOAD_EMAIL_SEND,
  SET_SUCCESS_EMAIL_SEND,
  SET_FAILURE_EMAIL_SEND,
  RESET_EMAIL_SEND,
  SET_SELECTED_CLIENT,
} from "./actionTypes";

import { userWalletsTable } from "./common-service";
// ***********************************************************************************
export const setSignInData = (data) => ({
  type: SET_SUCCESS_SIGN_IN_DATA,
  payload: data,
});
// ***********************************************************************************
export const setEspeciesOperate = (data) => ({
  type: SET_SUCCESS_ESPECIES_OPERATE,
  payload: data,
});
// ***********************************************************************************
export const setOpenBlur = () => ({
  type: SET_OPEN_BLUR,
});
export const mapCustomUsers = (res) => {
  const response = res.data.map((user) => customUser(user));
  return response;
};
const customUser = (user) => {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
  };
};
export const setAuth = () => ({
  type: SET_AUTH,
});
export const setRoutes = (routes) => ({
  type: SET_ROUTES,
  payload: routes,
});
export const setSelectedRoute = (idRoute) => ({
  type: SET_SELECTED_ROUTE,
  payload: idRoute,
});
export const setExpanded = () => ({
  type: SET_EXPANDED,
});
// **************************************
export const setUpdateUser = (user) => ({
  type: SET_UPDATE_USER,
  payload: user,
});
// **************************************
export const loadProvincesData = () => ({
  type: LOAD_PROVINCES_DATA,
});
export const setProvincesData = (data) => ({
  type: SET_PROVINCES_DATA,
  payload: data,
});
// **************************************
export const loadAnalyticsData = () => ({
  type: LOAD_ANALYTICS_DATA,
});
export const setAnalyticsData = (data) => ({
  type: SET_ANALYTICS_DATA,
  payload: data,
});
// **************************************
export const loadMovements = () => ({
  type: LOAD_MOVEMENTS,
});
export const setMovements = (data) => ({
  type: SET_MOVEMENTS,
  payload: data,
});
// **************************************
export const loadUserData = () => ({
  type: LOAD_USER_DATA,
});
export const setUserData = (data) => ({
  type: SET_USER_DATA,
  payload: data,
});
// **************************************
export const setModalCreateGroup = () => ({
  type: SET_MODAL_CREATE_GROUP,
});
// **************************************
export const setSelectedClientFromHomeToWallet = (name) => ({
  type: SET_SELECTED_CLIENT_FROM_HOME_TO_WALLET,
  payload: name,
});
// **************************************
export const loadUserInformation = () => ({
  type: LOAD_USER_INFORMATION,
});
// **************************************
export const setUserInformation = (data) => ({
  type: SET_USER_INFORMATION,
  payload: data,
});
// **************************************
export const loadAuthenticatedInformation = () => ({
  type: LOAD_AUTHENTICATED_INFORMATION,
});
// **************************************
export const setLogIn = () => ({
  type: SET_LOG_IN,
});
// **************************************
export const setLogOut = () => ({
  type: SET_LOG_OUT,
});
// **************************************
export const reloadSearchData = (data) => ({
  type: RELOAD_SEARCH_DATA,
  payload: data,
});

//************************************************************************************//
//************************************************************************************//
//********************************THE MiGRATIONS BELOW********************************//
//************************************************************************************//
//************************************************************************************//

export const a_setGenericServiceFailure = (data) => {
  return { type: SET_GENERIC_SERVICE_FAILURE, payload: data };
};

export const a_setTabOrderInProgress = (data) => {
  return { type: SET_TAB_ORDER_IN_PROGRESS, payload: data };
};
// ***********************************************************************************
export const a_loadRecoverUser = () => {
  return { type: LOAD_REQUEST_RECOVER_USER };
};

export const a_setSuccessRecoverUser = (data) => {
  return { type: SET_SUCCESS_RECOVER_USER, payload: data };
};

export const a_setFailureRecoverUser = (err) => {
  return { type: SET_FAILURE_RECOVER_USER, payload: { status: false } };
};
// ***********************************************************************************
export const a_loadRecoverPassVerify = () => {
  return { type: LOAD_REQUEST_RECOVER_PASS_VERIFY };
};

export const a_setSuccessRecoverPassVerify = (data) => {
  return { type: SET_SUCCESS_RECOVER_PASS_VERIFY, payload: data };
};

export const a_setFailureRecoverPassVerify = () => {
  return { type: SET_FAILURE_RECOVER_PASS_VERIFY, payload: null };
};
// ***********************************************************************************

export const a_loadRecoverPassConfirm = () => {
  return { type: LOAD_REQUEST_RECOVER_PASS_CONFIRM };
};

export const a_setSuccessRecoverPassConfirm = (data) => {
  return { type: SET_SUCCESS_RECOVER_PASS_CONFIRM, payload: data };
};

export const a_setFailureRecoverPassConfirm = () => {
  return { type: SET_FAILURE_RECOVER_PASS_CONFIRM, payload: null };
};
// ***********************************************************************************

export const a_loadRecoverPassRestore = () => {
  return { type: LOAD_REQUEST_RECOVER_PASS_RESTORE };
};

export const a_setSuccessRecoverPassRestore = (data) => {
  return { type: SET_SUCCESS_RECOVER_PASS_RESTORE, payload: data };
};

export const a_setFailureRecoverPassRestore = () => {
  return { type: SET_FAILURE_RECOVER_PASS_RESTORE, payload: null };
};
// ***********************************************************************************
export const a_loadPartnerInfo = () => {
  return { type: LOAD_REQUEST_USER_DATA };
};

export const a_setSuccessPartnerInfo = (data) => {
  return { type: SET_SUCCESS_USER_DATA, payload: data };
};

export const a_setFailureParterInfo = () => {
  return { type: SET_FAILURE_USER_DATA, payload: null };
};

export const a_setCustomersByPartner = (data) => {
  return {
    type: SET_SUCCESS_CUSTOMERS_BY_PARTNER,
    payload: userWalletsTable(data),
  };
};

export const a_setCustomersByPartnerFiltered = (data) => {
  return { type: SET_SUCCESS_CUSTOMERS_BY_PARTNER_FILTERED, payload: data };
};

export const a_loadRequestCustomersByPartner = () => {
  return { type: LOAD_REQUEST_CUSTOMERS_BY_PARTNER };
};

export const a_setSuccessCustomersByPartner = (data) => {
  return {
    type: SET_SUCCESS_CUSTOMERS_BY_PARTNER,
    payload: userWalletsTable(data),
  };
};

export const a_setFailureCustomersByPartner = () => {
  return { type: SET_FAILURE_CUSTOMERS_BY_PARTNER, payload: [] };
};

export const a_loadRequestWalletFee = () => {
  return { type: LOAD_REQUEST_WALLET_FEE };
};

export const a_setSuccessWalletFee = (data) => {
  return { type: SET_SUCCESS_WALLET_FEE, payload: data };
};

export const a_setFailureWalletFee = () => {
  return { type: SET_FAILURE_WALLET_FEE, payload: null };
};

export const a_loadRequestWalletHolding = () => {
  return { type: LOAD_REQUEST_WALLET_HOLDING };
};

export const a_setSuccessWalletHolding = (data) => {
  return { type: SET_SUCCESS_WALLET_HOLDING, payload: data };
};

export const a_setFailureWalletHolding = () => {
  return { type: SET_FAILURE_WALLET_HOLDING, payload: null };
};

export const a_loadRequestQuotation = () => {
  return { type: LOAD_REQUEST_QUOTATION };
};

export const a_setSuccessQuotation = (data) => {
  return { type: SET_SUCCESS_QUOTATION, payload: data };
};

export const a_setFailureQuotation = () => {
  return { type: SET_FAILURE_QUOTATION, payload: null };
};

export const a_setWebSocketConnection = (data) => {
  return { type: WEB_SOCKET_CONNECTION, payload: data };
};

export const a_setTickerFiltered = (data) => {
  return { type: SET_SUCCESS_TICKER_FILTERED, payload: data };
};

//----------------- Email ---------------------

export const a_loadEmailSend = () => {
  return { type: LOAD_EMAIL_SEND };
};

export const a_setSuccessEmailSend = (data) => {
  return { type: SET_SUCCESS_EMAIL_SEND, payload: data };
};

export const a_setFailureEmailSend = () => {
  return { type: SET_FAILURE_EMAIL_SEND, payload: null };
};
export const a_resetEmailSend = () => {
  return { type: RESET_EMAIL_SEND };
};

//----------------- Email ---------------------
