import React, { useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { SelectSearch } from '../../../../components/selectSearch/selectSearch';
import { LiquidityTermContainer } from '../liquidity-term-container/liquidity-term-container';

import arrowIconGray from '../../../../utils/arrow-down-icon-gray.svg';
import arrowIconBlue from '../../../../utils/arrow-down-blue-icon.svg';

import { selectInitialState } from '../../operate-service';

import './operate-main-card-view.scss';
import { OperateFooter } from '../operate-footer/operate-footer';
import { Table } from 'antd';
import { CustomInput } from '../common/custom-input/custom-input';
import {
  api_getAccount,
  api_getOperateInstrumentsFunds,
  api_getOperateInstrumentsMarket,
} from '../../operate-slice';
import { reloadSearchData } from '../../../../common/common-slice';
import { SelectClientOperate } from '../select-client/selectClientOperate';
import { PriceTypeContainer } from '../operate-price/operate-price';
import MaskedInput from 'react-text-mask';

export const OperateMainCardView = ({
  listTerms,
  selectTerm,
  selectedInstrument,
  setSelectedInstrument,
  selectedSubTypeInstrument,
  setSelectedSubTypeInstrument,
  chooseEspecie,
  children,
  quantityOperateTable,
  onChangeQuantity,
  priceOperateTable,
  handlePriceChange,
  limitOperateTable,
  confirmProcedure,
  totalQuantity,
  totalPrice,
  totalImporte,
  changePriceType,
  termNumber,
  limitPrice,
  hasClientBeenSelected,
  isClientSelectedAndHasComitente,
  onChangeAmount,
  subOptionItem,
  especiaByPatern,
}) => {
  const {
    account,
    selectedClient,
    tradingOption,
    instrument,
    specieInfo,
    instrumentsMarket,
    instrumentsFunds,
    especiesOperate,
    loadingInstrumentsMarket,
    loadingInstrumentsFunds,
    loadingGetAccount,
  } = useSelector((state) => state.operate);
  const { searchData } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [instrumentList, setInstrumentList] = useState([]);
  const [dropdownOpened, setDropDownOpened] = useState('none');
  const [allSelectionsMade, setAllSelectionsMade] = useState(false);
  const [especiesOptions, setEspeciesOptions] = useState([]);
  const [priceType, setPriceType] = useState('market');

  const prevSelectedClientIdRef = useRef();
  const lastSelectedTermRef = useRef(termNumber);
  const prevTradingOptionRef = useRef(tradingOption);

  useEffect(() => {
    if (
      selectedClient &&
      hasClientBeenSelected &&
      prevSelectedClientIdRef.current !== selectedClient.codComitente
    ) {
      dispatch(api_getOperateInstrumentsMarket());
      dispatch(api_getOperateInstrumentsFunds());
      if (selectedClient.codComitente) {
        dispatch(api_getAccount({ codComitente: selectedClient.codComitente }));
      }
    }
    prevSelectedClientIdRef.current = selectedClient?.codComitente;
  }, [dispatch, selectedClient, hasClientBeenSelected]);

  useEffect(() => {
    const translateType = (type) => {
      switch (type) {
        case 'FUND':
          return 'Fondos';
        case 'BOND':
          return 'Bonos';
        case 'EQUITY':
          return 'Acciones';
        case 'CERTIFICATE':
          return 'Cedears';
        default:
          return type;
      }
    };

    if (
      isClientSelectedAndHasComitente &&
      !loadingInstrumentsMarket &&
      !loadingInstrumentsFunds
    ) {
      const processInstruments = (instruments) => {
        if (!instruments || !instruments.types) {
          return [];
        }

        return instruments.types.map((inst) => ({
          type: translateType(inst.type),
          subTypes: inst.subTypes.map((subType) => ({
            name: subType.subType,
            tickers: subType.tickers,
          })),
        }));
      };

      const marketInstruments = processInstruments(instrumentsMarket);
      const fundInstruments = processInstruments(instrumentsFunds);
      const combinedInstruments = [...marketInstruments, ...fundInstruments];
      setInstrumentList(combinedInstruments);
    }
  }, [
    instrumentsMarket,
    instrumentsFunds,
    loadingInstrumentsMarket,
    loadingInstrumentsFunds,
    isClientSelectedAndHasComitente,
  ]);

  const instrumentOptions = instrumentList.map((inst, index) => ({
    description: inst.type,
    value: index,
  }));
  const selectedInstrumentType = instrumentList.find(
    (inst) => inst.type === selectedInstrument.description
  );
  const subTypeOptions = selectedInstrumentType
    ? selectedInstrumentType.subTypes.map((subType, index) => ({
        description: subType.name,
        value: index,
      }))
    : [];

  useEffect(() => {
    const translateInstrumentType = (type) => {
      switch (type) {
        case 'EQUITY':
        case 'ACCION':
          return 'Acciones';
        case 'BOND':
        case 'BONO':
          return 'Bonos';
        case 'FUND':
        case 'FCI':
          return 'Fondos';
        case 'CERTIFICATE':
        case 'CEDEAR':
          return 'Cedears';
        default:
          return type;
      }
    };

    // Agrega 'D' al final del ticker si no está presente
  const addVariantDToTicker = (ticker) => {
    if (!ticker.endsWith('D')) {
      return `${ticker}D`;
    }
    return ticker;
  };

    const getFilteredSpecies = (typeObj, subTypeDescription) => {
      const subTypeObj = typeObj?.subTypes?.find(
        (subType) => subType.name === subTypeDescription
      );
      return subTypeObj ? subTypeObj.tickers : [];
    };

    const getSpeciesInfo = (ticker) => {
      const foundSpecies = especiesOperate.find(
        (especie) => especie.ticker === ticker
      );
      if (!foundSpecies) return { ticker, name: undefined, type: undefined };

      return {
        ticker: foundSpecies.ticker,
        name: foundSpecies.name,
        type: foundSpecies.type,
      };
    };

    const selectedTypeObj = instrumentList.find(
      (inst) =>
        translateInstrumentType(inst.type) === selectedInstrument.description
    );

    if (tradingOption === 'COMPRAR' || tradingOption === 'SUSCRIBIR') {
      const filteredTickers = getFilteredSpecies(
        selectedTypeObj,
        selectedSubTypeInstrument.description
      );
      const speciesInfo = filteredTickers.map((ticker) =>
        getSpeciesInfo(ticker)
      );
      setEspeciesOptions(speciesInfo);
    } else if (
      // Lógica para vender o rescatar con tenencia
      (tradingOption === 'VENDER' || tradingOption === 'RESCATAR') &&
      account &&
      account.tenenciaDisponible
    ) {
      const filteredTickers = getFilteredSpecies(
        selectedTypeObj,
        selectedSubTypeInstrument.description
      );
      const tenenciasFiltradas = account.tenenciaDisponible?.filter((tenencia) =>
        filteredTickers?.includes(tenencia.abreviatura)
      );
      const speciesInfo = tenenciasFiltradas.map((tenencia) => {
      ;  // Agregar variante "D" a la abreviatura del bono si tiene tenencia
        const tickerWithVariantD = addVariantDToTicker(tenencia.abreviatura);
        return getSpeciesInfo(tickerWithVariantD);
      })
      setEspeciesOptions(speciesInfo);
    }
  }, [
    selectedInstrument,
  selectedSubTypeInstrument,
  instrumentList,
  tradingOption,
  especiesOperate,
  account,
  ]);

  useEffect(() => {
    lastSelectedTermRef.current = termNumber;
  }, [termNumber]);

  useEffect(() => {
    if (
      tradingOption !== prevTradingOptionRef.current &&
      prevTradingOptionRef.current !== null
    ) {
      if (termNumber === lastSelectedTermRef.current) {
        resetSelectsAndDependencies();
      }
    }
    prevTradingOptionRef.current = tradingOption;
  }, [tradingOption, termNumber]);

  const resetSelectsAndDependencies = () => {
    if (selectedInstrument.description !== 'Fondos') {
      setSelectedInstrument(selectInitialState);
    }
    setSelectedSubTypeInstrument(selectInitialState);
    dispatch(reloadSearchData({ data: '' }));
  };

  useEffect(() => {
    const isInstrumentSelected = selectedInstrument.value !== -1;
    const isTypeSelected =
      selectedInstrument.description !== 'Cedears'
        ? selectedSubTypeInstrument.value !== -1
        : true;
    const isSpecieSelected = searchData.data !== '';
    const isClientReady = isClientSelectedAndHasComitente && !loadingGetAccount;

    setAllSelectionsMade(
      isInstrumentSelected &&
        isTypeSelected &&
        isSpecieSelected &&
        isClientReady
    );
  }, [
    selectedInstrument,
    selectedSubTypeInstrument,
    searchData.data,
    isClientSelectedAndHasComitente,
    loadingGetAccount,
  ]);

  const onChangeSearch = (e) => {
    dispatch(
      reloadSearchData({ ...searchData, [e.target.name]: e.target.value })
    );
  };

  const onClickArrow = () => {
    dispatch(reloadSearchData({ data: '' }));
  };

  const onClickOption = (opt) => {
    const selection =
      opt['type'] !== 'FUND' ? opt.ticker + ' - ' + opt['name'] : opt.ticker;
    dispatch(reloadSearchData({ data: selection }));
  };

  const getAvailabilityText = () => {
    if (!hasClientBeenSelected) {
      return 'Seleccione un cliente para empezar a operar';
    }
    if (loadingGetAccount) {
      return 'Cargando información del cliente...';
    }
    if (!account || !account.disponibleOperar) {
      return 'Información de disponibilidad no disponible';
    }

    const plazo =
      termNumber === 0 ? 'inmediato' : termNumber === 1 ? '24Hs' : '48Hs';

    if (!account.disponibleOperar[plazo]) {
      return 'Información de plazo no disponible';
    }

    const disponibilidad = account.disponibleOperar[plazo];
    const disponiblePesos =
      disponibilidad.find((d) => d.codMoneda === 1)?.importe || 0;
    const disponibleDolares =
      disponibilidad.find((d) => d.codMoneda === 3)?.importe || 0;

    switch (tradingOption) {
      case 'COMPRAR':
      case 'SUSCRIBIR':
        return `Disponible para operar: ARS ${disponiblePesos.toLocaleString(
          'es-AR',
          { style: 'currency', currency: 'ARS' }
        )} / USD ${disponibleDolares.toLocaleString('es-AR', {
          style: 'currency',
          currency: 'USD',
        })}`;
      case 'VENDER':
      case 'RESCATAR':
        if (!specieInfo || !specieInfo.ticker) {
          return 'Seleccione una especie para ver la disponibilidad';
        }
        const selectedTickerInfo = account.tenenciaDisponible?.find(
          (t) => t.abreviatura === specieInfo.ticker
        );
        if (!selectedTickerInfo) {
          return 'Información no disponible para la especie seleccionada';
        }
        const cantidadDisponible = selectedTickerInfo.disponibleInmediato || 0;
        if (selectedTickerInfo.codMoneda === '1') {
          return `Disponible para operar: ARS ${cantidadDisponible}`;
        } else if (selectedTickerInfo.codMoneda === '3') {
          return `Disponible para operar: USD ${cantidadDisponible}`;
        } else {
          return `Disponible para operar: ${selectedTickerInfo.monedaSimb} ${cantidadDisponible}`;
        }
      default:
        return 'Seleccione una opción de trading';
    }
  };

  const createFlexibleNumberMask = (
    decimalPlaces = 2,
    allowDecimals = true
  ) => {
    return (rawValue) => {
      const digitsRegex = /\d/;
      const maxIntegers = 10;
      const integers = rawValue.split(',')[0].length || 1;

      const integerPart = Array.from(
        { length: Math.min(Math.max(integers, 1), maxIntegers) },
        () => digitsRegex
      );

      const decimalPart = allowDecimals
        ? [',', ...Array(decimalPlaces).fill(digitsRegex)]
        : [];

      return [...integerPart, ...decimalPart];
    };
  };

  const getTableColumns = () => {
    let columns = [];
    columns.push({
      key: 'quantity',
      dataIndex: 'quantity',
      title: (
        <p
          className={`operate-table-header-container ${
            allSelectionsMade ? 'blue-text' : 'gray-text'
          }`}
        >
          {instrument === 'Fondos' ? 'Cuotapartes' : 'Cantidad'}
        </p>
      ),
      render: (text, record) => {
        if (instrument === 'Fondos') {
          return (
            <MaskedInput
              guide={false}
              mask={createFlexibleNumberMask(6)}
              value={record.quantity}
              disabled={true}
              className={`data-element-operate-custom-input ${
                !allSelectionsMade ? 'enabled-custom-input' : ''
              }`}
            />
          );
        } else {
          return (
            <MaskedInput
              mask={createFlexibleNumberMask(0, false)}
              value={record.quantity}
              onChange={(e) => onChangeQuantity(e.target.value)}
              disabled={!allSelectionsMade}
              className={`data-element-operate-custom-input ${
                !allSelectionsMade ? 'enabled-custom-input' : ''
              }`}
              placeholderChar={'0'}
              guide={false}
            />
          );
        }
      },
    });
    columns.push({
      key: 'amount',
      dataIndex: 'amount',
      title: (
        <p
          className={`operate-table-header-container ${
            allSelectionsMade ? 'blue-text' : 'gray-text'
          }`}
        >
          Importe
        </p>
      ),
      render: (record) => {
        if (instrument === 'Fondos' && subOptionItem === 'RESCATAR') {
          if (listTerms[3].checked) {
            return (
              <MaskedInput
                mask={createFlexibleNumberMask(6)}
                value={totalImporte}
                onChange={(e) => onChangeAmount(e.target.value)}
                disabled={!allSelectionsMade}
                className={`data-element-operate-custom-input ${
                  !allSelectionsMade ? 'enabled-custom-input' : ''
                }`}
                guide={false}
              />
            );
          } else if (listTerms[4].checked) {
            return (
              <MaskedInput
                mask={createFlexibleNumberMask(6)}
                value={totalImporte}
                onChange={(e) => onChangeAmount(e.target.value)}
                disabled={true}
                className={`data-element-operate-custom-input ${
                  !allSelectionsMade ? 'enabled-custom-input' : ''
                }`}
                guide={false}
              />
            );
          }
        } else if (instrument === 'Fondos' && subOptionItem === 'SUSCRIBIR') {
          return (
            <MaskedInput
              mask={createFlexibleNumberMask(6)}
              value={totalImporte}
              onChange={(e) => onChangeAmount(e.target.value)}
              disabled={!allSelectionsMade}
              className={`data-element-operate-custom-input ${
                !allSelectionsMade ? 'enabled-custom-input' : ''
              }`}
              guide={false}
            />
          );
        } else {
          return (
            <MaskedInput
              mask={createFlexibleNumberMask(2)}
              value={totalImporte}
              onChange={(e) => onChangeAmount(e.target.value)}
              disabled={true}
              className={`data-element-operate-custom-input ${
                !allSelectionsMade ? 'enabled-custom-input' : ''
              }`}
              guide={false}
            />
          );
        }
      },
    });
    if (instrument !== 'Fondos') {
      columns.push({
        key: 'price',
        dataIndex: 'price',
        title: (
          <p
            className={`operate-table-header-container ${
              allSelectionsMade ? 'blue-text' : 'gray-text'
            }`}
          >
            Precio
          </p>
        ),
        render: (text, record) => {
          const isEditable =
            selectedClient?.isPriceEditable && allSelectionsMade;
          return (
            <div className="operate-container-price-container">
              <MaskedInput
                mask={createFlexibleNumberMask(2)}
                value={record.price}
                onChange={(e) => handlePriceChange(e.target.value)}
                disabled={!isEditable}
                className={`data-element-operate-custom-input ${
                  !allSelectionsMade ? 'enabled-custom-input' : ''
                }`}
                guide={false}
              />
              <PriceTypeContainer
                priceType={record.priceType}
                setPriceType={(newType) => setPriceType(newType, record.key)}
                allSelectionsMade={allSelectionsMade}
                isEnabled={
                  !!selectedClient && Object.keys(selectedClient).length !== 0
                }
                changePriceType={(newType) =>
                  changePriceType(newType, record.key)
                }
              />
            </div>
          );
        },
      });
    }

    return columns;
  };

  const dataSource = selectedClient
    ? [{ ...selectedClient, key: selectedClient.id }]
    : [];
  return (
    <div className="operate-dashboard-container-main-card">
      {children}
      <div className="operate-sub-items-buy-sell-view">
        <div className="operate-main-card-view-general-container">
          {/* GRID 1*/}
          <div className="operate-sub-items-buy-sell-view-name-group">
            <div className="operate-select-info">
              <p className="operate-select-info-text">
                {getAvailabilityText()}
              </p>
            </div>
            <div className="operate-select-container">
              <div className="operate-select-instrument-container">
                <div className="title-instrument-operate">
                  <p
                    className={`title-instrument-operate-text ${
                      !isClientSelectedAndHasComitente ||
                      loadingGetAccount ||
                      loadingInstrumentsMarket ||
                      loadingInstrumentsFunds
                        ? 'gray-text'
                        : 'blue-text'
                    }`}
                  >
                    Instrumento
                  </p>
                </div>
                <SelectClientOperate
                  title="Instrumento"
                  options={instrumentOptions}
                  selection={selectedInstrument}
                  setSelection={setSelectedInstrument}
                  sizeList="176px"
                  sizeContainer="128px"
                  currentOpened={dropdownOpened}
                  setCurrentOpened={(current) => setDropDownOpened(current)}
                  arrowIcon={
                    !hasClientBeenSelected ||
                    loadingGetAccount ||
                    loadingInstrumentsMarket ||
                    loadingInstrumentsFunds
                      ? arrowIconGray
                      : arrowIconBlue
                  }
                  disabled={!hasClientBeenSelected || loadingGetAccount}
                />
              </div>
              <div className="operate-select-type-container">
                <div className="title-type-operate">
                  <p
                    className={`title-type-operate-text ${
                      selectedInstrument.value !== -1
                        ? 'blue-text'
                        : 'gray-text'
                    }`}
                  >
                    Tipo
                  </p>
                </div>
                <SelectClientOperate
                  title="Tipo"
                  options={subTypeOptions}
                  selection={selectedSubTypeInstrument}
                  setSelection={setSelectedSubTypeInstrument}
                  disabledSelect={instrument === -1}
                  sizeList="176px"
                  sizeContainer="128px"
                  currentOpened={dropdownOpened}
                  setCurrentOpened={(current) => setDropDownOpened(current)}
                  arrowIcon={
                    selectedInstrument.value !== -1
                      ? arrowIconBlue
                      : arrowIconGray
                  }
                  disabled={
                    !hasClientBeenSelected || selectedInstrument.value === -1
                  }
                />
              </div>
              <div className="operate-select-specie-container">
                <div className="title-specie-operate">
                  <p
                    className={`title-specie-operate-text ${
                      selectedInstrument.value !== -1 &&
                      (selectedInstrument.description === 'Cedears' ||
                        selectedSubTypeInstrument.value !== -1)
                        ? 'blue-text'
                        : 'gray-text'
                    }`}
                  >
                    Especie
                  </p>
                </div>
                <SelectSearch
                  title="Buscar especie"
                  options={especiesOptions}
                  setSelection={chooseEspecie}
                  searchData={searchData}
                  onChangeSearch={onChangeSearch}
                  onClickArrow={onClickArrow}
                  onClickOption={onClickOption}
                  currentOpened={dropdownOpened}
                  setCurrentOpened={(current) => setDropDownOpened(current)}
                  arrowIcon={
                    selectedInstrument.value !== -1 &&
                    (selectedInstrument.description === 'Cedears' ||
                      selectedSubTypeInstrument.value !== -1)
                      ? arrowIconBlue
                      : arrowIconGray
                  }
                  isEnabled={
                    selectedInstrument.value !== -1 &&
                    (selectedInstrument.description === 'Cedears' ||
                      selectedSubTypeInstrument.value !== -1)
                  }
                />
              </div>
            </div>
          </div>
          <div className="operate-sub-items-buy-sell-view-low">
            {/* GRID 2*/}
            <div className="liquidity-term-container">
              {tradingOption !== 'SUSCRIBIR' && (
                <>
                  <div className="title-operate-term">
                    <p
                      className={`title-operate-term-text ${
                        allSelectionsMade ? 'blue-text' : 'gray-text'
                      }`}
                    >
                      Elija plazo de liquidación:
                    </p>
                  </div>
                  <LiquidityTermContainer
                    listTerms={listTerms}
                    selectTerm={selectTerm}
                    allSelectionsMade={allSelectionsMade}
                    isEnabled={allSelectionsMade}
                  />
                </>
              )}
              <Table
                className="operate-table-container"
                dataSource={dataSource}
                columns={getTableColumns()}
                pagination={false}
                summary={() => (
                  <Table.Summary fixed>
                    <OperateFooter
                      totalQuantity={totalQuantity}
                      totalPrice={totalPrice}
                      listTerms={listTerms}
                      confirmProcedure={confirmProcedure}
                      selectedInstrument={selectedInstrument}
                    />
                  </Table.Summary>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
