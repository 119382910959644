import React from 'react';
import { useSelector } from 'react-redux';
import { Movement } from '../movement/movement';
import { Subtitle } from '../../../../components/titles/titles';
import { Spinner } from '../../../../components/spinner/spinner';
import { NoData } from '../../../../components/no-data/no-data';

import movsIcon from '../../../../utils/calendar-icon.svg';

import './movements.scss';

export const Movements = () => {

  const { loadingLastMovements, lastMovements } = useSelector((state) => state.home);
  const movementsArray = lastMovements && Object.keys(lastMovements).length
  ? Object.values(lastMovements)
  : [];

  return (
    <div className="movements-container">
      <div className="movements-header">
        <div className="movements-header-title">
            <img src={movsIcon} alt="movimientos" />
          <Subtitle text="Últimos movimientos" type="dark" />
        </div>
      </div>
      {loadingLastMovements ? (
        <Spinner />
      ) : (
        <div className="movements-content">
          {movementsArray?.length ? (
            <div className="movements-iteration-container">
              {movementsArray.map((mov, i) => {
                return <Movement key={i} movement={mov} index={i} />;
              })}
            </div>
          ) : (
            <NoData text="No hay últimos movimientos disponibles." />
          )}
        </div>
      )}
    </div>
  );
};
