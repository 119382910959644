import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosCallAsync } from '../../common/common-service';

export const api_getDollarQuotation = createAsyncThunk(
  'market/fetchDollarQuotation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/dollarquotation',
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const api_postFundGetEspecies = createAsyncThunk(
  'market/fetchFundEspecies',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findAll',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postBondGetEspecies = createAsyncThunk(
  'market/fetchBondEspecies',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findAll',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postEquityGetEspecies = createAsyncThunk(
  'market/fetchEquityEspecies',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findAll',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postCertGetEspecies = createAsyncThunk(
  'market/fetchCertEspecies',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findAll',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_getAllInstruments = createAsyncThunk(
  'market/fetchAllInstruments',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findAll',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postByTickerHist = createAsyncThunk(
  'market/fetchByTickerHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postByFirstTickerHist = createAsyncThunk(
  'market/fetchFirstTickerHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postBySecondTickerHist = createAsyncThunk(
  'market/fetchSecondTickerHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const api_postByThirdTickerHist = createAsyncThunk(
  'market/fetchThirdTickerHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postByFundHist = createAsyncThunk(
  'market/fetchFundHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const api_postByBondHist = createAsyncThunk(
  'market/fetchBondHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const api_postByEquityHist = createAsyncThunk(
  'market/fetchEquityHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const api_postByCertHist = createAsyncThunk(
  'market/fetchCertHist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/market/tickerhistoric',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
const initialState = {
  loadingQuotationDollar: false,
  quotationDollar: {},
  loadingFundMarket: false,
  marketFundEsp: [],
  loadingBondMarket: false,
  marketBondEsp: [],
  loadingEquityMarket: false,
  marketEquityEsp: [],
  loadingCertMarket: false,
  marketCertEsp: [],
  loadingFirstTickerHist: false,
  firstTickerHist: [],
  loadingSecondTickerHist: false,
  secondTickerHist: [],
  loadingThirdTickerHist: false,
  thirdTickerHist: [],
  loadingFundHist: false,
  fundHist: [],
  loadingBondHist: false,
  bondHist: [],
  certHist: [],
  loadingCertHist: false,
  termNumber: 2,
  instrumentNumber: 0,
  selectedTicker: [],
  fundTickerFiltered: [],
  bondTickerFiltered: [],
  equityTickerFiltered: [],
  certTickerFiltered: [],
  tickerListWithFilter: [],
  loadingEquityHist: false,
  equityHist: [],
  loadingTickerHist: false,
  tickerHist: [],
  allInstruments: [],
  loadingAllInstruments: false,
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setMarketTerm(state, action) {
      state.termNumber = action.payload;
    },
    setInstrumentNumber(state, action) {
      state.instrumentNumber = action.payload;
    },
    a_setBondTickerFiltered(state, action) {
      state.bondTickerFiltered = action.payload;
    },
    a_setFundTickerFiltered(state, action) {
      state.fundTickerFiltered = action.payload;
    },
    a_setEquityTickerFiltered(state, action) {
      state.equityTickerFiltered = action.payload;
    },
    a_setSelectedTicker(state, action) {
      state.selectedTicker = action.payload;
    },
    a_setCertTickerFiltered(state, action) {
      state.certTickerFiltered = action.payload;
    },
    a_setTickerListWithFilter(state, action) {
      state.tickerListWithFilter = action.payload;
    },
    a_setMarketTerm(state, action) {
      state.termNumber = action.payload;
    },
    a_setInstrumentNumber(state, action) {
      state.instrumentNumber = action.payload;
    },
    a_resetFirstTickerHist: (state) => {
      state.loadingFirstTickerHist = false;
      state.firstTickerHist = [];
    },
    a_resetSecondTickerHist: (state) => {
      state.loadingSecondTickerHist = false;
      state.secondTickerHist = [];
    },
    a_resetThirdTickerHist: (state) => {
      state.loadingThirdTickerHist = false;
      state.thirdTickerHist = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(api_getDollarQuotation.pending, (state) => {
      state.loadingQuotationDollar = true;
    });
    builder.addCase(api_getDollarQuotation.fulfilled, (state, action) => {
      state.loadingQuotationDollar = false;
      state.quotationDollar = action.payload.dataBody;
    });
    builder.addCase(api_getDollarQuotation.rejected, (state) => {
      state.loadingQuotationDollar = false;
      state.quotationDollar = {};
    });
    builder.addCase(api_postFundGetEspecies.pending, (state) => {
      state.loadingFundMarket = true;
    });
    builder.addCase(api_postFundGetEspecies.fulfilled, (state, action) => {
      state.loadingFundMarket = false;
      state.marketFundEsp = action.payload.dataBody;
    });
    builder.addCase(api_postFundGetEspecies.rejected, (state) => {
      state.loadingFundMarket = false;
      state.marketFundEsp = [];
    });
    builder.addCase(api_postBondGetEspecies.pending, (state) => {
      state.loadingBondMarket = true;
    });
    builder.addCase(api_postBondGetEspecies.fulfilled, (state, action) => {
      state.loadingBondMarket = false;
      state.marketBondEsp = action.payload.dataBody;
    });
    builder.addCase(api_postBondGetEspecies.rejected, (state) => {
      state.loadingBondMarket = false;
      state.marketBondEsp = [];
    });
    builder.addCase(api_postEquityGetEspecies.pending, (state) => {
      state.loadingEquityMarket = true;
    });
    builder.addCase(api_postEquityGetEspecies.fulfilled, (state, action) => {
      state.loadingEquityMarket = false;
      state.marketEquityEsp = action.payload.dataBody;
    });
    builder.addCase(api_postEquityGetEspecies.rejected, (state) => {
      state.loadingEquityMarket = false;
      state.marketEquityEsp = [];
    });
    builder.addCase(api_postCertGetEspecies.pending, (state) => {
      state.loadingCertMarket = true;
    });
    builder.addCase(api_postCertGetEspecies.fulfilled, (state, action) => {
      state.loadingCertMarket = false;
      state.marketCertEsp = action.payload.dataBody;
    });
    builder.addCase(api_postCertGetEspecies.rejected, (state) => {
      state.loadingCertMarket = false;
      state.marketCertEsp = [];
    });
    builder.addCase(api_postByTickerHist.pending, (state) => {
      state.loadingTickerHist = true;
    });
    builder.addCase(api_postByTickerHist.fulfilled, (state, action) => {
      state.loadingTickerHist = false;
      state.tickerHist = action.payload.dataBody;
    });
    builder.addCase(api_postByTickerHist.rejected, (state) => {
      state.loadingTickerHist = false;
      state.tickerHist = [];
    });

    builder.addCase(api_postByFirstTickerHist.pending, (state) => {
      state.loadingFirstTickerHist = true;
    });
    builder.addCase(api_postByFirstTickerHist.fulfilled, (state, action) => {
      state.loadingFirstTickerHist = false;
      state.firstTickerHist = action.payload.dataBody;
    });
    builder.addCase(api_postByFirstTickerHist.rejected, (state) => {
      state.loadingFirstTickerHist = false;
      state.firstTickerHist = [];
    });
    builder.addCase(api_postBySecondTickerHist.pending, (state) => {
      state.loadingSecondTickerHist = true;
    });
    builder.addCase(api_postBySecondTickerHist.fulfilled, (state, action) => {
      state.loadingSecondTickerHist = false;
      state.secondTickerHist = action.payload.dataBody;
    });
    builder.addCase(api_postBySecondTickerHist.rejected, (state) => {
      state.loadingSecondTickerHist = false;
      state.secondTickerHist = [];
    });
    builder.addCase(api_postByThirdTickerHist.pending, (state) => {
      state.loadingThirdTickerHist = true;
    });
    builder.addCase(api_postByThirdTickerHist.fulfilled, (state, action) => {
      state.loadingThirdTickerHist = false;
      state.thirdTickerHist = action.payload.dataBody;
    });
    builder.addCase(api_postByThirdTickerHist.rejected, (state) => {
      state.loadingThirdTickerHist = false;
      state.thirdTickerHist = [];
    });
    builder.addCase(api_postByFundHist.pending, (state) => {
      state.loadingFundHist = true;
    });
    builder.addCase(api_postByFundHist.fulfilled, (state, action) => {
      state.loadingFundHist = false;
      state.fundHist = action.payload.dataBody;
    });
    builder.addCase(api_postByFundHist.rejected, (state) => {
      state.loadingFundHist = false;
    });
    builder.addCase(api_postByBondHist.pending, (state) => {
      state.loadingBondHist = true;
    });
    builder.addCase(api_postByBondHist.fulfilled, (state, action) => {
      state.loadingBondHist = false;
      state.bondHist = action.payload.dataBody;
    });
    builder.addCase(api_postByBondHist.rejected, (state) => {
      state.loadingBondHist = false;
    });
    builder.addCase(api_postByCertHist.pending, (state) => {
      state.loadingCertHist = true;
    });
    builder.addCase(api_postByCertHist.fulfilled, (state, action) => {
      state.loadingCertHist = false;
      state.certHist = action.payload.dataBody;
    });
    builder.addCase(api_postByCertHist.rejected, (state) => {
      state.loadingCertHist = false;
    });
    builder.addCase(api_postByEquityHist.pending, (state) => {
      state.loadingEquityHist = true;
    });
    builder.addCase(api_postByEquityHist.fulfilled, (state, action) => {
      state.loadingEquityHist = false;
      state.equityHist = action.payload.dataBody;
    });
    builder.addCase(api_postByEquityHist.rejected, (state) => {
      state.loadingEquityHist = false;
    });
    // get all instruments
    builder.addCase(api_getAllInstruments.pending, (state) => {
      state.loadingAllInstruments = true;
    });
    builder.addCase(api_getAllInstruments.fulfilled, (state, action) => {
      state.loadingAllInstruments = false;
      state.allInstruments = action.payload.dataBody;
    });
    builder.addCase(api_getAllInstruments.rejected, (state) => {
      state.loadingAllInstruments = false;
    });
  },
});
export const {
  setMarketTerm,
  setInstrumentNumber,
  a_setBondTickerFiltered,
  a_setFundTickerFiltered,
  a_setEquityTickerFiltered,
  a_setSelectedTicker,
  a_setCertTickerFiltered,
  a_setTickerListWithFilter,
  a_setMarketTerm,
  a_setInstrumentNumber,
  a_resetFirstTickerHist,
  a_resetSecondTickerHist,
  a_resetThirdTickerHist,
} = marketSlice.actions;
export const marketReducer = marketSlice.reducer;
export const marketActions = marketSlice.actions;
