import React from "react";

import "./analytics-style.scss";

export const Analytics = () => {
  
  return (
    <div></div>
  );
};
