import React from "react";
import { ElementData } from "../common/element-data";
import {
  noDecimalFormat,
  regularPriceFormat,
  twoDecimalsFormat,
  getCurrencyType
} from "../../operate-service";

import { useFetchSpecieInfo } from "../common/custom-hooks";

export const Bonds = () => {
  const specieData = useFetchSpecieInfo();

  const frecuenciaDePago = () => {
    let percentage = specieData.refData["COUPON_FREQUENCY"];

    switch (percentage) {
      case 1:
        percentage = "Mensual";
        break;

      case 2:
        percentage = "Semestral";
        break;

      case 3:
        percentage = "Cuatrimestral";
        break;

      case 4:
        percentage = "Trimestral";
        break;

      case 6:
        percentage = "Bimestral";
        break;

      default:
        percentage = "N/A";
        break;
    }

    return percentage;
  };

  const operacionesData = [
    {
      field: "Precio del Día",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.termData?.["LOW_PRICE"])}
       - ${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.termData?.["HIGH_PRICE"])}`,
    },
    {
      field: "Precio Máximo",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.termData?.["HIGH_LIMIT_PRICE"])}`,
    },
    {
      field: "Precio Mínimo",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.termData?.["LOW_LIMIT_PRICE"])}`,
    },
    {
      field: "Precio Año",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.refData?.["YEAR_MIN"])} -
      ${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.refData?.["YEAR_MAX"])}`,
    },
    {
      field: "Volumen Nominal",
      value: noDecimalFormat(specieData?.termData?.["VOLUME"]),
    },
    {
      field: "Vol. Promedio (10d)",
      value: noDecimalFormat(specieData?.refData?.["VOLUME_10D_AVG"]),
    },
  ];

  const datosDeEmisionData = [
    {
      field: "Emisor",
      value: specieData?.refData?.["COUPON_ISSUER"],
    },
    {
      field: "Legislación",
      value: specieData?.refData?.["LEGISLATION"],
    },
    {
      field: "Fecha de Emisión",
      value: specieData?.refData?.["ISSUE_DATE"]?.substring(0, 10),
    },
    {
      field: "Fecha de Vencimiento",
      value: specieData?.refData?.["MATURITY_DATE"]?.substring(0, 10),
    },
    {
      field: "Moneda de Cotización",
      value: specieData?.refData?.["CURRENCY"],
    },
    {
      field: "Moneda de Pago",
      value: specieData?.refData?.["COUPON_PAYMENT_CURRENCY"],
    },
    {
      field: "Frecuencia de Pago",
      value: frecuenciaDePago(specieData?.refData?.["COUPON_FREQUENCY"]),
    },
  ];

  const indicesTecnicosData = [
    {
      field: "MACD",
      value: `${twoDecimalsFormat(specieData?.refData?.["MACD"])} %`,
    },
    {
      field: "OE",
      value: `${twoDecimalsFormat(specieData?.refData?.["OE"])} %`,
    },
    {
      field: "RSI",
      value: `${twoDecimalsFormat(specieData?.refData?.["RSI"])} %`,
    },
  ];

  const Operaciones = () => {
    return (
      <>
        {operacionesData.map((element, id) => (
          <ElementData key={id} element={element} />
        ))}
      </>
    );
  };

  const DatosDeEmision = () => {
    return (
      <>
        <div className="bonos-detail-subtitle">Datos de Emisión</div>
        {datosDeEmisionData.map((element, id) => (
          <ElementData key={id} element={element} />
        ))}
      </>
    );
  };

  const IndicesTecnicos = () => {
    return (
      <>
        <div className="bonos-detail-subtitle">Índices Técnicos</div>
        {indicesTecnicosData.map((element, id) => (
          <ElementData key={id} element={element} />
        ))}
      </>
    );
  };

  return (
    <div className="bonos-performance-container">
      <div className="datos-operacion-content">
      <Operaciones />
      </div>
      <div className="datos-emision-content">
      <DatosDeEmision />
      <IndicesTecnicos />
      </div>
    </div>
  );
};
