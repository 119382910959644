import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components common and services
import { ClientWallet } from "../client-wallet/client-wallet";
import { Subtitle } from "../../../../components/titles/titles";
import { InputTextClientsWallet } from "../../../../components/inputTextClientsWallet/inputTextClientsWallet";
import { Spinner } from "../../../../components/spinner/spinner";
import { Arrow } from "../../../../components/arrow/arrow";
// import { auth, checkRoleName } from '../../../../common/common-service';
import { TooltipWhite } from "../../../../components/tooltip/tooltip-white";

// utils
import iconWallet from "../../../../utils/cartera-icon.svg";
import downArrow from "../../../../utils/small-down.svg";

// styles
import "./clients-wallet.scss";

export const ClientsWallet = () => {
  const { customersByPartner, loadingCustomersByPartner } = useSelector(
    (state) => state.home
  );
  const [searchClientWallet, setSearchClientWallet] = useState("");
  const [filteredAndSortedClients, setFilteredAndSortedClients] = useState([]);
  const [isAsc, setIsAsc] = useState(new Array(5).fill(false));
  const [expandedClientId, setExpandedClientId] = useState(null);
  const [sortKey, setSortKey] = useState("aumArs");

  useEffect(() => {
    applyFilterAndSort();
  }, [customersByPartner, searchClientWallet, isAsc, sortKey]);

  const applyFilterAndSort = () => {
    const filtered = searchClientWallet
      ? customersByPartner.filter((client) =>
          client.name.toLowerCase().includes(searchClientWallet.toLowerCase())
        )
      : customersByPartner;
    const sorted = sortClients(
      filtered,
      sortKey,
      isAsc[getColumnIndex(sortKey)]
    );
    setFilteredAndSortedClients(sorted);
  };

  const getColumnIndex = (key) => {
    const mapping = { aumArs: 0, aumUsd: 1, tmArs: 2, tmUsd: 3, fee: 4 };
    return mapping[key] || 0;
  };

  const sortClients = (clients, key, ascending) => {
    return [...clients].sort(compareValues(key, ascending ? "asc" : "desc"));
  };

  const compareValues = (key, order = "asc") => {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }

      return order === "desc" ? comparison * -1 : comparison;
    };
  };

  const toggleSort = (key) => {
    const columnIndex = getColumnIndex(key);
    let newIsAsc = [...isAsc];
    newIsAsc[columnIndex] = !newIsAsc[columnIndex];
    setIsAsc(newIsAsc);
    setSortKey(key);
    applyFilterAndSort();
  };

  const toggleClientDetails = (clientId) => {
    setExpandedClientId(expandedClientId === clientId ? null : clientId);
  };

  const onChangeClientWallet = (e) => {
    setSearchClientWallet(e.target.value);
  };

  return (
    <div className="clientsWallet-container">
      <div className="clientsWallet-header">
        <div className="clientsWallet-header-title">
          <img src={iconWallet} alt="cartera de clientes" />
          <Subtitle text="Cartera de clientes" type="dark" />
        </div>
        <div className="clientsWallet-header-selections">
          {!loadingCustomersByPartner && (
            <>
              <InputTextClientsWallet
                text={undefined}
                placeholder="Buscar por Nombre"
                onChange={onChangeClientWallet}
                type="text"
                value={searchClientWallet}
                defaultValue={undefined}
                name="searchField"
                inputSize="input--medium"
              />
              <a href="/operate" className="btn-home-view-wallet">
                OPERAR
              </a>
            </>
          )}
        </div>
      </div>

      {loadingCustomersByPartner ? (
        <Spinner />
      ) : (
        <>
          <div className="clientsWallet-header-content">
            <div className="clientsWallet-header-content-item-detalle">
              <p className="item-details-titles">DETALLES</p>
            </div>
            <div className="clientsWallet-header-content-item">
              <p className="item-details-titles">TOTAL INVERTIDO</p>
              <div className="space-text-tootlip-home"></div>
              <TooltipWhite text="Invertido ARS + Invertido USD * MEP" />
              <button
                className="no-button"
                onClick={() => toggleSort("aumArs")}
              >
                <Arrow open={isAsc[0]} icon={downArrow} className="ml" />
              </button>
            </div>
            <div className="clientsWallet-header-content-item">
              <p className="item-details-titles">INVERTIDO ARS</p>
              <div className="space-text-tootlip-home"></div>
              <TooltipWhite text="Total dinero invertido solo en ARS" />
              <button
                className="no-button"
                onClick={() => toggleSort("aumOnlyArs")}
              >
                <Arrow open={isAsc[1]} icon={downArrow} className="ml" />
              </button>
            </div>
            <div className="clientsWallet-header-content-item">
              <p className="item-details-titles">INVERTIDO USD</p>
              <div className="space-text-tootlip-home"></div>
              <TooltipWhite text="Total dinero invertido solo en USD" />
              <button
                className="no-button"
                onClick={() => toggleSort("aumOnlyUsd")}
              >
                <Arrow open={isAsc[2]} icon={downArrow} className="ml" />
              </button>
            </div>
            <div className="clientsWallet-header-content-item">
              <p className="item-details-titles">DISPONIBLE ARS</p>
              <div className="space-text-tootlip-home"></div>
              <TooltipWhite text="Disponible para Operar en ARS T0 (Inmediato)" />
              <button className="no-button" onClick={() => toggleSort("tmArs")}>
                <Arrow open={isAsc[3]} icon={downArrow} />
              </button>
            </div>
            <div className="clientsWallet-header-content-item">
              <p className="item-details-titles">DISPONIBLE USD</p>
              <div className="space-text-tootlip-home"></div>
              <TooltipWhite text="Disponible para Operar en USD T0 (Inmediato)" />
              <button className="no-button" onClick={() => toggleSort("tmUsd")}>
                <Arrow open={isAsc[4]} icon={downArrow} />
              </button>
            </div>
          </div>
          <div className="clientsWallet-content">
            {filteredAndSortedClients?.map((client) => (
              <ClientWallet
                key={client.codComitente}
                client={client}
                expandClient={expandedClientId === client.codComitente}
                onExpandToggle={() => toggleClientDetails(client.codComitente)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
