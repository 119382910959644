import React, { useState, useEffect } from 'react';

import { dataForWallet } from '../../market-service';
import { dataForPieChart } from '../../market-service';
import { PieChart } from '../market-pie-chart/pie-chart';
import { Spinner } from '../../../../components/spinner/spinner';

export const WalletPieChart = ({ specieInfo }) => {

    const [modalChartData, setModalChartData] = useState(false)
    
    useEffect(() => {
        setModalChartData(dataForPieChart(specieInfo?.PORTFOLIO));
        // setDataItems(dataForWallet(specieInfo?.referenceData.PORTFOLIO));
    }, [specieInfo])

    return (
        !modalChartData ? <Spinner /> :
            <>
                <div className="ticker-detail-data-left-pane">
                    <div className="ticker-wallet-subtype-list-container">
                        <ul className="ticker-wallet-subtype-list" >
                            {
                                modalChartData.labels.map((item, i) => {
                                    const percentageItem = modalChartData.amountData[i];
                                    const itemColor = modalChartData.colors[i];

                                    return (
                                        <li key={i} className="ticker-detail-subtype-list-item">
                                            <div className='ticker-detail-list-item-p'>
                                                <div className="colored-type-scuare" style={{ backgroundColor: itemColor }} />
                                                {item}
                                            </div>
                                            <p className='ticker-detail-list-item-p'>{`${percentageItem}%`}</p>
                                        </li>
                                    )

                                })
                            }
                        </ul>
                    </div>
                </div>
                <PieChart data={modalChartData} />
            </>
    );
};
