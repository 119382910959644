import React from 'react';
import { useSelector } from 'react-redux';
import { twoDecimalsFormat } from '../../../../common/common-service';

import iconPrice from '../../../../utils/icon-price.svg';

const CclQuotationCard = (props) => {
  const { quotationDollar, loadingQuotationDollar } = useSelector((state) => state.market);

  return loadingQuotationDollar ? (
    <div className="container-list">
      <div className="mep-quotation-item-container" />
    </div>
  ) : (
    <div className="container-list">
      <div className="mep-quotation-item-container">
        <div className="mep-quotation-item-title">
          <div className="mep-quotation-text">
            <div className="mep-quotation-symbol">CCL</div>
            <div className="mep-quotation-description">COTIZACIÓN CCL</div>
          </div>
        </div>
        <div className="mep-quotation-item-price-container">
          <div className="mep-quotation-info-container">
            <div className="mep-quotation-subtitle">Compra</div>
            <div className="mep-quotation-item-price">
              ${String(quotationDollar.dollarBuyCCL)}
            </div>
          </div>
          <div className="mep-quotation-info-container">
            <div className="mep-quotation-subtitle">Venta</div>
            <div className="mep-quotation-item-price">
              ${String(quotationDollar.dollarSellCCL)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CclQuotationCard.defaultProps = {};

export default CclQuotationCard;
