import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { a_setCurrency, api_getHoldingClient, api_getHoldingDetail } from "../../../wallet-slice";

import GridPercentageStatics from "../grid-percentage-statics/grid-percentage-statics";
import DistributionChart from "../distribution-chart/distribution-chart";
import { Spinner } from "../../../../../components/spinner/spinner";

import iconEmptyDoughnut from "../../../../../utils/empty-doughnut.png";
import iconGreyWarning from "../../../../../utils/grey-warning.png";

import "./distribution-card-pie.scss";

const DistributionCardPie = ({ title, chartRef, selectedClientDashboard }) => {
  const state = useSelector((state) => ({
    // ...state["common"],
    ...state["wallet"],
    ...state["home"]
  }));
  const {
    currencyOption,
    loadingCustomersByPartner,
    loadingHolding,
    loadingHoldingDetail,
    holding,
    holdingCodComitente,
    customersByPartner,
  } = state;
  const [currency, setCurrency] = useState(currencyOption);

  const dispatch = useDispatch();
  const callForData = async () => {
    dispatch(a_setCurrency(currency));
    if (selectedClientDashboard) {
      await dispatch(api_getHoldingClient(selectedClientDashboard?.codComitente));
    }else {
      await dispatch(api_getHoldingDetail());
    }
  } 
  useEffect(() => {
    callForData()
  }, [currency, dispatch, selectedClientDashboard]);

  const setCurrencyOption = () => {
    switch (currencyOption) {
      case "Pesos":
        return "ARS";
      case "Dólares":
        return "USD";
      case "Dólares MEP":
        return "USD";
    }
  };
  const isEmptyHolding = (holding) => {
    return !holding && !holding.dataBody && holding.dataBody.every((h) => h?.instruments?.length > 0);
  };
  
  const selectedHolding = selectedClientDashboard ? holdingCodComitente : holding;
  return (
    <div className="wallet-card-pie-container">
      <div className="wallet-card-pie-container-header">
        <div
          data-testid="wallet-card-pie-title"
          className="wallet-card-pie-container-header-title"
        >
          {title}
        </div>

        <div className="wallet-card-pie-container-types">
          <div
            data-testid="wallet-holding-pesos"
            onClick={() => setCurrency("Pesos")}
            className="wallet-card-pie-container-types-option"
          >
            <p
              className={`wallet-card-pie-container-types-option-text ${
                currencyOption === "Pesos" ? "selected" : ""
              }`}
            >
              ARS
            </p>
          </div>

          <div
            data-testid="wallet-holding-dolars"
            onClick={() => setCurrency("Dólares")}
            className="wallet-card-pie-container-types-option"
          >
            <p
              className={`wallet-card-pie-container-types-option-text ${
                currencyOption === "Dólares" ? "selected" : ""
              }`}
            >
              USD
            </p>
          </div>

          <div
            data-testid="wallet-holding-mepdolars"
            onClick={() => setCurrency("Dólares MEP")}
            className="wallet-card-pie-container-types-option"
          >
          </div>
        </div>
      </div>

      <div className="wallet-card-pie-container-statics">
        {loadingCustomersByPartner || loadingHolding || loadingHoldingDetail ? (
          <Spinner />
        ) : (
          <>
            {isEmptyHolding(selectedHolding) ? (
             <div className="wallet-no-holding-container">
                  <div className="wallet-empty-doughnut-container">
                    <img
                      className="wallet-empty-doughnut-img"
                      src={iconEmptyDoughnut}
                      alt="Icono de ausencia"
                    />
                   </div>

                  <div className="wallet-warning-message-container">
                      <img
                        className="wallet-warning-message-img"
                        src={iconGreyWarning}
                        alt="Alerta de ausencia"
                        />
                        No se ha encontrado posición
                        <br />
                        en ninguna moneda ni tenencia
                   </div>
                </div>
                ) : (
                  <DistributionChart
                    holding={selectedHolding}
                    currencyButton={currency}
                    chartRef={chartRef}
                  />
                )}

            <GridPercentageStatics
              currency={setCurrencyOption(currencyOption)}
              holding={selectedHolding}
              currencyButton={currency}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DistributionCardPie;
