import React from "react";

import radioCircle from "../../../../utils/radiocircle.svg";
import iconSearch from "../../../../utils/icon-buscar.svg";

//import BaseModal from '../../../../components/modal/base-modal';
import NewButton from '../../../../components/new-button/new-button';
// import Calendar from "react-date-range-calendar";

import './team-modals.scss';
import "./modal-reallocate-portfolio.scss";
import AlternativeModal from "../../../../components/alternativeModal/alternative-modal";

const ReallocatePortfolio = (props) => {
  return (
    <AlternativeModal onClose={props.onClose}>
        <div className="team-modal-container">
            <div className="modal-title">Reasignar Cartera</div>
            <div className="team-item-separator"></div>
            <div className="row">
                <div className="col-md-6">
                  <div className="modal-internal-component-title">Productor al que se le pausará su cartera</div>
                  <div className="input-search-producer">
                    <img
                      className="img-input-text"
                      src={iconSearch}
                      alt="..."
                    />
                    <input
                      className="input-text-clients-wallet"
                      value="Ej. Escriba nombre productor"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="modal-internal-component-title">Productor al que se le asignará la cartera</div>
                  <div className="input-search-producer">
                    <img
                      className="img-input-text"
                      src={iconSearch}
                      alt="..."
                    />
                    <input
                      className="input-text-clients-wallet"
                      value="Ej. Escriba nombre productor"
                    />
                  </div>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-md-12">
                  <div className="assignment-time">
                    <div className="modal-internal-component-title">Tiempo de la asignación de la cartera</div>
                    <div className="operate-main-card-view-icon-container">
                      <img
                        className="img-radio-term-operate checked"
                        src={radioCircle}
                        alt="radio"
                      />
                      <div className="operate-main-card-view-radio-text">
                        Reasignar sin límite de tiempo
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="calendar-container">
                    {/* <Calendar
                      onSelect={(startDate, endDate, validDateRange) => {
                        console.log(
                          startDate,
                          " this is the start date",
                          endDate,
                          " this is the end date value.......",
                          " and this is the validDateRange",
                          validDateRange
                        );
                      }}
                      onChange={(selectedDate) => {
                        console.log("here is the selected date", selectedDate);
                      }}
                      selectedRange={[null, null]}
                      disabledDates={() => {
                        return ["2019-08-16", "2019-08-20"];
                      }}
                      disablePrevDates
                      globalCss="tr{ font-size: 12px }"
                    /> */}
                  </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                  <div className="justification">
                    <div className="modal-internal-component-title">Justificativo de la reasignación</div>
                    <div className="input-search-producer">
                      <input
                        className="input-text-clients-wallet"
                        value="Ej. Reasignación temporal de la cartera por vacaciones"
                      />
                    </div>
                  </div>
                </div>
            </div>
            <div className="team-item-separator"></div>
            <div className="f-row-center">
                <div className="buttons-footer-container">
                    <NewButton className="new-btn-primary">
                      REASIGNAR CARTERA
                    </NewButton>
                    <div className="skip-upload-button" onClick={props.onClose}>No reasignar por ahora</div>
                </div>
            </div>
        </div>
    </AlternativeModal>
  );
};

ReallocatePortfolio.defaultProps = {
  onClose: () => console.log('close action: reallocate-portfolio')
}

export default ReallocatePortfolio;
