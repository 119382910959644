import React, { useState } from "react";
import editIcon from "../../utils/icon-edit.svg";
import "./inputTextUserProfile.scss";

const SIZES = ["input--small-1", "input--medium-1", "input--large-1"];

export const InputTextUserProfile = ({
  label,
  placeholder,
  onChange,
  type,
  value,
  name,
  inputSize,
  editable,
  children,
}) => {
  const checkInputSize = SIZES.includes(inputSize) ? inputSize : SIZES[0];
  const [editableInput, setEditableInput] = useState(false);

  return (
    <div
      className={`input-text-user-profile-container ${checkInputSize}`}
      onMouseLeave={() => setEditableInput(false)}
    >
      <label htmlFor={name}>{label}</label>
      <div
        className={`input-text-user-profile ${
          editableInput ? "focusInput" : undefined
        }`}
      >
        <input
          disabled={!editableInput}
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          value={value || ""}
          editable={editableInput.toString()}
        >
          {children}
        </input>

        {/* <div className="input-text-user-container-icon">
          {editable === "true" && (
            <img
              className="input-text-user-profile-edit-icon"
              onClick={() => setEditableInput((x) => !x)}
              src={editIcon}
              alt=""
            />
          )}
        </div> */}
      </div>
    </div>
  );
};
