import React from "react";

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import "./distribution-chart.scss";

const DistributionChart = ({ holding, currencyButton, chartRef }) => {

  const dataPercentage = (data) => {
    let percentage = [];
    let instruments = [];
    let dataPesos = {};

    let x = [];
    if (data) {
      x = data["instruments"] ? data["instruments"] : [];
      x.forEach((instrument) => {
        percentage.push(instrument?.percentage);
        instruments.push(instrument?.name);
      });
    }

    dataPesos = {
      labels: instruments,
      datasets: [
        {
          data: percentage,
          backgroundColor: [
            "#531DAB",
            "#7381F7",
            "#9254DE",
            "#D3ADF7",
            "#08979C",
            "#36CFC9",
            "#BAE7FF",
            "#00B5A9",
          ],
          borderColor: "#FFFFFF",
          borderWidth: 1,
        },
      ],
    };

    return dataPesos;
  };

  const getDataHolding = () => {
    if (holding && holding.dataBody) {
        if (currencyButton === "Pesos" && holding.dataBody[0]) {
            return holding?.dataBody?.[0];
        } else if (holding?.dataBody?.[1]) {
            return holding?.dataBody?.[1];
        }
    }
    return null; 
};

  return (
    <div className="statistics-graph">
      <div className="doughnut-wrapper">
        <Chart
          data-testid="wallet-distribution-chart"
          type="doughnut"
          data={dataPercentage(getDataHolding())}
          ref={chartRef}
          width={50}
          height={130}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default DistributionChart;
