import React from 'react'

const DetailTabInfo = ({estrategia,objetivo,depositaria}) => {
    return (
        <div className="detail-tab-info">
            <div className="ticker-modal-detail-row">
                <div className="ticker-modal-detail-title ticker-detail-modal-bold-text">
                    Estrategia
                </div>
                <div className="ticker-detail-modal-text">
                    {estrategia}
                </div>
            </div>
            <div className="ticker-modal-detail-row">
                <div className="ticker-modal-detail-title ticker-detail-modal-bold-text">
                    Objetivo
                </div>
                <div className="ticker-detail-modal-text">
                    {objetivo}
                </div>
            </div>
            <div className="ticker-modal-detail-row">
                <div className="ticker-modal-detail-title ticker-detail-modal-bold-text">
                    Depositaria
                </div>
                <div className="ticker-detail-modal-text">
                    {depositaria}
                </div>
            </div>
        </div>
    )
}

export default DetailTabInfo