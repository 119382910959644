import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import avatar from '../../../../utils/avatar-bg-gray.svg';
import { Spinner } from '../../../../components/spinner/spinner';
import { twoDecimalsFormat } from '../../../../common/common-service';

import './instrument-price.scss';

// import {
//   api_postFundGetEspecies,
//   api_postBondGetEspecies,
//   api_postCertGetEspecies,
//   api_postEquityGetEspecies,
//   api_postByFundHist,
//   api_postByBondHist,
//   api_postByCertHist,
//   api_postByEquityHist,
// } from '../../market-api';

import { codMonedaToName } from '../../market-service';
import { CardHistoryChart } from '../market-card-history-chart/card-history-chart';
import {
  api_postBondGetEspecies,
  api_postByBondHist,
  api_postByCertHist,
  api_postByEquityHist,
  api_postByFundHist,
  api_postCertGetEspecies,
  api_postEquityGetEspecies,
  api_postFundGetEspecies,
} from '../../market-slice';

export const CardInstrument = ({
  instrument,
  subType,
  especie,
  especieLoading,
  termNumber,
}) => {
  const [selectedSubTypeInstrument, setSelectedSubTypeInstrument] = useState({
    value: subType ? subType[0].value : -1,
    description: subType ? subType[0].description : '',
  });

  // const state = useSelector((state) => ({
  //   ...state['marketReducer'],
  // }));
  const { fundHist, bondHist, equityHist, certHist } = useSelector(
    (state) => state.market
  );

  const dispatch = useDispatch();

  const [selected, setSelected] = useState('');
  const [selectedHistory, setSelectedHistory] = useState([]);

  useEffect(() => {
    setSelected(especie[0]?.ticker);
  }, [especie]);

  useEffect(() => {
    const todaysDate = moment().format('YYYY-MM-DD');
    const fromDate = moment().year() + '-01-01';

    if (selected) {
      setSelectedHistory({ data: null, isLoading: true });
      switch (instrument) {
        case 'Fondos':
          dispatch(
            api_postByFundHist({
              ticker: selected,
              dateFrom: fromDate,
              dateTo: todaysDate,
            })
          );
          break;
        case 'Bonos':
          dispatch(
            api_postByBondHist({
              ticker: selected,
              dateFrom: fromDate,
              dateTo: todaysDate,
            })
          );
          break;
        case 'Acciones':
          dispatch(
            api_postByEquityHist({
              ticker: selected,
              dateFrom: fromDate,
              dateTo: todaysDate,
            })
          );
          break;
        case 'CEDEARs':
          dispatch(
            api_postByCertHist({
              ticker: selected,
              dateFrom: fromDate,
              dateTo: todaysDate,
            })
          );
          break;
      }
    }
  }, [selected]);

  useEffect(() => {
    switch (instrument) {
      case 'Fondos':
        if (fundHist) {
          setSelectedHistory({ data: fundHist, isLoading: false });
        }
        break;
      case 'Bonos':
        if (bondHist) {
          setSelectedHistory({ data: bondHist, isLoading: false });
        }
        break;
      case 'Acciones':
        if (equityHist) {
          setSelectedHistory({ data: equityHist, isLoading: false });
        }
        break;
      case 'CEDEARs':
        if (certHist) {
          setSelectedHistory({ data: certHist, isLoading: false });
        }
        break;
    }
  }, [fundHist, bondHist, equityHist, certHist, instrument]);

  const chooseSubTypeInstrument = (opt) => {
    setSelectedSubTypeInstrument(opt);

    switch (instrument) {
      case 'Fondos':
        dispatch(
          api_postFundGetEspecies({
            type: 'FUND',
            subType: opt['subType'],
            // EsFisico: 'True',
          })
        );
        break;
      case 'Bonos':
        dispatch(
          api_postBondGetEspecies({
            type: 'BOND',
            subType: opt['subType'],
          })
        );
        break;
      case 'Acciones':
        dispatch(
          api_postEquityGetEspecies({
            type: 'EQUITY',
            subType: opt['subType'],
          })
        );
        break;
      case 'CEDEARs':
        dispatch(
          api_postCertGetEspecies({
            type: 'CERTIFICATE',
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className="container-card-instrument">
      <div className="container-title-card">
        <p className="title-card">
          {instrument}
          {/* <img src={IconMercadoAmpliar} /> */}
        </p>
        {/* <img src={IconMercadoMover} /> */}
      </div>
      {!subType ? (
        <div className="container-options-card">
          <div className="empty-space" />
          &nbsp;
        </div>
      ) : (
        <div
          className={`container-options-card ${
            subType && subType.length === 3 ? 'three-options' : ''
          }
          `}
        >
          {subType && subType.length > 0 ? (
            subType.map((subtyp, index) => (
              <div
                key={subtyp.value}
                onClick={() => chooseSubTypeInstrument(subtyp)}
                className={`option-card-instrument ${
                  subtyp.value === selectedSubTypeInstrument.value
                    ? 'click-option-card'
                    : ''
                }`}
              >
                {subtyp.description}
              </div>
            ))
          ) : (
            <div>No hay subtipos disponibles</div>
          )}
          {subType && subType.length < 3 && (
            <div className="option-card-instrument empty-option"></div>
          )}
        </div>
      )}

      <div className="chart-container">
        {selectedHistory.isLoading ? (
          <Spinner />
        ) : !selectedHistory.data || selectedHistory.data.length === 0 ? (
          <div>No hay datos disponibles</div>
        ) : (
          <CardHistoryChart
            historyData={selectedHistory.data}
            instrument={instrument}
            termNumber={termNumber}
          />
        )}
        {/* GRAFICO */}
      </div>
      {/* grafico */}
      {especieLoading ? (
        <div className="container-every-ticker">
          <Spinner />
        </div>
      ) : (
        <div className="container-every-ticker">
          {especie?.map((e) => {
            let nowPrice = 0;
            let dayPercentageChange = 0;
            const marketDataTerm = e?.marketData
              ? e?.marketData['Plazo' + termNumber]
              : null;
            if (instrument === 'Fondos') {
              nowPrice = parseFloat(e.marketData?.NOW_PRICE);
              dayPercentageChange = parseFloat(
                e.marketData?.DAY_PERCENTAGE_CHANGE
              );
            } else if (marketDataTerm) {
              nowPrice = parseFloat(marketDataTerm.NOW_PRICE);
              dayPercentageChange = parseFloat(
                marketDataTerm.DAY_PERCENTAGE_CHANGE
              );
            }
            // if (instrument === 'Fondos') {
            //   nowPrice = parseFloat(e.marketData?.NOW_PRICE);
            //   dayPercentageChange = parseFloat(
            //     e.marketData?.DAY_PERCENTAGE_CHANGE
            //   );
            // } else {
            //   nowPrice = parseFloat(
            //     e.marketData['plazo' + termNumber]?.NOW_PRICE
            //   );
            //   dayPercentageChange = parseFloat(
            //     e.marketData['plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE
            //   );
            // }

            return (
              <div
                key={e.ticker}
                className={`container-ticker ${selected === e.ticker &&
                  'selected-ticker'}`}
                onClick={() => setSelected(e.ticker)}
              >
                <div className="container-logo-ticker">
                  <img src={e?.iconUrl} alt="avatar icon" className="img-custom"/>
                </div>
                <div className="title-ticker">
                  <p>{e?.ticker}</p>
                </div>
                <div className="import-ticker">
                  {nowPrice
                    ? e.currency + ' ' +
                      twoDecimalsFormat(nowPrice)
                    : '-'}
                </div>
                <div
                  className={
                    dayPercentageChange > 0
                      ? 'percentage-ticker-positive'
                      : 'percentage-ticker'
                  }
                >
                  {`${
                    dayPercentageChange
                      ? twoDecimalsFormat(dayPercentageChange.toFixed(2)) + '%'
                      : '-'
                  }`}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* <div className="container-button-information">
        <img src={DownArrowRight} />
        <a className="button-information">
          Ampliar información
        </a>
      </div> */}
    </div>
  );
};
