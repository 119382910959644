import { useState, useMemo }  from 'react';

export const amountDirectionColor = {
    '': 'text-bold',
    '-': 'text-rejected',
    '+': 'text-success'
}

export const statusColor = {
    'ACEPTADO': 'text-success',
    'PENDIENTE': 'text-pending',
    'CANCELADO': 'text-cancelled',
    'RECHAZADO': 'text-rejected'
}

export const useFilteredData = (items) => {
    const [filterConfig, setFilterConfig] = useState(null);
    const [sortConfig, setSortConfig] = useState(null);
    
    const filteredItems = useMemo(() => {
      let filterableItems = [...items];
      if (filterConfig !== null) {
        filterableItems = filterableItems.filter((item) => (item[filterConfig.key] === filterConfig.value));
      }
      if (sortConfig !== null) {
        filterableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return filterableItems;
    }, [items, filterConfig, sortConfig]);
  
    const requestFilter = ({key, value}) => {
      setFilterConfig({ key, value });
    }
  
    const requestSort = key => {
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    }
  
    return { items: filteredItems, requestFilter, requestSort, sortConfig };
  }
