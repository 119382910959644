import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components actions and services
import { codMonedaToName } from '../../market-service';
import { OptionsTipoPersona } from '../../market-service';
import { TableContainer } from '../market-table-container/table-container';
import { SelectClientsWallet } from '../../../../components/selectClientsWallet/selectClientsWallet';
import { InputTextClientsWallet } from '../../../../components/inputTextClientsWallet/inputTextClientsWallet';
import { subTypeFund } from "../../market-service";

import { twoDecimalsFormat } from '../../../../common/common-service';

// utils
import tip from '../../../../utils/tip-blue-light-icon.svg';
import { Checkbox } from 'antd';
import { Tooltip } from '../../../../components/tooltip/tooltip';
import ArrowDown from '../../../../utils/arrow-down-blue-icon.svg';

// styles
import './ticker-fund-table.scss';
import {
  a_setFundTickerFiltered,
  a_setSelectedTicker,
  a_setTickerListWithFilter,
} from '../../market-slice';

export const TickerFundTable = ({
  subType,
  selectSubTypeInstrument,
  openCompareModal,
}) => {
  const dispatch = useDispatch();


  const {
    marketFundEsp,
    fundTickerFiltered,
    tickerListWithFilter,
    selectedTicker,
    instrumentNumber,
  } = useSelector((state) => state.market);
  useEffect(() => {
    dispatch(
      a_setFundTickerFiltered(
        marketFundEsp.map((fund, i) => ({
          key: fund.ticker,
          checked: i === 0,
          ticker: fund.name,
          class: fund.referenceData?.CLAZZ || '-',
          price: fund.marketData?.NOW_PRICE || '-',
          day_percentage: fund.marketData?.DAY_PERCENTAGE_CHANGE
            ? `${fund.marketData.DAY_PERCENTAGE_CHANGE}%`
            : '-',
          month_to_date: fund.referenceData?.MTD_RETURN
            ? `${fund.referenceData.MTD_RETURN}%`
            : '-',
          year_to_date: fund.referenceData?.YTD_RETURN
            ? `${fund.referenceData.YTD_RETURN}%`
            : '-',
          year: fund.referenceData?.LAST_YEAR_RETURN || '-',
          currency: codMonedaToName(fund.referenceData?.CODMONEDA),
          minimum:
            parseFloat(fund.referenceData?.MINIMO_INVERSION) > 0
              ? fund.referenceData.MINIMO_INVERSION
              : 'Sin minimo',
          es_fisico: fund.referenceData?.ES_FISICO || '-',
        }))
      )
    );
  }, [marketFundEsp, instrumentNumber]);

  const [search, setSearch] = useState('');
  const [esFisica, setEsFisica] = useState({
    value: null,
    description: 'Tipo Persona',
  });

  const [currentOpened, setCurrentOpened] = useState(null);

  const handleSetCurrentOpened = (title) => {
    setCurrentOpened(currentOpened === title ? null : title);
  };

  const handleSearchTextChange = (e) => {
    setSearch(e.target.value);
  };

  const tickerFilteredWithCheckedStatus = () => {
    const tickerFiltered = fundTickerFiltered.filter((tickerItem) => {
      if (search === '' && !esFisica.value) {
        return tickerItem;
      } else {
        if (search !== '' && esFisica.value) {
          if (esFisica.value === 'fisica') {
            if (
              tickerItem.key.toLowerCase().includes(search.toLowerCase()) &&
              tickerItem.es_fisico === 'true'
            )
              return tickerItem;
          } else if (esFisica.value === 'juridica') {
            if (
              tickerItem.key.toLowerCase().includes(search.toLowerCase()) &&
              tickerItem.es_fisico === 'false'
            )
              return tickerItem;
          } else {
            if (tickerItem.key.toLowerCase().includes(search.toLowerCase()))
              return tickerItem;
          }
        } else if (search !== '') {
          if (tickerItem.key.toLowerCase().includes(search.toLowerCase()))
            return tickerItem;
        } else if (esFisica.value) {
          if (esFisica.value === 'fisica') {
            if (tickerItem.es_fisico === 'true') return tickerItem;
          } else if (esFisica.value === 'juridica') {
            if (tickerItem.es_fisico === 'false') return tickerItem;
          } else {
            return tickerItem;
          }
        }
      }
    });

    const filteredWithCheckedStatus = tickerFiltered.map((tickerItem) => {
      return selectedTicker.find(
        (selectedTicker) => selectedTicker.key === tickerItem.key
      )
        ? { ...tickerItem, checked: true }
        : tickerItem;
    });

    return filteredWithCheckedStatus;
  };

  useEffect(() => {
    dispatch(a_setTickerListWithFilter(tickerFilteredWithCheckedStatus()));
  }, [search, esFisica]);

  const columns = [
    {
      key: 'checked',
      dataIndex: 'checked',
      width: '6%',
      title: '',
      render: (text, record) => (
        <div className="custom-check">
          <Checkbox
            defaultChecked={false}
            checked={record.checked}
            onChange={() => checkClient(record)}
          />
        </div>
      ),
    },
    {
      title: 'TICKER',
      dataIndex: 'ticker',
      key: 'ticker',
      width: '20%',
      align: 'left',
      className: 'ticker-abreviation',
    },
    {
      title: 'CLASE',
      dataIndex: 'class',
      key: 'class',
      width: '3%',
      align: 'center',
    },
    {
      title: 'PRECIO',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (text, record) => {
        const numero = twoDecimalsFormat(text);
        return numero;
      },
    },
    {
      title: '% DIA',
      dataIndex: 'day_percentage',
      key: 'day_percentage',
      align: 'center',
      render: (text, record) => {
        const numero = twoDecimalsFormat(text);
        return numero;
      },
    },
    {
      title: '% MTD',
      dataIndex: 'month_to_date',
      key: 'month_to_date',
      align: 'center',
      render: (text, record) => {
        const numero = twoDecimalsFormat(text);
        return numero;
      },
    },
    {
      title: '% YTD',
      dataIndex: 'year_to_date',
      key: 'year_to_date',
      align: 'center',
      render: (text, record) => {
        const numero = twoDecimalsFormat(text);
        return numero;
      },
    },
    {
      title: 'ANUAL',
      dataIndex: 'year',
      key: 'year',
      align: 'center',
      render: (text, record) => {
        const numero = twoDecimalsFormat(text);
        return numero;
      },
    },
    {
      title: 'MONEDA',
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
    },
    {
      title: 'INV. MÍNIMA',
      dataIndex: 'minimum',
      key: 'minimum',
      align: 'center',
    } /* ,
        {
            title: "ACCION",
            dataIndex: 'action',
            key: 'action',
            render: () => (
                <div
                    className="acciones-active"
                    onClick={() => console.log('SUSCRIBIR')}
                >
                    SUSCRIBIR
                </div>
            ),
            align:'center',
        } */,
  ];

  const checkClient = (checkedRecord) => {
    dispatch(
      a_setTickerListWithFilter(
        tickerListWithFilter.map((tickerItem) => {
          if (checkedRecord.key === tickerItem.key) {
            if (!tickerItem.checked) {
              if (selectedTicker.length < 3) {
                dispatch(a_setSelectedTicker([...selectedTicker, tickerItem]));
                return {
                  ...tickerItem,
                  checked: !tickerItem.checked,
                };
              }
            } else {
              dispatch(
                a_setSelectedTicker(
                  selectedTicker.filter(
                    (ticker) => ticker.key !== tickerItem.key
                  )
                )
              );
              return {
                ...tickerItem,
                checked: !tickerItem.checked,
                className: '',
              };
            }
          }
          return tickerItem;
        })
      )
    );
  };

  const changeSubTypeInstrumnet = (option) => {
    selectSubTypeInstrument(option, 'Fondos');
  };

  return (
    <div
      className={
        selectedTicker.length
          ? 'container-instrument-table scale-down-hor-left'
          : 'container-instrument-table scale-up-hor-left'
      }
    >
      <div className="instrument-table-header-selections">
        <div className="instrument-table-header-title">Fondos</div>
        <div className="instrument-table-header-text-filter">
          <InputTextClientsWallet
            placeholder="Buscar Fondo"
            type="text"
            onChange={handleSearchTextChange}
            value={search}
          />
        </div>
        <div className="intrument-table-header-select">
          <SelectClientsWallet
            title="Tipo"
            options={subTypeFund}
            selection={subType}
            setSelection={changeSubTypeInstrumnet}
            setCurrentOpened={handleSetCurrentOpened}
            arrowIcon={ArrowDown}
          />
        </div>
        {/* <div className="intrument-table-header-select">
          <SelectClientsWallet
            placeholder="Tipo Persona"
            options={OptionsTipoPersona}
            selection={esFisica}
            setSelection={setEsFisica}
            setCurrentOpened={handleSetCurrentOpened}
            arrowIcon={ArrowDown}
          />
        </div> */}
        <button
          className={
            selectedTicker.length > 1
              ? 'boton-comparar-active'
              : 'boton-comparar'
          }
          onClick={() => {
            selectedTicker.length > 1
              ? openCompareModal(true)
              : openCompareModal(false);
          }}
        >
          Comparar
        </button>
        <Tooltip
          iconSrc={tip}
          text="Para comparar, puede seleccionar hasta 3 Fondos Comunes de Inversión, y luego hacer click en el botón Comparar. De esta forma, podrá visualizar el valor cuotaparte por día de los fondos seleccionados en un mismo gráfico."
        />
      </div>
      <TableContainer columns={columns} scroll={{ y: '60vh' }} />
    </div>
  );
};
