import moment from "moment";

export const formattedInstrumentList = (response) => {
  if (response && response.dataBody && Array.isArray(response.dataBody.instruments)) {
    return {
      ...response.dataBody,
      instruments: response.dataBody.instruments.map((element) => ({
        ...element,
        description: element.name,
      }))
    };
  } else {
    return {
      instruments: [],
    };
  }
};


export const isANumber = (element) => {
  if (element !== isNaN) return true;
};

export const noDecimalFormat = (num) => {
  if (num && isANumber(num) && num !== "N/A") {
    return parseFloat(num).toLocaleString("es-AR");
  } else {
    return (num = "N/A");
  }
};

export const regularPriceFormat = (num) => {
  if (num && isANumber(num) && num !== "N/A") {
    return parseFloat(num)
      .toFixed(3)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return (num = "N/A");
  }
};

export const priceFormatFunds = (num) => {
  if (num && isANumber(num) && num !== "N/A") {
    return parseFloat(num).toFixed(7).replace(".", ",");
  } else {
    return (num = "0");
  }
};

export const twoDecimalsFormat = (num) => {
  if (num && isANumber(num) && num !== "N/A") {
    return parseFloat(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return (num = 0);
  }
};

export const thousandFormat = (num) => {
  if (num && isANumber(num) && num !== "N/A") {
    return num.toLocaleString("es-AR");
  }
};

export const mmFormat = (num) => {
  if (num && isANumber(num) && num !== "N/A") {
    const tier = Math.floor(Math.log10(Math.abs(num)) / 3);
    const scale = 10 ** (tier * 3);
    const scaled = num / scale;
    return scaled.toFixed(1).replace(".", ",");
  } else {
    return (num = "N/A ");
  }
};

export const setCheckedRadio = (currentTerm) => {
  const { id, term, subText } = currentTerm;
  return { id, term, subText, checked: true };
};

export const setUncheckedRadio = (currentTerm) => {
  const { id, term, subText } = currentTerm;
  return { id, term, subText, checked: false };
};

export const setCheckList = (radio, data) => {
  const id = radio.id;
  const list = data.map((term) =>
    id === term.id ? setCheckedRadio(term) : setUncheckedRadio(term)
  );
  return list;
};

export const checkElement = (element) => {
  return {
    ...element,
    checked: true,
  };
};

export const unCheckElement = (element) => {
  return {
    ...element,
    checked: false,
  };
};

export const updateLimitElement = (customer, vpri, vlim) => {
  return {
    ...customer,
    price: vpri,
    limit: vlim,
  };
};

export const updateTableByGlobalEdit = (partner, quantity, price) => {
  return {
    ...partner,
    price: price,
    quantity: quantity,
  };
};

export const updateTableFields = (
  customer,
  quantity,
  price,
  limit,
  countType
) => {
  return {
    ...customer,
    price: price,
    quantity: quantity,
    limit: limit,
    countType: countType,
  };
};

export const unCheckOperateTable = (list) => list.map((x) => unCheckElement(x));

export const checkOperateTable = (list) => list.map((x) => checkElement(x));

export const dateRange = () => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const initDate = "2020-10-02";
  const finalDate = year + "-" + month + "-" + day;
  return {
    initDate,
    finalDate,
  };
};

export const getNumberPrice = (client) => {
  let result = 0;
  if (client.checked && client.price !== "") {
    result = parseFloat((client.price + "").replace(",", "."));
  }
  return result;
};
export const getNumberQuantity = (client) => {
  let result;
  if (client.checked) {
    if (client.quantity === "") {
      result = 0;
    } else {
      result = parseFloat((client.quantity + "").replace(",", ".")).toFixed(4);
    }
  } else {
    result = 0;
  }
  return result;
};
export const getNumberPriceClient = (client) => {
  let result = 0;
  if (client && client.price !== undefined && client.price !== "") {
    result = parseFloat(String(client.price).replace(",", "."));
  }
  return result;
};

export const getNumberQuantityClient = (client) => {
  let result = 0;
  if (client && client.quantity !== undefined && client.quantity !== "") {
    result = parseFloat(String(client.quantity).replace(",", ".")).toFixed(4);
  }
  return result;
};



export const getNumberQuantityForFunds = (client) => {
  let result;
  if (client.checked) {
    if (client.quantity === "") {
      result = parseFloat("0");
    } else if (client.countType === "PARCIAL") {
      result = parseFloat(client.quantity.replace(".", "").replace(",", "."));
    } else {
      result = parseFloat(client.quantity.replace(",", "."));
    }
  } else {
    result = parseFloat("0");
  }
  return result;
};

// Asumiendo que 'client' es un objeto y no un arreglo.
export const getSelectedClientQuantity = (selectedClient) => {
  return selectedClient ? parseFloat(selectedClient.quantity).toFixed(4) : '0.0000';
};

export const getSelectedClientPrice = (selectedClient) => {
  return selectedClient ? parseFloat(selectedClient.price).toFixed(2) : '0.00';
};

export const getTotalQuantity = (list) => {
  const totalQuantity = list?.reduce((acc, x) => acc + parseFloat(getNumberQuantity(x)), 0);
  return totalsFormat(totalQuantity, 4);
};

export const totalPriceTable = (list) => {
  const totalPrice = list?.reduce((acc, x) => acc + getNumberPrice(x), 0);
  return totalsFormat(totalPrice, 2);
};

export const selectInitialState = { value: -1, description: "" };

export const subTypeFund = [
  { value: 0, description: "Agresivos", subType: "Agresivo" },
  { value: 1, description: "Moderados", subType: "Moderado" },
  { value: 2, description: "Conservadores", subType: "Conservador" },
];

export const subTypeBond = [
  { value: 0, description: "Soberanos", subType: "GOVERNMENT" },
  { value: 1, description: "Provinciales", subType: "PROVINCIAL" },
  { value: 2, description: "Corporativos", subType: "CORPORATE" },
];

export const subTypeEquity = [
  { value: 0, description: "S&P Merval", subType: "BYMA.MERV" },
  { value: 2, description: "S&P/BYMA Índice General", subType: "BYMA.IGB" },
];

export const optionsWallets = [
  { value: 0, description: "Cartera Fondos Conservador" },
  { value: 1, description: "Cartera Fondos Moderado" },
  { value: 2, description: "Cartera Fondos Agresivo" },
];

export const isTimeToOperate = (open, close, isFund) => {
  let extra = moment().format("YYYY-MM-DD") + " ";
  let start_time = moment(extra + open);
  let end_time = moment(extra + close);

  if (isFund) return moment().isSameOrBefore(end_time);
  return moment().isBetween(start_time, end_time);
};

export const defineTypes = (
  instrument,
  combinedInstruments,
  isTimeToOperate
) => {
  let canOperate;
  let instrumentData;
  let translatedInstrument;

  switch (instrument) {
    case "FONDOS":
      translatedInstrument = "FUND";
      break;
    case "ACCIONES":
      translatedInstrument = "EQUITY";
      break;
    case "BONOS":
      translatedInstrument = "BOND";
      break;
    case "CEDEARS":
      translatedInstrument = "CERTIFICATE";
      break;
    default:
      return []; 
  }

  instrumentData = combinedInstruments.find(inst => inst.type === translatedInstrument);

  if (!instrumentData) {
    return [];
  }

  if (canOperate) {
    return instrumentData.subTypes.map(subType => ({ value: subType.subType, label: subType.subType }));
  } else {
    return [];
  }
};


export const chooseFieldFromEspecie = (selectedInstrument, especie) => {
  if (selectedInstrument.description === "Fondos") {
    return especie.code;
  } else {
    return especie.ticker;
  }
};

export const orderList = (data) => {
  let orderList = data["message"];
  return Array.isArray(orderList) ? orderList : [];
};

export const totalsFormat = (number, n) => {
  const format = number?.toFixed(n).toString();
  if (number > 999) {
    return format
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
  }
};

export const filterTickerInformation = (data) => {
  return data[0];
};

export const onClickTapOption = (i, tabOptions, setTabOptions) => {
  let tO = tabOptions.map((item) => {
    let x = { ...item };
    x.selected = false;
    return x;
  });
  tO[i]["selected"] = true;
  setTabOptions(tO);
  tO[i].onClick();
};

export const bondsPricePerUnit = (price) => {
  const pricePerUnit = parseFloat(price) / 100;
  const pricePerUnitFormatted = pricePerUnit
    .toFixed(2)
    .toString()
    .replace(".", ",");

  return pricePerUnitFormatted;
};

export const getCurrencyType = (type) => {
  return type?.replace("S", "$");
};

export const percenWithTwoDecimals = (value) => {
  return parseFloat(value)?.toFixed(4) * 100 + " %";
};

/**
 * @description function to get default specie despite its currency
 * @example if AL30D or AL30C is chosen, this function will return AL30.
 * @param {string} specie
 */
export const getDefaultSpecie = (specie) => {
  const hasDifferentTypes =
    specie.charAt(5) === "C" || specie.charAt(5) === "D";
  if (specie.length === 5 && hasDifferentTypes) {
    return specie.slice(0, -1);
  } else {
    return specie;
  }
};

export const getTermType = (plazo) => {
  switch (plazo) {
    case 1:
      return "C. I";
    case 2:
      return "24 hs";
    default:
      return "48 hs";
  }
};

export const getNewItem = (item, type, nowPrice) => {
  let newItem = { ...item, countType: type };

  if (type === "TOTAL") {
    let netAmount = nowPrice * (parseFloat(newItem?.speciesCount.replace(".", "").replace(",", "."))).toFixed(4);
    newItem["quantity"] = (newItem?.speciesCount+"")?.replace(".", "");
    newItem["price"] = netAmount.toFixed(2).replace(".", ",");
  }

  if (type === "PARCIAL") {
    newItem["limit"] = nowPrice;
    newItem["quantity"] = "0,00";
    newItem["price"] = "0,00";
  }

  return newItem;
};

export const getCurrentTerm = (termNumber) => {
  switch (termNumber) {
    case 3:
      return "PARCIAL";
    case 4:
      return "TOTAL";
    default:
      return "";
  }
};

export const getPriceByInstru = (instrument, price) => {
  switch (instrument) {
    case "Bonos":
      return parseFloat((price) / 100).toFixed(4);
    case "Cedears":
      return parseFloat(price) * 1.5;
    case "Fondos":
      return parseFloat(price); 
    default:
      return parseFloat(price); 
  }
};

export const getPriceLimit = (
  marketData,
  termNumber,
  subOptionItem,
  instrument
) => {
  try {
    let dataByTerm = marketData?.["plazo" + termNumber];
    if (subOptionItem === "COMPRAR") {
      return getPriceByInstru(instrument, dataByTerm.ASK_PRICE).replace(
        ".",
        ","
      );
    }
    if (subOptionItem === "VENDER") {
      return getPriceByInstru(instrument, dataByTerm.BID_PRICE).replace(
        ".",
        ","
      );
    }
    if (subOptionItem === "SUSCRIBIR" || subOptionItem === "RESCATAR") {
      return marketData.NOW_PRICE.replace(".", ",");
    }
  } catch (err) {
    return "";
  }
  return "0,0";
};

export const getClientsListFilteredByTabs = (
  customersByPartner,
  currency,
  id
) => {
  return customersByPartner.filter((client) => {
    let amount;
    if (currency === "ARS") {
      amount = client["tmArs"][id]["amount"];
    }
    if (currency === "USD" || currency === "USD-C") {
      amount = client["tmUsd"][id]["amount"];
    }
    return parseFloat(amount.replace(".", "").replace(",", ".")) > 0;
  });
};

export const getClientWithAmountToShow = (client, currency, tmId) => {
  if (currency === "ARS") {
    return {
      ...client,
      amountToShow: "ARS " + client["tmArs"][tmId]["amount"],
    };
  }
  if (currency === "USD" || currency === "USD-C") {
    return {
      ...client,
      amountToShow: "USD " + client["tmUsd"][tmId]["amount"],
    };
  }
};

export const filterBySearches = (firstFiltered, search) => {
  let resultFiltered = firstFiltered;

  if (search.name !== "" && search.codComitente !== "") {
    resultFiltered = resultFiltered.filter((dataElement) =>
      dataElement.name.toLowerCase().includes(search.name.toLowerCase())
    );
    resultFiltered = resultFiltered.filter((dataElement) =>
      dataElement.numComitente.includes(search.codComitente)
    );
    return resultFiltered;
  }

  if (search.name !== "") {
    resultFiltered = firstFiltered.filter((dataElement) =>
      dataElement.name.toLowerCase().includes(search.name.toLowerCase())
    );
  }
  if (search.codComitente !== "") {
    resultFiltered = firstFiltered.filter((dataElement) =>
      dataElement.numComitente.includes(search.codComitente)
    );
  }
  return resultFiltered;
};

const goBackTransform = (instrument, price) => {
  if (price === undefined || price === null) {
    return "";
  }
  if (instrument === "Bonos") {
    price = price.replace(".", "").replace(",", ".") * 100;
  }
  return price.toString().replace(".", ",");
};

const cleanNum = (n) => {
  if ((n + "").slice(-1) === ",") {
    n = n + "0";
  }
  return n;
};
export const clientFormatted = (
  selectedClient,
  instrument,
  tradingOption,
  specieInfo
) => {
  let resultList = [];
  let esTotal = false;
  if (instrument === "Fondos" && tradingOption === "RESCATAR") {
    esTotal = selectedClient.countType === "TOTAL";
  }
  let comm = {
    esTotal: esTotal,
    codComitente: selectedClient.codComitente,
    numComitente: selectedClient.numComitente,
    email: selectedClient.email,
    cuit: selectedClient.cuit,
    nombreComitente: selectedClient.name,
    cantidad: cleanNum(esTotal ? selectedClient.speciesCount : selectedClient.quantity),
    importeNeto: esTotal ? 0 : cleanNum(selectedClient.price.toString().replace(".", ",")),
    precio: cleanNum(
      esTotal
        ? specieInfo.marketData.NOW_PRICE.replace(".", ",")
        : goBackTransform(instrument, selectedClient.price)
    ),
  };
  resultList.push(comm);
  return resultList;
};

export const clientListFormatted = (
  customersByPartnerFiltered,
  instrument,
  tradingOption,
  specieInfo
) => {
  let checkItems = customersByPartnerFiltered.filter((x) => x.checked);
  let resultList = checkItems.map((client) => {
    let comm = {};
    let esTotal = false;
    if (instrument === "Fondos" && tradingOption === "RESCATAR") {
      esTotal = client.countType === "TOTAL";
      comm["esTotal"] = esTotal;
    }
    comm = {
      ...comm,
      codComitente: client.codComitente,
      numComitente: client.numComitente,
      email: client.email,
      cuit: client.cuit,
      nombreComitente: client.name,
      cantidad: cleanNum(esTotal ? client.speciesCount : client.quantity),
      importeNeto: esTotal ? 0 : cleanNum(client.price.toString().replace(".", ",")),
      precio: cleanNum(
        esTotal
          ? specieInfo.marketData.NOW_PRICE.replace(".", ",")
          : goBackTransform(instrument, client.limit)
      ),
    };

    return comm;
  });
  return resultList;
};

export const emptyOrders = (orders) => {
  return orders?.reduce((acc, o) => {
    if (o.checked) {
      let price = parseFloat((o.price + "").replace(".", "").replace(",", "."));
      let quant = parseFloat(
        (o.quantity + "").replace(".", "").replace(",", ".")
      );
      return acc && price > 0 && quant > 0;
    }
    return true
  }, true);
};
export const isOrderEmpty = (orders) => {
  return orders.some(order => {
    const quantity = parseFloat(order.quantity);
    const price = parseFloat(order.price);
    return !order.checked || Number.isNaN(quantity) || quantity <= 0 || Number.isNaN(price) || price <= 0;
  });
};

export const isOrderEmptyClient = (selectedClient, instrument) => {
  if (!selectedClient || selectedClient.quantity == null || selectedClient.price == null) {
    return true;
  }

  const quantityStr = (selectedClient.quantity || '').toString().replace(',', '.');
  const priceStr = (selectedClient.price || '').toString().replace(',', '.');
  const quantity = parseFloat(quantityStr);
  const price = parseFloat(priceStr);
  
  if (instrument === 'Fondos') {
    return Number.isNaN(quantity) || quantity <= 0 || Number.isNaN(price) || price <= 0;
  } else {
    return Number.isNaN(quantity) || quantity <= 0 || !Number.isInteger(quantity) || Number.isNaN(price) || price <= 0;
  }
};




 
export const cantByInst = (p) => {
  if (p.CodFondo) {
    if ((!p.CantCuotapartes && p.Cantidad === 0) || (parseFloat(p.CantCuotapartes) === 0)) {
      return '-'
    } else {
      return p.Cantidad
    }
  } else {
    return p.Cantidad
  }
};

export const orderType = (instDesc) => {
  var srt = instDesc.toLowerCase(); 
  switch ( srt ) {
    case "acciones":
      return "Accion";
    case "bonos":
      return "Bono";
    case "cedears":
      return "CEDEAR";
    default:
      return "Fondo";
  }
};

export const getValueWithCodMoneda = (codMoneda, value) => {
  return `${(codMoneda === 1 ? "ARS" : "USD")} ${value}`
}