import React from 'react'
import { Chart } from 'react-chartjs-2'
import moment from 'moment'
import './card-history-chart.scss'
import { generateDateLabels } from '../../market-service'

export const CardHistoryChart = ({ historyData = [], instrument , termNumber}) => {

    if (!historyData || historyData.length === 0) {
        return <div>No hay datos disponibles</div>;
    }  

    const firstMonth = moment(historyData[0].fecha).format('MMMM');

    return (
        <div className="card-history-chart-container">
            <Chart 
                type="line"
                width={100}
                height={35}
                data={{
                    labels:generateDateLabels(historyData),
                    datasets:[{
                         data: // instrument === 'Fondos' ? 
                            historyData.map(dataItem => dataItem.marketData.PREV_CLOSE_PRICE) ,
                            // : historyData.map(dataItem => dataItem.marketData.HIGH_PRICE),
                        fill:true,
                        borderColor : '#5c9afe',
                        tension:0.25,
                        pointRadius:1,
                    }],
                }}
                options={{
                    interaction: {
                        mode: 'nearest',
                        intersect: false
                    },
                    scales:{
                        x:{
                            display:false,
                            grid: {
                                display: false
                            }
                        },
                        y:{
                            display: false,
                            grid: {
                                display: false
                            }
                        },
                    },
                    plugins:{
                        legend:{
                            display:false
                        },
                    },
                }}
            />
            <div className="market-history-chart-period">
                <p style={{ textTransform: 'capitalize' }}>{firstMonth}</p>
                <p>Mes Actual</p>
            </div>
            <div>
                % Mensual FCI / % Diario Instrumentos
            </div>
            <br></br>
        </div>
    )
}
