import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TeamLeft } from "./team-components/teamLeft/teamLeft";
import { TeamRight } from "./team-components/team-right/team-right";

import ReallocatePortfolio from "./team-components/modals/reallocate-portforlio";
import DisableAccount from "./team-components/modals/disable-account";
import RolChange from "./team-components/modals/rol-change";
import SendMessage from "./team-components/modals/send-message";

import { api_getTeamTree, api_getTeamHoldingAccounts } from "../team/team-api";

import "./team-style.scss";

export const Team = () => {
  const state = useSelector((state) => ({
    ...state["commonReducer"],
    ...state["teamReducer"],
  }));

  const [menuOpened, setMenuOpened] = useState(true);
  const [modalState, setModalState] = useState("none");

  const { teamTree, teamHoldingAccounts } = state;

  const dispatch = useDispatch();

  // getTeamTree -------------------------------
  useEffect(() => {
    try {
      let params = {
        idProducer: 179,
      };
      dispatch(api_getTeamTree(params));
    } catch (error) {
      throw new Error(error);
    }
  }, []);

/*   useEffect(() => {
    try {
      let params = {
        idProducer: 180,
        accountsDateFrom: "2020-01-01",
        accountsDateTo: "2021-02-01",
        moneyDate: "2021-02-01",
      };
      dispatch(api_getTeamHoldingAccounts(params));
    } catch (error) {
      throw new Error(error);
    }
  }, []); */

  const closeModal = () => {
    setModalState("none");
  };

  const selectClient = (client) => {
    try {
      let params = {
        idProducer: client.idPartner,
        accountsDateFrom: "2020-01-01",
        accountsDateTo: "2021-02-01",
        moneyDate: "2021-02-01",
      };
      dispatch(api_getTeamHoldingAccounts(params));
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
        <div className="render-container">
          <div className="team-dashboard-container">
            <TeamLeft 
              teamTree={teamTree} 
              selectClient={selectClient} 
              menuOpened={menuOpened} 
              setMenuOpened={setMenuOpened}
              setModalVisibility={setModalState}
            />
            <TeamRight 
              teamHoldingAccounts={teamHoldingAccounts} 
              menuOpened={menuOpened} 
            />
          </div>
        </div>
        {modalState === "reallocate-portfolio" && (
          <ReallocatePortfolio onClose={closeModal} />
        )}
        {modalState === "disable-account" && (
          <DisableAccount onClose={closeModal} account={teamTree} />
        )}
        {modalState === "rol-change" && (
          <RolChange onClose={closeModal} />
        )}
        {modalState === "send-message" && (
          <SendMessage onClose={closeModal} />
        )}
    </>

  );
};
