import React from "react";
import { ElementData } from "../common/element-data";
import {
  regularPriceFormat,
  noDecimalFormat,
  twoDecimalsFormat,
  mmFormat,
  percenWithTwoDecimals,
  getCurrencyType,
} from "../../operate-service";

import { useFetchSpecieInfo } from "../common/custom-hooks";

import "./equities-detail-especie.scss";

export const Equities = () => {
  const specieData = useFetchSpecieInfo();

  const operacionesData = [
    {
      field: "Precio del Día",
      value: `${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.termData?.["LOW_PRICE"])} - 
      ${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.termData?.["HIGH_PRICE"])}`,
    },
    {
      field: "Precio Año",
      value: `${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.refData?.["YEAR_MIN"])} - 
      ${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.refData?.["YEAR_MAX"])}`,
    },
    {
      field: "Volumen Nominal",
      value: noDecimalFormat(specieData?.termData?.["VOLUME"]),
    },
    {
      field: "Volumen Promedio (10d)",
      value: noDecimalFormat(specieData?.refData?.["VOLUME_10D_AVG"]),
    },
    {
      field: "Volumen Promedio (3m)",
      value: noDecimalFormat(specieData?.refData?.["VOLUME_3M_AVG"]),
    },
    {
      field: "Beta",
      value: twoDecimalsFormat(specieData?.refData?.["BETA"]),
    },
    {
      field: "Cap. de Mercado",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${mmFormat(
        specieData?.refData?.["MARKET_CAPITAL"]
      )}MM`,
    },
  ];

  const balanceData = [
    {
      field: "Ingresos Totales",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${mmFormat(
        specieData?.refData?.["TOTAL_REVENUE"]
      )}MM`,
    },
    {
      field: "EBITDA",
      value: `${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.refData?.["EBITDA"])}`,
    },
    {
      field: "Ingresos Netos",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${mmFormat(
        specieData?.refData?.["NET_INCOME"]
      )}MM`,
    },
    {
      field: "Ganancia por Acción",
      value: `${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.refData?.["EARN_PER_SHARE"])}`,
    },
    {
      field: "Dividendos por Acción",
      value: `${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.refData?.["DIVIDENDS_PER_SHARE"])}`,
    },
    {
      field: "Valor de Libro por Acción",
      value: `${getCurrencyType(
        specieData?.refData?.["CURRENCY"]
      )} ${regularPriceFormat(specieData?.refData?.["BOOK_VALUE_PER_SHARE"])}`,
    },
  ];

  const ratiosData = [
    {
      field: "Precio/Beneficio",
      value: regularPriceFormat(specieData?.refData?.["PRICE_EARNING_TTM"]),
    },
    {
      field: "Precio de Valor Contable",
      value: regularPriceFormat(specieData?.refData?.["PRICE_BOOK"]),
    },
    {
      field: "Precio de Venta",
      value: regularPriceFormat(specieData?.refData?.["PRICE_SALE"]),
    },
    {
      field: "ROA",
      value: `${twoDecimalsFormat(specieData?.refData?.["ROA"])} %`,
    },
    {
      field: "ROE",
      value: `${twoDecimalsFormat(specieData?.refData?.["ROE"])} %`,
    },
    {
      field: "Dividendos/Rendimientos",
      value: regularPriceFormat(specieData?.refData?.["DIVIDEND_YIELD"]),
    },
  ];

  const indicesTecnicosData = [
    {
      field: "RSI",
      value: `${percenWithTwoDecimals(specieData?.refData?.["RSI"])}`,
    },
    {
      field: "OE",
      value: `${percenWithTwoDecimals(specieData?.refData?.["OE"])}`,
    },
    {
      field: "MACD",
      value: `${twoDecimalsFormat(specieData?.refData?.["MACD"])}`,
    },
  ];

  const Operaciones = () => {
    return (
      <div className="operations-container">
        {operacionesData.map((element, id) => (
          <ElementData key={id} element={element} />
        ))}
      </div>
    );
  };

  const Balance = () => {
    return (
      <>
        <div className="especie-detail-subtitle">Balance</div>
        <div className="balance-container">
          {balanceData.map((element, id) => (
            <ElementData key={id} element={element} />
          ))}
        </div>
      </>
    );
  };

  const Ratios = () => {
    return (
      <>
        <div className="especie-detail-subtitle">Ratios</div>
        <div className="ratios-container">
          {ratiosData.map((element, id) => (
            <ElementData key={id} element={element} />
          ))}
        </div>
      </>
    );
  };

  const IndicesTecnicos = () => {
    return (
      <>
        <div className="especie-detail-subtitle">Índices Técnicos</div>
        <div className="technical-indexes-container">
          {indicesTecnicosData.map((element, id) => (
            <ElementData key={id} element={element} />
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="performance-container">
      <Operaciones />
      <Balance />
      <Ratios />
      <IndicesTecnicos />
    </div>
  );
};
