import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCallAsync } from "../../common/common-service";

// GET CUSTOMER DETAILS
export const api_getCustomerDetails = createAsyncThunk(
  "home/fetchCustomerDetails",
  async (codComitente, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/home/partner/customers/portfolio/detail",
        method: "POST",
        data: codComitente,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// GET LAST MOVEMENTS
export const api_getLastMovements = createAsyncThunk(
  "home/fetchLastMovements",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/home/partner/lastmov",
        method: "POST",
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// PARTNER INFO
export const api_getPartnerInformationPersonal = createAsyncThunk(
  "home/api_getPartnerInfo",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/home/partner/info",
        method: "POST",
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// END PARTNER INFO

// CUSTOMERS BY PARTNER
export const api_getPartnerInformationExtensive = createAsyncThunk(
  "common/api_getPartnerInformationExtensive",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/home/info",
        method: "GET",
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// END CUSTOMERS BY PARTNER
const initialState = {
  /*-------- /home/partner/customers/portfolio/detail --------*/
  loadingCustomerDetail: false,
  customerDetail: {},
  /*-------- /home/partner/info --------*/
  loadingUserLogged: false,
  partnerInfo: {},
  /*-------- /home/partner/lastmov --------*/
  loadingLastMovements: false,
  lastMovements: [],
  /*-------- /home/info--------*/
  loadingCustomersByPartner: false,
  customersByPartner: [],
  userPortfolio: [],
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET CUSTOMER DETAILS
    builder.addCase(api_getCustomerDetails.pending, (state) => {
      state.loadingCustomerDetail = true;
    });
    builder.addCase(api_getCustomerDetails.fulfilled, (state, action) => {
      state.loadingCustomerDetail = false;
      state.customerDetail = action.payload.dataBody;
    });
    builder.addCase(api_getCustomerDetails.rejected, (state) => {
      state.loadingCustomerDetail = false;
    });

    // // PARTNER INFO
    builder.addCase(api_getPartnerInformationPersonal.pending, (state) => {
      state.loadingUserLogged = true;
    });
    builder.addCase(api_getPartnerInformationPersonal.fulfilled, (state, action) => {
      state.loadingUserLogged = false;
      state.partnerInfo = { ...action.payload.dataBody };
    });
    builder.addCase(api_getPartnerInformationPersonal.rejected, (state, action) => {
      state.loadingUserLogged = false;
      state.errorUserLogged = action.error.message;
    });

    // GET LAST MOVEMENTS
    builder.addCase(api_getLastMovements.pending, (state) => {
      state.loadingLastMovements = true;
    });
    builder.addCase(api_getLastMovements.fulfilled, (state, action) => {
      state.loadingLastMovements = false;
      state.lastMovements = {...action.payload.dataBody };
    });
    builder.addCase(api_getLastMovements.rejected, (state) => {
      state.loadingLastMovements = false;
      state.lastMovements = [];
    });
    // // CUSTOMERS BY PARTNER
    builder.addCase(api_getPartnerInformationExtensive.pending, (state) => {
      state.loadingCustomersByPartner = true;
    });
    builder.addCase(api_getPartnerInformationExtensive.fulfilled, (state, action) => {
      state.loadingCustomersByPartner = false;
      state.userPortfolio = action.payload.dataBody
      state.customersByPartner = action.payload.dataBody.customers;
    });
    builder.addCase(api_getPartnerInformationExtensive.rejected, (state, action) => {
      state.loadingCustomersByPartner = false;
      state.error = action.error;
    });
  },
});

export const homeReducer = homeSlice.reducer;
export const homeActions = homeSlice.actions;
