import React, { useState } from "react";

import iconMoreOptions from "../../../../utils/hamburger-button.svg";
import showMenuIcon from "../../../../utils/menu-burger.svg";
import hideMenuIcon from "../../../../utils/caret-left.svg";

import { Spinner } from "../../../../components/spinner/spinner";

import "./teamLeft.scss";

export const TeamLeft = ({
  teamTree,
  selectClient,
  menuOpened,
  setMenuOpened,
  setModalVisibility
}) => {
  return (
    <>
      <div
        className={
          menuOpened
            ? "show-hide-menu-button slide-in-left"
            : "show-hide-menu-button-relocate slide-in-right"
        }
      >
        <button
          onClick={() => setMenuOpened(!menuOpened)}
          className="menu-trigger-button"
        >
          <img
            src={menuOpened ? hideMenuIcon : showMenuIcon}
            alt="hide-teams-menu"
            width="auto"
            height="auto"
          />
        </button>
      </div>
      <div
        className={
          menuOpened
            ? "team-dashboard-container-left slide-in-left"
            : "team-dashboard-container-left-off-canvas slide-in-right"
        }
      >
        <div className="table-container">
          <div className="item-partner-separator">&nbsp;</div>
          <h2 className="title-team">Mi Equipo</h2>
          <div className="item-partner-separator">&nbsp;</div>
          {teamTree && teamTree.rolDescription ? (
            <ul className="table">
            <li>
              <div className="item-partner item-main director">
                <div className="team-rol blue">{teamTree.rolDescription}</div>
                <div className="name">{teamTree.nombre}</div>
                <div className="team">
                  E. {teamTree.cantidadPartnersPorTeam}
                </div>
                <div className="clients">
                  C. {teamTree.teamCantidadHijosDirectos}{" "}
                  <span>({teamTree.cantidadComitentes})</span>
                </div>
              {/* estaba aca los 3 puntitos */}
              </div>
            </li>
          </ul>
          ) : (
            /* <ul className="table">
              <li>
                <div className="item-partner item-main director">
                  <div className="team-rol blue">{teamTree.rolDescription}</div>
                  <div className="name">
                    {teamTree.nombre} {teamTree.apellido}
                  </div>
                  <div className="team">
                    E. {teamTree.cantidadPartnersPorTeam}
                  </div>
                  <div className="clients">
                    C. {teamTree.teamCantidadHijosDirectos}{" "}
                    <span>({teamTree.cantidadComitentes})</span>
                  </div>
                  <div className="dropdown">
                    <button className="view-more">
                      <img
                        src={iconMoreOptions}
                        alt="Menu Icon"
                        width="auto"
                        height="auto"
                      />
                    </button>
                    <div className="dropdown-content">
                      <a href="#" onClick={() => setModalState("send-message")}>
                        ENVIAR MENSAJE
                      </a>
                      <a
                        href="#"
                        onClick={() => setModalState("reallocate-portfolio")}
                      >
                        REASIGNAR CARTERA
                      </a>

                      <a href="#" onClick={() => setModalState("rol-change")}>
                        CAMBIAR ROL
                      </a>
                      <a
                        href="#"
                        onClick={() => setModalState("disable-account")}
                        className="dropdown-delete-account-special-link"
                      >
                        DESHABILITAR CUENTA
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul> */
            <Spinner />
          )}
          <div className="item-partner-separator">&nbsp;</div>
          <h2 className="title-search">Buscador de partners a cargo</h2>
          <div className="selections">
            <div className="custom--search--clean--container input--small--10">
              <input
                type="text"
                name="search"
                className="custom--search--clean--input input--small--10"
                placeholder="Buscar Partner..."
                onChange={(e) => console.log(e)}
              ></input>
              <div className="custom--search--clean--iconSearch">
                <img src="/static/media/icon-buscar.d471b63d.svg" alt="seach" />
              </div>
              {/* <div className="custom--search--clean--iconClean"></div> */}{" "}
              {/*mostrar solo cuando el usuario tipea*/}
            </div>
          </div>
          <div className="item-partner-separator">&nbsp;</div>
          {teamTree && teamTree.team ? (
            <Tree teamTree={teamTree.team} selectClient={selectClient} setModalVisibility={setModalVisibility} />
          ) : (
            <Spinner />
          )}
          
        </div>

      </div>
    </>
  );
};

const Tree = ({ teamTree, selectClient, setModalVisibility }) => {
  return (
    <ul className="table">
      {teamTree &&
        teamTree.map((tree, i) => (
          <TreeNode node={tree} key={i} selectClient={selectClient} setModalVisibility={setModalVisibility} />
        ))}
    </ul>
  );
};

const TreeNode = ({ node, selectClient, setModalVisibility }) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const hasChild = node.team ? true : false;

  return (
    <li>
      <div className="extend-tree">
        <div className="item-partner item-main">
          <div
            className="arrow"
            onClick={() => setChildVisiblity((childVisible) => !childVisible)}
          >
            {node.team.length !== 0 && (
              <div className={`${childVisible ? "active" : ""}`}>
                <i className="icon-caret-down"></i>
              </div>
            )}
          </div>
          <div className={`team-rol ${(node.team.length !== 0 ) ? 'light-blue' : 'blue'}`}>{node.rolDescription}</div>
          <div className="name" onClick={() => selectClient(node)}>
            {node.nombre} {node.apellido}
          </div>
          <div className="team">
            {node.cantidadPartnersPorTeam
              ? `E. ${node.cantidadPartnersPorTeam}`
              : ""}
          </div>
          <div className="clients">
            C. {node.teamCantidadHijosDirectos}{" "}
            <span>
              {node.cantidadPartnersPorTeam
                ? `(${node.cantidadComitentes})`
                : ""}
            </span>
          </div>
          <div className="dropdown">
                  <button className="view-more">
                    <img
                      src={iconMoreOptions}
                      alt="Menu Icon"
                      width="auto"
                      height="auto"
                    />
                  </button>
                  <div className="dropdown-content">
                    <a href="#" onClick={() => setModalVisibility("send-message")}>
                      ENVIAR MENSAJE
                    </a>
                    <a
                      href="#"
                      onClick={() => setModalVisibility("reallocate-portfolio")}
                    >
                      REASIGNAR CARTERA
                    </a>

                    <a href="#" onClick={() => setModalVisibility("rol-change")}>
                      CAMBIAR ROL
                    </a>
                    <a
                      href="#"
                      onClick={() => setModalVisibility("disable-account")}
                      className="dropdown-delete-account-special-link"
                    >
                      DESHABILITAR CUENTA
                    </a>
                  </div>
                </div>
     

          {hasChild && childVisible && (
            <ul className="display-team">
              <Tree teamTree={node.team} selectClient={selectClient} setModalVisibility={setModalVisibility} />
            </ul>
          )}
        </div>
        
      </div>
    </li>
    /* <li>
      <div className="extend-tree">
        <div className="item-partner item-main">
          <div
            className="arrow"
            onClick={() => setChildVisiblity((childVisible) => !childVisible)}
          >
            {node.team.length !== 0 && (
              <div className={`${childVisible ? "active" : ""}`}>
                <i className="icon-caret-down"></i>
              </div>
            )}
          </div>
          <div className="team-rol blue">{node.rolDescription}</div>
          <div className="name" onClick={() => selectClient(node)}>
            {node.nombre} {node.apellido}
          </div>
          <div className="team">
            {node.cantidadPartnersPorTeam
              ? `E. ${node.cantidadPartnersPorTeam}`
              : ""}
          </div>
          <div className="clients">
            C. {node.teamCantidadHijosDirectos}{" "}
            <span>
              {node.cantidadPartnersPorTeam
                ? `(${node.cantidadComitentes})`
                : ""}
            </span>
          </div>

          {hasChild && childVisible && (
            <ul className="display-team">
              <Tree teamTree={node.team} selectClient={selectClient}/>
            </ul>
          )}
        </div>
      </div>
    </li> */
  );
};
