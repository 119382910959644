import { axiosCall } from "../../common/common-service";

import {
  a_loadRequestFeeDetail,
  a_setSuccessFeeDetail,
  a_setFailureFeeDetail,
  a_loadRequestCurrentAccount,
  a_setSuccessCurrentAccount,
  a_setFailureCurrentAccount,
  a_loadRequestHolding,
  a_setSuccessHolding,
  a_setFailureHolding,
  a_loadRequestHoldingDetail,
  a_setSuccessHoldingDetail,
  a_setFailureHoldingDetail,
  a_loadRequestTicketbyNum,
  a_setSuccessTicketbyNum,
  a_setFailureTicketbyNum,
  a_loadRequestTicket,
  a_setSuccessTicket,
  a_setFailureTicket,
  a_loadRequestLiquidation,
  a_setSuccessLiquidation,
  a_setFailureLiquidation,
} from "./wallet-action";

export const api_getFeeDetail = (params) => {
  return axiosCall({
    path: "/portfolio/partner/fee/detail",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestFeeDetail,
    a_onSuccess: a_setSuccessFeeDetail,
    a_onError: a_setFailureFeeDetail,
  });
};

// DEPRECATED
export const api_getCurrentAccount = (params) => {
  return axiosCall({
    path: "/portfolio/currentaccount",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestCurrentAccount,
    a_onSuccess: a_setSuccessCurrentAccount,
    a_onError: a_setFailureCurrentAccount,
  });
};

export const api_getCurrentAccount_v2 = (params) => {
  return axiosCall({
    path: "/portfolio/currentaccountv2",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestCurrentAccount,
    a_onSuccess: a_setSuccessCurrentAccount,
    a_onError: a_setFailureCurrentAccount,
  });
};

export const api_getHolding = (params) => {
  return axiosCall({
    path: "/portfolio/holding",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestHolding,
    a_onSuccess: a_setSuccessHolding,
    a_onError: a_setFailureHolding,
  });
};

export const api_getHoldingDetail = (params) => {
  return axiosCall({
    path: "/portfolio/holding/detail",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestHoldingDetail,
    a_onSuccess: a_setSuccessHoldingDetail,
    a_onError: a_setFailureHoldingDetail,
  });
};

export const api_getTicketbyNum = (params) => {
  return axiosCall({
    path: "/portfolio/ticket-by-num",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestTicketbyNum,
    a_onSuccess: a_setSuccessTicketbyNum,
    a_onError: a_setFailureTicketbyNum,
  });
};

export const api_getTicket = (params) => {
  return axiosCall({
    baseURL: "_PARTNERS",
    path: "/portfolio/ticket",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestTicket,
    a_onSuccess: a_setSuccessTicket,
    a_onError: a_setFailureTicket,
  });
};

export const api_getLiquidation = (params) => {
  return axiosCall({
    baseURL: "_PARTNERS",
    path: "/portfolio/liquidation",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestLiquidation,
    a_onSuccess: a_setSuccessLiquidation,
    a_onError: a_setFailureLiquidation,
  });
};
