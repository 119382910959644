import React from 'react';
import { useSelector } from 'react-redux';

// ant design
import { Button } from 'antd';

// utils
import iconSuccess from '../../utils/checked.png';
import iconError from '../../utils/error.png';
import question from '../../utils/question.svg';

// styles
import './recover-user-pass.scss';
import './problem-login.scss';

// components actions
import VerifyUserEmail from './recover-password/verify-user-email';
import VerifyCode from './recover-password/verify-code';
import VerifyNewPassword from './recover-password/verify-new-password';

export const RecoverPass = ({ backToLogin, onClickAction, onModalTerms }) => {
  const state = useSelector((state) => state['common']);
  const { recoverPassVerify, tokenConfirm, recoverPassRestore, passwordResetStatus, codeVerificationStatus} = state;
  const isErrorMessage = recoverPassRestore === null;
  const isVerifyUserEmail = recoverPassVerify?.logging !== true;
  const isVerifyCode = recoverPassVerify?.logging === true && codeVerificationStatus === null;
  const isVerifyNewPassword = recoverPassVerify?.logging === true && codeVerificationStatus?.logging === true && tokenConfirm && passwordResetStatus === null;
  const isSuccessMessage = passwordResetStatus?.logging === true && recoverPassVerify?.logging === true && codeVerificationStatus?.logging === true && tokenConfirm;
  return (
    <div className="recover-password-container">
      <div className="recover-password-wrapper">
      {isErrorMessage && <ErrorMessage backToLogin={backToLogin} />}
      {isVerifyUserEmail && <VerifyUserEmail backToLogin={backToLogin} />}
      {isVerifyCode && <VerifyCode />}
      {isVerifyNewPassword && <VerifyNewPassword />}
      {isSuccessMessage && <SuccessMessage backToLogin={backToLogin} />}
        <div className="container-form-problem-login">
          <img src={question} alt="logo" />
          <div className="description-login">
            Tenés problemas para ingresar a tu cuenta?
          </div>
          <div className="click-form-login" onClick={onClickAction}>
            Escríbenos aquí
          </div>
          <div className="description-login">
            Te responderemos a la brevedad
          </div>
          <div className="legals-link" onClick={onModalTerms}>
            <p> Al ingresar, aceptas los términos y condiciones</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessMessage = ({ backToLogin }) => {
  return (
    <div className="success-message">
      {<img src={iconSuccess} alt="icon" />}
      <h2>¡Su contraseña fue cambiada con éxito!</h2>
      <p>Ya podes ingresar con tu nombre de usuario y tu nueva contraseña.</p>
      <Button className="sbtn-to-login" onClick={backToLogin} block="true">
        IR AL LOGIN
      </Button>
    </div>
  );
};

const ErrorMessage = ({ backToLogin }) => {
  return (
    <div className="success-message error-message">
      {<img src={iconError} alt="icon" />}
      <h2>¡Ha surgido un error durante el proceso!</h2>
      <p className="text-danger">
        No se ha podido cambiar tu contraseña. Podes volver a intentarlo en unos
        minutos.
      </p>
      <Button className="btn-to-login" onClick={backToLogin} block="true">
        IR AL LOGIN
      </Button>
    </div>
  );
};
