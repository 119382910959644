import React from "react";
import "./team-report-download.scss";

import Reporte from "../../../../utils/icon-cta-descargarecibos.svg";

export const TeamReportDownload = () => {
  
  const weeklyReport = () => {
    console.log("Llamado a servicio de informe")
  };

  return (
    <>
    <div className="button-receipt-download" onClick={weeklyReport}>
      Descarga de Recibos
    </div>
    </>
  );
};