import React from "react";
import { useSelector } from "react-redux";

// components
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { NoData } from "../../../../components/no-data/no-data";

// utils
import checkBlue from "../../../../utils/circle-icon-blue.svg";
import checkGreen from "../../../../utils/circle-icon-green.svg";

// styles
import "./statics-profile.scss";

export const StaticsProfile = () => {

  const { userPortfolio } = useSelector((state) => state.home);

  const availableInvested =   userPortfolio?.availableMoneyArs + userPortfolio?.investedMoneyArs;
  const available =  userPortfolio?.availableMoneyArs * 100 / availableInvested ;
  const invested = userPortfolio?.investedMoneyArs * 100 / availableInvested ;

  const dataSet = [available, invested];
  let donutGrap = dataSet;

  if (available < 1) {
    donutGrap = [4, 96]
  } else if (invested < 1) {
    donutGrap = [96, 4]
  }

  const dataPesos = {
    labels: ["Disponible en Pesos", "Invertido en Pesos"],
    datasets: [
      {
        data: [
          donutGrap[0].toFixed(2),
          donutGrap[1].toFixed(2),
        ],
        backgroundColor: ["#52B5B1", "#7381F7"],
      },
    ],
  };

  const noBalanceARS =
  userPortfolio.availableMoneyArs === 0 &&
  userPortfolio.investedMoneyArs === 0;

  const Check = ({ icon, description }) => {
    return (
      <>
        <div className="checks-title">
          <img src={icon} alt="reference" className="check-title-image" />
          <div className="check-reference-text">{description}</div>
        </div>
      </>
    );
  };

  return (
    <div className="statics">
      {noBalanceARS ? (
        <NoData text="Saldo no disponible en pesos" />
      ) : (
        <div className="doughnut-wrapper" id="ChartHome">
          <div className="checks-home">
            <Check icon={checkBlue} description={`Invertido  ${ invested.toFixed(2)}%`}/>
            <Check icon={checkGreen} description={`Disponible  ${available.toFixed(2)}%`} />
          </div>
          <Chart
            type="doughnut"
            data={dataPesos}
            options={{
              responsive: false,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: (labels) => ` ${dataPesos.labels[labels.dataIndex]} : ${dataSet[labels.dataIndex].toFixed(2)}%`,
                  }
                }
              }
            }}
          />
          
        </div>
      )}
    </div>
  );
};
