import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Spinner } from "../../../../components/spinner/spinner";

import {
  api_getDataDisclaimer,
  api_postAuthDisclaimer,
} from "../../operate-api";

import "./disclaimer.scss";

export const Disclaimer = ({ disclaimerID, partnerID }) => {
  const state = useSelector((state) => state["operateReducer"]);
  const { loadingDiscData, discData } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(api_getDataDisclaimer({ disclaimerViewId: disclaimerID }));
  }, []);

  const confirmDisclaimer = () => {
    dispatch(
      api_postAuthDisclaimer({
        disclaimerViewId: disclaimerID
      })
    );
  };

  return loadingDiscData ? (
    <Spinner />
  ) : (
    <>
      <div className="component-disclaimer-modal-container">
        <div className="component-disclaimer-modal-wrapper">
          <div className="component-disclaimer-modal-title-container">
            <div className="component-disclaimer-modal-title">
              {discData.title}
            </div>
          </div>
          <div className="component-disclaimer-modal-description-container">
            <div className="component-disclaimer-modal-description">
              {discData.description}
            </div>
          </div>
          <div className="component-disclaimer-modal-time-container">
            <div
              className="component-disclaimer-modal-time"
              onClick={() => {
                confirmDisclaimer();
              }}
            >
              Aceptar Términos y Condiciones
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
