import {
  /*------------- /team/tree -------------*/
  LOAD_REQUEST_TEAM_TREE,
  SET_SUCCESS_TEAM_TREE,
  SET_FAILURE_TEAM_TREE,
  /*------------- /team/data -------------*/
  LOAD_REQUEST_TEAM_HOLDING_ACCOUNTS,
  SET_SUCCESS_TEAM_HOLDING_ACCOUNTS,
  SET_FAILURE_TEAM_HOLDING_ACCOUNTS,
  /*------------- /file/uploadsend -------------*/
  LOAD_REQUEST_TEAM_UPLOAD_BILL,
  SET_SUCCESS_TEAM_UPLOAD_BILL,
  SET_FAILURE_TEAM_UPLOAD_BILL,
  /*------------- /team/file/list -------------*/
  LOAD_REQUEST_TEAM_BILL_LIST,
  SET_SUCCESS_TEAM_BILL_LIST,
  SET_FAILURE_TEAM_BILL_LIST,
  /*------------- /team/fee -------------*/
  LOAD_REQUEST_TEAM_FEE,
  SET_SUCCESS_TEAM_FEE,
  SET_FAILURE_TEAM_FEE,
} from "../../common/actionTypes";

export const a_loadRequestTeamTree = () => {
  return { type: LOAD_REQUEST_TEAM_TREE };
};

export const a_setSuccessTeamTree = (data) => {
  return { type: SET_SUCCESS_TEAM_TREE, payload: data };
};

export const a_setFailureTeamTree = () => {
  return { type: SET_FAILURE_TEAM_TREE, payload: null };
};

export const a_loadRequestTeamHoldingAccounts = () => {
  return { type: LOAD_REQUEST_TEAM_HOLDING_ACCOUNTS };
};

export const a_setSuccessTeamHoldingAccounts = (data) => {
  return { type: SET_SUCCESS_TEAM_HOLDING_ACCOUNTS, payload: data };
};

export const a_setFailureTeamHoldingAccounts = () => {
  return { type: SET_FAILURE_TEAM_HOLDING_ACCOUNTS, payload: null };
};

export const a_loadRequestTeamUploadBill = () => {
  return { type: LOAD_REQUEST_TEAM_UPLOAD_BILL };
};

export const a_setSuccessTeamUploadBill = (data) => {
  return { type: SET_SUCCESS_TEAM_UPLOAD_BILL, payload: data };
};

export const a_setFailureTeamUploadBill = () => {
  return { type: SET_FAILURE_TEAM_UPLOAD_BILL, payload: null };
};

export const a_loadRequestTeamBillsList = () => {
  return { type: LOAD_REQUEST_TEAM_BILL_LIST };
};

export const a_setSuccessTeamBillsList = (data) => {
  return { type: SET_SUCCESS_TEAM_BILL_LIST, payload: data };
};

export const a_setFailureTeamBillsList = () => {
  return { type: SET_FAILURE_TEAM_BILL_LIST, payload: null };
};

export const a_loadRequestTeamFee = () => {
  return { type: LOAD_REQUEST_TEAM_FEE };
};

export const a_setSuccessTeamFee = (data) => {
  // data = [
  //     {
  //         "codComitente": "unCodComitente-1",
  //         "fee": [
  //             {
  //                 "fecha": "2021-01-05",
  //                 "total": "439,59"
  //             },
  //             {
  //                 "fecha": "2021-02-07",
  //                 "total": "409,59"
  //             },
  //             {
  //                 "fecha": "2021-03-09",
  //                 "total": "329,59"
  //             },
  //             {
  //                 "fecha": "2021-04-10",
  //                 "total": "529,59"
  //             }
  //         ]
  //     },
  //     {
  //         "codComitente": "unCodComitente-2",
  //         "fee": [
  //             {
  //                 "fecha": "2021-03-03",
  //                 "total": "914,76"
  //             },
  //             {
  //                 "fecha": "2021-05-10",
  //                 "total": "429,59"
  //             },
  //             {
  //                 "fecha": "2021-07-17",
  //                 "total": "29,99"
  //             }
  //         ]
  //     },
  //     {
  //         "codComitente": "unCodComitente-3",
  //         "fee": [
  //             {
  //                 "fecha": "2021-02-13",
  //                 "total": "114,76"
  //             },
  //             {
  //                 "fecha": "2021-04-15",
  //                 "total": "329,59"
  //             },
  //             {
  //                 "fecha": "2021-06-22",
  //                 "total": "529,59"
  //             },
  //             {
  //                 "fecha": "2021-08-28",
  //                 "total": "729,59"
  //             }
  //         ]
  //     },
  //     {
  //         "codComitente": "unCodComitente-4",
  //         "fee": [
  //             {
  //                 "fecha": "2021-09-03",
  //                 "total": "214,76"
  //             },
  //             {
  //                 "fecha": "2021-11-13",
  //                 "total": "529,59"
  //             }
  //         ]
  //     }
  // ];
  return { type: SET_SUCCESS_TEAM_FEE, payload: data };
};

export const a_setFailureTeamFee = () => {
  return { type: SET_FAILURE_TEAM_FEE, payload: null };
};
