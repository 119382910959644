import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { isTimeToOperate } from "../../operate-service";


import "./operate-schedule-view.scss";

export const OperateScheduleView = () => {
  const { operateInstruments, instrument } = useSelector((state) => state.operate);

  const [classByTime, setClassByTime] = useState("warning-container");
  const [startTime, setStartTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);

  useEffect(() => {
    const isFund = (instrument==="Fondos") ? true : false;
    
    const getClassName = () => {
      let instSelected;
      let term;
      
      if (isFund) {
        instSelected = operateInstruments?.["instruments"][0];
        term = instSelected?.["terms"][0];
      } else {
        instSelected = operateInstruments?.["instruments"].find((x) =>
        x?.["terms"].find((y) => y["checked"])
        );
        term = instSelected?.["terms"]?.find((x) => x["checked"]);
      }
      
      if (instSelected && term) {
        let openingHour = term["openingHour"];
        setStartTime(openingHour);
        let closingHour = term["closingHour"];
        setEndTime(closingHour);
        if (isTimeToOperate(openingHour, closingHour, isFund)) {
          return "next-step-container";
        }
      }
      
      return "warning-container";
    };
    
    setClassByTime(getClassName());
  }, [instrument,
      operateInstruments["instruments"][0]["terms"],
      operateInstruments["instruments"][1]["terms"],
      operateInstruments["instruments"][2]["terms"],
      operateInstruments["instruments"][3]["terms"],
    ]);

  return (
    <>
    <div className="operate-container-schedule">
      <div className={`operate-container-schedule-container ${classByTime}`}>
        <div className="operate-container-schedule-title">HORARIO</div>
        <div className="operate-container-schedule-atention">
          Días hábiles de {startTime} hs a {endTime} hs
        </div>
      </div>
    </div>
    <br></br>
    </>
  );
};
