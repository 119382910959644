import React from "react";

import HeaderWallet from "./header-wallet";
import WalletTabs from "../wallet-tabs/wallet-tabs";
import Tenencia from "../tenencia/tenencia";
import Fee from "../fee/fee";
import CheckingAccount from "../checking-account/checking-account";
import Vouchers from "../vouchers/vouchers";

import "./wallet-right.scss";

const WalletRight = ({
  // TABS
  option,
  setOption,
  // CROSS
  selectedClientDashboard,
  setDate,
  // FEE
  setPeriod,
  period,
  // CUENTA CORRIENTE
  selectedDate,
  setStartDate,
  startDate,
  // COMPROBANTES
  liquidation,
  // TENENCIA
  funds,
  bonds,
  cedears,
  actions,
  foreignExchange,
  divisas,
  negotiableObligations,
  financialTrusts,
  lets,
  lecaps,
  quotation,
  menuOpened
}) => {
  return (
    <div className={
      menuOpened
        ? "wallet-dashboard-container-right slide-in-right"
        : "wallet-dashboard-container-right-full-size"
    }>
      {/* **********************  HEADER ********************* */}
      <HeaderWallet selectedClientDashboard={selectedClientDashboard} />
      {/* **********************   TABS  ********************* */}
      <><div className="vertical-separator">&nbsp;</div></>
      <WalletTabs
        option={option}
        setOption={setOption}
        selectedClientDashboard={selectedClientDashboard}
      />
      <><div className="item-wallet-separator">&nbsp;</div></>
      {/* *****************  CARDS / VIEWS  ****************** */}
      {option === "TENENCIA" && (
        <Tenencia
          selectedClientDashboard={selectedClientDashboard}
          data={{
            funds,
            bonds,
            cedears,
            actions,
            foreignExchange,
            divisas,
            negotiableObligations,
            financialTrusts,
            lets,
            lecaps,
            quotation,
          }}
        />
      )}
      {/* {option === "FEE" && (
        <Fee
          setDate={setDate}
          period={period}
          setPeriod={setPeriod}
          selectedClientDashboard={selectedClientDashboard}
        />
      )} */}
      {option === "CUENTA_CORRIENTE" && selectedClientDashboard && (
        <CheckingAccount
          setDate={setDate}
          selectedDate={selectedDate}
          setStartDate={setStartDate}
          startDate={startDate}
          selectedClientDashboard={selectedClientDashboard}
        />
      )}
      {option === "COMPROBANTES" && selectedClientDashboard && (
        <Vouchers
          liquidation={liquidation}
          selectedClientDashboard={selectedClientDashboard}
        />
      )}
      <><div className="item-wallet-separator">&nbsp;</div></>
      <><div className="item-wallet-separator">&nbsp;</div></>
    </div>
  );
};

export default WalletRight;
