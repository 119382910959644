import React from 'react';
import { useSelector } from 'react-redux'

import iconPrice from "../../../../utils/icon-price.svg"

const MepQuotationCard = (props) => {
    const { quotationDollar, loadingQuotationDollar } = useSelector(state => (state.market));
    return (
        (loadingQuotationDollar) ? (
            <div className="container-list">
                <div className="mep-quotation-item-container" />
            </div>
        ) : (
            <div className="container-list">
                <div className="mep-quotation-item-container">
                    <div className="mep-quotation-item-title">
                        <div className="mep-quotation-text">
                            <div className='mep-quotation-symbol'>
                                MEP
                            </div>
                            <div className="mep-quotation-description">
                                COTIZACIÓN MEP
                            </div>
                        </div>
                    </div>
                    <div className="mep-quotation-item-price-container">
                        <div className='mep-quotation-info-container'>
                            <div className='mep-quotation-subtitle'>
                                Compra
                            </div>
                            <div className='mep-quotation-item-price'>
                                ${String(quotationDollar.dollarBuyMEP)}
                            </div>
                        </div>
                        <div className='mep-quotation-info-container'>
                            <div className='mep-quotation-subtitle'>
                                Venta
                            </div>
                            <div className='mep-quotation-item-price'>
                                ${String(quotationDollar.dollarSellMEP)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

MepQuotationCard.defaultProps = {
}

export default MepQuotationCard