import React from "react";
import "./spinner.scss";

export const Spinner = () => {
  return <div className="loader">Loading</div>;
};

export const HorizontalSpinner = () => {
  return (
    <div className="horizontal-loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}