import moment from 'moment';
import React from 'react';
import { uniq } from 'lodash';
import { Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../../components/spinner/spinner';
import { generateDateLabels, codMonedaToName} from '../../market-service';

export const MarketHistoryChart = ({ label , width , height }) => {

    // const state = useSelector((state) => ({
    //     ...state["marketReducer"],
    //     ...state["commonReducer"],
    //     ...state["operateReducer"],
    // }));
    // const {
    //     // tickerHist,
    //     // loadingTickerHist,
    //     // termNumber,
    //     // instrumentNumber,
    //     // specieInfo
    // } = state
    const { loadingTickerHist, tickerHist, termNumber, instrumentNumber } = useSelector((state) => state.market);
    const { specieInfo } = useSelector((state) => state.operate);

    let months = tickerHist?.map((r) => moment(r.fecha).format('MMM'))
    return (
        loadingTickerHist ? <Spinner /> :
            <div className="ticker-detail-chart-cotizaciones-tab">
                <Chart
                    type="line"
                    width={400}
                    height={height}
                    data={{
                        labels: generateDateLabels(tickerHist),
                        datasets: [{
                            data: instrumentNumber > 0 ? tickerHist.map(dataItem =>
                                // dataItem.price.marketData['plazo' + termNumber]?.PREV_CLOSE_PRICE) :
                                // tickerHist.map(dataItem => dataItem.price.marketData?.PREV_CLOSE_PRICE)
                                dataItem?.marketData?.PREV_CLOSE_PRICE) :
                                tickerHist.map(dataItem => dataItem?.marketData?.PREV_CLOSE_PRICE),
                            fill: true,
                            borderColor: "#5c9afe",
                            tension:0.25,
                            pointRadius:1,
                        }]
                    }}
                    options={{
                        interaction: {
                            mode: 'nearest',
                            intersect: false
                        },
                        spanGaps:true,
                        scales:{
                            x:{
                                display:false,
                                grid: {
                                    display: false
                                }
                            },
                            y:{
                                position: 'right',
                                title:{
                                    display:true,
                                    text: codMonedaToName(specieInfo?.cod || '')
                                    // text: codMonedaToName(specieInfo?.referenceData?.CODMONEDA || ''),
                                } 
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        }
                    }}
                />
                <div className="market-history-chart-period">
                    {
                        uniq(months).map((month, index) => (
                            <p key={index} style={{ textTransform: 'capitalize' }}>{month}</p>
                        ))
                    }
                    <p/>
                </div>
            </div>
    );
};
