import {
  SET_CURRENCY,
  /*---------- /portfolio/partner/fee/detail ----------*/
  LOAD_REQUEST_FEE_DETAIL,
  SET_SUCCESS_FEE_DETAIL,
  SET_FAILURE_FEE_DETAIL,
  /*---------- /portfolio/partner/holding ----------*/
  LOAD_REQUEST_HOLDING,
  SET_SUCCESS_HOLDING,
  SET_FAILURE_HOLDING,
  /*-------- /portfolio/partner/holding/detail --------*/
  LOAD_REQUEST_HOLDING_DETAIL,
  SET_SUCCESS_HOLDING_DETAIL,
  SET_FAILURE_HOLDING_DETAIL,
  /*------------ /portfolio/currentaccount ------------*/
  LOAD_REQUEST_CURRENT_ACCOUNT,
  SET_SUCCESS_CURRENT_ACCOUNT,
  SET_FAILURE_CURRENT_ACCOUNT,
  /*------------- /portfolio/ticket-by-num -------------*/
  LOAD_REQUEST_TICKET_BY_NUM,
  SET_SUCCESS_TICKET_BY_NUM,
  SET_FAILURE_TICKET_BY_NUM,
  /*------------- /portfolio/ticket -------------*/
  LOAD_REQUEST_TICKET,
  SET_SUCCESS_TICKET,
  SET_FAILURE_TICKET,
  /*------------- /portfolio/liquidation -------------*/
  LOAD_REQUEST_LIQUIDATION,
  SET_SUCCESS_LIQUIDATION,
  SET_FAILURE_LIQUIDATION,
} from "../../common/actionTypes";

export const a_setCurrency = (data) => {
  return { type: SET_CURRENCY, payload: data };
};

export const a_loadRequestFeeDetail = () => {
  return { type: LOAD_REQUEST_FEE_DETAIL };
};

export const a_setSuccessFeeDetail = (data) => {
  return { type: SET_SUCCESS_FEE_DETAIL, payload: data };
};

export const a_setFailureFeeDetail = () => {
  return { type: SET_FAILURE_FEE_DETAIL, payload: [] };
};

export const a_loadRequestHolding = () => {
  return { type: LOAD_REQUEST_HOLDING };
};

export const a_setSuccessHolding = (data) => {
  return { type: SET_SUCCESS_HOLDING, payload: data };
};

export const a_setFailureHolding = () => {
  return { type: SET_FAILURE_HOLDING, payload: [] };
};

export const a_loadRequestHoldingDetail = () => {
  return { type: LOAD_REQUEST_HOLDING_DETAIL };
};

export const a_setSuccessHoldingDetail = (data) => {
  return { type: SET_SUCCESS_HOLDING_DETAIL, payload: data };
};

export const a_setFailureHoldingDetail = () => {
  return { type: SET_FAILURE_HOLDING_DETAIL, payload: [] };
};

export const a_loadRequestCurrentAccount = () => {
  return { type: LOAD_REQUEST_CURRENT_ACCOUNT };
};

export const a_setSuccessCurrentAccount = (data) => {
  return { type: SET_SUCCESS_CURRENT_ACCOUNT, payload: data };
};

export const a_setFailureCurrentAccount = () => {
  return { type: SET_FAILURE_CURRENT_ACCOUNT, payload: [] };
};

export const a_loadRequestTicketbyNum = () => {
  return { type: LOAD_REQUEST_TICKET_BY_NUM };
};

export const a_setSuccessTicketbyNum = (data) => {
  return { type: SET_SUCCESS_TICKET_BY_NUM, payload: data };
};

export const a_setFailureTicketbyNum = () => {
  return { type: SET_FAILURE_TICKET_BY_NUM, payload: null };
};

export const a_loadRequestTicket = () => {
  return { type: LOAD_REQUEST_TICKET };
};

export const a_setSuccessTicket = (data) => {
  return { type: SET_SUCCESS_TICKET, payload: data };
};

export const a_setFailureTicket = () => {
  return { type: SET_FAILURE_TICKET, payload: null };
};

export const a_loadRequestLiquidation = () => {
  return { type: LOAD_REQUEST_LIQUIDATION };
};

export const a_setSuccessLiquidation = (data) => {
  return { type: SET_SUCCESS_LIQUIDATION, payload: data };
};

export const a_setFailureLiquidation = () => {
  return { type: SET_FAILURE_LIQUIDATION, payload: [] };
};
