import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  subTypeEquity,
  subTypeBond,
  setCheckList,
} from "../../../operate/operate-service";
import { subTypeFund } from "../../market-service";

import { TERM_C } from "../../../../constants/constants";
import { CardInstrument } from "./card-instrument";
import { LiquidityTermOptions } from "../market-liquidity-term-options/liquidity-term-options";

import "./instrument-price.scss"; 
import { a_setMarketTerm } from "../../market-slice";

export const InstrumentPrice = () => {
  const dispatch = useDispatch();

  const [listTerms, setListTerms] = useState(TERM_C);

  const [termNumber, setTermNumber] = useState(2);

  // const state = useSelector((state) => ({
  //   ...state["marketReducer"],
  // }));

  // const {
  //   // loadingFundMarket,
  //   // marketFundEsp,
  //   // loadingEquityMarket,
  //   // marketEquityEsp,
  //   // loadingBondMarket,
  //   // marketBondEsp,
  //   // loadingCertMarket,
  //   // marketCertEsp,
  // } = state;

  const {
    loadingFundMarket,
    marketFundEsp,
    loadingBondMarket,
    marketBondEsp,
    loadingEquityMarket,
    marketEquityEsp,
    loadingCertMarket,
    marketCertEsp,
  } = useSelector((state) => state.market);
  
  const selectTerm = (radio) => {
    setListTerms(setCheckList(radio, listTerms));
    setTermNumber(radio["id"] - 1);
    dispatch(a_setMarketTerm(radio["id"] - 1));
  };

  return (
    <div className="container-instrument-price">
      <div className="container-options">
        <div className="container-options-title">
          <p>Tipo de Plazo:</p>
        </div>
        <LiquidityTermOptions options={listTerms} setOption={selectTerm} />
      </div>
      <div className="container-card-information">
        <CardInstrument
          instrument={"Fondos"}
          subType={subTypeFund}
          especie={marketFundEsp}
          especieLoading={loadingFundMarket}
        />
        <CardInstrument
          instrument={"Bonos"}
          subType={subTypeBond}
          especie={marketBondEsp}
          especieLoading={loadingBondMarket}
          termNumber={termNumber}
        />
        <CardInstrument
          instrument={"Acciones"}
          subType={subTypeEquity}
          especie={marketEquityEsp}
          especieLoading={loadingEquityMarket}
          termNumber={termNumber}
        />
        <CardInstrument
          instrument={"CEDEARs"}
          especie={marketCertEsp}
          especieLoading={loadingCertMarket}
          termNumber={termNumber}
        />
      </div>
    </div>
  );
};
