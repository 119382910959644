import React from "react";

export const ElementData = ({ element }) => {
  return (
    <div className="element-data">
      <div className="field" tabIndex="0">
        {element.field}
      </div>
      <div className="value" tabIndex="0">
        {element.value}
      </div>
    </div>
  );
};
