import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { OptionsTipoMoneda } from '../../market-service';
import { useTableActiveData } from '../market-hooks/tables-hooks';
import { TableContainer } from '../market-table-container/table-container';
import { SelectClientsWallet } from '../../../../components/selectClientsWallet/selectClientsWallet';
import { InputTextClientsWallet } from '../../../../components/inputTextClientsWallet/inputTextClientsWallet';
import ArrowDown from '../../../../utils/arrow-down-blue-icon.svg';
import { a_setCertTickerFiltered } from '../../market-slice';

export const TickerCertTable = ({ termNumber }) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => ({
    ...state['marketReducer'],
    ...state['commonReducer'],
  }));

  // const { marketCertEsp, instrumentNumber } = state;
  const { marketCertEsp, instrumentNumber } = useSelector((state) => state.market);

  const [
    handleSearchTextChange,
    search,
    handleMonedaChange,
    moneda,
    columns,
  ] = useTableActiveData('cert');
  const [currentOpened, setCurrentOpenedState] = useState(null);

  const setCurrentOpened = (title) => {
    setCurrentOpenedState(title);
  };

  const filterCertificadosTerm = async () => {
   await dispatch(
      a_setCertTickerFiltered(
        marketCertEsp.map((cert, i) => ({
          key: cert.ticker,
          selected: i === 0 ? true : false,
          ticker: cert.name,
          last: cert?.marketData?.['Plazo' + termNumber]?.NOW_PRICE ? parseFloat(cert?.marketData?.['Plazo' + termNumber]?.NOW_PRICE).toFixed(2) : '-',
          day_percentage: cert?.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE ? `${parseFloat(
                cert?.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE
              ).toFixed(2)}%`
            : '-',
          buy_price: cert?.marketData?.['Plazo' + termNumber]?.BID_PRICE
            ? `${parseFloat(
                cert?.marketData?.['Plazo' + termNumber]?.BID_PRICE
              ).toFixed(2)}`
            : `-`,
          sell_price: cert?.marketData?.['Plazo' + termNumber]?.ASK_PRICE
            ? `${parseFloat(
                cert?.marketData?.['Plazo' + termNumber]?.ASK_PRICE
              ).toFixed(2)}`
            : `-`,
          vol_nom: cert?.marketData?.['Plazo' + termNumber]?.VOLUME
            ? `${parseFloat(
                cert.marketData?.['Plazo' + termNumber]?.VOLUME
              ).toFixed(2)}`
            : `-`,
          currency: cert?.currency,
          last_closeure_price: cert?.marketData?.['Plazo' + termNumber]
            ?.PREV_CLOSE_PRICE
            ? cert?.marketData?.['Plazo' + termNumber]?.PREV_CLOSE_PRICE
            : '-',
        }))
      )
    );
  }

  useEffect(() => {
    filterCertificadosTerm();
  }, [marketCertEsp, instrumentNumber, termNumber]);

  return (
    <div className="container-instrument-table">
      <div className="instrument-table-header-selections">
        <div className="instrument-table-header-title">CEDEARs</div>
        <div className="instrument-table-header-text-filter">
          <InputTextClientsWallet
            placeholder="Buscar Ticker"
            type="text"
            onChange={handleSearchTextChange}
            value={search}
          />
        </div>
        <div className="intrument-table-header-select">
          <SelectClientsWallet
            placeholder="Tipo Persona"
            options={OptionsTipoMoneda}
            selection={moneda}
            setSelection={handleMonedaChange}
            sizeList="176px"
            sizeContainer="128px"
            setCurrentOpened={setCurrentOpened}
            arrowIcon={ArrowDown}
          />
        </div>
      </div>
      <TableContainer columns={columns} scroll={{ y: '60vh' }} />
    </div>
  );
};
