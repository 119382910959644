export const SET_OPEN_BLUR = "SET_OPEN_BLUR";
/* ********************************************************************* */

export const SET_GENERIC_SERVICE_FAILURE = "SET_GENERIC_SERVICE_FAILURE";
export const SET_TAB_ORDER_IN_PROGRESS = "SET_TAB_ORDER_IN_PROGRESS";
/* ********************************************************************* */

export const LOAD_REQUEST_SIGN_IN_DATA = "LOAD_REQUEST_SIGN_IN_DATA";
export const SET_SUCCESS_SIGN_IN_DATA = "SET_SUCCESS_SIGN_IN_DATA";
export const SET_FAILURE_SIGN_IN_DATA = "SET_FAILURE_SIGN_IN_DATA";
/* ********************************************************************* */

export const LOAD_REQUEST_USER_DATA = "LOAD_REQUEST_USER_DATA";
export const SET_SUCCESS_USER_DATA = "SET_SUCCESS_USER_DATA";
export const SET_FAILURE_USER_DATA = "SET_FAILURE_USER_DATA";
/* ********************************************************************* */

export const LOAD_REQUEST_USER_PORTFOLIO = "LOAD_REQUEST_USER_PORTFOLIO";
export const SET_SUCCESS_USER_PORTFOLIO = "SET_SUCCESS_USER_PORTFOLIO";
export const SET_FAILURE_USER_PORTFOLIO = "SET_FAILURE_USER_PORTFOLIO";
/* ********************************************************************* */

export const LOAD_REQUEST_LAST_MOVEMENTS = "LOAD_REQUEST_LAST_MOVEMENTS";
export const SET_SUCCESS_LAST_MOVEMENTS = "SET_SUCCESS_LAST_MOVEMENTS";
export const SET_FAILURE_LAST_MOVEMENTS = "SET_FAILURE_LAST_MOVEMENTS";
/* ********************************************************************* */

export const LOAD_REQUEST_CUSTOMERS_BY_PARTNER =
  "LOAD_REQUEST_CUSTOMERS_BY_PARTNER";
export const SET_SUCCESS_CUSTOMERS_BY_PARTNER =
  "SET_SUCCESS_CUSTOMERS_BY_PARTNER";
export const SET_FAILURE_CUSTOMERS_BY_PARTNER =
  "SET_FAILURE_CUSTOMERS_BY_PARTNER";
/* ********************************************************************* */

export const LOAD_REQUEST_EXPENSES = "LOAD_REQUEST_EXPENSES";
export const SET_SUCCESS_EXPENSES = "SET_SUCCESS_EXPENSES";
export const SET_FAILURE_EXPENSES = "SET_FAILURE_EXPENSES";
/* ********************************************************************* */

export const LOAD_REQUEST_INSERT_ORDER = "LOAD_REQUEST_INSERT_ORDER";
export const SET_INSERT_ORDER = "SET_INSERT_ORDER";
export const SET_SUCCESS_INSERT_ORDER = "SET_SUCCESS_INSERT_ORDER";
export const SET_FAILURE_INSERT_ORDER = "SET_FAILURE_INSERT_ORDER";
/* ********************************************************************* */

export const LOAD_REQUEST_DATA_DISCLAIMER = "LOAD_REQUEST_DATA_DISCLAIMER";
export const SET_SUCCESS_DATA_DISCLAIMER = "SET_SUCCESS_DATA_DISCLAIMER";
export const SET_FAILURE_DATA_DISCLAIMER = "SET_FAILURE_DATA_DISCLAIMER";
/* ********************************************************************* */

export const LOAD_REQUEST_AUTH_DISCLAIMER = "LOAD_REQUEST_AUTH_DISCLAIMER";
export const SET_SUCCESS_AUTH_DISCLAIMER = "SET_SUCCESS_AUTH_DISCLAIMER";
export const SET_FAILURE_AUTH_DISCLAIMER = "SET_FAILURE_AUTH_DISCLAIMER";
/* ********************************************************************* */

export const LOAD_USER_INFORMATION = "LOAD_USER_INFORMATION";
export const SET_USER_INFORMATION = "SET_USER_INFORMATION";
/* ********************************************************************* */

export const SET_AUTH = "SET_AUTH";
/* ********************************************************************* */

export const SET_ROUTES = "SET_ROUTES";
export const SET_SELECTED_ROUTE = "SET_SELECTED_ROUTE";
/* ********************************************************************* */

export const SET_EXPANDED = "SET_EXPANDED";
/* ********************************************************************* */

export const SET_UPDATE_USER = "SET_UPDATE_USER";
/* ********************************************************************* */

export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
/* ********************************************************************* */

export const LOAD_PROVINCES_DATA = "LOAD_PROVINCES_DATA";
export const SET_PROVINCES_DATA = "SET_PROVINCES_DATA";
/* ********************************************************************* */

export const SET_SUCCESS_CUSTOMERS_BY_PARTNER_FILTERED =
  "SET_SUCCESS_CUSTOMERS_BY_PARTNER_FILTERED";
/* ********************************************************************* */

export const LOAD_MOVEMENTS = "LOAD_MOVEMENTS";
export const SET_MOVEMENTS = "SET_MOVEMENTS";
/* ********************************************************************* */

export const LOAD_AUTHENTICATED_INFORMATION = "LOAD_AUTHENTICATED_INFORMATION";
/* ********************************************************************* */

export const SET_LOG_IN = "SET_LOG_IN";
export const SET_LOG_OUT = "SET_LOG_OUT";
/* ********************************************************************* */

export const LOAD_ANALYTICS_DATA = "LOAD_ANALYTICS_DATA";
export const SET_ANALYTICS_DATA = "SET_ANALYTICS_DATA";
/* ********************************************************************* */

export const SET_MODAL_CREATE_GROUP = "SET_MODAL_CREATE_GROUP";
/* ********************************************************************* */

export const SET_SELECTED_CLIENT_FROM_HOME_TO_WALLET =
  "SET_SELECTED_CLIENT_FROM_HOME_TO_WALLET";
/* ********************************************************************* */
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
export const LOAD_REQUEST_ESPECIES_OPERATE = "LOAD_REQUEST_ESPECIES_OPERATE";
export const SET_SUCCESS_ESPECIES_OPERATE = "SET_SUCCESS_ESPECIES_OPERATE";
export const SET_FAILURE_ESPECIES_OPERATE = "SET_FAILURE_ESPECIES_OPERATE";
/* ********************************************************************* */

export const LOAD_REQUEST_ORDERS = "LOAD_REQUEST_ORDERS";
export const SET_SUCCESS_ORDERS = "SET_SUCCESS_ORDERS";
export const SET_FAILURE_ORDERS = "SET_FAILURE_ORDERS";
/* ********************************************************************* */

export const LOAD_REQUEST_ORDERS_FINALIZED = "LOAD_REQUEST_ORDERS_FINALIZED";
export const SET_SUCCESS_ORDERS_FINALIZED = "SET_SUCCESS_ORDERS_FINALIZED";
export const SET_FAILURE_ORDERS_FINALIZED = "SET_FAILURE_ORDERS_FINALIZED";
/* ********************************************************************* */

export const LOAD_REQUEST_ORDERS_LOTE = "LOAD_REQUEST_ORDERS_LOTE";
export const SET_SUCCESS_ORDERS_LOTE = "SET_SUCCESS_ORDERS_LOTE";
export const SET_FAILURE_ORDERS_LOTE = "SET_FAILURE_ORDERS_LOTE";
/* ********************************************************************* */

export const RELOAD_SEARCH_DATA = "RELOAD_SEARCH_DATA";
/* ********************************************************************* */

export const SET_TRADING_OPTION = "SET_TRADING_OPTION";
/* ********************************************************************* */

export const SET_INSTRUMENT = "SET_INSTRUMENT";
/* ********************************************************************* */

export const SET_LIQUIDITY_TERM = "SET_LIQUIDITY_TERM";
/* ********************************************************************* */

export const SET_SUCCESS_OPERATE_INSTRUMENTS =
  "SET_SUCCESS_OPERATE_INSTRUMENTS";
export const SET_FAILURE_OPERATE_INSTRUMENTS =
  "SET_FAILURE_OPERATE_INSTRUMENTS";
export const LOAD_REQUEST_OPERATE_INSTRUMENTS =
  "LOAD_REQUEST_OPERATE_INSTRUMENTS";
/* ********************************************************************* */

export const SET_SUCCESS_OPERATE_HOLIDAY_STATE =
  "SET_SUCCESS_OPERATE_HOLIDAY_STATE";
export const SET_FAILURE_OPERATE_HOLIDAY_STATE =
  "SET_FAILURE_OPERATE_HOLIDAY_STATE";
export const LOAD_REQUEST_OPERATE_HOLIDAY_STATE =
  "LOAD_REQUEST_OPERATE_HOLIDAY_STATE";
/* ********************************************************************* */

export const SET_SUCCESS_OPERATE_ARGENTINA_HOLIDAY_STATE =
  "SET_SUCCESS_OPERATE_ARGENTINA_HOLIDAY_STATE";
export const SET_FAILURE_OPERATE_ARGENTINA_HOLIDAY_STATE =
  "SET_FAILURE_OPERATE_ARGENTINA_HOLIDAY_STATE";
export const LOAD_REQUEST_OPERATE_ARGENTINA_HOLIDAY_STATE =
  "LOAD_REQUEST_OPERATE_ARGENTINA_HOLIDAY_STATE";
/* ********************************************************************* */

export const SET_DATA_MODAL_CANCEL = "SET_DATA_MODAL_CANCEL";
/* ********************************************************************* */

export const SET_SUCCESS_OPERATE_CANCEL_ORDER =
  "SET_SUCCESS_OPERATE_CANCEL_ORDER";
export const SET_FAILURE_OPERATE_CANCEL_ORDER =
  "SET_FAILURE_OPERATE_CANCEL_ORDER";
export const LOAD_REQUEST_OPERATE_CANCEL_ORDER =
  "LOAD_REQUEST_OPERATE_CANCEL_ORDER";
/* ********************************************************************* */

export const LOAD_REQUEST_OPERATE_CANCEL_FUND =
  "LOAD_REQUEST_OPERATE_CANCEL_FUND";
export const SET_SUCCESS_OPERATE_CANCEL_FUND =
  "SET_SUCCESS_OPERATE_CANCEL_FUND";
export const SET_FAILURE_OPERATE_CANCEL_FUND =
  "SET_FAILURE_OPERATE_CANCEL_FUND";
/* ********************************************************************* */

export const LOAD_REQUEST_ORDERS_LOT = "LOAD_REQUEST_ORDERS_LOT";
export const SET_SUCCESS_ORDERS_LOT = "SET_SUCCESS_ORDERS_LOT";
export const SET_FAILURE_ORDERS_LOT = "SET_FAILURE_ORDERS_LOT";
/* ********************************************************************* */

export const LOAD_REQUEST_OK_ORDER_LIST = "LOAD_REQUEST_OK_ORDER_LIST";
export const SET_SUCCESS_OK_ORDER_LIST = "SET_SUCCESS_OK_ORDER_LIST";
export const SET_FAILURE_OK_ORDER_LIST = "SET_FAILURE_OK_ORDER_LIST";
/* ********************************************************************* */

export const LOAD_REQUEST_FAIL_ORDER_LIST = "LOAD_REQUEST_FAIL_ORDER_LIST";
export const SET_SUCCESS_FAIL_ORDER_LIST = "SET_SUCCESS_FAIL_ORDER_LIST";
export const SET_FAILURE_FAIL_ORDER_LIST = "SET_FAILURE_FAIL_ORDER_LIST";
/* ********************************************************************* */

export const SET_CURRENCY = "SET_CURRENCY";
/* ********************************************************************* */

export const LOAD_REQUEST_SPECIE_INFO = "LOAD_REQUEST_SPECIE_INFO";
export const SET_SUCCESS_SPECIE_INFO = "SET_SUCCESS_SPECIE_INFO";
export const SET_FAILURE_SPECIE_INFO = "SET_FAILURE_SPECIE_INFO";
/* ********************************************************************* */

export const LOAD_REQUEST_WALLET_FEE = "LOAD_REQUEST_WALLET_FEE";
export const SET_SUCCESS_WALLET_FEE = "SET_SUCCESS_WALLET_FEE";
export const SET_FAILURE_WALLET_FEE = "SET_FAILURE_WALLET_FEE";
/* ********************************************************************* */

export const LOAD_REQUEST_CUSTOMER_DETAIL = "LOAD_REQUEST_CUSTOMER_DETAIL";
export const SET_SUCCESS_CUSTOMER_DETAIL = "SET_SUCCESS_CUSTOMER_DETAIL";
export const SET_FAILURE_CUSTOMER_DETAIL = "SET_FAILURE_CUSTOMER_DETAIL";
/* ********************************************************************* */

export const LOAD_REQUEST_FEE_DETAIL = "LOAD_REQUEST_FEE_DETAIL";
export const SET_SUCCESS_FEE_DETAIL = "SET_SUCCESS_FEE_DETAIL";
export const SET_FAILURE_FEE_DETAIL = "SET_FAILURE_FEE_DETAIL";
/* ********************************************************************* */

export const LOAD_REQUEST_HOLDING_DETAIL = "LOAD_REQUEST_HOLDING_DETAIL";
export const SET_SUCCESS_HOLDING_DETAIL = "SET_SUCCESS_HOLDING_DETAIL";
export const SET_FAILURE_HOLDING_DETAIL = "SET_FAILURE_HOLDING_DETAIL";
/* ********************************************************************* */

export const LOAD_REQUEST_HOLDING = "LOAD_REQUEST_HOLDING";
export const SET_SUCCESS_HOLDING = "SET_SUCCESS_HOLDING";
export const SET_FAILURE_HOLDING = "SET_FAILURE_HOLDING";
/* ********************************************************************* */

export const LOAD_REQUEST_QUOTATION = "LOAD_REQUEST_QUOTATION";
export const SET_SUCCESS_QUOTATION = "SET_SUCCESS_QUOTATION";
export const SET_FAILURE_QUOTATION = "SET_FAILURE_QUOTATION";
/* ********************************************************************* */

export const LOAD_REQUEST_WALLET_HOLDING = "LOAD_REQUEST_WALLET_HOLDING";
export const SET_SUCCESS_WALLET_HOLDING = "SET_SUCCESS_WALLET_HOLDING";
export const SET_FAILURE_WALLET_HOLDING = "SET_FAILURE_WALLET_HOLDING";

export const LOAD_REQUEST_CURRENT_ACCOUNT = "LOAD_REQUEST_CURRENT_ACCOUNT";
export const SET_SUCCESS_CURRENT_ACCOUNT = "SET_SUCCESS_CURRENT_ACCOUNT";
export const SET_FAILURE_CURRENT_ACCOUNT = "SET_FAILURE_CURRENT_ACCOUNT";
/* ********************************************************************* */

export const LOAD_REQUEST_TICKET_BY_NUM = "LOAD_REQUEST_TICKET_BY_NUM";
export const SET_SUCCESS_TICKET_BY_NUM = "SET_SUCCESS_TICKET_BY_NUM";
export const SET_FAILURE_TICKET_BY_NUM = "SET_FAILURE_TICKET_BY_NUM";
/* ********************************************************************* */

export const LOAD_REQUEST_TICKET = "LOAD_REQUEST_TICKET";
export const SET_SUCCESS_TICKET = "SET_SUCCESS_TICKET";
export const SET_FAILURE_TICKET = "SET_FAILURE_TICKET";
/* ********************************************************************* */

export const LOAD_REQUEST_TEAM_TREE = "LOAD_REQUEST_TEAM_TREE";
export const SET_SUCCESS_TEAM_TREE = "SET_SUCCESS_TEAM_TREE";
export const SET_FAILURE_TEAM_TREE = "SET_FAILURE_TEAM_TREE";
/* ********************************************************************* */

export const LOAD_REQUEST_TEAM_HOLDING_ACCOUNTS =
  "LOAD_REQUEST_TEAM_HOLDING_ACCOUNTS";
export const SET_SUCCESS_TEAM_HOLDING_ACCOUNTS =
  "SET_SUCCESS_TEAM_HOLDING_ACCOUNTS";
export const SET_FAILURE_TEAM_HOLDING_ACCOUNTS =
  "SET_FAILURE_TEAM_HOLDING_ACCOUNTS";
/* ********************************************************************* */

export const LOAD_REQUEST_TEAM_UPLOAD_BILL = "LOAD_REQUEST_TEAM_UPLOAD_BILL";
export const SET_SUCCESS_TEAM_UPLOAD_BILL = "SET_SUCCESS_TEAM_UPLOAD_BILL";
export const SET_FAILURE_TEAM_UPLOAD_BILL = "SET_FAILURE_TEAM_UPLOAD_BILL";
/* ********************************************************************* */

export const LOAD_REQUEST_TEAM_BILL_LIST = "LOAD_REQUEST_TEAM_BILL_LIST";
export const SET_SUCCESS_TEAM_BILL_LIST = "SET_SUCCESS_TEAM_BILL_LIST";
export const SET_FAILURE_TEAM_BILL_LIST = "SET_FAILURE_TEAM_BILL_LIST";
/* ********************************************************************* */

export const LOAD_REQUEST_LIQUIDATION = "LOAD_REQUEST_LIQUIDATION";
export const SET_SUCCESS_LIQUIDATION = "SET_SUCCESS_LIQUIDATION";
export const SET_FAILURE_LIQUIDATION = "SET_FAILURE_LIQUIDATION";
/* ********************************************************************* */

export const WEB_SOCKET_CONNECTION = "WEB_SOCKET_CONNECTION";
/* ********************************************************************* */

export const SET_OPEN_CUSTOMER_POSITIONS = "SET_OPEN_CUSTOMER_POSITIONS";
export const SET_CLOSE_CUSTOMER_POSITIONS = "SET_CLOSE_CUSTOMER_POSITIONS";
export const SET_MESSAGE_CUSTOMER_POSITIONS = "SET_MESSAGE_CUSTOMER_POSITIONS";
/* ********************************************************************* */

export const SET_OPEN_CUSTOMER_HOLDINGS = "SET_OPEN_CUSTOMER_HOLDINGS";
export const SET_CLOSE_CUSTOMER_HOLDINGS = "SET_CLOSE_CUSTOMER_HOLDINGS";
export const SET_MESSAGE_CUSTOMER_HOLDINGS = "SET_MESSAGE_CUSTOMER_HOLDINGS";
/* ********************************************************************* */

export const LOAD_REQUEST_GET_BY_TICKER_HIST =
  "LOAD_REQUEST_GET_BY_TICKER_HIST";
export const SET_SUCCESS_GET_BY_TICKER_HIST = "SET_SUCCESS_GET_BY_TICKER_HIST";
export const SET_FAILURE_GET_BY_TICKER_HIST = "SET_FAILURE_GET_BY_TICKER_HIST";
/* ********************************************************************* */

export const LOAD_REQUEST_RECOVER_USER = "LOAD_REQUEST_RECOVER_USER";
export const SET_SUCCESS_RECOVER_USER = "SET_SUCCESS_RECOVER_USER";
export const SET_FAILURE_RECOVER_USER = "SET_FAILURE_RECOVER_USER";
/* ********************************************************************* */
/* ********************************************************************* */

export const LOAD_REQUEST_ESPECIES_FUND = "LOAD_REQUEST_ESPECIES_FUND";
export const SET_SUCCESS_ESPECIES_FUND = "SET_SUCCESS_ESPECIES_FUND";
export const SET_FAILURE_ESPECIES_FUND = "SET_FAILURE_ESPECIES_FUND";
/* ********************************************************************* */
/* ********************************************************************* */

export const LOAD_REQUEST_ESPECIES_EQUITY = "LOAD_REQUEST_ESPECIES_EQUITY";
export const SET_SUCCESS_ESPECIES_EQUITY = "SET_SUCCESS_ESPECIES_EQUITY";
export const SET_FAILURE_ESPECIES_EQUITY = "SET_FAILURE_ESPECIES_EQUITY";
/* ********************************************************************* */
/* ********************************************************************* */

export const LOAD_REQUEST_ESPECIES_BOND = "LOAD_REQUEST_ESPECIES_BOND";
export const SET_SUCCESS_ESPECIES_BOND = "SET_SUCCESS_ESPECIES_BOND";
export const SET_FAILURE_ESPECIES_BOND = "SET_FAILURE_ESPECIES_BOND";
/* ********************************************************************* */
/* ********************************************************************* */

export const LOAD_REQUEST_ESPECIES_CERT = "LOAD_REQUEST_ESPECIES_CERT";
export const SET_SUCCESS_ESPECIES_CERT = "SET_SUCCESS_ESPECIES_CERT";
export const SET_FAILURE_ESPECIES_CERT = "SET_FAILURE_ESPECIES_CERT";
/* ********************************************************************* */
/* ********************************************************************* */

export const SET_SUCCESS_TICKER_FILTERED = "SET_SUCCESS_TICKER_FILTERED";
/* ********************************************************************* */

export const LOAD_REQUEST_RECOVER_PASS_VERIFY =
  "LOAD_REQUEST_RECOVER_PASS_VERIFY";
export const SET_SUCCESS_RECOVER_PASS_VERIFY =
  "SET_SUCCESS_RECOVER_PASS_VERIFY";
export const SET_FAILURE_RECOVER_PASS_VERIFY =
  "SET_FAILURE_RECOVER_PASS_VERIFY";
/* ********************************************************************* */

export const LOAD_REQUEST_RECOVER_PASS_CONFIRM =
  "LOAD_REQUEST_RECOVER_PASS_CONFIRM";
export const SET_SUCCESS_RECOVER_PASS_CONFIRM =
  "SET_SUCCESS_RECOVER_PASS_CONFIRM";
export const SET_FAILURE_RECOVER_PASS_CONFIRM =
  "SET_FAILURE_RECOVER_PASS_CONFIRM";
/* ********************************************************************* */

export const LOAD_REQUEST_RECOVER_PASS_RESTORE =
  "LOAD_REQUEST_RECOVER_PASS_RESTORE";
export const SET_SUCCESS_RECOVER_PASS_RESTORE =
  "SET_SUCCESS_RECOVER_PASS_RESTORE";
export const SET_FAILURE_RECOVER_PASS_RESTORE =
  "SET_FAILURE_RECOVER_PASS_RESTORE";
/* ********************************************************************* */

export const LOAD_REQUEST_TEAM_FEE = "LOAD_REQUEST_TEAM_FEE";
export const SET_SUCCESS_TEAM_FEE = "SET_SUCCESS_TEAM_FEE";
export const SET_FAILURE_TEAM_FEE = "SET_FAILURE_TEAM_FEE";
/* ********************************************************************* */

/* ********************************************************************* */

export const SET_TICKER_LIST_WITH_FILTER = "SET_TICKER_LIST_WITH_FILTER";
export const SET_BOND_TICKER_FILTERED = "SET_BOND_TICKER_FILTERED";
export const SET_FUND_TICKER_FILTERED = "SET_FUND_TICKER_FILTERED";
export const SET_EQUITY_TICKER_FILTERED = "SET_EQUITY_TICKER_FILTERED";
export const SET_CERT_TICKER_FILTERED = "SET_CERT_TICKER_FILTERED";
export const SET_SELECTED_TICKER = "SET_SELECTED_TICKER";
export const SET_MARKET_TERM = "SET_MARKET_TERM";
export const SET_MARKET_INSTRUMENT_NUMBER = "SET_MARKET_INSTRUMENT_NUMBER";
/* ********************************************************************* */

export const LOADING_QUOTATION_DOLLAR = "LOADING_QUOTATION_DOLLAR";
export const SET_QUOTATION_DOLLAR = "SET_QUOTATION_DOLLAR";

/* ********************************************************************* */
export const LOAD_REQUEST_FIRST_TICKER_HIST = "LOAD_REQUEST_FIRST_TICKER_HIST";
export const SET_SUCCESS_FIRST_TICKER_HIST = "SET_SUCCESS_FIRST_TICKER_HIST";
export const SET_FAILURE_FIRST_TICKER_HIST = "SET_FAILURE_FIRST_TICKER_HIST";
export const RESET_FIRST_TICKER_HIST = "RESET_FIRST_TICKER_HIST";

export const LOAD_REQUEST_SECOND_TICKER_HIST =
  "LOAD_REQUEST_SECOND_TICKER_HIST";
export const SET_SUCCESS_SECOND_TICKER_HIST = "SET_SUCCESS_SECOND_TICKER_HIST";
export const SET_FAILURE_SECOND_TICKER_HIST = "SET_FAILURE_SECOND_TICKER_HIST";
export const RESET_SECOND_TICKER_HIST = "RESET_SECOND_TICKER_HIST";

export const LOAD_REQUEST_THIRD_TICKER_HIST = "LOAD_REQUEST_THIRD_TICKER_HIST";
export const SET_SUCCESS_THIRD_TICKER_HIST = "SET_SUCCESS_THIRD_TICKER_HIST";
export const SET_FAILURE_THIRD_TICKER_HIST = "SET_FAILURE_THIRD_TICKER_HIST";
export const RESET_THIRD_TICKER_HIST = "RESET_THIRD_TICKER_HIST";

/* ********************************************************************* */

/* ********************************************************************* */
export const LOAD_EMAIL_SEND = "LOAD_EMAIL_SEND";
export const SET_SUCCESS_EMAIL_SEND = "SET_SUCCESS_EMAIL_SEND";
export const SET_FAILURE_EMAIL_SEND = "SET_FAILURE_EMAIL_SEND";
export const RESET_EMAIL_SEND = "RESET_EMAIL_SEND";
/* ********************************************************************* */

/* ********************************************************************* */

export const SET_FUND_HIST = "SET_FUND_HIST";
export const LOADING_FUND_HIST = "LOADING_FUND_HIST";
export const SET_FAILURE_FUND_HIST = "SET_FAILURE_FUND_HIST";
export const SET_BOND_HIST = "SET_BOND_HIST";
export const LOADING_BOND_HIST = "LOADING_BOND_HIST";
export const SET_FAILURE_BOND_HIST = "SET_FAILURE_BOND_HIST";
export const SET_EQUITY_HIST = "SET_EQUITY_HIST";
export const LOADING_EQUITY_HIST = "LOADING_EQUITY_HIST";
export const SET_FAILURE_EQUITY_HIST = "SET_FAILURE_EQUITY_HIST";
export const SET_CERT_HIST = "SET_CERT_HIST";
export const LOADING_CERT_HIST = "LOADING_CERT_HIST";
export const SET_FAILURE_CERT_HIST = "SET_FAILURE_CERT_HIST";

/* ********************************************************************* */
