import React from "react";
import { useSelector } from "react-redux";

import "./liquidity-term-container.scss";

export const LiquidityTermContainer = ({
  listTerms,
  selectTerm,
  allSelectionsMade,
  isEnabled
}) => {

  const { instrument, tradingOption } = useSelector((state) => state.operate);

  const listTermsByInstrument = () => {
    if (instrument === "Fondos" && tradingOption === "RESCATAR") {
      return listTerms.filter((x, i) => x.id >= 4);
    } else if (instrument === "Fondos" && tradingOption === "SUSCRIBIR") {
      return [];
    }

    if (instrument !== "Fondos") {
      return listTerms.filter((x) => x.id <= 3);
    }
  };

  return (
    <div className="operate-main-card-view-radio-buttons-wrapper">
      <div className="operate-main-card-view-radios-container">
        {listTermsByInstrument() &&
          listTermsByInstrument().map((termRadio) => (
            <div
              className="operate-main-card-view-radio-element"
              key={termRadio.id}
            >
              <div className="operate-main-card-view-icon-container">
                <label className="control control-radio">
                  <input
                    type="radio"
                    name="termRadioGroup"
                    checked={termRadio.checked}
                    onChange={() => isEnabled && selectTerm(termRadio)}
                    disabled={!isEnabled}
                  />
                  <div
                    className={`control_indicator ${
                      allSelectionsMade ? "active" : "inactive"
                    }`}
                  ></div>
                </label>
                <div
                  className={`operate-main-card-view-radio-text ${
                    allSelectionsMade ? "active" : "inactive"
                  }`}
                >
                  {termRadio.term}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
