import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCallAsync } from "./common-service";

import useJwt from "../auth/jwt/useJwt";

const config = useJwt.jwtConfig;

// const initialUser = () => {
//   const item = window.localStorage.getItem("userData");
//   return item ? JSON.parse(item) : {};
// };

// START LOGIN
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { dispatch,rejectWithValue }) => {
    try {
      const response = await axios.post(config.loginEndpoint, credentials);
      // localStorage.setItem(
      //   "userData",
      //   JSON.stringify(response.data.dataBody.authenticationResult)
      // );
      localStorage.setItem(
        "userData",
        JSON.stringify(response.data.dataBody.authenticationResult.expiresIn)
      )
      localStorage.setItem(
        "logIn",
        JSON.stringify(Math.floor(new Date().getTime() / 1000))
      )
      localStorage.setItem(
        "idToken",
        response.data.dataBody.authenticationResult.idToken
      );
      localStorage.setItem(
        "refreshToken",
        response.data.dataBody.authenticationResult.refreshToken
      );
      const result = response.data.dataBody.authenticationResult;
      return result;
    } catch (error) {
      if (error.response && error.response.data) {
        // Aquí capturamos el error y lo rechazamos con un valor para que puedas manejarlo más adelante
        return rejectWithValue(error.response.data);
      } else {
        // Para errores de red u otros errores no relacionados con la API
        return rejectWithValue(error.message);
      }
    }
  }
);

// END LOGIN

// LOGOUT
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    localStorage.removeItem("userData");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("logIn");
    return {};
  }
);
// END LOGOUT

// RECOVER USER
export const apiGetRecoverUser = createAsyncThunk(
  "/apiGetRecoverUser",
  async (params, thunkAPI) => {
    const response = await thunkAPI.dispatch(
      axiosCallAsync({
        base: "_PARTNERS",
        path: "/recover/user",
        method: "POST",
        data: params,
      })
    );
    return response;
  }
);
// END RECOVER USER

// RECOVER PASS  SOLICITUD DE RECUPERACIÓN DE CONTRASEÑA
export const api_requestPasswordRecovery = createAsyncThunk(
  "/api_requestPasswordRecovery",
  async (params) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/recover/password",
        method: "POST",
        data: params,
      });
      return response;  // Retornar los datos relevantes
    } catch (error) {
      // Si hay un error, el middleware de Redux Toolkit manejará automáticamente el estado de rechazo
      throw error;
    }
  }
);
// END RECOVER PASS

// RECOVER PASS CONFIRM VERIFICACIÓN DEL CÓDIGO ENVIADO POR EMAIL
export const api_verifyRecoveryCode = createAsyncThunk(
  "/api_verifyRecoveryCode",
  async (params) => {
    try {

      const response = await 
      axiosCallAsync({
        base: "_PARTNERS",
        path: "/recover/verify/code",
        method: "PATCH",
        data: params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);
// END RECOVER PASS CONFIRM

// RECOVER PASS RESTORE RESTABLECIMIENTO DE LA CONTRASEÑA
export const api_resetPassword = createAsyncThunk(
  "/api_resetPassword",
  async (params) => {
    try {

      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/recover/reset",
        method: "PATCH",
        data: params,
      })
      return response;
    } catch (error) {
      throw error;
    }
  }
);
// END RECOVER PASS RESTORE

// PARTNER INFO
export const api_getPartnerInfo = createAsyncThunk(
  "/api_getPartnerInfo",
  async (params, { dispatch }) => {
    const response = await dispatch(
      axiosCallAsync({
        base: "_PARTNERS",
        path: "/home/partner/info",
        method: "GET",
        data: params,
      })
    );
    return response;
  }
);
// END PARTNER INFO

// CUSTOMERS BY PARTNER
export const api_getCustomersByPartner = createAsyncThunk(
  "common/api_getCustomersByPartner",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: "_PARTNERS",
        path: "/home/partner/customers/portfolio",
        method: "POST",
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
// END CUSTOMERS BY PARTNER

// GET WALLET FEE
export const api_getWalletFee = createAsyncThunk(
  "common/api_getWalletFee",
  async (params, { dispatch }) => {
    const response = await dispatch(
      axiosCallAsync({
        base: "_PARTNERS",
        path: "/portfolio/partner/fee",
        method: "POST",
        data: params,
      })
    );
    return response;
  }
);
// END GET WALLET FEE

// GET WALLET HOLDING
export const api_getWalletHolding = createAsyncThunk(
  "common/api_getWalletHolding",
  async (params, { dispatch }) => {
    const response = await dispatch(
      axiosCallAsync({
        path: "/portfolio/partner/holding/detail",
        method: "GET",
        data: params,
      })
    );
    return response
  }
);
// END GET WALLET HOLDING

// GET QUOTATION
export const api_getQuotation = createAsyncThunk(
  "common/api_getQuotation",
  async (_, { dispatch }) => {
    const response = await  dispatch(
      axiosCallAsync({
        path: "/operate/dollarquotation",
        method: "GET",
      })
    );
    return response;
  }
);
// END GET QUOTATION

// SEND LOGIN CONTACT ME EMAIL
export const api_sendLoginContactMeEmail = createAsyncThunk(
  "/api_sendLoginContactMeEmail",
  async (params, { dispatch }) => {
    const response = await dispatch(
      axiosCallAsync({
        base: "_PARTNERS",
        path: "/help/send",
        method: "POST",
        data: params,
      })
    );
    return response;
  }
);
// END SEND LOGIN CONTACT ME EMAIL

const commonSlice = createSlice({
  name: "common",
  initialState: {
    genFailSer: false,
    tabOrderInProgress: false,
    signInData: null,
    loadingExpenses: false,
    expenses: [],
    loadingUserPortfolio: false,
    userPortfolio: null,
    loadingLastMovements: false,
    lastMovements: [],
    loadingEspeciesOperate: false,
    especiesOperate: [],
    selectedClientFromHomeToWallet: null,
    openModalCreateGroup: false,
    openBlur: false,
    authenticatedLoadingStatus: false,
    isLogged: false,
    currentUrl: 0,
    routes: [],
    expanded: false,
    searchData: { data: "" },
    loadingSpeciesData: false,
    quotation: [],
    customersByPartner: [],
    customersByPartnerFiltered: [],
    loadingCustomersByPartner: false,
    walletFee: [],
    loadingWalletFee: false,
    userLogged: {},
    loadingUserLogged: false,
    wsConnections: [],
    recoverUser: {},
    recoverPassVerify: {},
    recoverPassConfirm: {},
    recoverPassRestore: {},
    tickerFiltered: [],
    loadingEmailSend: false,
    emailSendStatus: false,
    userData: {},
    partnerInfo: {},
    loadingRequestPasswordRecovery: false,
    passwordRecoveryStatus: null,
    loadingVerifyRecoveryCode: false,
    codeVerificationStatus: null,
    loadingResetPassword: false,
    passwordResetStatus: null,
    error: null,
    errorCode: null,
    errorMessage: null,
    loadingWalletHolding: false,
    walletHolding: [],
    loadingQuotation: false,
    loadingEmailContactMe: false,
    emailContactMeStatus: null,
    emailContactMeError: null,
    errorUserLogged: null,
    recoveryStatus: {},
    emailRecoverPass: "",
    userRecoverPassword: "",
    codeVerifyPass: "",
    tokenConfirm: null,
  },
  reducers: {
    setOpenBlur(state) {
      state.openBlur = !state.openBlur;
    },
    setRoutes(state, action) {
      state.routes = action.payload;
    },
    loadAuthenticatedInformation: (state) => {
      state.authenticatedLoadingStatus = true;
    },
    setSelectedRoute(state, action) {
      state.currentUrl = action.payload;
    },
    setExpanded(state, action) {
      state.expanded = !state.expanded;
    },
    setGenericServiceFailure(state, action) {
      state.genFailSer = action.payload;
    },
    setLogIn(state) {
      state.authenticatedLoadingStatus = true;
      state.isLogged = true;
    },
    setLogOut(state) {
      state.authenticatedLoadingStatus = true;
      state.isLogged = false;
    },
    setSignInData(state, action) {
      state.signInData = action.payload;
    },
    setErrorMessage(state) {
      state.errorMessage = null;
      state.errorCode = null
    },
    setEmailRecoverPassword(state, action) {
      state.emailRecoverPass = action.payload
    },
    setUserReveryPassword(state, action) {
      state.userRecoverPassword = action.payload
    },
    setCodeVerify(state, token){
      state.codeVerifyPass = token
    },
    a_resetEmailSend(state) {
      state.loadingEmailSend= false
      state.emailSendStatus= false
    },
    a_setCustomersByPartnerFiltered(state, action) {
      state.loadingCustomersByPartner = false;
      state.customersByPartnerFiltered = action.payload;
    },
    setEspeciesOperate(state, action) {
      state.especiesOperate = action.payload;
      state.loadingEspeciesOperate = false;
    },
    reloadSearchData(state, action) {
      state.searchData = action.payload;
    },
    a_setTabOrderInProgress(state, action) {
      state.tabOrderInProgress = action.payload;
    },
    setSelectedClientFromHomeToWallet(state, name){
      state.selectedClientFromHomeToWallet= name
    },
    setStatesForPasswordRecoveryNull(state, action) {
      state.recoverPassVerify = {}
      state.tokenConfirm = null
      state.recoverPassRestore = {}
      state.passwordResetStatus = null
      state.codeVerificationStatus = null
    }
  },
  extraReducers: (builder) => {
    //LOGIN
    builder.addCase(login.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.isLogged = true;
    });
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error;
    });
    //LOGOUT
    builder.addCase(logout.fulfilled, (state) => {
      state.userData = null;
      state.isLogged = false;
    });
    // // RECOVER USSER
    builder.addCase(apiGetRecoverUser.pending, (state) => {
      state.loadingRecoverUser = true;
      state.error = null;
    });
    builder.addCase(apiGetRecoverUser.fulfilled, (state, action) => {
      state.loadingRecoverUser = false;
      state.recoverUser = action.payload;
    });
    builder.addCase(apiGetRecoverUser.rejected, (state, action) => {
      state.loadingRecoverUser = false;
      state.error = action.error;
    });
    // // SOLICITUD DE RECUPERACIÓN DE CONTRASEÑA
    builder.addCase(api_requestPasswordRecovery.fulfilled, (state, action) => {
      state.loadingRequestPasswordRecovery = false;
      state.recoveryStatus = action?.payload?.dataBody;
      state.recoverPassVerify = action?.payload;
    });
    builder.addCase(api_requestPasswordRecovery.pending, (state) => {
      state.loadingRequestPasswordRecovery = true;
    });
    builder.addCase(api_requestPasswordRecovery.rejected, (state, action) => {
      state.recoveryStatus = action;
      state.loadingRequestPasswordRecovery = false;
      state.errorCode = action?.payload?.payload?.errorCode;
      state.errorMessage = action?.payload?.payload?.message;
    });

    // // VERIFICACIÓN DEL CÓDIGO
    builder.addCase(api_verifyRecoveryCode.pending, (state) => {
      state.loadingVerifyRecoveryCode = true;
    });
    builder.addCase(api_verifyRecoveryCode.fulfilled, (state, action) => {
      state.loadingVerifyRecoveryCode = false;
      state.codeVerificationStatus = action?.payload;
      state.recoverPassConfirm = action?.payload;
      state.tokenConfirm = action?.payload?.dataBody?.token
    });
    builder.addCase(api_verifyRecoveryCode.rejected, (state, action) => {
      state.loadingVerifyRecoveryCode = false;
      state.error = action.error;
    });

    // // RESTABLECIMIENTO DE LA CONTRASEÑA
    builder.addCase(api_resetPassword.pending, (state) => {
      state.loadingResetPassword = true;
    });
    builder.addCase(api_resetPassword.fulfilled, (state, action) => {
      state.loadingResetPassword = false;
      state.passwordResetStatus = action?.payload;
      state.recoverPassRestore = action?.payload?.dataBody
    });
    builder.addCase(api_resetPassword.rejected, (state, action) => {
      state.loadingResetPassword = false;
      state.error = action.error;
    });
    // // PARTNER INFO
    builder.addCase(api_getPartnerInfo.pending, (state) => {
      state.loadingUserLogged = true;
      state.errorUserLogged = null;
    });
    builder.addCase(api_getPartnerInfo.fulfilled, (state, action) => {
      state.loadingUserLogged = false;
      state.partnerInfo = action.payload;
      state.errorUserLogged = null;
    });
    builder.addCase(api_getPartnerInfo.rejected, (state, action) => {
      state.loadingUserLogged = false;
      state.errorUserLogged = action.error.message;
    });
    // // CUSTOMERS BY PARTNER
    builder.addCase(api_getCustomersByPartner.pending, (state) => {
      state.loadingCustomersByPartner = true;
      state.error = null;
    });
    builder.addCase(api_getCustomersByPartner.fulfilled, (state, action) => {
      state.loadingCustomersByPartner = false;
      state.customersByPartner = action.payload.dataBody.customers;
    });
    builder.addCase(api_getCustomersByPartner.rejected, (state, action) => {
      state.loadingCustomersByPartner = false;
      state.error = action.error;
    });
    // // GET WALLET FEE
    // [api_getWalletFee.pending]: (state) => {
    //   state.loadingWalletFee = true;
    //   state.error = null;
    // },
    // [api_getWalletFee.fulfilled]: (state, action) => {
    //   state.loadingWalletFee = false;
    //   state.walletFee = action.payload;
    // },
    // [api_getWalletFee.rejected]: (state, action) => {
    //   state.loadingWalletFee = false;
    //   state.error = action.error;
    // },
    // // GET WALLET HOLDING
    builder.addCase(api_getWalletHolding.pending, (state) => {
      state.loadingWalletHolding = true;
      state.error = null;
    });
    builder.addCase(api_getWalletHolding.fulfilled, (state, action) => {
      state.loadingWalletHolding = false;
      state.walletHolding = action.payload;
    });
    builder.addCase(api_getWalletHolding.rejected, (state, action) => {
      state.loadingWalletHolding = false;
      state.error = action.error;
    });
    // // GET QUOTATION
    // [api_getQuotation.pending]: (state) => {
    //   state.loadingQuotation = true;
    //   state.error = null;
    // },
    // [api_getQuotation.fulfilled]: (state, action) => {
    //   state.loadingQuotation = false;
    //   state.quotation = action.payload.dataBody;
    // },
    // [api_getQuotation.rejected]: (state, action) => {
    //   state.loadingQuotation = false;
    //   state.error = action.error;
    // },
    // // SEND LOGIN CONTACT ME EMAIL
    builder.addCase(api_sendLoginContactMeEmail.pending, (state) => {
      state.loadingEmailContactMe = true;
      state.emailContactMeError = null;
    });
    builder.addCase(api_sendLoginContactMeEmail.fulfilled, (state, action) => {
      state.loadingEmailContactMe = false;
      state.emailContactMeStatus = action.payload;
    });
    builder.addCase(api_sendLoginContactMeEmail.rejected, (state, action) => {
      state.loadingEmailContactMe = false;
      state.emailContactMeError = action.error.message;
    });
  },
});

export const {
  setOpenBlur,
  setRoutes,
  setSelectedRoute,
  setExpanded,
  setGenericServiceFailure,
  setSignInData,
  loadAuthenticatedInformation,
  setLogIn,
  setLogOut,
  setErrorMessage,
  setEmailRecoverPassword,
  setCodeVerify,
  a_resetEmailSend,
  a_setCustomersByPartnerFiltered,
  reloadSearchData,
  setEspeciesOperate,
  
  setSelectedClientFromHomeToWallet,
  setStatesForPasswordRecoveryNull,
  setUserReveryPassword,
} = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
