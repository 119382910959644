import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// ant design
import { Button, Input, Form } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';

// components actions & services
import { api_resetPassword, setErrorMessage } from '../../../common/common-slice';

// styles
import '../recover-user-pass.scss';

const VerifyNewPassword = (props) => {
  const dispatch = useDispatch();
  const { recoverPassConfirm, emailRecoverPass, tokenConfirm } = useSelector((state) => state['common']);
  const [hasEnteredNewPassword, setHasEnteredNewPassword] = useState(false);
  const [hasEnteredRepeatPassword, setHasEnteredRepeatPassword] = useState(
    false
  );

  const handleNewPassword = ({ newPassword }) => {
    try {
      dispatch(api_resetPassword({ password: newPassword, email: emailRecoverPass, token: tokenConfirm }));
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <h3 className="subtitle">Nueva Clave de Ingreso</h3>
      <p className="recover-password-desrcription">
        Por favor, introduce tu nueva contraseña
      </p>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleNewPassword}
      >
        <Form.Item
          name="newPassword"
          className="recover-user-form"
          rules={[
            { required: true, message: '¡Por favor ingrese su contraseña!' },
          ]}
        >
          <Input.Password
            className="input-text"
            placeholder="Nueva contraseña*"
            iconRender={(visible) =>
              hasEnteredNewPassword ? (
                visible ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )
              ) : null
            }
            onChange={(e) => setHasEnteredNewPassword(!!e.target.value)}
          />
        </Form.Item>
        <div className='condition-container'>
        <p className="conditions-new-password">
          <CheckOutlined className='icon-conditions' /> Entre 8 y 30 caracteres
        </p>
        <p className="conditions-new-password">
          <CheckOutlined className='icon-conditions'/> Un número
        </p>
        <p className="conditions-new-password">
          <CheckOutlined className='icon-conditions'/> Una minúscula
        </p>
        <p className="conditions-new-password">
          <CheckOutlined className='icon-conditions'/> Una mayúscula
        </p>
        <p className="conditions-new-password">
          <CheckOutlined className='icon-conditions'/> No mas de 3 caracteres consecutivos iguales
        </p>
      </div>
        <Form.Item
          name="repeatPassword"
          className="recover-user-form"
          rules={[
            { required: true, message: '¡Por favor, confirme su contraseña!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('¡Las dos contraseñas que ingresó no coinciden!')
                );
              },
            }),
          ]}
        >
          <Input.Password
            className="input-text"
            placeholder="Confirmar contraseña*"
            iconRender={(visible) =>
              hasEnteredRepeatPassword ? (
                visible ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )
              ) : null
            }
            onChange={(e) => setHasEnteredRepeatPassword(!!e.target.value)}
          />
        </Form.Item>
        <div className="btn-container">
          <Button className="btn-recover-submit" htmlType="submit" block="true">
            CREAR CONTRASEÑA
          </Button>
        </div>
      </Form>
    </>
  );
};

VerifyNewPassword.defaultProps = {};

export default VerifyNewPassword;
