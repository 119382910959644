import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// components common and services
import { Portfolio } from '../home/home-components/portfolio/portfolio';
import { Movements } from './home-components/movements/movements';
import { ClientsWallet } from './home-components/clients-wallet/clients-wallet';
import { api_getLastMovements, api_getPartnerInformationPersonal } from './home-slice';
// import { RecentsOperations } from './home-components/recents-oprations/recents-oprations';

// utils
import iconCopy from '../../utils/icon-copy.svg';

// styles
import './home-view.scss';




export const Home = () => {

  const { signInData } = useSelector((state) => state.common);
  const { partnerInfo } = useSelector((state) => state.home);
  const [copy, setCopy] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(api_getPartnerInformationPersonal());
    dispatch(
      api_getLastMovements({
        dateFrom: moment()
          .subtract(7, 'days')
          .startOf('date')
          .format('YYYY-MM-DD'),
        dateTo: moment()
          .endOf('day')
          .format('YYYY-MM-DD'),
      })
    );
  }, [signInData, dispatch]);

  const copyToClipboard = (code) => {
    const textarea = document.createElement('textarea');
    textarea.value = code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  return (
    <div className="home-container">
      <div className="home-container-header">
        <div className="home-container-header-title">
          ¡ Hola {partnerInfo.name} !
        </div>
        {/* {auth('ROLE_BANZA', 'PERMISSION_CREF_HOME') ? ( */}
          <>
            <div className="home-container-referCode">
              <div className="home-container-header-referCode">
                Cód. Referido
                <button
                  onClick={() => {
                    copyToClipboard(partnerInfo?.referredCode?.toString());
                    setCopy(true);
                  }}
                >
                  {partnerInfo?.referredCode?.toString()}
                  <img src={iconCopy} alt="copy" />
                </button>
               
                {copy ? <div className="copy-text">¡Copiado!</div> : ''}
              </div>
            </div>
          </>
        {/* ) : (
          undefined
        )} */}
      </div>
      <div className="home-container-cards">
        <Portfolio />
        <Movements />
      </div>
      {/* <div className="home-container-cards">
        <RecentsOperations />
      </div> */}
      <div className="home-container-cards-client">
        <ClientsWallet />
      </div>
    </div>
  );
};
