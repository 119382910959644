import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import {
  noDecimalFormat,
  priceFormatFunds,
  twoDecimalsFormat,
  getCurrencyType,
} from "../../operate-service";

import "./funds-detail-especie.scss";

export const FundsDetailEspecie = () => {
  const { specieInfo } = useSelector((state) => state.operate);

  const [refData, setRefData] = useState("");
  const [option, setOption] = useState("COTIZACION");

  useEffect(() => {
    try {
      setRefData(specieInfo);
    } catch (error) {
      throw new Error(error);
    }
  }, [specieInfo]);

  return (
    <div className="detail-fund-especie-container">
      <DetailFundEspecieTabs option={option} setOption={setOption} />
      <div className="detail-fund-especie-content">
        {option === "COTIZACION" ? (
          <>
            {/* <DetailFundEspecieStatics /> */}
            <div className="detail-fund-especie-info-first">
              <DetailFundEspecieName />
              <DetailFundEspecieDataCotization refData={refData} />
            </div>
            <div className="detail-fund-especie-info-second">
              <DetailFundEspecieDataCotizationRendimientos refData={refData} />
            </div>
          </>
        ) : (
          <>
            <div className="detail-fund-especie-content-wallet">
              <DetailFundEspecieName />
              <DetailFundEspeciePieStatics refData={refData} />
            </div>
            <DetailFundEspecieStrategyGoalAndDepositary refData={refData} />
          </>
        )}
        <div className="detail-fund-expecie-line-header" />
      </div>
    </div>
  );
};

/* ******************************************** */
const DetailFundEspecieTabs = ({ option, setOption }) => {
  return (
    <div className="tabs">
      <div
        className={`option ${option === "COTIZACION" ? "selected" : ""}`}
        onClick={() => setOption("COTIZACION")}
      >
        <p className="option-text">COTIZACIÓN</p>
      </div>
      <div
        className={`option ${option === "CARTERA" ? "selected" : ""}`}
        onClick={() => setOption("CARTERA")}
      >
        <p className="option-text">CARTERA</p>
      </div>
    </div>
  );
};

const DetailFundEspecieDataCotization = ({ refData }) => {
  const lengthData = refData?.length;

  const settlementTermFormat = (plazoresc) => {
    switch (plazoresc) {
      case "0":
        plazoresc = "Inmediato";
        break;

      case "1":
        plazoresc = "24hs hábiles";
        break;

      case "2":
        plazoresc = "48hs hábiles";
        break;

      default:
        plazoresc = "N/A";
        break;
    }
    return plazoresc;
  };

  const dataCotization = [
    /*{
      description: "Precio Año",
      value: `AR $${priceFormatFunds(refData["YEAR_MIN"])} - 
          AR $${priceFormatFunds(refData["YEAR_MAX"])}`,
    },*/
    {
      description: "Moneda de cotización",
      value: refData?.referenceData?.currency,
    },
    /*{
      description: "Vol. Promedio (10d)",
      value: noDecimalFormat(refData["VOLUME_10D_AVG"]),
    },
    {
      description: "Vol. Promedio (3m)",
      value: noDecimalFormat(refData["VOLUME_3M_AVG"]),
    },
    {
      description: "Vol. Acumulado",
      value: noDecimalFormat(refData["VOLUME_X_PRICE_ACCUM_LAST_WEEK"]),
    },*/
    {
      description: "Patrimonio",
      value: `${getCurrencyType(refData?.currency)} ${noDecimalFormat(
        refData?.referenceData?.WORTH
      )}`,
    },
    {
      description: "Clase",
      value: refData?.referenceData?.CLAZZ,
    },
    {
      description: "Importe mínimo para operar",
      value: refData?.referenceData?.["MINIMO_INVERSION"],
    },
    {
      description: "Plazo de liquidez",
      value: settlementTermFormat(refData?.referenceData?.["PLAZORESC"]),
    },
  ];

  return (
    <div className="data-cotization">
      {dataCotization.map((element, i) => (
        <div
          key={i}
          className={`data-cotization-element ${
            i !== lengthData - 1 ? "apply-border-bottom" : ""
          }`}
        >
          <div className="description">{element.description}</div>
          <div className="value">{element.value}</div>
        </div>
      ))}
    </div>
  );
};

const DetailFundEspecieDataCotizationRendimientos = ({ refData }) => {
  const lengthData = refData?.length;

  const dataCotizationRendimientos = [
    {
      description: "Votalidad Diaria",
      value: `${twoDecimalsFormat(refData.referenceData?.["DAILY_VOLATILITY"])} %`,
    },
    {
      description: "Votalidad Anual",
      value: `${twoDecimalsFormat(refData.referenceData?.["YEARLY_VOLATILITY"])} %`,
    },
    {
      description: "Rendimiento MTD",
      value: `${twoDecimalsFormat(refData.referenceData?.["MTD_RETURN"])} %`,
    },
    {
      description: "Rendimiento QTD",
      value: `${twoDecimalsFormat(refData.referenceData?.["QTD_RETURN"])} %`,
    },
    {
      description: "Rendimiento YTD",
      value: `${twoDecimalsFormat(refData.referenceData?.["YTD_RETURN"])} %`,
    },
    {
      description: "Rendimiento Anualizado",
      value: `${twoDecimalsFormat(refData.referenceData?.["LAST_YEAR_RETURN"])} %`,
    },
    {
      description: "Máxima Baja",
      value: `${twoDecimalsFormat(refData.referenceData?.["MAX_DROP"])} %`,
    },
  ];

  return (
    <div className="data-cotization-rendimientos">
      <div className="especie-detail-subtitle">Rendimientos</div>
      {dataCotizationRendimientos.map((element, i) => (
        <div
          key={i}
          className={`data-cotization-element ${
            i !== lengthData - 1 ? "apply-border-bottom" : ""
          }`}
        >
          <div className="description">{element.description}</div>
          <div className="value">{element.value}</div>
        </div>
      ))}
    </div>
  );
};

const DetailFundEspecieStatics = () => {
  return (
    <div className="statics">
      {/*Aqui debe ir el grafico de velas de precios*/}
    </div>
  );
};

const DetailFundEspecieName = () => {
  const { specieInfo } = useSelector((state) => state.operate);

  const [termData, setTermData] = useState("");

  useEffect(() => {
    setTermData(specieInfo["marketData"]);
  }, [specieInfo]);

  return (
    <div className="especie">
      <div className="especie-ticker">{specieInfo?.["ticker"]}</div>
      <div className="especie-data">
        <span className="especie-data-price">
        {`$ ${priceFormatFunds(termData?.["NOW_PRICE"])}`}
        </span>

        {/* 
          IMPORTANTE:
          
          para valores positivos usar la clase "especie-data-percentage-positive"
          para valores positivos usar la clase "especie-data-percentage-negative"
        */}

        <span className="especie-data-percentage-normal">
        &nbsp;{`(${twoDecimalsFormat(termData?.["DAY_PERCENTAGE_CHANGE"])} %)`}
        </span>
      </div>
    </div>
  );
};

export const DetailFundEspeciePieStatics = ({ refData }) => {
  const arrayColours = [
    "#7381F7",
    "#531DAB",
    "#9254DE",
    "#D3ADF7",
    "#08979C",
    "#36CFC9",
    "#87E8DE",
    "#BAE7FF",
    "#E6DA2C",
    "#FFF675",
    "#608EF4",
    "#00000029",
    "#707070",
    "#CECECE",
    "#AAA453",
  ];

  const carteraListEspecie = () => {
    if (refData && Array.isArray(refData.referenceData?.PORTFOLIO)) {
      return refData.referenceData.PORTFOLIO.map((element, i) => {
        return {
          id: i + 1,
          color: arrayColours[i],
          name: element.ITEM,
          amount: element.AMOUNT,
          currency: element.CURRENCY,
          percentage: element.RATIO,
        };
      });
    }
    return [];
  };
  

  const getArrayFromDataList = () => {
    const data = [];
    const backgroundColor = [];

    carteraListEspecie().forEach((element, index) => {
      data.push(element["percentage"]);
      backgroundColor.push(arrayColours[index]);
    });

    let firtCircle = {
      data: data,
      backgroundColor: backgroundColor,
    };

    let arrayDataSet = [];
    arrayDataSet.push(firtCircle);

    return arrayDataSet;
  };

  const arrayDataSet = getArrayFromDataList();

  const portfolioDataOriginal = {
    labels: carteraListEspecie().map((idx) => idx.name),
    datasets: arrayDataSet,
  };

  return (
    <>
      <div className="statics">
        <Chart
          type="doughnut"
          data={portfolioDataOriginal}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
      <div className="portfolio">
        {carteraListEspecie()?.map((element, i) => (
          <div key={i} className="portfolio-element">
            <div
              style={{ background: element.color }}
              className="point-color"
            ></div>
            <div className="description">{element.name}</div>
            <div className="percentage">{`${twoDecimalsFormat(
              element.percentage
            )}%`}</div>
          </div>
        ))}
      </div>
    </>
  );
};

const DetailFundEspecieStrategyGoalAndDepositary = ({ refData }) => {
  return (
    <div className="especie-data-text">
      <div className="especie-detail-subtitle">
        <p className="especie-detail-subtitle-text">Estrategia</p>
      </div>
      <p className="especie-text">{refData.referenceData?.["ESTRATEGIA"]}</p>
      <div className="especie-detail-subtitle">
        <p className="especie-detail-subtitle-text">Objetivo</p>
      </div>
      <p className="especie-text">{refData.referenceData?.["OBJETIVO"]}</p>
      <div className="especie-detail-subtitle">
        <p className="especie-detail-subtitle-text">Depositaria</p>
      </div>
      <p className="especie-text">{refData.referenceData?.["AGENTE_CUSTODIA"]}</p>
    </div>
  );
};
