import React from "react";

import { ElementData } from "../common/element-data";
import {
  noDecimalFormat,
  regularPriceFormat,
  twoDecimalsFormat,
  getCurrencyType
} from "../../operate-service";

import { useFetchSpecieInfo } from "../common/custom-hooks";

export const Cedears = () => {
  const specieData = useFetchSpecieInfo();

  const operacionesData = [
    {
      field: "Precio del Día",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.termData?.["LOW_PRICE"])} 
      - ${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.termData?.["HIGH_PRICE"])}`,
    },
    {
      field: "Precio Año",
      value: `${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.refData?.["YEAR_MIN"])} -
      ${getCurrencyType(specieData?.refData?.["CURRENCY"])} ${regularPriceFormat(specieData?.refData?.["YEAR_MAX"])}`,
    },
    {
      field: "Volumen Nominal",
      value: noDecimalFormat(specieData?.termData?.["VOLUME"]),
    },
  ];

  const fundamentalsData = [
    {
      field: "Beta",
      value: twoDecimalsFormat(specieData?.refData?.["BETA"]),
    },
    {
      field: "Cap. de Mercado",
      value: regularPriceFormat(specieData?.refData?.["MARKET_CAPITAL"]),
    },
    {
      field: "Free float",
      value: specieData?.refData?.["FLOATING"],
    },
  ];

  const Operaciones = () => {
    return (
      <div>
        {operacionesData.map((element, id) => (
          <ElementData key={id} element={element} />
        ))}
      </div>
    );
  };

  const Fundamentals = () => {
    return (
      <div>
        <div className="especie-detail-subtitle">Fundamentals</div>
        {fundamentalsData.map((element, id) => (
          <ElementData key={id} element={element} />
        ))}
      </div>
    );
  };

  return (
    <div className="performance-container">
      <Operaciones />
      <Fundamentals />
    </div>
  );
};
