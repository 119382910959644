import React from "react";
import "./grid-percentage-statics.scss";

const GridPercentageStatics = ({
  currency,
  holding,
  currencyButton,
}) => {
  const totalBalance = () => {
    if (holding && holding?.dataBody?.length !== 0) {
      switch (currencyButton) {
        case "Pesos":
          if (holding?.dataBody) {
            return holding?.dataBody[0]?.total;
          }
        case "Dólares":
          if (holding?.dataBody) {
            return holding?.dataBody[1]?.total;
          }
        case "Dólares MEP":
          if (holding?.dataBody) {
            return holding?.dataBody[2]?.total;
          }
      }
    } else {
      return "0";
    }
  };

  const getDataHolding = () => {
    switch (currencyButton) {
      case "Pesos":
        return <InstrumentDetail dataInstruments={holding?.dataBody && holding?.dataBody[0]} />;
      case "Dólares":
        return <InstrumentDetail dataInstruments={holding?.dataBody && holding?.dataBody[1]} />;
      case "Dólares MEP":
        return <InstrumentDetail dataInstruments={holding?.dataBody && holding?.dataBody[2]} />;
    }
  };

  const InstrumentDetail = ({ dataInstruments }) => {
    const dataColors = [
      "#300D69",
      "#9863EC",
      "#6A1CE8",
      "#B1B5EC",
      "#5316B5",
      "#00FFEC",
      "#1F6964",
      "#00B5A9",
    ];
    return (
      <ul className="list">
        {dataInstruments?.instruments?.map((instrument, i) => (
          <li className="element" key={i}>
            <div
              className="point-icon"
              style={{ borderColor: dataColors[i] }}
            ></div>
            <div data-testid="wallet-holding-description" className="description">
              {instrument.name} ({instrument.percentage}%)
            </div>
            <div data-testid="wallet-holding-value" className="percentage">
              {currency} {instrument.value}
            </div>
          </li>
        ))}

        {dataInstruments ? (
          ""
        ) : (
          <p className="msj-no-tenencia">
            EL CLIENTE SELECCIONADO NO POSEE TENENCIA
          </p>
        )}
      </ul>
    );
  };

  return (
    <div className="wallet-cartera-list">
      {getDataHolding()}
      <div>
        <div className="total">
          <div data-testid="wallet-holding-total-title" className="title">TOTAL TENENCIA</div>
          <div data-testid="wallet-holding-total-value" className="value">
            {currency === "ARS"
              ? `AR$ ${totalBalance()}`
              : `U$D ${totalBalance()}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridPercentageStatics;
