import React from 'react'
import {twoDecimalsFormat} from "../../../../common/common-service";


const QuotationTabInfo = ({
    currency,
    profileType,
    liquidityTerm,
    variation,
    worth,
    minimum
}) => {

    return (
        <div className="ticker-detail-list"> 
            <table className="ticker-detail-table">
                <tbody className="ticker-detail-table">
                    <tr>
                        <td>
                            <p className='ticker-detail-modal-text'>Moneda</p>
                            <p className="ticker-detail-modal-bold-text">
                                {currency}
                            </p>
                        </td>
                        <td>
                            <p className='ticker-detail-modal-text'>Riesgo</p>
                            <p className="ticker-detail-modal-bold-text">
                                {profileType}
                            </p>
                        </td>
                        <td>
                            <p className='ticker-detail-modal-text'>Plazo de Liquidez</p>
                            <p className="ticker-detail-modal-bold-text">
                                {liquidityTerm}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className='ticker-detail-modal-text'>Variacion</p>
                            <p className="ticker-detail-modal-bold-text">
                                {`${variation}%`}
                            </p>
                        </td>
                        <td>
                            <p className='ticker-detail-modal-text'>Patrimonio</p>
                            <p className="ticker-detail-modal-bold-text">
                                {`${currency} ${twoDecimalsFormat(worth)}`}
                            </p>
                        </td>
                        <td>
                            <p className='ticker-detail-modal-text'>Importe minimo para operar</p>
                            <p className="ticker-detail-modal-bold-text">
                                {minimum > 0 ? minimum : 'Sin minimo'}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <><div className="vertical-market-separator">&nbsp;</div></>
        </div>
    )
}

export default QuotationTabInfo