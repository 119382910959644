import React from 'react'
import { useSelector } from 'react-redux';

import moment from 'moment';
import { uniq } from 'lodash';
import { Chart } from 'react-chartjs-2';

import { Spinner } from '../../../../components/spinner/spinner';
import { ComparedTickerItemCard } from '../compared-ticker-item-card/compared-ticker-item-card';
// import iconExport from "../../../../utils/excel-icon.svg";
import { generateDateLabels } from '../../market-service'
import { colorPallete } from '../../market-service'

export const CompareChartContainer = ({ fullData, periodType, selectedTicker }) => {

    const { loadingFirstTickerHist, loadingSecondTickerHist, loadingThirdTickerHist } = useSelector((state) => state.market);
    


  let months = fullData.length > 0 ? fullData.map((r) => moment(r[0]?.fecha).format('MMM')) : [];

const allPrevClosePricesFirstItem = Object.values(fullData[0]);
const allPrevClosePricesSecondItem = Object.values(fullData[1]);
const allPrevClosePricesThirdItem = fullData[2] && Object.values(fullData[2]);
const allPrevPricesFirstItem = allPrevClosePricesFirstItem.flatMap((item) => item?.marketData?.PREV_CLOSE_PRICE);
const allPrevPricesSecondItem = allPrevClosePricesSecondItem.flatMap((item) => item?.marketData?.PREV_CLOSE_PRICE);
const allPrevPricesThirdItem = fullData[2] && allPrevClosePricesThirdItem.flatMap((item) => item?.marketData.PREV_CLOSE_PRICE);
    return (
        <div className="compare-chart-container">
            <div className="compare-chart-left">
                {/* <button
                    className="compare-chart-export-button"
                    onClick={() => console.log('Export Button Pressed')}
                >
                    <span className="export-icon">
                        <img src={iconExport} alt="export-icon" />
                    </span>
                    <span>Exportar</span>
                </button> */}
                {
                    selectedTicker.map((ticker, i) => (
                        <ComparedTickerItemCard
                            key={i}
                            tickerData={ticker}
                            tickerColor={colorPallete[i]}
                        />
                    ))
                }
            </div>
            <div className="compare-chart-rigth">
                {
                    (
                        loadingFirstTickerHist ||
                        loadingSecondTickerHist ||
                        loadingThirdTickerHist
                    ) ? (
                        <Spinner />
                    ) : (
                        <>
                        { allPrevClosePricesFirstItem &&
                            <Chart
                            type="line"
                            data={{
                                labels: generateDateLabels(allPrevClosePricesFirstItem),
                                datasets:[{
                                    data: allPrevPricesFirstItem,
                                    fill: true,
                                    borderColor: colorPallete[0],
                                    tension:0.25,
                                    pointRadius:1,
                                    yAxisID: 'price'
                                }],
                            }}
                            options={{
                                interaction: {
                                    mode: 'nearest',
                                        intersect: false
                                    },
                                    spanGaps: true,
                                    scales: {
                                        x:{
                                            display: false,
                                            grid: {
                                                display: false
                                            }
                                        },
                                        price: {
                                            position: 'right'
                                        },
                                        y1:{
                                            display:false
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                    }
                                }}
                                />
                                }
                        {allPrevPricesSecondItem && 
                                <Chart
                                type="line"
                                data={{
                                    labels: generateDateLabels(allPrevClosePricesSecondItem) ,
                                    datasets:[{
                                        data: allPrevPricesSecondItem,
                                        fill: true,
                                        borderColor: colorPallete[1],
                                        tension:0.25,
                                        pointRadius:1,
                                        yAxisID: 'price'
                                    }],
                                }}
                                options={{
                                    interaction: {
                                        mode: 'nearest',
                                            intersect: false
                                        },
                                        spanGaps: true,
                                        scales: {
                                            x:{
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            price: {
                                                position: 'right'
                                            },
                                            y1:{
                                                display:false
                                            }
                                        },
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        }
                                    }}
                                    />
                                    }
                        { allPrevPricesThirdItem &&
                                <Chart
                                type="line"
                                data={{
                                    labels: generateDateLabels(allPrevClosePricesThirdItem) ,
                                    datasets:[{
                                        data: allPrevPricesThirdItem,
                                        fill: true,
                                        borderColor: colorPallete[3],
                                        tension:0.25,
                                        pointRadius:1,
                                        yAxisID: 'price'
                                    }],
                                }}
                                options={{
                                    interaction: {
                                        mode: 'nearest',
                                            intersect: false
                                        },
                                        spanGaps: true,
                                        scales: {
                                            x:{
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            price: {
                                                position: 'right'
                                            },
                                            y1:{
                                                display:false
                                            }
                                        },
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        }
                                    }}
                                    /> 
                                 }
                            <div className="market-history-chart-period" style={{ padding: 0, paddingRight: 20 }}>
                                {
                                    uniq(months).map((month, index) => (
                                        <p key={index} style={{ color: '#757575', fontSize: 12, fontWeight: '300'  }}>{month}</p>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}
