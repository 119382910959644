import React, { useEffect } from 'react'
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { a_setSelectedTicker, a_setTickerListWithFilter } from '../../market-slice';


export const TableContainer = ({ columns, scroll, tickerType }) => {

    const dispatch = useDispatch();

    const state = useSelector((state) => ({
        ...state["marketReducer"],
        ...state["commonReducer"],
        ...state["operateReducer"],
    }));

    const {
        // fundTickerFiltered,
        // equityTickerFiltered,
        // bondTickerFiltered,
        // certTickerFiltered,
        // instrumentNumber,
        // tickerListWithFilter,
    } = state;
    const { fundTickerFiltered, equityTickerFiltered, bondTickerFiltered, certTickerFiltered, instrumentNumber, tickerListWithFilter } = useSelector((state) => state.market)

    useEffect(() => {
        if (instrumentNumber === 0) {
            if (fundTickerFiltered.length) {
                dispatch(a_setSelectedTicker([fundTickerFiltered[0]]))
                dispatch(a_setTickerListWithFilter(fundTickerFiltered))
            }
        } else if (instrumentNumber === 1) {
            if (bondTickerFiltered.length) {
                dispatch(a_setSelectedTicker([bondTickerFiltered[0]]))
                dispatch(a_setTickerListWithFilter(bondTickerFiltered))
            }
        } else if (instrumentNumber === 2) {
            if (equityTickerFiltered.length) {
                dispatch(a_setSelectedTicker([equityTickerFiltered[0]]))
                dispatch(a_setTickerListWithFilter(equityTickerFiltered))
            }
        } else if (instrumentNumber === 3) {
            if (certTickerFiltered.length) {
                dispatch(a_setSelectedTicker([certTickerFiltered[0]]))
                dispatch(a_setTickerListWithFilter(certTickerFiltered))
            }
        }
    }, [
        fundTickerFiltered,
        bondTickerFiltered,
        equityTickerFiltered,
        certTickerFiltered,
        instrumentNumber
    ]);

    return (
        <Table
            columns={columns}
            dataSource={tickerListWithFilter}
            scroll={scroll}
        />
    )
}
