import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import arrowDown from '../../../../utils/arrow-down-blue-icon.svg';

export const DetailCardButton = ({ setModalOpen }) => {

    // const state = useSelector((state) => ({
    //     ...state["commonReducer"],
    //     ...state["marketReducer"],
    // }));

    // const {
    //     loadingTickerHist,
    // } = state;

    const { loadingTickerHist } = useSelector((state) => state.market);
    return ( 
        <div className="ticker-detail-button-container">
            <Button
                onClick={() => { setModalOpen(true) }}
                disabled={loadingTickerHist}
            >
                { loadingTickerHist ? 'Cargando...' : 'Ver más' }
                {!loadingTickerHist && (
                    <img src={arrowDown} alt='arrow icon' />
                )}
            </Button>
        </div>
    );
};
