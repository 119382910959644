import React from "react";
import { Provider, useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import { store } from "./common/store";
import { Auth } from "aws-amplify";
import { useIdleTimer } from "react-idle-timer";

import { App } from "./App";


import * as serviceWorker from "./serviceWorker";
import {
  loadAuthenticatedInformation,
  setLogOut,
} from "../src/common/common-action";

import {
  closeSocketConnections
} from "../src/common/common-service";

// import "./index.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

export default function IdlComponent() {
  // const dispatch = useDispatch();

  // const processLogOut = async () => {
  //   dispatch(loadAuthenticatedInformation());
  //   await Auth.signOut({ global: true });
  //   dispatch(setLogOut());
  //   closeSocketConnections();
  // };

  // const handleOnIdle = event => {

  //   processLogOut();
  // };

  // const handleOnActive = event => {

  // }

  // const handleOnAction = event => {
  //   //console.log('user did something', event)
  // }

  // const {getRemainingTime, getLastActiveTime} = useIdleTimer({
  //   timeout: 15 * 60 * 1000,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  // });

  return (
    <App />
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IdlComponent />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
