import React from "react";

import iconSearch from "../../../../utils/icon-buscar.svg";

//import BaseModal from '../../../../components/modal/base-modal';
import NewButton from '../../../../components/new-button/new-button';

import './team-modals.scss';
import AlternativeModal from "../../../../components/alternativeModal/alternative-modal";

const RolChange = (props) => {
  return (
    <AlternativeModal onClose={props.onClose}>
        <div className="team-modal-container">
            <div className="modal-title">Cambiar Rol</div>
            <div className="row my-10px">
                <div className="col-md-12">
                  <div className="modal-internal-component-title">Escriba o seleccione el nombre de productor</div>
                  <div className="input-search-producer">
                    <img
                      className="img-input-text"
                      src={iconSearch}
                      alt="..."
                    />
                    <input
                      className="input-text-clients-wallet"
                      value="Ej. Escriba nombre productor"
                    />
                  </div>
                </div>
            </div>
            <div className="row my-10px">
                <div className="col-md-12">
                  <div className="modal-internal-component-title">Productor al que se le asignará la cartera</div>
                  <div className="radio-buttons-group">
                    <label className="radio-button">
                        <input type="radio" value="0" name="radio" checked />
                        <span>Team Líder</span>
                    </label>
                    <label className="radio-button">
                        <input type="radio" value="1" name="radio" />
                        <span>Agente Productor</span>
                    </label>
                    <label className="radio-button">
                        <input type="radio" value="1" name="radio" />
                        <span>Consultivo</span>
                    </label>
                  </div>
                </div>
            </div>
            <div className="row my-10px">
                <div className="col-md-12">
                  <div className="justification">
                    <div className="modal-internal-component-title">Justificativo de la reasignación</div>
                    <div className="input-search-producer">
                      <input
                        className="input-text-clients-wallet"
                        value="Ej. Reasignación temporal de la cartera por vacaciones"
                      />
                    </div>
                  </div>
                </div>
            </div>
            <div className="f-row-center">
                <div className="buttons-footer-container">
                    <NewButton className="new-btn-primary footer-cta-button">
                      CAMBIAR ROL
                    </NewButton>
                    <span className="skip-upload-button" onClick={props.onClose}>No cambiar por ahora</span>
                </div>
            </div>
        </div>
    </AlternativeModal>
  );
};

RolChange.defaultProps = {
  onClose: () => console.log('close action: rol-change')
}

export default RolChange;
