import React from 'react'
import './liquidity-term-options.scss'

export const LiquidityTermOptions = ({ options, setOption , disabled }) => {

    return (
        <>
            {
                options.map((option,i) => {
                    if(i<3){
                        return (
                            <div key={option.term} className="option-item">
                                <label className="control control-radio">
                                    <input 
                                    disabled={disabled}
                                    name={option.term} 
                                    value={option.key} 
                                    type="radio" 
                                    onChange={()=>setOption(option)}
                                    checked={option.checked}
                                    />
                                    <div className="control_indicator"></div>
                                </label>
                                <div className="option-text">
                                    {option.term}
                                </div>                                   
                            </div>
                        )
                    }
                })

            }
        </>
    )
}
