import React from "react";
import closeIcon from "../../utils/icon-close.svg";

import "./alternative-modal.scss";

const AlternativeModal = ({ onClose, children }) => {
  return ( 
    <div className="alternative-modal">
    <div className="alternative-modal-content">
      <div className="modal-close-container">
        <img
          className="modal-close-icon"
          src={closeIcon}
          alt="..."
          onClick={onClose}
        />
      </div>
      {children}
    </div>
  </div>
  );
};

AlternativeModal.defaultProps = {
  title: null,
  onClose: () => console.log("close action: alternative-modal"),
};

export default AlternativeModal;
