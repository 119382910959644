import React from 'react';
import { useSelector } from 'react-redux';
import { twoDecimalsFormat } from '../../../../common/common-service';

import iconAR from '../../../../utils/AR-ICON.svg';
import iconBR from '../../../../utils/BR-ICON.svg';

const BrlArsQuotationCard = (props) => {
  const { quotationDollar, loadingQuotationDollar } = useSelector(
    (state) => state.market
  );

  return loadingQuotationDollar ? (
    <div className="container-list">
      <div className="quotation-item-container" />
    </div>
  ) : (
    <div className="container-list">
      <div className="quotation-item-container">
        <div className="quotation-item-title">
          <span className='quotation-icon-container'>
            <img className="quotation-icon" src={iconBR} alt="icon price" />
          </span>
          <div className="quotation-text">
            <div className="quotation-symbol">BRLARS</div>
            <div className="quotation-description">
              BRAZILIAN REAL
            </div>
          </div>
        </div>
        <div className="quotation-item-price-container">
          <p className="quotation-item-price">${String(quotationDollar.BRL)}</p>
        </div>
      </div>
    </div>
  );
};

BrlArsQuotationCard.defaultProps = {};

export default BrlArsQuotationCard;
