import React from "react";
import moment from "moment";

import DarkBlueDot from "../../../../utils/DarkBlueDot.svg";
import GreenDot from "../../../../utils/greendot.svg";
import DarkVioletDot from "../../../../utils/DarkVioletDot.svg";
import RedDot from "../../../../utils/reddot.svg";
import LightBlueDot from "../../../../utils/LightBlueDot.svg";
import OrangeDot from "../../../../utils/OrangeDot.svg";
import BlueVioletDot from "../../../../utils/BlueVioletDot.svg";
import YellowDot from "../../../../utils/yellowdot.svg";
import BlackDot from "../../../../utils/BlackDot.svg";


import {
  twoDecimalsFormat,
} from '../../../../common/common-service';

import "./movement.scss";

export const getDotColor = (movementType) => {
  const colorMap = {
    'Rescate FCI': DarkBlueDot,
    'Orden Compra': GreenDot,
    'Orden Venta': DarkVioletDot,
    'Suscripción FCI': RedDot,
    'Ingresaste': LightBlueDot,
    'Retiraste': OrangeDot,
    'Liquidación FCI': BlueVioletDot,
    'Boleto Mercado': BlackDot,
  };

  // Default to yellowDot if the movementType is not found in the mapping
  return colorMap[movementType] || YellowDot;
};

export const Movement = ({ movement, index }) => {
  return (
    <div className="movement-container">
      <div className="movement-item-description">
      <img
          src={getDotColor(movement.movementType)}
          alt="decoration"
          className="dot"
        />
        <div className="movement-type-container">
          <p className="movement-type-text">{movement.movementType}</p>
        </div>
      </div>
      <div className="movement-customer-name-container">
        <p className="movement-customer-name-text">{movement.customerName}</p>
      </div>
      <div className="movement-detail-container">
        <p className="movement-detail-text">{movement.currency}</p>
      </div>
      <div className="movement-amount">
        <p
          className={`movement-amount-text ${
            parseFloat(movement.amount) < 0 ? "negative" : "positive"
          }`}
        >
          {twoDecimalsFormat(movement.amount)}
        </p>
      </div>
      <div className="movement-date-container">
        <p className="movement-date-text">
          {moment(movement.agreementDate).format("DD-MM-YYYY")}
        </p>
      </div>
    </div>
  );
};
