import React from "react";

import "./styleguide.scss";

export const StyleGuide = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12">STYLEGUIDE</div>
      </div>

      <section className="page-section">
        <h2>Responsive</h2>
        <p>
          Responsive modifiers enable specifying different column sizes,
          offsets, alignment and distribution at xs, sm, md &amp; lg viewport
          widths.
        </p>
        <div className="row">
          <div className="col-xs-12 col-sm-3 col-md-2 col-lg-1">
            <div className="box-row"></div>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-8 col-lg-10">
            <div className="box-row"></div>
          </div>
          <div className="col-xs-6 col-sm-3 col-md-2 col-lg-1">
            <div className="box-row"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-3 col-md-2 col-lg-1">
            <div className="box-row"></div>
          </div>
          <div className="col-xs-12 col-sm-9 col-md-10 col-lg-11">
            <div className="box-row"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-10 col-sm-6 col-md-8 col-lg-10">
            <div className="box-row"></div>
          </div>
          <div className="col-xs-2 col-sm-6 col-md-4 col-lg-2">
            <div className="box-row"></div>
          </div>
        </div>
        <pre>
          <code>
            &lt;div className="row"&gt;
            <br />
            &nbsp;&lt;div className="col-xs-12 col-sm-8 col-md-6 col-lg-4"&gt;
            <br />
            &nbsp;&nbsp;&lt;div className="box"&gt;Responsive&lt;/div&gt;
            <br />
            &nbsp;&lt;/div&gt;
            <br />
            &lt;/div&gt;
          </code>
        </pre>
      </section>

      <div className="row">
        <div className="col-xs-12">COLORS</div>
      </div>

      <div>
        <div className="row colors">
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$black</h5>
              <p>#101010</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$white</h5>
              <p>#ffffff</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$light-gray</h5>
              <p>#a4a7be</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$light-gray-2</h5>
              <p>#a6adb4</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$mid-gray</h5>
              <p>#67698b</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$dark-gray</h5>
              <p>#4d4f5c</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$light-gray-blue</h5>
              <p>#d9daf5</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$light-gray-blue-2</h5>
              <p>#d5d9f0</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$light-blue</h5>
              <p>#aec9f5</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$mid-blue</h5>
              <p>#737dff</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$dark-blue</h5>
              <p>#423994</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$darkest-blue</h5>
              <p>#01043b</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$bright-green</h5>
              <p>#11d4cd</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$green</h5>
              <p>#0ab8b2</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="color-box">
              <h5>$red</h5>
              <p>#e80451</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">ICONOS</div>
      </div>

      <div>
        <div className="row icons">
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-paperclip"></i>
              <h5>icon-paperclip</h5>
              <p>&lt;i className="icon-paperclip"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-checked"></i>
              <h5>icon-checked</h5>
              <p>&lt;i className="icon-checked"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-calendar"></i>
              <h5>icon-calendar</h5>
              <p>&lt;i className="icon-calendar"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-spinner"></i>
              <h5>icon-spinner</h5>
              <p>&lt;i className="icon-spinner"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-error"></i>
              <h5>icon-error</h5>
              <p>&lt;i className="icon-error"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-caution">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
              <h5>icon-caution</h5>
              <p>
                &lt;i className="icon-caution"&gt;
                <br />
                &lt;span className="path1"&gt;&lt;/span&gt;
                <br />
                &lt;span className="path2"&gt;&lt;/span&gt;
                <br />
                &lt;span className="path3"&gt;&lt;/span&gt;&lt;/i&gt;
              </p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-upload"></i>
              <h5>icon-upload</h5>
              <p>&lt;i className="icon-upload"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-arrow-angle-expand"></i>
              <h5>icon-arrow-angle-expand</h5>
              <p>&lt;i className="icon-arrow-angle-expand"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-download"></i>
              <h5>icon-download</h5>
              <p>&lt;i className="icon-download"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-send"></i>
              <h5>icon-send</h5>
              <p>&lt;i className="icon-send"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-ticket"></i>
              <h5>icon-ticket</h5>
              <p>&lt;i className="icon-ticket"&gt;&lt;/i&gt;</p>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="icon-box">
              <i className="icon-information"></i>
              <h5>icon-information</h5>
              <p>&lt;i className="icon-information"&gt;&lt;/i&gt;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
