import React from "react";

import helpIconToolTip from "../../utils/tip-icon-gray.svg";

import "./tooltip.scss";

export const TooltipWhite = ({ text }) => {
  return (
    <div className="tooltip-wrapper">
      <p data-tooltip={text}>
        <img className="tooltip" src={helpIconToolTip} alt="tooltip" />
      </p>
    </div>
  );
};