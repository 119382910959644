import React from 'react';

export const ComparedTickerItemCard = ({ tickerData, tickerColor }) => {
  const pricePercentageChange = parseFloat(
    tickerData?.day_percentage
  ).toFixed(2);

  return (
    <div className="compared-ticker-item-card">
      <div className="item-card-top-section">
        <div
          className="colored-type-scuare"
          style={{ backgroundColor: tickerColor }}
        />
      </div>
      <div className="item-card-bottom-section">{tickerData?.ticker}</div>
      <div
        className={
          pricePercentageChange > 0
            ? 'ticker-price-percentage-up'
            : 'ticker-price-percentage-down'
        }
      >
        {pricePercentageChange > 0
          ? `+${pricePercentageChange}%`
          : `-${pricePercentageChange}%`}
      </div>
    </div>
  );
};
