import React from "react";
import "./button.scss";

const STYLES = [
  "btn--primary",
  "btn--primary--angost",
  "btn--primary--tiny",
  "btn--secondary",
  "btn--third--solid",
  "btn--third--outline"
];
const SIZES = ["btn--small", "btn--medium", "btn--large"];

export const Button = ({
  dataTestId,
  children,
  onClick,
  type,
  buttonStyle,
  buttonSize,
  disabled
}) => {
  const btnStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
  const btnSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`btn ${btnStyle} ${btnSize} ${disabled ? "btn-disabled" : ""}`}
    >
      {children}
    </button>
  );
};
