import React from 'react';
import './instrument-options.scss';

export const InstrumentOptions = ({ options, setOption, disabled }) => {
  return (
    <>
      {options.map((option) => {
        return (
          <div key={option.name} className="option-item">
            <label className="control control-radio">
              <input
                disabled={disabled}
                name={option.name}
                value={option.key}
                type="radio"
                onChange={() => setOption(option)}
                checked={option.checked}
              />
              <div className="control_indicator"></div>
            </label>
            <div className="option-text">{option.name}</div>
          </div>
        );
      })}
    </>
  );
};
