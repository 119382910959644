import React from "react";
import "./new-button.scss";

const STYLES = [
  "new-btn-primary"
];

const NewButton = ({
  children,
  onClick,
  type,
  buttonStyle,
  disabled
}) => {
  const btnStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`new-btn ${btnStyle} ${disabled ? "btn-disabled" : ""}`}
    >
      {children}
    </button>
  );
};

export default NewButton;