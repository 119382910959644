import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//import BaseModal from '../../../../components/modal/base-modal'
import AlternativeModal from '../../../../components/alternativeModal/alternative-modal';
import './fund-compare-modal.css';

// import {
//     api_postByFirstTickerHist,
//     api_postBySecondTickerHist,
//     api_postByThirdTickerHist
// } from '../../market-api'

import moment from 'moment';
import { TickerCompareModalBody } from '../ticker-compare-modal-body/ticker-compare-modal-body';
import {
  a_resetFirstTickerHist,
  a_resetSecondTickerHist,
  a_resetThirdTickerHist,
  api_postByFirstTickerHist,
  api_postBySecondTickerHist,
  api_postByThirdTickerHist,
} from '../../market-slice';

export const FundCompareModal = ({ closeModal }) => {
  const [requestSended, setRequestSended] = useState(false);
  const [periodToFetch, setPeriodToFeth] = useState(null);

  const dispatch = useDispatch();

  // const state = useSelector((state) => ({
  //     ...state["marketReducer"],
  //     ...state["commonReducer"],
  //     ...state["operateReducer"],
  // }));

  // const {
  //     selectedTicker,
  // } = state;

  const { selectedTicker } = useSelector((state) => state.market);

  useEffect(() => {
    setRequestSended(false);
    const dateFrom = periodToFetch
      ? moment(periodToFetch[0]).format('YYYY-MM-DD')
      : moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD');

    const dateTo = periodToFetch
      ? moment(periodToFetch[1]).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

    if (selectedTicker.length) {
      if (selectedTicker[0]) {
        dispatch(
          api_postByFirstTickerHist({
            ticker: selectedTicker?.[0].key,
            dateFrom: dateFrom,
            dateTo: dateTo,
          })
        );
      }
      if (selectedTicker[1]) {
        dispatch(
          api_postBySecondTickerHist({
            ticker: selectedTicker?.[1].key,
            dateFrom: dateFrom,
            dateTo: dateTo,
          })
        );
      }
      if (selectedTicker[2]) {
        dispatch(
          api_postByThirdTickerHist({
            ticker: selectedTicker?.[2].key,
            dateFrom: dateFrom,
            dateTo: dateTo,
          })
        );
      }
    }
    setRequestSended(true);

    // return () => {
    //   dispatch(a_resetFirstTickerHist());
    //   dispatch(a_resetSecondTickerHist());
    //   dispatch(a_resetThirdTickerHist());
    // };
  }, [selectedTicker, periodToFetch]);

  return (
    <div className="fund-compare-modal">
      {/* <BaseModal onClose={closeModal} title={title}>
                {requestSended &&
                    <TickerCompareModalBody
                        selectedPeriod={periodToFetch}
                        setPeriod={setPeriodToFeth}
                    />
                }
            </BaseModal> */}
      <AlternativeModal onClose={closeModal}>
        {requestSended && (
          <TickerCompareModalBody
            selectedPeriod={periodToFetch}
            setPeriod={setPeriodToFeth}
          />
        )}
      </AlternativeModal>
    </div>
  );
};
