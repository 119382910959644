import React, { useState } from "react";

import { Prices } from "../prices/prices";
import { Equities } from "../equities/equities-detail-especie";
import { Bonds } from "../bonds/bonds-detail-especie";
import { Cedears } from "../cedears/cedears-detail-especie";

import { 
  twoDecimalsFormat, 
  getCurrencyType 
} from "../../operate-service";

import { useFetchSpecieInfo } from "../common/custom-hooks";

import "./common-detail-especie.scss";

export const CommonDetailEspecie = () => {
  const [option, setOption] = useState("PRECIOS");
  const specieData = useFetchSpecieInfo();

  return (
    <div className={`operate-detail-especie2-container`}>
      <div className="tabs">
        <div
          className={`option ${option === "PRECIOS" ? "selected" : ""}`}
          onClick={() => setOption("PRECIOS")}
        >
          <p className="option-text">PRECIOS</p>
        </div>
        {/* <div
          className={`option ${option === "PERFORMANCE" ? "selected" : ""}`}
          onClick={() => setOption("PERFORMANCE")}
        >
          <p className="option-text">PERFORMANCE</p>
        </div> */}
      </div>
      <div className="operate-detail-title-container">
        <div className="title">
          <p className="title-text">{specieData?.specieInfo?.["ticker"]}</p>
          </div>
        <div className="subtitle">
          <p className="subtitle-text">{`${specieData?.specieInfo?.["name"]} 
             / ${getCurrencyType(specieData?.refData?.currency)} ${twoDecimalsFormat(specieData?.termData?.["NOW_PRICE"])}`}</p>
        </div>
      </div>
      {option === "PRECIOS" ? (
        <Prices />
      ) : specieData?.specieInfo?.type === "EQUITY" ? (
        <Equities />
      ) : specieData?.specieInfo?.type === "BOND" ? (
        <Bonds />
      ) : (
        <Cedears />
      )}
    </div>
  );
};
