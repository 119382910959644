import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import { InputTextUserProfile } from '../inputTextUserProfile/inputTextUserProfile';
import { Spinner } from '../spinner/spinner';

// utils
import basicTabIcon from '../../utils/BasicTab.svg';
import iconProfile from '../../utils/icon-perfil2.svg';
import radioCircle from '../../utils/radiocircle.svg';
import iconCopy from '../../utils/copy-icon.svg';

// styles
import './user-view-profile.scss';

export const UserViewProfile = ({ openModal }) => {
  const { loadingUserLogged, partnerInfo } = useSelector((state) => state.home);

  return (
    <>
      <div
        className={`user-profile-view-container ${
          openModal === 'personalData' ? 'openProfileView' : ''
        }`}
      >
        {loadingUserLogged ? (
          <Spinner />
        ) : (
          <>
            <UserProfileViewContainerHeader user={partnerInfo} />
            <DividerLine />
            <div className="user-profile-view-container-personal-data">
              <UserProfilePersonalDataSection user={partnerInfo} />
              <UserProfileFiscalData user={partnerInfo} />
            </div>
          </>
        )} 
      </div>
      <div
        className={`user-profile-view-container ${
          openModal === 'bankData' ? 'open-bank-view' : ''
        }`}
      >
         {loadingUserLogged ? (
          <Spinner />
        ) : ( 
          <>
            <div className="user-profile-view-container-personal-data">
              <UserProfileAdCapPayment />
            </div>
          </>
         )} 
      </div>
    </>
  );
};

const UserProfileViewContainerHeader = ({ user }) => {
  return (
    <div className="user-profile-view-container-header">
      <div class="circle-partner-letter-big">{user?.fullName?.charAt(0).toUpperCase()}</div>
      <div className="user-profile-view-container-header-info">
        <p className="user-profile-view-container-header-info-name">
          {user?.fullName}
        </p>
        <p className="user-profile-view-container-header-info-email">
          {user?.email}
        </p>
        <p className="user-profile-view-container-header-info-codRef">
          Cód. Referido: <span>{user?.referredCode}</span>
        </p>
      </div>
    </div>
  );
};

const DividerLine = () => (
  <img style={{ width: '100%' }} src={basicTabIcon} alt="..." />
);

const UserProfilePersonalDataSection = ({ user }) => {
  return (
    <div className="user-profile-view-container-personal-data-section-1">
      <div className="section-title-user-profile">DATOS PERSONALES</div>
      <InputTextUserProfile
        label="Celular"
        placeholder={undefined}
        onChange={undefined}
        type="text"
        value={user?.mobile}
        name="mobile"
        inputSize={undefined}
        editable="false"
        children={undefined}
      />
      <InputTextUserProfile
        label="Teléfono"
        placeholder={undefined}
        onChange={undefined}
        type="text"
        value={user?.phone}
        name="phone"
        inputSize={undefined}
        editable="false"
        children={undefined}
      />
      <InputTextUserProfile
        label="CUIL"
        placeholder={undefined}
        onChange={undefined}
        type="text"
        value={user?.cuil}
        name="cuil"
        inputSize={undefined}
        editable="false"
        children={undefined}
      />
      <InputTextUserProfile
        label="Fecha de nacimiento"
        placeholder={undefined}
        onChange={undefined}
        type="text"
        value={user?.birthDate}
        name="birthDate"
        inputSize={undefined}
        editable="false"
        children={undefined}
      />
    </div>
  );
};

const UserProfileFiscalData = ({ user }) => {
  return (
    <div className="user-profile-view-container-personal-data-section-2">
      <div className="section-title-user-profile">DIRECCIÓN FISCAL</div>
      <InputTextUserProfile
        label="Calle"
        placeholder={undefined}
        onChange={undefined}
        type="text"
        value={user?.street}
        name="street"
        inputSize={undefined}
        editable="false"
        children={undefined}
      />
      <div className="section-title-user-profile-numbers">
        <InputTextUserProfile
          label="Número"
          placeholder={undefined}
          onChange={undefined}
          type="text"
          value={user?.number}
          name="number"
          inputSize={undefined}
          editable="false"
          children={undefined}
        />
        <InputTextUserProfile
          label="Piso"
          placeholder={undefined}
          onChange={undefined}
          type="text"
          value={user?.floor}
          name="floor"
          inputSize={undefined}
          editable="false"
          children={undefined}
        />
        <InputTextUserProfile
          label="Dpto"
          placeholder={undefined}
          onChange={undefined}
          type="text"
          value={user?.department}
          name="department"
          inputSize={undefined}
          editable="false"
          children={undefined}
        />
      </div>
      <div className="section-title-user-profile-location">
        <InputTextUserProfile
          label="Localidad"
          placeholder={undefined}
          onChange={undefined}
          type="text"
          value={user?.location}
          name="location"
          inputSize={undefined}
          editable="false"
          children={undefined}
        />
        <div className="section-title-user-profile-location-cp">
          <InputTextUserProfile
            label="C.P."
            placeholder={undefined}
            onChange={undefined}
            type="text"
            value={user?.postalCode}
            name="postalCode"
            inputSize={undefined}
            editable="false"
            children={undefined}
          />
        </div>
      </div>

      <InputTextUserProfile
        label="Provincia"
        placeholder={undefined}
        onChange={undefined}
        type="text"
        value={user?.provinceName}
        name="province"
        inputSize={undefined}
        editable="false"
        children={undefined}
      />
    </div>
  );
};

const UserProfileAdCapPayment = () => {
  const [type, setType] = useState('Pesos');
  const [copiedText, setCopiedText] = useState(null);
  const [showCopyIcon, setShowCopyIcon] = useState(true);

  const copyToClipboard = (text, identifier) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(identifier);
        setShowCopyIcon(false);
        setTimeout(() => {
          setCopiedText(null);
          setShowCopyIcon(true);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="user-profile-view-container-transaction-data-payment">
      <p className="user-profile-transaction-data-title">
        CUENTAS BANCARIAS ADCAP
      </p>
      <div className="payment-data">
        <div className="checkboxes">
          <div className="checkbox-currency">
            <img
              onClick={() => {
                setType('Pesos');
              }}
              className={`img-radio ${type === 'Pesos' ? 'checked' : ''}`}
              src={radioCircle}
              alt="radio"
            />
            <p className="currency-p">Pesos</p>
          </div>
          <div className="checkbox-currency">
            <img
              onClick={() => {
                setType('Dólares');
              }}
              className={`img-radio ${type === 'Dólares' ? 'checked' : ''}`}
              src={radioCircle}
              alt="radio"
            />
            <p className="currency-p">Dólares</p>
          </div>
        </div>
        {type === 'Pesos' ? (
          <div className="container-account-information">
            <div className="transaction-data-payment-list-cbu">
              <p className="transaction-data-p">
                Alias:
                <span onClick={() => copyToClipboard('BANZAPESOS', 'alias')}>
                  BANZAPESOS
                  {showCopyIcon && (
                    <img className="icon-copy" src={iconCopy} alt="icon copy" />
                  )}
                </span>
                {copiedText === 'alias' && (
                  <span className="copy-text">¡Copiado!</span>
                )}
              </p>
              <p className="transaction-data-p">
                CBU:
                <span
                  onClick={() =>
                    copyToClipboard('3220001813000042800020', 'cbu')
                  }
                >
                  3220001813000042800020
                  {showCopyIcon && (
                    <img className="icon-copy" src={iconCopy} alt="icon copy" />
                  )}
                </span>
                {copiedText === 'cbu' && (
                  <span className="copy-text">¡Copiado!</span>
                )}
              </p>
              <p className="transaction-data-p">
                CUIT:
                <span onClick={() => copyToClipboard('30-71161012-6', 'cuit')}>
                  30-71161012-6
                  {showCopyIcon && (
                    <img className="icon-copy" src={iconCopy} alt="icon copy" />
                  )}
                </span>
                {copiedText === 'cuit' && (
                  <span className="copy-text">¡Copiado!</span>
                )}
              </p>
            </div>
            <div className="transaction-data-payment-list-account">
              <p className="transaction-data-p">Tipo: Cuenta corriente</p>
              <p className="transaction-data-p">Banco: Banco Industrial</p>
            </div>
          </div>
        ) : (
          <div className="container-account-information">
            <div className="transaction-data-payment-list-cbu">
              <p className="transaction-data-p">
                Alias:
                <span onClick={() => copyToClipboard('BANZAPESOS', 'alias')}>
                  BANZADOLARES
                  {showCopyIcon && (
                    <img className="icon-copy" src={iconCopy} alt="icon copy" />
                  )}
                </span>
                {copiedText === 'alias' && (
                  <span className="copy-text">¡Copiado!</span>
                )}
              </p>
              <p className="transaction-data-p">
                CBU:
                <span
                  onClick={() =>
                    copyToClipboard('3220001812000042800036', 'cbu')
                  }
                >
                  3220001812000042800036
                  {showCopyIcon && (
                    <img className="icon-copy" src={iconCopy} alt="icon copy" />
                  )}
                </span>
                {copiedText === 'cbu' && (
                  <span className="copy-text">¡Copiado!</span>
                )}
              </p>
              <p className="transaction-data-p">
                CUIT:
                <span onClick={() => copyToClipboard('30-71161012-6', 'cuit')}>
                  30-71161012-6
                  {showCopyIcon && (
                    <img className="icon-copy" src={iconCopy} alt="icon copy" />
                  )}
                </span>
                {copiedText === 'cuit' && (
                  <span className="copy-text">¡Copiado!</span>
                )}
              </p>
            </div>
            <div className="transaction-data-payment-list-account">
              <p className="transaction-data-p">Tipo: Cuenta corriente</p>
              <p className="transaction-data-p">Banco: Banco Industrial</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
