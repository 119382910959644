import moment from 'moment';

export const colorPallete = ['#7381F7', '#3F3D8F', '#9254DE', '#D3ADF7', '#08979C', '#36CFC9', '#BAE7FF', '#386394', '#7385DE', '#377E7C', '#14C2BC', '#1ABB6A', '#419438', '#39CE29', '#828700', '#889438', '#335138', '#092A0F', '#B77943', '#BE6D10', '#F1AD26'];

export const setCheckedRadio = (currentOption) => {
  const { id, name, subText } = currentOption;
  return { id, name, subText, checked: true };
};

export const setUncheckedRadio = (currentOption) => {
  const { id, name, subText } = currentOption;
  return { id, name, subText, checked: false };
};

export const setRadioList = (radio, data) => {
  const id = radio.id;
  const list = data.map((opt) =>
    id === opt.id ? setCheckedRadio(opt) : setUncheckedRadio(opt)
  );
  return list;
};

export const codMonedaToName = (codMoneda) => {
  switch (codMoneda) {
    case "1":
      return "AR$"
      break;
    default:
      return "U$D"
      break;
  }
}

export const dataForPieChart = (data) => {
  if (!Array.isArray(data)) {
    return {
      labels: [],
      amountData: [],
      colors: colorPallete,
    };
  }

  const labels = data.map(item => item.ITEM);
  const amountData = data.map(item => item.RATIO);

  return {
    labels: labels,
    amountData: amountData,
    colors: colorPallete,
  }
}


export const dataForWallet = (data) => {

  const labels = data.map(item => item.ITEM);
  const amountData = data.map(item => item.RATIO);
  const colors = colorPallete;
  const final = labels.map((item,i) => ({name:item,percentage:amountData[i],color:colors[i]}))

  return extractThreeFirstHigherValues(final);
}

export const codLiquidityTermToName = (termCod) => {
  switch (termCod) {
    case "0":
      return 'Inmediato'
      break;
    case "1":
      return '24hs'
      break;
    case "2":
      return '48hs'
      break;
    default:
      return ''
      break
  }
}

export const filterCheckedTicker = (tickers) => {
  let checked = tickers.filter(ticker => (ticker.checked === true))
  return checked[checked.length - 1];
}

export const calendarMonthNamesInPeriod = (beginingDate,endDate) => {

  const days = (new Date(endDate).getTime() - new Date(beginingDate).getTime())/1000 ;
  const quantityOfMonths = days / (60 * 60 * 24 * 7 * 4);
  const monthsDates = [moment(beginingDate).format('MMM-YYYY')]

  for(let i=1;i < quantityOfMonths; i++){
    monthsDates.push(moment(beginingDate).add(i,'month').format('MMM-YYYY'))
  }

  return monthsDates;
}

export const selectDateDataFromDatasetItem = (dataSetItem) => {
  const months = calendarMonthNamesInPeriod(dataSetItem[0]?.fecha, dataSetItem[dataSetItem.length - 1]?.fecha)
  const values = months.map(month => {
      const valuesInMonth = dataSetItem.filter(item => moment(item.fecha).format('MMM') === month)
      const periodValue = valuesInMonth.map(item => parseFloat(item.price.marketData.NOW_PRICE));
      return periodValue.reduce((acc, e) => acc + e, 0) / periodValue.length
  })
  return values;
}

export const createDataSetForWeeklyPeriod = (fullRecords) => {
  
  const weeks = separateDatesOnWeeks(fullRecords[0]?.fecha, fullRecords[fullRecords.length - 1]?.fecha, fullRecords);
  let startPoint = 0;
  const periodValues = [];
  weeks.forEach(week => {
      let periodSum = 0;
      for (let i = startPoint; i < startPoint + 4; i++) {
          periodSum += parseFloat(fullRecords[i]?.price.marketData.NOW_PRICE);
      }
      periodValues.push(periodSum / 4)
      startPoint += 4;
  })

  return periodValues;
}

export const generateDateLabels = (allRecords) => {
  const labels = [];
  allRecords.forEach(record => {
    labels.push(moment(record.fecha).format('DD-MM-YYYY'))
  });
  return labels;
}

export const separateDatesOnWeeks = (startDate,endDate,allRecords) => {

  const monthsNumber = [];

  let currentMonth = 0;

  allRecords.forEach(record => {
    if(currentMonth !== moment(record.fecha).format('M')){
      monthsNumber.push(moment(record.fecha).format('M'))
      currentMonth = moment(record.fecha).format('M')
    }
  });

  const labelsForWeeklyData = []

  monthsNumber.forEach(monthNumber => {
    const monthDays = allRecords.filter(record => {
      return moment(record.fecha).format('M') === monthNumber;
    })

    const weeksOnMonth = parseInt(monthDays.length / 7);
    const monthName = [moment()
      .month(monthNumber-1)
      .format('MMM')];

    if(weeksOnMonth >= 4){
      labelsForWeeklyData.push(...monthName.concat(Array(weeksOnMonth - 1).fill('')));
    }else if(weeksOnMonth >= 1){
      if(monthNumber === moment(endDate).format('M')){
        labelsForWeeklyData.push(...monthName.concat(Array(weeksOnMonth - 1).fill('')));
      }else {
        labelsForWeeklyData.push(...Array(weeksOnMonth).fill(''));
      }
    }
  })

  return labelsForWeeklyData;
}

export const OptionsTipoPersona = [
  { value: 'fisica', description: 'Persona Fisica' },
  { value: 'juridica', description: 'Persona Juridica' },
  { value: 'all', description: 'Todas' },
]

export const OptionsTipoMoneda = [
  { value: 'ars', description: 'ARS' },
  { value: 'usd', description: 'USD' },
  { value: 'all', description: 'Todas' },
]

export const TradingViewMiniSymbolWidget = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
export const extractThreeFirstHigherValues = (listOfValues) => {
  let sorted = listOfValues.sort((a,b) => b.percentage - a.percentage)

  return sorted
}

export const formatBigNumbers = (number = '') => {
  let cleanNumber = number.replace(/\./g, "");
  cleanNumber = number.replace(',','.');
  const floatNumber = parseFloat(cleanNumber)
  const formatedNumber = floatNumber.toLocaleString()
  return formatedNumber
}

export const subTypeFund = [
  { value: 0, description: "Conservadores", subType: "CONSERVADOR" },
  { value: 1, description: "Moderados", subType: "MODERADO" },
  { value: 2, description: "Agresivos", subType: "AGRESIVO" },
];