import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import {
  // ConfigProvider, DatePicker,
  Table,
  Radio,
  Select,
  Button,
} from "antd";
// import locale from 'antd/lib/locale/es_ES';
import {
  getMonthsListWithCurr,
  getYearsListWithCurr,
  currentMonth,
  currentYear,
} from "./utils";
import { exportFeeDetails } from "../../export-xlsx";
import { Spinner } from "../../../../components/spinner/spinner";
import BidimensionalChart from "./bidimensional-chart/bidimensional-chart";
import iconWarning from "../../../../utils/warning.svg";
import iconExcel from "../../../../utils/downloand-excel-btn.svg";

import "./fee.scss";
import { NoData } from "../../../../components/no-data/no-data";

const Fee = ({ period, setPeriod, setDate, selectedClientDashboard }) => {
  const {
    loadingWalletFee,
    walletFee,
    loadingFeeDetail,
    feeDetail,
  } = useSelector((state) => ({
    ...state["common"],
    ...state["wallet"],
  }));

  const notInitialRender = useRef(false);

  const [filterType, setFilterType] = useState(period === 2 ? "year" : "month");
  const [radioValue, setRadioValue] = useState(period === 2 ? "year" : "month");
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [totalFee, setTotalFee] = useState(undefined);
  const [feeDetailArr, setFeeDetailArr] = useState([]);

  useEffect(() => {
    if (!loadingWalletFee) setTotalFee(walletFee ? walletFee["total"] : null);
  }, [walletFee]);

  useEffect(() => {
    if (feeDetail.feeDetails && feeDetail.feeDetails.length) {
      setFeeDetailArr(feeDetail.feeDetails);
    }
  }, [feeDetail]);

  const handleDateChange = ({ name, value }) => {
    let newValue = value.toString().replace(/[^\d.-]/g, ""); // dejamos solo numeros
    name === "month" && setSelectedMonth(parseInt(newValue));
    if (name === "year") {
      setSelectedYear(parseInt(newValue));
      setSelectedMonth(
        newValue === "2021" && currentMonth < 8 ? 8 : currentMonth
      );
    }
  };
  const onRadioChange = (e) => {
    const newFilterType = e.target.value;
    setRadioValue(newFilterType);
    setFilterType(newFilterType);
    setPeriod(newFilterType === "year" ? 2 : 1);
    // Aquí estableces la fecha según sea mensual o anual
    setDate(
      moment()
        .year(selectedYear)
        // Si es anual, establece al inicio del año; si es mensual, al inicio del mes actual
        .startOf(newFilterType === "year" ? "year" : "month")
        .format("YYYY-MM-DD")
    );
  };
  useEffect(() => {
    if (notInitialRender.current) {
      setDate(
        moment()
          .month(selectedMonth)
          .year(selectedYear)
          .startOf(filterType)
          .format("YYYY-MM-DD")
      );
    } else {
      notInitialRender.current = true;
    }
  }, [selectedMonth, selectedYear]);

  const columns = [
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
    },
    {
      title: "Comitente",
      dataIndex: "nombreComitente",
      key: "nombreComitente",
    },
    {
      title: "Boleto",
      dataIndex: "NBoleto",
      key: "NBoleto",
    },
    {
      title: "Monto Operado en AR$",
      dataIndex: "montoOperado",
      key: "montoOperado",
    },
    {
      title: "Fee Operación sin IVA (para bonos y ON)",
      dataIndex: "arancelNeto",
      key: "arancelNeto",
    },
    {
      title: "Fee Operación",
      dataIndex: "arancelConIva",
      key: "arancelConIva",
    },
    {
      title: "Acuerdo Partner",
      dataIndex: "comisiones",
      key: "comisiones",
    },
    {
      title: "Fee del Partner sin Retención",
      dataIndex: "feeNeto",
      key: "feeNeto",
    },
    {
      title: "Fee del Partner sin Retención con IVA",
      dataIndex: "feeConIva",
      key: "feeConIva",
    },
  ];

  const monthsArr = getMonthsListWithCurr(true, selectedYear);
  const yearsArr = getYearsListWithCurr();

  const exportFee = () => {
    exportFeeDetails({
      feeDetailArr,
      clientText: selectedClientDashboard ? "Cuenta comitente nº" : "",
      client: selectedClientDashboard || { name: "", numComitente: "" },
      fee: {
        type: period === 1 ? "Fee acumulado MTD" : "Fee acumulado YTD",
        amount: totalFee,
      },
    });
  };

  return (
    <div className="fee-view-container">
      <div className="fee-header-block">
        <div className="fee-filter-btn-group">
          <div className="container-options-title">
            <p>Tipo de Plazo:</p>
          </div>
          <Radio.Group onChange={onRadioChange} value={radioValue}>
            <Radio value="month">Mensual</Radio>
            <Radio value="year">Anual</Radio>
          </Radio.Group>
          {filterType === "month" && (
            <Select
              style={{ width: 190, marginRight: 10 }}
              size="large"
              onChange={(value) => handleDateChange({ name: "month", value })}
              defaultValue={currentMonth}
              value={monthsArr[selectedMonth]}
            >
              {monthsArr.map((month, index) => (
                <Select.Option
                  key={index}
                  value={index}
                  disabled={parseInt(selectedYear) === 2021 && index < 8}
                >
                  {month}
                </Select.Option>
              ))}
            </Select>
          )}
          {(filterType === "month" || filterType === "year") && (
            <Select
              style={{ width: 190, marginRight: 10 }}
              size="large"
              onChange={(value) => handleDateChange({ name: "year", value })}
              defaultValue={`${currentYear} (en curso)`}
            >
              {yearsArr.map((year, index) => (
                <Select.Option key={index} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          )}
          {
            // (filterType === 'custom') && (
            //   <ConfigProvider locale={locale}>
            //     <DatePicker.RangePicker
            //         size="large"
            //         placeholder={['Desde', 'Hasta']}
            //         // onChange={(range) => handleRangeDates(range)}
            //         ranges={{
            //             'Mes anterior': [moment().add(-1,'month').startOf('month'), moment().add(-1,'month').endOf('month')],
            //             'Este mes': [moment().startOf('month'), moment().endOf('month')]
            //         }}
            //     />
            //   </ConfigProvider>
            // )
          }
        </div>
        <div className="export-btn-container">
          <Button
            type="primary"
            onClick={() => exportFee()}
            //icon={<i className="icon-download" style={{ paddingRight: 10 }} />}
            //icon={iconExcel}
            className="export-button-fee"
            size="large"
          >
            <span className="export-fee-icon">
              <img
                src={iconExcel}
                alt="Exportar FEE"
                width="auto"
                height="auto"
              ></img>
            </span>
            <span className="export-fee-button-title">Exportar FEE</span>
          </Button>
        </div>
      </div>
      <div className="wallet-dashboard-container-fee">
        <div className="wallet-dashboard-container-fee-header">
          {
            <div
              data-testid="fee-title"
              className="total-fee-container-cartera"
            >
              {period === 2 ? "FEE acumulado YTD:" : "FEE del mes:"}
              {totalFee && totalFee !== "0,00" ? (
                <div
                  data-testid="wallet-total-fee"
                  className="total-fee-result-title"
                >
                  ${totalFee}
                </div>
              ) : (
                <NoData text="No hay datos disponibles." />
              )}
            </div>
          }
        </div>
        <div className="wallet-dashboard-container-fee-content">
          <BidimensionalChart
            series={walletFee}
            period={period}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
        </div>
      </div>
      <>
        <div className="item-wallet-separator">&nbsp;</div>
      </>
      <div className="fee-detail">
        {!loadingFeeDetail ? (
          <>
            <div className="fee-detail-container-top">
              <div
                data-testid="wallet-fee-detail-title"
                className="fee-detail-title"
              >
                Detalle de Fee
              </div>
            </div>
            {feeDetailArr?.length ? (
              <Table
                dataSource={feeDetailArr}
                columns={columns}
                className="table-detail-fee"
                pagination={{ pageSize: 8 }}
              />
            ) : (
              <NoData text="No se ha encontrado ningún movimiento" />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default Fee;
