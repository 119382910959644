import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './selectSearch.scss';

export const SelectSearch = ({
  title,
  options,
  setSelection,
  searchData,
  onChangeSearch,
  onClickArrow,
  onClickOption,
  setCurrentOpened,
  currentOpened,
  arrowIcon,
  isEnabled,
}) => {

  
  const {
    loadingSpecieInfo,
    loadingEspeciesOperate,
    loadingInstrumentsMarket,
    loadingInstrumentsFunds,
  } = useSelector((state) => state.operate);

  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    currentOpened !== title && setOpenSelect(false);
  }, [currentOpened]);

  const elementIncludes = (element, x) => {
    const tickerLower = element['ticker']
      ? element['ticker'].toLowerCase()
      : '';
    const nameLower = element['name'] ? element['name'].toLowerCase() : '';
    return (
      tickerLower.includes(x.toLowerCase()) ||
      nameLower.includes(x.toLowerCase())
    );
  };

  const onChange = (e) => {
    onChangeSearch(e);
  };

  const arrowDirectionClick = () => {
    onClickArrow();
    setOpenSelect((x) => !x);
    setCurrentOpened(title);
  };

  const optionListClick = (opt) => {
    setSelection(opt);
    setOpenSelect(false);
    onClickOption(opt);
  };

  const filteredOptions = () => {
    if (searchData.data === '') {
      return options;
    } else {
      return options.filter((element) => {
        return elementIncludes(element, searchData.data);
      });
    }
  };
  const handleArrowClick = () => {
    if (!options.length > 0) return;
    arrowDirectionClick();
  };
  const inputValue = searchData.data || '';

  return !loadingSpecieInfo &&
    !loadingEspeciesOperate &&
    !loadingInstrumentsMarket &&
    !loadingInstrumentsFunds ? (
    <div
      className={`select-search-container ${
        isEnabled
          ? 'select-search-container-active'
          : 'select-search-container-disabled'
      }`}
    >
      <input
        disabled={!isEnabled}
        className="select-search-container-title"
        value={inputValue}
        placeholder={title}
        name="data"
        autoComplete="off"
        onChange={(e) => {
          setOpenSelect(e.target.value !== '');
          onChange(e);
        }}
      />
      <ArrowDirection
        open={openSelect}
        onClick={isEnabled ? handleArrowClick : undefined}
        src={arrowIcon}
      />
      <div
        className={`select-search-container-options ${
          openSelect ? 'open-clients-wallet' : ''
        }`}
      >
        {filteredOptions() &&
          filteredOptions().map((opt, i) => {
            const displayText =
              opt.type !== 'Fondos'
                ? `${opt.ticker} - ${opt.name}`
                : opt.ticker;
            return (
              <div className="select-search-container-options-wrapper" key={i}>
                <div
                  className="option-search-clients-wallet"
                  onClick={() => optionListClick(opt)}
                >
                  <div className="option-search-clients-wallet-item">
                    {displayText}
                  </div>
                </div>
                {filteredOptions().length - 1 !== i ? (
                  <div className="option-search-clients-wallet-divider"></div>
                ) : undefined}
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <div
      className={`select-search-container ${
        isEnabled
          ? 'select-search-container-active'
          : 'select-search-container-disabled'
      }`}
    >
      <input
        disabled
        className="select-search-container-title"
        placeholder="Cargando..."
        name="data"
        autoComplete="off"
      />
      <ArrowDirection src={arrowIcon} />
    </div>
  );
};

const ArrowDirection = ({ open, onClick, src }) => (
  <img
    className={`arrow-container ${open ? 'arrow-invert' : ''}`}
    onClick={onClick}
    src={src}
    alt="..."
  />
);
