import React from "react";
//import { SelectClientsWallet } from "../../../../components/selectClientsWallet/selectClientsWallet";
import Graphic from "../../../../utils/Grupo 4161.svg";
import Conversion from "../../../../utils/conversion.svg";

import "./profit-fee.scss";

export const ProfitFee = () => {
  return (
    <div className="fee-container">
      <div className="profit-values-container">
        <div className="fee-profit-title">Fee Ganancia</div>
        <div className="fee-profit-value">AR$ 1.000.000,00</div>
      </div>
      {/* <div className="fee-profit-line-separator">
        <div className="line"></div>
      </div> */}
      <div className="profit-values-container">
        <div className="fee-profit-title">Equivalente en USD</div>
        <div className="fee-profit-value">U$D 938.54</div>
      </div>
      {/* <div className="graphic">
        <div className="title-grid-operate">Fee Ganancia</div>
        <SelectClientsWallet
        title="Instrumento"
        // options={instrumentList}
        // selection={selectedInstrument}
        // setSelection={setSelectedInstrument}
        sizeList="176px"
        sizeContainer="128px"
      />
        <img src={Graphic} alt="Icon" />
      </div> */}
      {/* <h6>Fee Ganancia</h6>
      <h4>ARS 5.594,84</h4>
      <div className="conversion">
        <div>
          <p>Equivalente USD</p>
          <p>USD 938,54</p>
        </div>
      </div> */}
    </div>
  );
};
