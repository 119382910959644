import React from "react";

import "./custom-input.scss";

export const CustomInput = ({ value, onChange, disabled, index, d, placeholder }) => {

  return (
    <div className="data-element-operate-custom-input-general-container">
      <div className="data-element-operate-custom-input-wrapper">
        <input
          className={`data-element-operate-custom-input ${
            !disabled ? "enabled-custom-input" : ""
          }`}
          disabled={disabled}
          type="text"
          placeholder={placeholder}
          value={value || ''}
          onChange={(e) => {
            const regExp = d ? new RegExp(`^-?\\d*([,]\\d{0,${d}})?$`) : /^-?\d*([.,]\d*)?$/;
            let value = e.target.value;
            value = (!d && value < 1 && value !== '') ? "1" : value;
            if (value === "" || regExp.test(value)) {
              onChange(value, index);
            }
          }}
        />
      </div>
    </div>
  );
};
