import React from 'react';

import './forms.scss';

const Select = ({ label, name, onChange, value, options, className }) => {
    return (
        <div className={"form-container " + className}>
            {(label) && <label>{label}</label>}
            <select className="form-control" name={name} onChange={onChange} value={value}>
                {
                    options.map((option, index) => (
                        <option key={index} value={option?.value}>{option?.label}</option>
                    ))
                }
            </select>
        </div> 
    )
}

Select.defaultProps = {
    name: 'selectname',
    className: '',
    value: '',
    options: [],
    onChange: () => console.log('onChange select')
}

export default Select