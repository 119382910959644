import React, { useState } from "react";

import SectionTitle from './treasury-components/section-title/section-title';
// XL buttons
import NormalButton from './treasury-components/buttons-xl/normal-button';
import OutlineButton from './treasury-components/buttons-xl/outline-button';
// Modas
import AddReceipt from './treasury-components/modals/add-receipt';
import WithdrawMoney from './treasury-components/modals/withdraw-money';
// Tabs Card
import TabsCard from './treasury-components/tabs-card/tabs-card';
// Table
import Table from './treasury-components/table/table';
//  Overview
import Overview from './treasury-components/overview/overview';


import "./treasury-style.scss";

const ViewExample1 = () => (
  <p>Esta es una vista de ejemplo 1</p>
)

export const Treasury = () => {
  const [modalVisibility, setModal] = useState('none')
  return (
    <div className="treasury-container">
      <SectionTitle title="Tesorería" />
      <div className="treasury-overview-container">
        <Overview />
        <Overview currency="Dólares" shortCurrency="USD" />
        <div className="treasury-buttons-container">
          <NormalButton onClick={() => setModal('add-receipt')} />
          <OutlineButton onClick={() => setModal('withdraw-money')} />
        </div>
      </div>
      <TabsCard 
        tabs={['total', 'operaciones ventas', 'operaciones compra', 'depósitos clientes', 'extracciones clientes']}
        views={[Table, ViewExample1]}
      />
      {
        (modalVisibility === 'add-receipt') && (
          <AddReceipt onClose={() => setModal('none')} />
        )
      }
      {
        (modalVisibility === 'withdraw-money') && (
          <WithdrawMoney onClose={() => setModal('none')} />
        )
      }
    </div>
  );
};