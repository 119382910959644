import {
  SET_TRADING_OPTION,
  SET_INSTRUMENT,
  SET_LIQUIDITY_TERM,
  /*--- component -> operateSearch ---*/
  SET_SELECTED_CLIENT,
  /*--- component -> modalCancelProcess ---*/
  SET_DATA_MODAL_CANCEL,
  /*--------- /operate/instruments ---------*/
  LOAD_REQUEST_OPERATE_INSTRUMENTS,
  SET_SUCCESS_OPERATE_INSTRUMENTS,
  SET_FAILURE_OPERATE_INSTRUMENTS,
  /*----------- /operate/holiday -----------*/
  LOAD_REQUEST_OPERATE_HOLIDAY_STATE,
  SET_SUCCESS_OPERATE_HOLIDAY_STATE,
  SET_FAILURE_OPERATE_HOLIDAY_STATE,
  /*----- /operate/holiday -- Argentina -----*/
  LOAD_REQUEST_OPERATE_ARGENTINA_HOLIDAY_STATE,
  SET_SUCCESS_OPERATE_ARGENTINA_HOLIDAY_STATE,
  SET_FAILURE_OPERATE_ARGENTINA_HOLIDAY_STATE,
  /*--------- /broker/cancelarorden ---------*/
  LOAD_REQUEST_OPERATE_CANCEL_ORDER,
  SET_SUCCESS_OPERATE_CANCEL_ORDER,
  SET_FAILURE_OPERATE_CANCEL_ORDER,
  /*----- /broker/cancelarsolicitudfondo ------*/
  LOAD_REQUEST_OPERATE_CANCEL_FUND,
  SET_SUCCESS_OPERATE_CANCEL_FUND,
  SET_FAILURE_OPERATE_CANCEL_FUND,
  /*-------- /operate/ticker/findAll --------*/
  LOAD_REQUEST_ESPECIES_OPERATE,
  SET_SUCCESS_ESPECIES_OPERATE,
  SET_FAILURE_ESPECIES_OPERATE,
  /*-- /operate/getordenesandsolicitudesfdo --*/
  LOAD_REQUEST_OK_ORDER_LIST,
  SET_SUCCESS_OK_ORDER_LIST,
  SET_FAILURE_OK_ORDER_LIST,
  /*-- /operate/getordenesandsolicitudesfdo --*/
  LOAD_REQUEST_FAIL_ORDER_LIST,
  SET_SUCCESS_FAIL_ORDER_LIST,
  SET_FAILURE_FAIL_ORDER_LIST,
  /*----------- /broker/getlotes -----------*/
  LOAD_REQUEST_ORDERS_LOT,
  SET_SUCCESS_ORDERS_LOT,
  SET_FAILURE_ORDERS_LOT,
  /*----------- /operate/expenses -----------*/
  LOAD_REQUEST_EXPENSES,
  SET_SUCCESS_EXPENSES,
  SET_FAILURE_EXPENSES,
  /*------ /operate/ticker/findByTicker ------*/
  LOAD_REQUEST_SPECIE_INFO,
  SET_SUCCESS_SPECIE_INFO,
  SET_FAILURE_SPECIE_INFO,
  /*----------- /operate/insertlot -----------*/
  LOAD_REQUEST_INSERT_ORDER,
  SET_INSERT_ORDER,
  SET_SUCCESS_INSERT_ORDER,
  SET_FAILURE_INSERT_ORDER,
  /*------------ /disclaimer/data -----------*/
  LOAD_REQUEST_DATA_DISCLAIMER,
  SET_SUCCESS_DATA_DISCLAIMER,
  SET_FAILURE_DATA_DISCLAIMER,
  /*------- /disclaimer/authorization -------*/
  LOAD_REQUEST_AUTH_DISCLAIMER,
  SET_SUCCESS_AUTH_DISCLAIMER,
  SET_FAILURE_AUTH_DISCLAIMER,
  /*------------ websocket?ap_pm ------------*/
  SET_OPEN_CUSTOMER_POSITIONS,
  SET_CLOSE_CUSTOMER_POSITIONS,
  SET_MESSAGE_CUSTOMER_POSITIONS,
  /*------------ websocket?ap_tvl ------------*/
  SET_OPEN_CUSTOMER_HOLDINGS,
  SET_CLOSE_CUSTOMER_HOLDINGS,
  SET_MESSAGE_CUSTOMER_HOLDINGS,
} from "../../common/actionTypes";

import {
  formattedInstrumentList,
  filterTickerInformation,
} from "./operate-service";

import { parseSTR } from "../../common/common-service";


export const setSelectedClient = (data) => ({
  type: SET_SELECTED_CLIENT,
  payload: data,
});

export const a_setTradingOption = (data) => {
  return { type: SET_TRADING_OPTION, payload: data };
};

export const a_setInstrument = (data) => {
  return { type: SET_INSTRUMENT, payload: data };
};

export const a_setLiquidityTerm = (data) => {
  return { type: SET_LIQUIDITY_TERM, payload: data };
};

export const a_setDataModalCancel = (data) => {
  return { type: SET_DATA_MODAL_CANCEL, payload: data };
};

export const a_setOperateInstruments = (data) => {
  return { type: SET_SUCCESS_OPERATE_INSTRUMENTS, payload: data };
};

export const a_setSuccessOperateInstruments = (data) => {
  return {
    type: SET_SUCCESS_OPERATE_INSTRUMENTS,
    payload: formattedInstrumentList(data),
  };
};

export const a_setFailureOperateInstruments = () => {
  return {
    type: SET_FAILURE_OPERATE_INSTRUMENTS,
    payload: {
      instruments: [{ terms: [] }, { terms: [] }, { terms: [] }, { terms: [] }],
    },
  };
};

export const a_setSuccessOperateHolidayState = (data) => {
  return { type: SET_SUCCESS_OPERATE_HOLIDAY_STATE, payload: data };
};

export const a_setFailureOperateHolidayState = () => {
  return { type: SET_FAILURE_OPERATE_HOLIDAY_STATE, payload: null };
};

export const a_setSuccessOperateArgHolidayState = (data) => {
  return { type: SET_SUCCESS_OPERATE_ARGENTINA_HOLIDAY_STATE, payload: data };
};

export const a_setFailureOperateArgHolidayState = () => {
  return { type: SET_FAILURE_OPERATE_ARGENTINA_HOLIDAY_STATE, payload: null };
};

export const a_loadRequestOperateCancelOrder = () => {
  return { type: LOAD_REQUEST_OPERATE_CANCEL_ORDER };
};

export const a_setSuccessOperateCancelOrder = (data) => {
  return { type: SET_SUCCESS_OPERATE_CANCEL_ORDER, payload: data };
};

export const a_setFailureOperateCancelOrder = (err) => {
  return { type: SET_FAILURE_OPERATE_CANCEL_ORDER, payload: err };
};

export const a_loadRequestOperateCancelFund = () => {
  return { type: LOAD_REQUEST_OPERATE_CANCEL_FUND };
};

export const a_setSuccessOperateCancelFund = (data) => {
  return { type: SET_SUCCESS_OPERATE_CANCEL_FUND, payload: data };
};

export const a_setFailureOperateCancelFund = (err) => {
  return { type: SET_FAILURE_OPERATE_CANCEL_FUND, payload: err };
};

export const a_loadRequestOperateInstruments = () => {
  return { type: LOAD_REQUEST_OPERATE_INSTRUMENTS };
};

export const a_loadRequestOperateHolidayState = () => {
  return { type: LOAD_REQUEST_OPERATE_HOLIDAY_STATE };
};

export const a_loadRequestOperateArgHolidayState = () => {
  return { type: LOAD_REQUEST_OPERATE_ARGENTINA_HOLIDAY_STATE };
};

export const a_loadRequestEspeciesOperate = () => {
  return { type: LOAD_REQUEST_ESPECIES_OPERATE };
};

export const a_setSuccessEspeciesOperate = (data) => {
  return { type: SET_SUCCESS_ESPECIES_OPERATE, payload: data };
};

export const a_setFailureEspeciesOperate = () => {
  return { type: SET_FAILURE_ESPECIES_OPERATE, payload: [] };
};

export const a_loadRequestOkOrderList = () => {
  return { type: LOAD_REQUEST_OK_ORDER_LIST };
};

export const a_setSuccessOkOrderList = (data) => {
  return { type: SET_SUCCESS_OK_ORDER_LIST, payload: data };
};

export const a_setFailureOkOrderList = () => {
  return { type: SET_FAILURE_OK_ORDER_LIST, payload: null };
};

export const a_loadRequestFailOrderList = () => {
  return { type: LOAD_REQUEST_FAIL_ORDER_LIST };
};

export const a_setSuccessFailOrderList = (data) => {
  return { type: SET_SUCCESS_FAIL_ORDER_LIST, payload: data };
};

export const a_setFailureFailOrderList = () => {
  return { type: SET_FAILURE_FAIL_ORDER_LIST, payload: null };
};

export const a_loadRequestOrdersLot = () => {
  return { type: LOAD_REQUEST_ORDERS_LOT };
};

export const a_setSuccessOrdersLot = (data) => {
  return { type: SET_SUCCESS_ORDERS_LOT, payload: data };
};

export const a_setFailureOrdersLot = () => {
  return { type: SET_FAILURE_ORDERS_LOT, payload: [] };
};

export const a_loadRequestExpenses = () => {
  return { type: LOAD_REQUEST_EXPENSES };
};

export const a_setSuccessExpenses = (data) => {
  return { type: SET_SUCCESS_EXPENSES, payload: data };
};

export const a_setFailureExpenses = () => {
  return { type: SET_FAILURE_EXPENSES, payload: [] };
};

export const a_loadRequestSpecieInfo = () => {
  return { type: LOAD_REQUEST_SPECIE_INFO };
};

export const a_setSuccessSpecieInfo = (data) => {
  return {
    type: SET_SUCCESS_SPECIE_INFO,
    payload: filterTickerInformation(data),
  };
};

export const a_setFailureSpecieInfo = () => {
  return { type: SET_FAILURE_SPECIE_INFO, payload: {} };
};

export const a_setSpecieInfo = (data) => {
  return { type: SET_SUCCESS_SPECIE_INFO, payload: data };
};

export const a_loadRequestInsertLot = () => {
  return { type: LOAD_REQUEST_INSERT_ORDER };
};

export const a_setInsertLot = (data) => {
  return { type: SET_INSERT_ORDER, payload: data };
};

export const a_setSuccessInsertLot = (data) => {
  return { type: SET_SUCCESS_INSERT_ORDER, payload: data };
};

export const a_setFailureInsertLot = () => {
  return { type: SET_FAILURE_INSERT_ORDER, payload: null };
};

export const a_loadRequestDataDisclaimer = () => {
  return { type: LOAD_REQUEST_DATA_DISCLAIMER };
};

export const a_setSuccessDataDisclaimer = (data) => {
  return { type: SET_SUCCESS_DATA_DISCLAIMER, payload: data };
};

export const a_setFailureDataDisclaimer = () => {
  return { type: SET_FAILURE_DATA_DISCLAIMER, payload: null };
};

export const a_loadRequestAuthDisclaimer = () => {
  return { type: LOAD_REQUEST_AUTH_DISCLAIMER };
};

export const a_setSuccessAuthDisclaimer = (data) => {
  return { type: SET_SUCCESS_AUTH_DISCLAIMER, payload: data };
};

export const a_setFailureAuthDisclaimer = () => {
  return { type: SET_FAILURE_AUTH_DISCLAIMER, payload: null };
};

export const a_setOpenCustomerPositions = (data) => {
  return { type: SET_OPEN_CUSTOMER_POSITIONS, payload: data };
};

export const a_setCloseCustomerPositions = (data) => {
  return { type: SET_CLOSE_CUSTOMER_POSITIONS, payload: data };
};

export const a_setMessageCustomerPositions = (data) => {
  return { type: SET_MESSAGE_CUSTOMER_POSITIONS, payload: parseSTR(data) };
};

export const a_setOpenCustomerHoldings = (data) => {
  return { type: SET_OPEN_CUSTOMER_HOLDINGS, payload: data };
};

export const a_setCloseCustomerHoldings = (data) => {
  return { type: SET_CLOSE_CUSTOMER_HOLDINGS, payload: data };
};

export const a_setMessageCustomerHoldings = (data) => {
  return { type: SET_MESSAGE_CUSTOMER_HOLDINGS, payload: parseSTR(data) };
};
