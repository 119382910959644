import React from 'react';

export const PriceTypeContainer = ({
  priceType,
  setPriceType,
  allSelectionsMade,
  isEnabled,
  changePriceType,
}) => {
  const priceTypes = [
    { id: 1, type: 'market', label: 'Precio Mercado' },
    { id: 2, type: 'limit', label: 'Precio Límite' },
  ];

  const handlePriceTypeChange = (type) => {
    if (isEnabled) {
      setPriceType(type);
      changePriceType(type);
    }
  };
  return (
    <div className="operate-container-price-limit">
      {priceTypes.map((priceOption) => (
        <div
          className="operate-main-card-view-icon-container"
          key={priceOption.id}
        >
          <label className="control control-radio">
            <input
              type="radio"
              name="priceTypeRadioGroup"
              checked={priceType === priceOption.type}
              onChange={() => handlePriceTypeChange(priceOption.type)}
              disabled={!isEnabled}
            />
            <div
              className={`control_indicator ${
                allSelectionsMade ? 'active' : 'inactive'
              }`}
            ></div>
          </label>
          <div
            className={`operate-main-card-view-radio-text ${
              allSelectionsMade ? 'active' : 'inactive'
            }`}
          >
            {priceOption.label}
          </div>
        </div>
      ))}
    </div>
  );
};
