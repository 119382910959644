import { axiosCall, socketConnection } from "./common-service";

import {
  a_loadRequestCustomersByPartner,
  a_setSuccessCustomersByPartner,
  a_setFailureCustomersByPartner,
  a_loadRequestWalletFee,
  a_setSuccessWalletFee,
  a_setFailureWalletFee,
  a_loadRequestWalletHolding,
  a_setSuccessWalletHolding,
  a_setFailureWalletHolding,
  a_loadRequestQuotation,
  a_setSuccessQuotation,
  a_setFailureQuotation,
  a_loadPartnerInfo,
  a_setSuccessPartnerInfo,
  a_setFailureParterInfo,
  a_loadRecoverUser,
  a_setSuccessRecoverUser,
  a_setFailureRecoverUser,
  a_loadRecoverPassVerify,
  a_setSuccessRecoverPassVerify,
  a_setFailureRecoverPassVerify,
  a_loadRecoverPassConfirm,
  a_setSuccessRecoverPassConfirm,
  a_setFailureRecoverPassConfirm,
  a_loadRecoverPassRestore,
  a_setSuccessRecoverPassRestore,
  a_setFailureRecoverPassRestore,
  a_loadEmailSend,
  a_setSuccessEmailSend,
  a_setFailureEmailSend,
} from "./common-action";

import {
  a_setOpenCustomerPositions,
  a_setCloseCustomerPositions,
  a_setMessageCustomerPositions,
  a_setOpenCustomerHoldings,
  a_setCloseCustomerHoldings,
  a_setMessageCustomerHoldings,
} from "../views/operate/operate-action";

export const api_getRecoverUser = (params) => {
  return axiosCall({
    path: ["BACK_OFFICE", "/onbording/verifyUser"],
    method: "POST",
    data: params,
    a_onLoading: a_loadRecoverUser,
    a_onSuccess: a_setSuccessRecoverUser,
    a_onError: a_setFailureRecoverUser,
  });
};

export const api_getRecoverPassVerify = (params) => {
  return axiosCall({
    path: ["BACK_OFFICE", "/onbording/verify"],
    method: "POST",
    data: params,
    a_onLoading: a_loadRecoverPassVerify,
    a_onSuccess: a_setSuccessRecoverPassVerify,
    a_onError: a_setFailureRecoverPassVerify,
  });
};

export const api_getRecoverPassConfirm = (params) => {
  return axiosCall({
    path: ["BACK_OFFICE", "/onbording/confirm"],
    method: "POST",
    data: params,
    a_onLoading: a_loadRecoverPassConfirm,
    a_onSuccess: a_setSuccessRecoverPassConfirm,
    a_onError: a_setFailureRecoverPassConfirm,
  });
};

export const api_getRecoverPassRestore = (params) => {
  return axiosCall({
    path: ["BACK_OFFICE", "/onbording/restore"],
    method: "POST",
    data: params,
    a_onLoading: a_loadRecoverPassRestore,
    a_onSuccess: a_setSuccessRecoverPassRestore,
    a_onError: a_setFailureRecoverPassRestore,
  });
};

export const api_getPartnerInfo = (params) => {
  return axiosCall({
    path: "/home/partner/info",
    method: "POST",
    data: params,
    a_onLoading: a_loadPartnerInfo,
    a_onSuccess: a_setSuccessPartnerInfo,
    a_onError: a_setFailureParterInfo,
  });
};

export const api_getCustomersByPartner = (params) => {
  return axiosCall({
    path: "/home/partner/customers/portfolio",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestCustomersByPartner,
    a_onSuccess: a_setSuccessCustomersByPartner,
    a_onError: a_setFailureCustomersByPartner,
  });
};

export const api_getWalletFee = (params) => {
  return axiosCall({
    path: "/portfolio/partner/fee",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestWalletFee,
    a_onSuccess: a_setSuccessWalletFee,
    a_onError: a_setFailureWalletFee,
  });
};

export const api_getWalletHolding = (params) => {
  return axiosCall({
    path: "/portfolio/partner/holding/detail",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestWalletHolding,
    a_onSuccess: a_setSuccessWalletHolding,
    a_onError: a_setFailureWalletHolding,
  });
};

export const api_getQuotation = () => {
  return axiosCall({
    path: "/utils/quotation",
    method: "GET",
    a_onLoading: a_loadRequestQuotation,
    a_onSuccess: a_setSuccessQuotation,
    a_onError: a_setFailureQuotation,
  });
};

export const api_sendLoginContactMeEmail = (params) => {
  return axiosCall({
    path: "/mailing",
    method: "POST",
    data:params,
    a_onLoading: a_loadEmailSend,
    a_onSuccess: a_setSuccessEmailSend,
    a_onError: a_setFailureEmailSend,
  });
};

export const ws_customerPositions = () => {
  return socketConnection({
    channel: "ap_pm",
    a_onOpen: a_setOpenCustomerPositions,
    a_onClose: a_setCloseCustomerPositions,
    a_onMessage: a_setMessageCustomerPositions,
  });
};

export const ws_customerHoldings = () => {
  return socketConnection({
    channel: "ap_tvl",
    a_onOpen: a_setOpenCustomerHoldings,
    a_onClose: a_setCloseCustomerHoldings,
    a_onMessage: a_setMessageCustomerHoldings,
  });
};
