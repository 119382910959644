import React from 'react';
import './terms-and-Conditions.scss';
import cancelTermsAndConditions from '../../utils/cancelTermsAndConditions.svg';

export const TemsAndConditions = ({ setModal }) => {

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('container-shadow')) {
      setModal(false);
    }
  }
  return (
    <div className="container-shadow" onClick={handleOutsideClick}>
      <div className="container-center">
        <div className="container-tems-conditions">
          <div className="container-cancel" onClick={() => setModal(false)}>
            <img src={cancelTermsAndConditions} alt="..." />
          </div>
          <p className="title-terms-and-conditions">
            TÉRMINOS Y CONDICIONES WEB Y APLICACIÓN MÓVIL “BANZA PARTNERS”
          </p>
          <p className="text">
            El Agente se obliga a leer cuidadosamente los términos y condiciones
            que a continuación se expresan (los “Términos y Condiciones”), tanto
            al acceder al sistema web de AdCap Securities Argentina S.A.
            (“AdCap”), como a la aplicación para dispositivos móviles, operando
            en ambos casos bajo la plataforma denominada Banza Partners (“Banza
            Partners”).
          </p>
          <p className="text">
            Al ingresar a Banza Partners el Agente acepta y se obliga a cumplir
            con los términos y condiciones aquí establecidos (los “Términos y
            Condiciones”).
          </p>
          <p className="text">
            Los Términos y Condiciones son de carácter vinculante y de lectura
            obligatoria para el ingreso y utilización de Banza Partners, sin
            perjuicio de la plena vigencia de del convenio como agente productor
            y/u otra documentación que el Agente hubiera suscripto.
          </p>
          <p className="text">
            Los términos en mayúscula no definidos en los Términos y Condiciones
            corresponden a las definiciones previstas en el convenio con AdCap
            que el Agente ha suscripto oportunamente.
          </p>
          <p className="subtitle">ADVERTENCIAS</p>
          <p className="text">
            • Toda la información publicada vinculada con servicios que ofrece
            AdCap podrá ser modificada sin previo aviso.
          </p>
          <p className="text">
            • Es condición esencial del servicio que AdCap pone a su
            disposición, que la información contenida en Banza Partners sea
            utilizada en forma lícita y de acuerdo a los Términos y Condiciones,
            el contrato como agente productor y cualquier otra documentación
            suscripta con AdCap.
          </p>
          <p className="text">
            • AdCap no será responsable de los daños o perjuicios que el Agente
            pudiera sufrir derivados del acceso, uso o mala utilización de los
            contenidos de Banza Partners.
          </p>
          <p className="text">
            • AdCap no será responsable si por cualquier causa fuera imposible
            acceder a Banza Partners, se desconectara el servicio, o por los
            accesos no exitosos a cualquiera de los vínculos incluidos en Banza
            Partners.
          </p>
          <p className="text">
            • AdCap prohíbe la copia, duplicación, redistribución,
            comercialización o cualquier otra actividad que se pueda realizar
            con los contenidos de Banza Partners.
          </p>
          <p className="title-terms-and-conditions">
            TÉRMINOS Y CONDICIONES PARTICULARES
          </p>
          <p className="subtitle">1. Privacidad y Seguridad</p>
          <p className="text">
            El Agente declara y garantiza que comprende la importancia del
            resguardo de las claves personales (las “Claves”) que utilice en el
            marco de Banza Partners y que:
          </p>
          <p className="text">
            • Las Claves de acceso sólo deben ser conocidas por el Agente y son
            intransferibles.
          </p>
          <p className="text">
            • No deberá informar las Claves a terceros, quedando, por lo tanto,
            AdCap exenta de responsabilidad por su divulgación a terceros o uso
            incorrecto.
          </p>
          <p className="text">
            • Las Claves no deberán ser deducibles, debiendo evitarse en lo
            posible constituirla con su fecha de nacimiento, su número de
            documento, su número telefónico, o con caracteres repetidos, que
            sean de sencilla conclusión.
          </p>
          <p className="text">
            • Es importante modificar las Claves con cierta frecuencia o cuando
            se presuma el conocimiento de la misma por parte de terceros o que
            no responda a estas normas básicas de seguridad.
          </p>
          <p className="text">
            • Cada clave es secreta e intransferible y cualquier operación y/o
            consulta realizada con dicha clave será entendida como emanada
            válida, legítima y auténticamente del Agente, sin estar AdCap
            obligada a realizar o tomar resguardo adicional en tal sentido.
          </p>
          <p className="text">
            • Datos personales: de acuerdo a lo establecido por la Ley 25.326,
            sus complementarias y modificatorias, se notifica que la información
            y datos suministrados por el Agente serán utilizados a los fines de
            las validaciones de seguridad y actividades previstas en Banza
            Partners. Asimismo, podrán ser utilizados por AdCap y/o cualquiera
            de sus sociedades vinculadas – en los términos que prevean las
            normativas nacionales o, en su caso, extranjeras con alcance
            extraterritorial – para ofrecimientos comerciales al Agente, el
            desarrollo de actividades promocionales propias, estadísticas u
            otras relacionadas con la actividad de AdCap. Adicionalmente, el
            Agente entiende y acepta que la información podrá ser recibida,
            conservada y procesada fuera de la República Argentina, de acuerdo
            con los sistemas de procesamiento de la información, prácticas de
            seguridad interna, riesgo y compliance correspondientes a AdCap y
            los proveedores informáticos de Banza Partners. Finalmente, se hace
            saber al Agente que podrá ejercer los derechos de acceso,
            rectificación y supresión de los datos suministrados, que le
            confiere – sin limitación – la normativa previamente indicada. Para
            mayor información ver “Políticas de Privacidad” en www.Banza
            Partners.com.ar.
          </p>
          <p className="subtitle">
            2. Alcances del permiso de uso de Banza Partners.
          </p>
          <p className="text">
            • Banza Partners podrá ser utilizada tanto por usuarios personas
            humanas y personas jurídicas (o cualquier sujeto que no califique
            como persona humana) que sea un Agente Productor registrados ante la
            Comisión Nacional de Valores y con un convenio vigente con AdCap.
          </p>
          <p className="text">
            • Queda expresamente determinado que el Agente deberá ajustarse
            íntegramente a la totalidad de las condiciones que AdCap establezca
            para Banza Partners. Adicionalmente, el Agente acepta
            irrestrictamente el criterio de AdCap para la categorización de
            perfiles de riesgo y cualquier operatoria comprendida.
          </p>
          <p className="text">
            • Operaciones y Consultas: el Agente podrá realizar operaciones de
            valores negociables, de suscripción y rescate de cuotapartes, entre
            otras, así como consultar tales movimientos, de manera diaria
            (consulta de operaciones diarias), o bien el detalle histórico, así
            como todas aquellas consultas que se implementen a futuro.
          </p>
          <p className="text">
            • Acceso: el Agente utilizará para el ingreso su clave personal, una
            vez que haya cumplido todos los requisitos de acceso establecidos
            por AdCap.
          </p>
          <p className="subtitle">
            3. Límite de Responsabilidad. Inexistencia de Garantías.
          </p>
          <p className="text">
            • AdCap no será responsable de errores, falencias u omisiones
            emergentes de la utilización de Banza Partners, y no otorga a sus
            Agentes garantías tácitas o expresas, incluyendo sin limitación de
            ningún tipo, inexistencia de virus de computadora y/o de dispositivo
            móvil, garantía por violación de derechos de terceros, dominios,
            títulos de dominio, o de servicios, pérdidas, gastos directos o
            indirectos, interrupción, demora, defecto, error, omisión, falla de
            Banza Partners, etc., en forma inherente o consecuente con la
            utilización de Banza Partners. Por consiguiente, bajo ningún
            supuesto AdCap, con la extensión admisible bajo la ley aplicable,
            será responsable de daño alguno que los Agentes puedan sufrir por la
            utilización de Banza Partners.
          </p>
          <p className="subtitle">
            4. Obligaciones y responsabilidad del Agente. Indemnidad.
          </p>
          <p className="text">
            • El Agente es el exclusivo responsable de toda actividad que se
            produzca respecto de su actividad y debe atenerse al cumplimiento de
            todas las disposiciones e instrucciones emanadas por AdCap o de la
            normativa aplicable. El Agente se obliga a indemnizar y a mantener
            indemne, a AdCap, sus directores y accionistas (las “Personas
            Indemnizadas) contra, y respecto de, toda pérdida, reclamo, multa,
            costo, gasto, daño, honorario, perjuicio, responsabilidad y/o demás
            desembolsos, de cualquier clase y/o naturaleza, a los que cualquiera
            de las Personas Indemnizadas pueda estar sujeta en la medida en que
            tales pérdidas, reclamos, multas, costos, gastos, daños, honorarios,
            perjuicios, responsabilidades y/o desembolsos, de cualquier clase
            y/o naturaleza, fueren consecuencia de actuaciones administrativas
            y/o judiciales y/o de cualquier otra índole, provenientes del
            accionar del Agente.
          </p>
          <p className="subtitle">5. Incumplimientos del Agente.</p>
          <p className="text">
            •Sin perjuicio de lo previsto en el contrato como agente productor,
            cualquier incumplimiento por parte del Agente de sus obligaciones o
            uso no autorizado de Banza Partners derivará, bajo criterio propio y
            exclusivo de AdCap, en la cancelación de la autorización para operar
            Banza Partners por parte del Agente. Sin perjuicio de lo
            anteriormente previsto, el Agente acepta que AdCap podrá suspender
            la autorización a operar del Agente si a su exclusivo criterio, se
            estuvieran realizando operaciones no autorizadas por la normativa
            aplicable o debidas bajo la cuenta del Agente.
          </p>
          <p className="subtitle">6. Información a los Agentes.</p>
          <p className="text">
            • AdCap podrá informar a los Agentes cualquier cuestión relativa al
            uso de Banza Partners y sus efectos, por medio de un aviso general
            de Banza Partners, mensaje de correo electrónico a su dirección de
            correo electrónico registrada en la información del Agente.
          </p>
          <p className="subtitle">7. Declaraciones y Garantías del Agente.</p>
          <p className="text">El Agente declara y garantiza que:</p>
          <p className="text">
            • Tiene plena capacidad para obligarse por la presente y para
            realizar las operaciones habilitadas en Banza Partners.
          </p>
          <p className="text">
            • Cada documento (sea en soporte físico o digital) probará una
            obligación válida y vinculante de su parte, exigible de conformidad
            con sus términos, y no sujeta a repudio o desconocimiento.
          </p>
          <p className="text">
            • Ha proporcionado y proporcionará en el futuro de manera cabal y
            completa la información necesaria para que AdCap realice análisis de
            riesgo y realice cualquier diligencia necesaria en virtud de las
            normas de prevención del lavado de activos y el financiamiento del
            terrorismo.
          </p>
          <p className="text">
            • Resguarda y resguardará adecuadamente las claves o información de
            acceso para el contacto no presencial con AdCap, haciéndose
            responsable de cualquier uso no autorizado.
          </p>
        </div>
      </div>
    </div>
  );
};
