import React from "react";
import { Button } from "antd";
import inMaintenance from "../../../utils/in-maintenance.svg";

import "./temporaly-view.scss";

export const TemporalyView = ({ viewAttr }) => {
  return (
    <>
      {viewAttr.lockLevel === "IN_MAINTENANCE" ? (
        <div className="in-maintenance-container">
          <div className="in-maintenance-wrapper">
            <figure>
              <img src={inMaintenance} alt="EN MANTENIMIENTO" />
            </figure>
            <h2>ESTE MODULO SE ENCUENTRA EN MANTENIMIENTO</h2>
            <p>No tomará mucho tiempo. Volvé más tarde</p>
          </div>
        </div>
      ) : null}

      {viewAttr.lockLevel === "IN_CONSTRUCTION" ? (
        <div className="component-modal-container">
          <div className="component-modal-wrapper">
            <div className="component-modal-title-container">
              <h2>Proximamente nueva pantalla</h2>
            </div>
            <div className="component-modal-description-container">
              <p>En proceso de construccion...</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
