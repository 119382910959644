import React from "react";
import { useSelector } from "react-redux";
import {
  balanceTotalByTM,
  twoDecimalsFormat,
} from "../../common/common-service";

import { HorizontalSpinner } from '../../components/spinner/spinner'
import downloadPossessionIcon from "../../utils/download-icon-white.svg";

import "./balance-card.scss";

export const BalanceCard = ({
  disclaimer,
  currencyOption,
  decoration,
  selectedClientDashboard,
  openOrCloseModalHolding,
  style,
}) => {
  const { 
    customersByPartner, 
    loadingHolding
  } = useSelector((state) => ({
    ...state["home"],
  }));

  const title = () => {
    switch (currencyOption) {
      case "Pesos":
        return "Saldo disponible AR$";
      case "Dólares":
        return "Saldo disponible U$D";
      case "Dólares MEP":
        return "Valor del dolar MEP";
    }
  };

  let totalARS = balanceTotalByTM(customersByPartner, "tmArs", selectedClientDashboard);
  let totalUSD = balanceTotalByTM(customersByPartner, "tmUsd", selectedClientDashboard);

  const waitValue = (type, value) => {
    let set = new Set([null, undefined]);
    return !set.has(value) ? type+" "+(value===""?"0,00":value) : "Cargando...";
  };

const walletBalance = [
  {
    field: "Ahora",
    value:
      currencyOption === "Pesos" && totalARS
        ? waitValue("AR$", twoDecimalsFormat(totalARS[0]))
        : currencyOption === "Dólares" && totalUSD
        ? waitValue("U$D", twoDecimalsFormat(totalUSD[0]))
        : null
  },
  {
    field: "24hs",
    value:
      currencyOption === "Pesos" && totalARS
        ? waitValue("AR$", twoDecimalsFormat(totalARS[1]))
        : currencyOption === "Dólares" && totalUSD
        ? waitValue("U$D", twoDecimalsFormat(totalUSD[1]))
        : null
  },
  {
    field: "48hs",
    value:
      currencyOption === "Pesos" && totalARS
        ? waitValue("AR$", twoDecimalsFormat(totalARS[2]))
        : currencyOption === "Dólares" && totalUSD
        ? waitValue("U$D", twoDecimalsFormat(totalUSD[2]))
        : null
  },
];

  const walletBalanceForClient = [
    {
      field: "Ahora",
      value:
        currencyOption === "Pesos"
          ? waitValue("AR$", selectedClientDashboard?.["tmArs"]?.[0].amount)
          : currencyOption === "Dólares"
          ? waitValue("U$D", selectedClientDashboard?.["tmUsd"]?.[0].amount)
          : waitValue(
              "U$D MEP",
              0
            ),
    },
    {
      field: "24hs",
      value:
        currencyOption === "Pesos"
          ? waitValue("AR$", selectedClientDashboard?.["tmArs"]?.[1].amount)
          : currencyOption === "Dólares"
          ? waitValue("U$D", selectedClientDashboard?.["tmUsd"]?.[1].amount)
          : waitValue(
              "U$D MEP",
              0
            ),
    },
    {
      field: "48hs",
      value:
        currencyOption === "Pesos"
          ? waitValue("AR$", selectedClientDashboard?.["tmArs"]?.[2].amount)
          : currencyOption === "Dólares"
          ? waitValue("U$D", selectedClientDashboard?.["tmUsd"]?.[2].amount)
          : waitValue(
              "U$D MEP",
              0
            ),
    },
  ];

  return (
    <div className="balance-card-container">
      {
      /*
      <div className="button-possession-download" data-testid="wallet-download-btn" onClick={() => (!loadingHolding && openOrCloseModalHolding())}>

          (loadingHolding) ? <HorizontalSpinner/> : (
            <>
              <span>Descargar Tenencia</span>
              <img src={downloadPossessionIcon} width="auto" height="auto" alt="download icon" />
            </>
          )
      </div>
      */
      }
      <div className="wallet-mini-card-container saldos" style={style}>
        <div className="header" style={style}>
        {<img src={decoration} alt="icon" />}
          {title()}
        </div>

        {selectedClientDashboard ? (
          <>
            {walletBalanceForClient.map((element, i) => (
              <div className="item" key={i}>
                <div data-testid="wallet-balance-card-field" className="field">{element?.field}</div>
                <div data-testid="wallet-balance-card-value" className="value">{element?.value}</div>
              </div>
            ))}
          </>
        ) : (
          <>
            {walletBalance.map((element, i) => (
              <div className="item" key={i} style={style}>
                <div data-testid="wallet-balance-card-field" className="field">{element?.field}</div>
                <div data-testid="wallet-balance-card-value" className="value">{element?.value}</div>
              </div>
            ))}
          </>
        )}

        {currencyOption === "Dólares MEP" && <p className="disclaimer-mep">{disclaimer}</p>}
      </div>
    </div>
  );
};
