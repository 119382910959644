import { store } from "./store";

import axios from "axios";
import WebSocket from "isomorphic-ws";

import { 
  a_setGenericServiceFailure, 
  a_setWebSocketConnection,
} from "./common-action";
import { createAsyncThunk } from "@reduxjs/toolkit";
import useJwt from "../auth/jwt/getJwt";

export const getDate = () => {
  let d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const axiosCallAsync = async ({ base, path, method, data }) => {
  try {
    const accessToken = localStorage.getItem('idToken');
    const url = base === 'BACK_OFFICE' 
      ? `${process.env.REACT_APP_ENV_PARTNERS_BACK_OFFICE}${path}` 
      : `${process.env.REACT_APP_ENV_BACK_PARTNERS}${path}`;
    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    const response = await axios.request({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      [dataOrParams]: data,
    });

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      throw new Error('Respuesta no exitosa');
    }
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

export const axiosCall = (obj) => {
  const ERROR_STATUS = "Status error - status diferent to 200. The status is: ";

  const state = store.getState();
  const JWT = state?.commonReducer?.signInData?.signInUserSession?.idToken?.jwtToken;

  let { base, path, method, data, a_onLoading, a_onSuccess, a_onError } = obj;
  
  if (Array.isArray(path)) { base = path[0]; path = path[1]; }

  //axios default configs
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.withCredentials = false;
  if (JWT) axios.defaults.headers.common["Authorization"] = `Bearer ${JWT}`

  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  let RAEBP = process.env.REACT_APP_ENV_BACK_PARTNERS;
  let RAEPBO = process.env.REACT_APP_ENV_PARTNERS_BACK_OFFICE;
  let url = (base === "BACK_OFFICE" ? RAEPBO : RAEBP) + path;

  return async (dispatch) => {
    dispatch(a_onLoading());
    axios
      .request({
        url,
        method,
        [dataOrParams]: data,
      })
      .then(function (response) {
        if (response["status"] === 200) {
          let statusCode = response["data"]["statusCode"];
          if (statusCode !== 200) {
            throw new Error(`${ERROR_STATUS} ${statusCode}`);
          }
          dispatch(a_onSuccess(response["data"]["dataBody"]));
        } else {
          throw new Error(`${ERROR_STATUS} ${response["status"]}`);
        }
      })
      .catch(function (error) {
        dispatch(a_setGenericServiceFailure(true));
        dispatch(a_onError(error));
      });
  };
};
export const socketConnection = (obj) => {
  const state = store.getState();
  const cr = state?.commonReducer;
  const JWT = cr?.signInData?.signInUserSession?.idToken?.jwtToken;

  const { channel, a_onOpen, a_onClose, a_onMessage } = obj;

  const ws = new WebSocket(
    process.env.REACT_APP_ENV_WSRT_PARTNERS +
      "?token=" +
      JWT +
      "&channel=" +
      channel
  );
  const wsc = cr.wsConnections;
  wsc.push(ws);
  store.dispatch(a_setWebSocketConnection(wsc));

  return async (dispatch) => {
    ws.onopen = function open() {
      dispatch(a_onOpen());
    };

    ws.onclose = function close() {
      dispatch(a_onClose());
    };

    ws.onmessage = function incoming(data) {
      dispatch(a_onMessage(data));
    };
  };
};

export const closeSocketConnections = () => {
  const state = store.getState();
  const wscs = state?.commonReducer?.wsConnections;
  wscs && wscs.forEach((ws) => ws.close());
};

export const fillTerms = (tms) => {
  return tms.map((tm, i) => {
    return {
      ...tm,
      amount: tm?.amount !== "" ? tm?.amount : "0,00",
    };
  });
};

export const userWalletsTable = (object) => {
  return object["customers"]?.map((element, i) => {
    return {
      ...element,
      key: element.codComitente,
      quantity: "",
      price: "",
      limit: "",
      checked: true,
      countType: "TOTAL",
      amountToShow:
        "ARS " +
        element["tmArs"][2]["amount"] +
        " / " +
        "USD " +
        element["tmUsd"][2]["amount"],
      speciesCount: 0,
      tmArs: fillTerms(element["tmArs"]),
      tmUsd: fillTerms(element["tmUsd"]),
      edit: true,
    };
  });
};

export const balanceTotalByTM = (clients, tm, selectedClientDashboard) => {
  
  let totalsForInmediateOperation = clients?.map((client) => {
    // Aquí iteramos sobre las propiedades del objeto client
    return client.tmArs?.map((tm) => {
      // Retornamos la propiedad amount de cada objeto tmArs
      return tm.amount
    });
  });
  let amountTotalForInmediateOperation = selectedClientDashboard?.tmArs?.map((tm) => tm?.amount)
  let totalSumsInmediato = totalsForInmediateOperation?.reduce((acc, array) =>
  acc + (Array.isArray(array) ? array[0] : 0), 0
);
  
  
  let totalsFor24HoursOperation =clients?.map((client) => {
    // Aquí iteramos sobre las propiedades del objeto client
    return client.tmUsd?.map((tm) => {
      // Retornamos la propiedad amount de cada objeto tmArs
      return tm.amount;
    });
  });
  let amountTotalFor24HoursOperation = selectedClientDashboard?.tmUsd?.map((tm) => tm?.amount)
  let totalSums24 = totalsFor24HoursOperation?.reduce((acc, array) =>
  acc + (Array.isArray(array) ? array[0] : 0), 0
);  

  if (selectedClientDashboard) {
      if (tm === "tmArs") {
      return amountTotalForInmediateOperation    
    } else if (tm === "tmUsd") {
      return amountTotalFor24HoursOperation
    }
  } else {
    if (tm === "tmArs") {
      return [
        totalSumsInmediato,
        totalSumsInmediato,
        totalSumsInmediato
      ]    
    } else if (tm === "tmUsd") {
      return [
        totalSums24,
        totalSums24,
        totalSums24
      ]
    }
  }
};

export const twoDecimalsFormat = (number) => {
  if (number==="-") return number
  return Number.parseFloat(number ? number : 0)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const epochToTimestamp = (epoch) => {
  let toMili;
  if (epoch < 10000000000) toMili = epoch * 1000;
  return (toMili += new Date().getTimezoneOffset() * -1);
};

export const initCountdown = (user, processLogOut) => {
  let currentT = new Date().getTime();
  let epochExp = epochToTimestamp(
    user?.signInUserSession?.accessToken?.payload?.exp
  );
  var counter = (epochExp - currentT) / 1000;
  setInterval(function () {
    if (counter-- <= 0) {
      processLogOut();
    }
  }, 1000);
};

export const isFieldEmpty = (field, symbol) => {
  return !field ? " - " : symbol ? `${symbol} ${field}` : field;
};

export const parseSTR = (data) => {
  return JSON.parse(data?.data);
};

export const auth = (role, permission) => {
  const state = store.getState().home;
  const uL = state?.partnerInfo;
  return uL?.enableViews;
};

export const checkRoleName = (roleName) => {
  const state = store.getState();
  const uL = state?.commonReducer?.userLogged;
  return uL?.roleName === roleName
};