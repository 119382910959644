import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// ant design
import { Button, Input, Form } from 'antd';

// components actions & services
import { api_verifyRecoveryCode, setErrorMessage, setCodeVerify, api_requestPasswordRecovery } from '../../../common/common-slice';

// styles
import '../recover-user-pass.scss';

const VerifyCode = (props) => {
  const dispatch = useDispatch();
  const { recoverPassVerify, recoverPassConfirm, emailRecoverPass, errorCode, userRecoverPassword} = useSelector((state) => state['common']);
  const [inputValues, setValues] = useState(Array(6).fill(''));
  const passVerify = recoverPassVerify?.message;
  const inputsRef = useRef([]);

  const handleCode = ({externalCode}) => {
    const externalCodeJoin = inputValues.join('');

    externalCode = externalCodeJoin

    if (externalCode) {
      try {
        dispatch(api_verifyRecoveryCode({ code: externalCode, email: emailRecoverPass }));
        dispatch(setCodeVerify(externalCode));
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const onComplete = (code) => {
    console.log('Código completado:', code);
  };
  const requestNewCode = async () => {
    await dispatch(api_requestPasswordRecovery({ user: userRecoverPassword, email: emailRecoverPass }));
    dispatch(setErrorMessage(true))
  }
  const handleChange = (value, index) => {
    const newValues = [...inputValues];
    newValues[index] = value;
    setValues(newValues);
  
    // Verifica si el siguiente input existe y tiene un ref antes de hacer focus
    const nextIndex = index + 1;
    if (index < 6 - 1 && value && newValues[index] && inputsRef.current && inputsRef.current[nextIndex]) {
      inputsRef.current[nextIndex].focus();
    }
  
    // Completa el código si todos los valores están presentes
    if (newValues.every((val) => val) && newValues.length === 6) {
      onComplete(newValues.join(''));
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !inputValues[index]) {
      const prevIndex = index - 1;
      if (inputsRef.current && inputsRef.current[prevIndex]) {
        inputsRef.current[prevIndex].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData
      .getData('text')
      .split('')
      .filter((_, i) => i < 6);
  
    paste.forEach((char, i) => {
      if (inputsRef.current && inputsRef.current[i]) {
        inputsRef.current[i].value = char;
      }
    });
  
    setValues(paste.concat(Array(6 - paste.length).fill('')));
    const nextIndex = paste.length <= 6 ? paste.length : 6 - 1;
  
    if (inputsRef.current && inputsRef.current[nextIndex]) {
      inputsRef.current[nextIndex].focus();
    }
  
    if (paste.length === 6) {
      onComplete(paste.join(''));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <h3 className="subtitle">Revisá tu correo</h3>
      <p className="recover-password-desrcription">
        Te enviaremos el código de verificación a xxxx@gmail.com
      </p>
      {errorCode && (
        <div className="msg-error">
          Ha ocurrido un error, por favor inténtelo nuevamente.
      </div>
      )}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="recover-code-form"
        onFinish={handleCode}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="externalCode"
          className="recover-user-form"
           rules={[
            {
              required: true,
               message: 'El código de acceso no es válido',
            },
          ]}
        >
        <div className="verification-code-input">
        {inputValues.map((value, index) => (
          <Input
          key={index}
          placeholder={passVerify?.externalCode || ''}
          ref={(el) => (inputsRef.current[index] = el)}
          type="text"
          maxLength="1"
          value={value}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          className={`verification-input ${recoverPassConfirm === null ? "error" : ""} `}
          />
        ))}
        </div>
        </Form.Item>

        {recoverPassConfirm === null && (
          <div className="msg-error">
            Pin incorrecto
          </div>
        )}

        <div className="btn-container">
        <Button className="btn-to-login" onClick={requestNewCode} block="true">
            SOLICITAR NUEVO CODIGO
          </Button>
          <Button className="btn-recover-submit" htmlType="submit" block="true">
            CONFIRMAR CODIGO
          </Button>
        </div>
    </Form>
    </>
  );
};

VerifyCode.defaultProps = {};

export default VerifyCode;
