import React from 'react';

import { Spinner } from '../../../../components/spinner/spinner';
import { useSelector } from 'react-redux';
import { DetailPieChart } from '../market-detail-card-pie-chart/detail-pie-chart';
import { extractThreeFirstHigherValues } from '../../market-service';

export const DetailWalletTab = () => {

    const state = useSelector((state) => ({
        ...state["marketReducer"],
        ...state["commonReducer"],
        ...state["operateReducer"],
    }));

    const {
        // specieInfo,
    } = state
    const { specieInfo } = useSelector((state) => state.operate)


    return (
            <div className="ticker-detail-body">
                <div className="ticker-detail-type-title">
                    {specieInfo?.ES_FISICO ? `Persona Fisica` : ``}
                </div>
                <div className="ticker-detail-title">
                    {specieInfo?.ticker}
                </div>
                {specieInfo ? <DetailPieChart specieInfo={specieInfo}/>:<Spinner/>}
            </div>
    );
};
