import React from "react";

import { auth, checkRoleName } from "../../../../common/common-service";

import "./wallet-tabs.scss";

const WalletTabs = ({ option, setOption, selectedClientDashboard }) => {
  return (
    <div className="wallet-tabs-container">
      <div
        data-testid="tenencia-tab-btn"
        className="option"
        onClick={() => setOption("TENENCIA")}
      >
        <p className={`option-text ${option === "TENENCIA" ? "selected" : ""}`}>TENENCIA</p>
      </div>
        {/* <div
          data-testid="fee-tab-btn"
          className="option"
          onClick={() => setOption("FEE")}
        >
         <p className={`option-text ${option === "FEE" ? "selected" : ""}`}>FEE</p>
        </div> */}
      {selectedClientDashboard && (
        <div
          data-testid="cuenta-corriente-tab-btn"
          className="option"
          onClick={() => setOption("CUENTA_CORRIENTE")}
        >
          
         <p className={`option-text ${
            option === "CUENTA_CORRIENTE" ? "selected" : ""
          }`}>MOVIMIENTOS</p>
        </div>
      )}
      {/* {selectedClientDashboard && (
        <div
          data-testid="comprobantes-tab-btn"
          className="option"
          onClick={() => setOption("COMPROBANTES")}
        >
          
         <p className={`option-text ${option === "COMPROBANTES" ? "selected" : ""}`}>COMPROBANTES</p> 
        </div>
      )} */}
    </div>
  );
};

export default WalletTabs;
