import React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from '../../../../components/spinner/spinner';
import { codLiquidityTermToName, formatBigNumbers } from '../../market-service';
import { MarketHistoryChart } from '../market-detail-history-chart/market-history-chart';
import {twoDecimalsFormat} from "../../../../common/common-service";


export const DetailFundQuotationTab = () => {

    
    const { loadingTickerHist } = useSelector((state) => state.market);
    const { specieInfo } = useSelector((state) => state.operate)
    return (
        <div className="ticker-detail-body">
            {/* <div className="ticker-detail-type-title">
                {specieInfo?.ES_FISICO ? `Persona Fisica` : ``}
            </div> */}
            <div className="ticker-detail-title">
                {specieInfo?.ticker}
            </div>
            {loadingTickerHist ?
                <Spinner /> :
                <MarketHistoryChart
                    width={200}
                    height={100}
                />
            }
            <div className="ticker-detail-list">
                <div className="ticker-detail-list-small-title">
                    Precio Anual
                </div>
                <div className="ticker-detail-ul-container">
                    <ul className="ticker-detail-ul">{loadingTickerHist ?
                        <li key={1} className="ticker-detail-list-title">
                            Cargando...
                        </li>
                        :
                        <li key={1} className="ticker-detail-list-title">
                            {`${specieInfo?.currency || 'AR$'} ${parseFloat(specieInfo?.marketData?.PREV_CLOSE_PRICE).toFixed(2)} - 
                            ${specieInfo?.currency || 'AR$'} ${parseFloat(specieInfo?.marketData?.NOW_PRICE).toFixed(2)}`}    
                            {/* {`AR$ ${tickerHist?.message[0]?.price?.marketData?.NOW_PRICE} - 
                            AR$ ${tickerHist?.message[tickerHist.message.length - 1]?.price?.marketData?.NOW_PRICE}`}     */}
                        </li>
                        }
                        <li key="currency" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Moneda</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.currency ?
                                    specieInfo?.currency
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                        <li key="day_percentage" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Variación</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.marketData?.DAY_PERCENTAGE_CHANGE ?
                                    parseFloat(specieInfo?.marketData?.DAY_PERCENTAGE_CHANGE).toFixed(2) + '%'
                                    :
                                    '-'
                                }
                            </p>
                        </li>
                        <li key="profile" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Riesgo</p>
                            <p className='ticker-detail-list-item-value'>
                                {specieInfo?.profile}
                            </p>
                        </li>
                        <li key="worth" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Patrimonio</p>
                            <p className='ticker-detail-list-item-value'>
                                {
                                    `${specieInfo?.currency} 
                                    ${(specieInfo?.marketData?.CLAZZ_WORTH)}`
                                }
                            </p>
                        </li>
                        <li key="plazo_resc" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>Plazo de Liquidez</p>
                            <p className='ticker-detail-list-item-value'>
                                {`${codLiquidityTermToName(specieInfo?.PLAZORESC)}`}
                            </p>
                        </li>
                        <li key="minimum" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>
                                Importe Minimo
                            </p>
                            <p className='ticker-detail-list-item-value'>
                                {`${specieInfo?.MINIMO_INVERSION > 0 ?
                                    specieInfo?.MINIMO_INVERSION
                                    :
                                    'Sin minimo'}`
                                }
                            </p>
                        </li>
                        <li key="update_time" className="ticker-detail-list-item">
                            <p className='ticker-detail-list-item-p'>
                                Hora Actualización
                            </p>
                            <p className='ticker-detail-list-item-value'>
                                {`${specieInfo?.marketData?.UPDATE_TIME ?
                                    specieInfo?.marketData?.UPDATE_TIME
                                    :
                                    '-'}`
                                }
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
