import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/button/button';
import { Arrow } from '../../../../components/arrow/arrow';

// components common and services
import { setSelectedClientFromHomeToWallet } from '../../../../common/common-slice';
import {
  twoDecimalsFormat,
  auth,
  checkRoleName,
} from '../../../../common/common-service';

// utils
import arrowDown from '../../../../utils/arrow-down-blue-icon.svg';
import calendar from '../../../../utils/calendar-days.svg';
import userVector from '../../../../utils/user-vector.svg';
import userAdress from '../../../../utils/user-adress.svg';
import Address from '../../../../utils/address.svg';

// styles
import './client-wallet.scss';
import { api_getCustomerDetails } from '../../home-slice';

export const ClientWallet = ({ client, onExpandToggle, expandClient }) => {

  const { loadingCustomerDetail, customerDetail } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const [whoAmICustomerDetail, setWhoAmICustomerDetail] = useState({});

  
  useEffect(() => {
    if (
      expandClient &&
      client.numComitente !== customerDetail.numComitente

    ) {
      dispatch(
        api_getCustomerDetails({
          codComitente: parseInt(client.codComitente),
        })
        );
      }
    }, [expandClient, client]);
    
    useEffect(() => {
      if (client.numComitente === customerDetail.numComitente) {
        setWhoAmICustomerDetail(customerDetail);
      }
    }, [loadingCustomerDetail, customerDetail, client]);
  
    return (
      <div
      className={`clientWallet-container ${expandClient ? 'expandClient' : ''}`}
      >
      <div className="clientWallet-items-first">
        <div className="clientWallet-item-fullname">
          <div>{client?.name}</div>
          <button className="arrow-wrapper" onClick={onExpandToggle}>
            <Arrow open={expandClient} icon={arrowDown} />
          </button>
        </div>
        <div className="clientWallet-item-data-1">{twoDecimalsFormat(client?.aumArs)}</div>
        <div className="clientWallet-item-data-1">{twoDecimalsFormat(client?.aumOnlyArs)}</div>
        <div className="clientWallet-item-data-1">{twoDecimalsFormat(client?.aumOnlyUsd)}</div>
        {/* <div className="clientWallet-item-data-1">{client?.aumUsd}</div> */}
        <div className="clientWallet-item-data-1">
          {twoDecimalsFormat(client?.tmArs[0].amount)}
        </div>
        <div className="clientWallet-item-data-1">
          {twoDecimalsFormat(client?.tmUsd[0].amount)}
        </div>
        {/*
          <div className="clientWallet-item-data-1 end">
            {twoDecimalsFormat(client?.fee)}
          </div>
        */}
      </div>

      {loadingCustomerDetail ? (
        <p className="loading">Cargando...</p>
      ) : (
        <div className="clientWallet-items-second">
          <div className="items-second-names">
            <ClientDetail
              icon={userAdress}
              data={`N° de Comitente: ${whoAmICustomerDetail.numComitente}`}
            />
            <ClientDetail
              icon={userVector}
              data={whoAmICustomerDetail.email}
            />
            <ClientDetail
              icon={Address}
              data={whoAmICustomerDetail.address}
            />
          </div>
          <div className="items-second-numbers">
            <div className="clientWallet-item-data-2">
              <ClientDetail
                  icon={userAdress}
                  data={`DNI: ${whoAmICustomerDetail.identification}`}
              />
            </div >
            <div className="id-birthday-content">
              <ClientDetail
                  icon={calendar}
                  data={whoAmICustomerDetail.birthDate}
              />
            </div>
          </div>
          <div className="progress-bar-wrapper">
            <ol className="progress-bar">
              {whoAmICustomerDetail.category === 'Conservador' ? (
                <Category
                  conservativeClass="is-active"
                  moderateClass={undefined}
                  aggresiveClass={undefined}
                />
              ) : whoAmICustomerDetail.category === 'Moderado' ? (
                <Category
                  conservativeClass="is-complete"
                  moderateClass="is-active"
                  aggresiveClass={undefined}
                />
              ) : whoAmICustomerDetail.category === 'Arriesgado' ? (
                <Category
                  conservativeClass="is-complete"
                  moderateClass="is-complete"
                  aggresiveClass="is-active"
                />
              ) : (
                undefined
              )}
            </ol>
          </div>
          <div className="items-second-buttons">
            <Link to={'/wallet'}>
              <button
                onClick={() =>
                  dispatch(setSelectedClientFromHomeToWallet(client))
                }
                type="button"
                className="btn-home-view-wallet"
              >
                VER CARTERA
              </button>
              {/* le saco este componente porque no me permite cambiar los estilos 
                  y si se los cambio se los cambio a todos los botones que esten con este componente */}
              {/*            <Button
                onClick={() =>
                  dispatch(setSelectedClientFromHomeToWallet(client))
                }
                type="button"
                buttonStyle="btn-home-view-wallet"
              >
                VER CARTERA
              </Button> */}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export const ClientDetail = ({ icon, data }) => {
  return data ? (
    <div className="clientWallet-item-data-2">
      <img src={icon} alt="icon" />
      {data}
    </div>
  ) : (
    <div className="clientWallet-item-data-2"></div>
  );
};

export const Category = ({
  conservativeClass,
  moderateClass,
  aggresiveClass,
}) => {
  return (
    <>
      <li className={conservativeClass}>
        <span>Conservador</span>
      </li>
      <li className={moderateClass}>
        <span>Moderado</span>
      </li>
      <li className={aggresiveClass}>
        <span>Agresivo</span>
      </li>
    </>
  );
};
