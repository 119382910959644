import React, { useState, useEffect } from "react";
import { DatePicker, Select, Tabs, Table, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { api_getLiquidation, api_getTicket } from "../../wallet-api";
import jsPDF from "jspdf";
import moment from "moment";
import { Spinner } from "../../../../components/spinner/spinner";
import { NoData } from "../../../../components/no-data/no-data";

import ticketForm from "../../../../utils/ticketForm.png";
import liquidationForm from "../../../../utils/liquidationForm.png";
import iconCerrarIcon from "../../../../utils/icon-close.svg";
import ticketIcon from "../../../../utils/icon-ticket.svg";

import "./vouchers.scss";
import { DownloadOutlined } from "@ant-design/icons";

const Vouchers = ({ selectedClientDashboard }) => {
  const state = useSelector((state) => ({
    ...state["commonReducer"],
    ...state["walletReducer"],
  }));

  const dispatch = useDispatch();

  const {
    /*loadingLiquidation,*/ liquidation,
    /*loadingTicket,*/ ticket,
  } = state;
  const [modalStateTicket, setModalTicket] = useState(false);
  const [modalStateLiquidation, setModalLiquidation] = useState(false);
  const [selectTicket, setSelectTicket] = useState(null);
  const [selectedTabValue, setSelectedTabValue] = useState("1");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [operation, setOperation] = useState();
  const [ticketPdfOut, setTicketPdfOut] = useState(null);
  const [liquidationPdfOut, setLiquidationtPdfOut] = useState(null);

  let todayDate = new Date(new Date().setDate(new Date().getDate() + 30));
  let priorDate = new Date(new Date().setDate(new Date().getDate() - 30));
  let to = moment(todayDate).format("YYYY-MM-DD");
  let from = moment(priorDate).format("YYYY-MM-DD");

  const openOrCloseModalTicket = () => {
    setModalTicket(!modalStateTicket);
  };
  const openOrCloseModalLiquidation = () => {
    setModalLiquidation(!modalStateLiquidation);
  };

  const getTicket = (dataSelectTicket) => {
    setSelectTicket(dataSelectTicket);
    openOrCloseModalTicket();
  };
  const getLiquidation = (dataSelectTicket) => {
    setSelectTicket(dataSelectTicket);
    openOrCloseModalLiquidation();
  };

  useEffect(() => {
    selectTicket !== null &&
      modalStateTicket &&
      generateTicketForm(selectTicket);
  }, [selectTicket]);

  useEffect(() => {
    selectTicket !== null &&
      modalStateLiquidation &&
      generateLiquidationForm(selectTicket, liquidation.datosComitente);
  }, [selectTicket, liquidation]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      try {
        let params = {
          dateFrom: dateFrom,
          dateTo: dateTo,
          idAccount: selectedClientDashboard?.codComitente,
          dateType: "1",
        };
        dispatch(api_getLiquidation(params));
      } catch (error) {
        throw new Error(error);
      }
    } else {
      setDateFrom(from);
      setDateTo(to);
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      try {
        let params = {
          idAccount: selectedClientDashboard?.codComitente,
          dateFrom: dateFrom,
          dateTo: dateTo,
        };
        dispatch(api_getTicket(params));
      } catch (error) {
        throw new Error(error);
      }
    } else {
      setDateFrom(from);
      setDateTo(to);
    }
  }, [dateFrom, dateTo]);

  const TabPane = Tabs.TabPane;

  const tableTicket = [
    {
      title: "N°",
      dataIndex: "IDBoleto",
      key: "IDBoleto",
    },
    {
      title: "Operación",
      dataIndex: "tipoOperacion",
      key: "tipoOperacion",
    },
    {
      title: "Fecha de concertación",
      dataIndex: "fechaConcertacion",
      key: "fechaConcertacion",
    },
    {
      title: "Fecha de liquidación",
      dataIndex: "fechaLiquidacion",
      key: "fechaLiquidacion",
    },
    {
      title: "Instrumento",
      dataIndex: "instrumento",
      key: "instrumento",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
    },
    {
      title: "Moneda",
      dataIndex: "moneda",
      key: "moneda",
    },
    {
      title: "Monto",
      dataIndex: "neto",
      key: "neto",
    },
    {
      title: " ",
      key: "action",
      render: (dataSelectTicket) => (
        <i
          onClick={() => {
            getTicket(dataSelectTicket);
          }}
          className="icon-arrow-angle-expand"
          src={ticketIcon}
          alt="ticket-icon"
        ></i>
      ),
    },
  ];

  const tableLiquidation = [
    {
      title: "N°",
      dataIndex: "numSolicitud",
      key: "numSolicitud",
    },
    {
      title: "Operación",
      dataIndex: "tpOperacion",
      key: "tpOperacion",
    },
    {
      title: "Fecha de concertación",
      dataIndex: "fechaConcertacion",
      key: "fechaConcertacion",
    },
    {
      title: "Fecha de liquidación",
      dataIndex: "fechaLiquidacion",
      key: "fechaLiquidacion",
    },
    {
      title: "Instrumento",
      dataIndex: "fondo",
      key: "fondo",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Precio",
      dataIndex: "valorCuotaparte",
      key: "valorCuotaparte",
    },
    {
      title: "Moneda",
      dataIndex: "monedaSimb",
      key: "monedaSimb",
    },
    {
      title: "Monto",
      dataIndex: "importe",
      key: "importe",
    },
    {
      title: " ",
      key: "action",
      render: (dataSelectTicket) => (
        <i
          onClick={() => {
            getLiquidation(dataSelectTicket);
          }}
          className="icon-arrow-angle-expand"
          src={ticketIcon}
          alt="ticket-icon"
        ></i>
      ),
    },
  ];

  const setInstInfo = (doc, tbn) => {
    let str = tbn?.instrumento;
    let ispc = [];
    let line = 0;
    let cc = 0;
    let strL = "";
    for (let i = 0; i < str?.length; i++) {
      if (str[i] === " " && cc < 16) ispc[line] = i;
      if (cc === 16) {
        cc = 0;
        line++;
      }
      cc++;
    }
    doc.setFontSize(9);
    doc.setTextColor(256, 256, 256);
    doc.text(157, 80, "INSTRUMENTO");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(7);

    if (ispc?.length > 0) {
      //173 x1
      //199 x2
      //26  x1-x2
      const getDim = (srtL) => {
        return 157 + (26 - strL?.length) / (strL?.length / 2.7);
      };
      strL = str.slice(0, ispc[0] + 1);
      doc.text(getDim(strL), 102, strL);
      for (var i = 1; i < line; i++) {
        strL = str.slice(ispc[i - 1] + 1, ispc[i] + 1);
        doc.text(getDim(strL), 102 + i * 3, strL);
      }
      strL = str.slice(ispc[ispc?.length - 1] + 1, str?.length);
      doc.text(getDim(strL), 102 + i * 3, strL);

      strL = tbn?.abreviatura + " / " + tbn?.codigoInstrumento;
      doc.text(157 + (26 - strL?.length) / 2.7, 108 + i * 3, strL);
    } else {
      strL = tbn?.instrumento;
      // doc.text(157 + (26 - strL?.length) / 2.7, 102, strL);
      strL = tbn?.abreviatura + " / " + tbn?.codigoInstrumento;
      doc.text(157 + (26 - strL?.length) / 2.7, 108, strL);
    }
  };

  const generateTicketForm = (tbn) => {
    let imgData = document.createElement("img");
    imgData.src = ticketForm;
    let doc = new jsPDF("p", "mm", "a4");
    doc.setProperties({ title: "Boleto" });
    doc.viewerPreferences({ DisplayDocTitle: true });
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    doc.addImage(imgData, "PNG", 0, 0, width, height);

    doc.setFont("helvetica");
    doc.setFontSize(14);
    doc.setTextColor(256, 256, 256);
    doc.text(154, 19, "Boleto N°: " + tbn?.IDBoleto);
    doc.setFontSize(9);
    doc.setTextColor(0, 0, 0);

    doc.setFontSize(9);
    doc.setTextColor(256, 256, 256);
    doc.text(16, 80, "COMITENTE");
    doc.setTextColor(0, 0, 0);
    doc.text(16, 91, tbn?.nombreComitente + " / " + tbn?.numComitente);
    doc.text(16, 101, "Tipo de contribuyente: " + tbn?.contribuyenteIVA);
    doc.text(16, 111, "CUIT / CUIL: " + tbn?.CUIT);
    doc.text(
      16,
      121,
      "Domicilio: " +
        tbn?.calle +
        " N° " +
        tbn?.numero +
        (tbn?.piso ? "Piso: " + tbn?.piso : "") +
        " CP " +
        tbn?.codigoPostal +
        ", " +
        tbn?.provincia +
        ", " +
        tbn?.pais
    );
    doc.setTextColor(256, 256, 256);
    doc.text(16, 132, "FECHA DE CONCERTACIÓN");
    doc.text(110, 132, "FECHA DE LIQUIDACIÓN");

    doc.setTextColor(0, 0, 0);
    doc.text(78, 132, "" + tbn?.fechaConcertacion);
    doc.text(169, 132, "" + tbn?.fechaLiquidacion);

    doc.setFontSize(7);
    setInstInfo(doc, tbn);

    doc.setFontSize(9);
    doc.setTextColor(256, 256, 256);
    doc.text(16, 147, "Cantidad");
    doc.text(45.5, 147, "Precio");
    doc.text(67.5, 147, "Importe Bruto");
    doc.text(101, 147, "Agente");
    doc.text(121, 147, "N° de Registro");
    doc.text(148, 147, "Hora de Orden");
    doc.text(175, 147, "N° de Orden");

    doc.setTextColor(0, 0, 0);
    for (let i = 0; i < tbn?.boletoOrdenes?.length; i++) {
      doc.text(16, 155 + i * 4, tbn?.boletoOrdenes[i]?.cantidadNominalMer + "");
      doc.text(45.5, 155 + i * 4, tbn?.boletoOrdenes[i]?.precioMer + "");
      doc.text(67.5, 155 + i * 4, tbn?.boletoOrdenes[i]?.importeBrutoMer + "");
      doc.text(106, 155 + i * 4, tbn?.boletoOrdenes[i]?.agente + "");
      doc.text(128, 155 + i * 4, tbn?.boletoOrdenes[i]?.numRegistro + "");
      doc.text(153, 155 + i * 4, tbn?.boletoOrdenes[i]?.horaEjecucion);
      doc.text(180, 155 + i * 4, tbn?.boletoOrdenes[i]?.numeroOrden + "");
    }
    doc.setFontSize(7);
    doc.setTextColor(256, 256, 256);
    doc.text(18, 218, "Derecho");
    doc.text(18, 221, "de Registro");
    doc.text(43, 218, "Derecho");
    doc.text(43, 221, "de Mercado");
    doc.text(70, 219.5, "Aranceles");
    doc.text(100, 219.5, "IVA Aranceles");
    doc.text(124, 218, "IVA Derecho");
    doc.text(124, 221, "de Mercado");
    doc.text(153, 219.5, "Total IVA");
    doc.text(175.5, 219.5, "Percepción IIBB");

    doc.setTextColor(0, 0, 0);
    doc.text(
      43,
      230,
      tbn?.derechoMercadoPorcentual + " / " + tbn?.derechoMercado
    );
    doc.text(70, 230, tbn?.arancelPorcentual + " / " + tbn?.arancel);
    doc.text(100, 230, tbn?.IVAArancelPorcentual + " / " + tbn?.IVAArancel);
    doc.text(
      124,
      230,
      tbn?.IVADerechoMercadoPorcentual + " / " + tbn?.IVADerechoMercado
    );
    doc.text(153, 230, "" + tbn?.IVATotal);
    doc.text(175.5, 230, "" + tbn?.percepcionIIBB);

    doc.setFontSize(9);
    doc.setTextColor(256, 256, 256);
    doc.text(23, 247, "CANTIDAD");
    doc.text(52.5, 247, "IMPORTE BRUTO");
    doc.text(88, 247, "IMPORTE NETO");

    doc.setTextColor(0, 0, 0);
    doc.text(23, 259, "" + tbn?.cantidad);
    doc.text(56, 259, "" + tbn?.bruto);
    doc.text(91, 259, "" + tbn?.neto);

    setTicketPdfOut(doc); // almaceno para preview y descarga
  };

  const generateLiquidationForm = (tbn, accountData) => {
    let imgData = document.createElement("img");
    imgData.src = liquidationForm;
    let doc = new jsPDF("p", "mm", "a4");
    doc.setProperties({ title: `Liquidación - ${tbn?.tpOperacion}` });
    doc.viewerPreferences({ DisplayDocTitle: true });
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    doc.addImage(imgData, "PNG", 0, 0, width, height);

    doc.setFont("helvetica");
    doc.setFontSize(10);
    doc.setTextColor(256, 256, 256);
    doc.setFont(undefined, "bold");
    doc.text(184, 42.1, "N° " + tbn?.numSolicitud);

    doc.setFontSize(6);
    doc.setTextColor(0, 0, 0);
    if (tbn?.tpOperacion === "Rescate") {
      doc.text(7, 50, "LIQUIDACIÓN DE RESCATE DE CUOTAPARTES / RECIBO DE COBRO");
    } else {
      doc.text(7, 50, "LIQUIDACIÓN DE SUSCRIPCIÓN DE CUOTAPARTES / RECIBO DE PAGO");
    }
    doc.setTextColor(146, 146, 146);
    doc.text(7, 55.7, "" + accountData?.descripcion);
    doc.text(7, 59.8, "CUIT " + accountData?.cuit);
    doc.text(
      7,
      63.9,
      "Domicilio: " +
        accountData?.calle +
        " " +
        accountData?.alturaCalle +
        " CP " +
        accountData?.codigoPostal +
        " " +
        accountData?.localidad +
        " " +
        accountData?.pais
    );
    doc.setFontSize(6);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    doc.text(
      7,
      70,
      "Informamos que en el día de la fecha ha sido aceptada la Solicitud de " +
        tbn?.tpOperacion +
        " número " +
        tbn?.numSolicitud +
        " para el Fondo Común de Inversión " +
        tbn?.fondo
    );
    doc.text(
      7,
      72.9,
      "(número de inscripción 0000 en la Comisión Nacional de Valores) y que la misma se haya registrada a favor de la cuenta: " +
        tbn?.numComitente
    );

    doc.setFontSize(6);
    doc.setTextColor(0, 0, 0);
    if (tbn?.tpOperacion === "Rescate") {
      doc.text(7, 80, "DETALLE DE LA LIQUIDACIÓN DE RESCATE DE CUOTAPARTES");
    } else {
      doc.text(7, 80, "DETALLE DE LA LIQUIDACIÓN DE SUSCRIPCIÓN DE CUOTAPARTES");
    }

    doc.setTextColor(255, 255, 255);
    if (tbn?.tpOperacion === "Rescate") {
      doc.text(10, 88, "CUOTAPARTES RESCATADAS");
    } else {
      doc.text(10, 88, "CUOTAPARTES SUSCRIPTAS");
    }
    doc.text(10, 95.3, "VALOR CUOTAPARTE");
    doc.text(10, 102.6, "GASTO %");
    doc.text(10, 110, "IMPORTE NETO");
    if (tbn?.tpOperacion === "Rescate") {
      doc.text(10, 117.3, "FORMA DE COBRO");
    }

    doc.setFontSize(8);
    doc.setFont(undefined, "normal");
    doc.setTextColor(0, 0, 0);
    doc.text(51, 88, "" + tbn?.cantidad);
    doc.text(51, 95.3, "" + tbn?.valorCuotaparte);
    doc.text(51, 102.6, "0");
    doc.text(51, 110, "" + tbn?.monedaSimbFdo + " " + tbn?.importe);
    if (tbn?.tpOperacion === "Rescate") {
      doc.text(51, 117.3, "En cuenta");
    }

    if (tbn?.tpOperacion === "Rescate") {
      doc.setFontSize(6);
      doc.setTextColor(146, 146, 146);
      doc.setFont(undefined, "bold");
      doc.text(
        7,
        124.9,
        "Los fondos estarán disponibles a partir del: " + tbn?.fechaLiquidacion
      );
    }
    setLiquidationtPdfOut(doc); // almaceno para preview y descarga
  };

  const Option = Select.Option;

  const handleOperation = (value) => {
    setOperation(value);
  };

  const handleRangeDates = (range) => {
    if (Array.isArray(range) && range.length >= 2) {
      let rangeFrom = moment(range[0]).format("YYYY-MM-DD");
      let rangeTo = moment(range[1]).format("YYYY-MM-DD"); 
      setDateFrom(rangeFrom);
      setDateTo(rangeTo);
    } 
  };
  
  const getData = (value) => {
    if (selectedTabValue === "1") {
      if (value === "Compra Contado" || value === "Venta Contado") {
        let dataFilteredTick = [];
        try {
          ticket.forEach((instrument) => {
            if (instrument.tipoOperacion === value) {
              dataFilteredTick.push({
                ...instrument,
              });
            }
          });
        } catch (error) {
        } finally {
          return dataFilteredTick;
        }
      } else {
        return ticket;
      }
    } else {
      if (value === "Rescate" || value === "Suscripción") {
        let dataFilteredLiq = [];
        liquidation && liquidation.liquidaciones.forEach((instrument) => {
          if (instrument.tpOperacion === value) {
            dataFilteredLiq.push({
              ...instrument,
            });
          }
        });
        return dataFilteredLiq;
      } else {
        return liquidation?.liquidaciones;
      }
    }
  };

  const filters = (
    <Row type="flex" justify="end" gutter={16}>
      <Col span={16}>
        <DatePicker.RangePicker
          placeholder={[from, to]}
          onChange={(range) => handleRangeDates(range)}
        />
      </Col>

      <Col span={8}>
        <Select
          defaultValue="Todos"
          style={{ width: 120 }}
          onChange={handleOperation}
        >
          {selectedTabValue === "1" ? (
            <>
              <Option value="Compra Contado">Compra</Option>
              <Option value="Venta Contado">Venta</Option>
            </>
          ) : (
            <>
              <Option value="Rescate">Rescate</Option>
              <Option value="Suscripción">Suscripción</Option>
            </>
          )}
        </Select>
      </Col>
    </Row>
  );

  return (
    <div className="filters">
      <Tabs
        defaultActiveKey="1"
        onChange={setSelectedTabValue}
        tabBarExtraContent={filters}
      >
        {dateFrom == null || dateTo == null ? (
          ""
        ) : (
          <>
            <TabPane tab="Boletos" key="1">
              <div className="table-tickets">
                <Table
                  columns={tableTicket}
                  locale={{ emptyText: <NoData text="No se encontraron movimientos" /> }}
                  dataSource={getData(operation)}
                  pagination={{ pageSize: 7 }}
                />
              </div>
            </TabPane>

            <TabPane tab="Liquidaciones" key="2">
              <div className="table-tickets">
                <Table
                  columns={tableLiquidation}
                  locale={{ emptyText: <NoData text="No se encontraron movimientos" /> }}
                  dataSource={getData(operation)}
                  pagination={{ pageSize: 7 }}
                />
              </div>
            </TabPane>
          </>
        )}
      </Tabs>

      {dateFrom == null || dateTo == null ? (
        <NoData text="No se han seleccionado filtros para la búsqueda" />
      ) : (
        ""
      )}

      <ModalTicketPreview
        closeModal={openOrCloseModalTicket}
        modalState={modalStateTicket}
        ticketPdfOut={ticketPdfOut}
        selectTicket={selectTicket}
      />

      <ModalLiquidationPreview
        closeModal={openOrCloseModalLiquidation}
        modalState={modalStateLiquidation}
        liquidationPdfOut={liquidationPdfOut}
        selectTicket={selectTicket}
      />
    </div>
  );
};

const ModalTicketPreview = ({
  modalState,
  closeModal,
  ticketPdfOut,
  selectTicket,
}) => {
  if (!modalState) {
    return null;
  }
  return (
    <div className="ticket-preview-modal-container show-modal">
      <div className="ticket-preview-container">
        <div className="ticket-preview-wrapper">
          <div className="container-fluid">
            <div className="row ticket-preview">
              <div className="col-md-12">
                <div className="btn-close">
                  <img
                    className="component-disclaimer-modal-close-icon"
                    src={iconCerrarIcon}
                    alt="..."
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </div>
              </div>
              <div className="col-md-9">
                {ticketPdfOut ? (
                  <iframe
                    title="Vista previa del PDF"
                    width="100%"
                    height="500"
                    src={ticketPdfOut.output("datauristring")}
                  ></iframe>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="col-md-3">
                <h3 className="text-button">Boleto N° {selectTicket?.IDBoleto}</h3>
                <button
                className="download-button"
                  onClick={() =>
                    ticketPdfOut.save(`boleto-${selectTicket?.IDBoleto}.pdf`)
                  }
                >
                  <DownloadOutlined />
                  Descargar PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalLiquidationPreview = ({
  modalState,
  closeModal,
  liquidationPdfOut,
  selectTicket,
}) => {
  if (!modalState) {
    return null;
  }
  return (
    <div className="ticket-preview-modal-container show-modal">
      <div className="ticket-preview-container">
        <div className="ticket-preview-wrapper">
          <div className="container-fluid">
            <div className="row ticket-preview">
              <div className="col-md-12">
                <div className="btn-close">
                  <img
                    className="component-disclaimer-modal-close-icon"
                    src={iconCerrarIcon}
                    alt="..."
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </div>
              </div>
              <div className="col-md-9">
                {liquidationPdfOut ? (
                  <iframe
                    frameBorder="0"
                    width="100%"
                    height="500"
                    src={liquidationPdfOut.output("datauristring")}
                  ></iframe>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="col-md-3">
                <button className="text-button">Liquidación N° {selectTicket?.numSolicitud}</button>
                <button
                className="download-button"
                  onClick={() =>
                    liquidationPdfOut.save(
                      `liquidacion-${selectTicket?.numSolicitud}.pdf`
                    )
                  }
                >
                  <DownloadOutlined />
                  Descargar PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vouchers;