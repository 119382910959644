import React from "react";
import { Link } from "react-router-dom";
import { TooltipWhite } from "../../../../components/tooltip/tooltip-white";

import arrowLink from "../../../../utils/DownArrowRight.svg";

import "./card-holdings.scss";

const STYLES = [
  "card--footer--portfolio--green",
  "card--footer--portfolio--red",
  "card--footer--portfolio--blue",
  "card--footer--portfolio--hover",
];

export const CardHoldings = ({
  cardStyle,
  description,
  amountPesos,
  amountDollars,
  redirect,
  dataTooltipARS,
  dataTooltipUSD,
}) => {
  const myCardStyle = STYLES.includes(cardStyle) ? cardStyle : STYLES[0];
/*   console.log(amountPesos)
 */

  return (
    <div className={`card--footer--portfolio ${myCardStyle}`}>
      <div className="card-profile">
        <div className="card-profile-description">
          {description}
          </div>
        <div className="card-profile-amount">
          <div className="profile-amount-values">
            {`ARS    ${amountPesos}`}
            <TooltipWhite text={dataTooltipARS} />
          </div>
          <div className={description === "FEE Total" ? "avoid-view" : "equivalency-container"}>
            {
              description === "Total Invertido" ? (
                <p className="card-profile-usd-equivalency">
                  {`USD    ${amountDollars}`}
                </p>
              ) : (
                 `USD    ${amountDollars}`
              )
            }
           
            <div className="card-profile-value-equivalency">
           
            <TooltipWhite text={dataTooltipUSD} />
            </div>
          </div>
        </div>
      </div>
      {redirect && (
        <Link className="card-profile-link" to={redirect}>
          <img src={arrowLink} alt="access link" />
          <div className="card-profile-link-url">Ampliar info</div>
        </Link>
      )}
    </div>
  );
};
