import moment from 'moment';
import 'moment/locale/es'

export const currentMonth =  moment().month();
export const currentYear =  moment().year();

// Retorna array de meses y destaca el mes en curso si se pasa current = true
export const getMonthsListWithCurr = (current = true, selectedYear = currentYear) => {
    let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];
    // Si el año seleccionado no es el actual no agrego etiqueta en curso
    if (current && currentYear === parseInt(selectedYear)) {
        months[currentMonth] = `${months[currentMonth]} (en curso)`;
    } 
    return months;
}

// Retorna array de años desde el 2021 y destaca el año en curso
export const getYearsListWithCurr = () => {
    let years = [];
    for (let year = 2021; year < currentYear; year++) {
        years.push(year);
    }
    years.push(`${currentYear} (en curso)`);
    return years.reverse();
}

// Retorna array de dias (Ej: mar. 02)
export const createDayList = ({ selectedMonth, selectedYear }) => {
    const month = selectedMonth ? parseInt(selectedMonth) : moment().month();
    const year = selectedYear ? parseInt(selectedYear) : moment.year();

    let dateMoment = moment().month(month).year(year);
    let yearMonth = dateMoment.format('YYYY-MM');

    let dayList = [];
    let daysInMonth = moment(yearMonth).daysInMonth();

    for (let index = 1; index <= (daysInMonth); index++) {
      let day = index.toString().padStart(2, '0');
      dayList.push(moment(yearMonth).date(day).locale('es').format("ddd")+' '+day);
    }
    return dayList;
}

// Retorna array de mes, solamente primera 3 letras y en mayusculas
export const createShortMonthList = () => getMonthsListWithCurr(false).map(month => month.toUpperCase().substring(0,3));


