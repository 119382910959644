import { useState, useMemo }  from 'react';
import moment from 'moment';
import _ from 'lodash';

export const useFilteredData = (items) => {
  const [filterConfig, setFilterConfig] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'settlementDate' });
 
  const filteredItems = useMemo(() => {
    let filterableItems = items;

    if (filterConfig !== null) {
      filterableItems = filterableItems.filter((item) => (item[filterConfig.key] === filterConfig.value));
    } 
    // else {
    //   // by default filter by ARS
    //   filterableItems = filterableItems.filter((item) => (item?.instrumento === 'Pesos'));
    // }
    if (sortConfig !== null) {
      filterableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    // Allways filter by: estaAnulado = -1, tpActivo = Moneda
    // filterableItems = filterableItems.filter(item => (item?.estaAnulado !== '-1') && (item?.tpActivo === 'MONEDA'));

    return filterableItems;
  }, [items, filterConfig, sortConfig]);

  const requestFilter = ({ key, value }) => {
    if (value === null || value === 'null') {
      return setFilterConfig(null);
    }
    setFilterConfig({ key, value });
  }

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return { items: filteredItems, requestFilter, requestSort, sortConfig };
}

export const getUniqueOptionsList = (items, key = 'currency', parentFilter = undefined) => {
  let arr = items;
  if (parentFilter !== undefined) {
    arr = items.filter(i => i[parentFilter.key] === parentFilter.value);
  }
  if (arr.length > 0) {
    var result = [];
    arr.forEach(function (elem) {
        if (result.indexOf(elem[key]) === -1) {
            (elem[key] !== '' && elem[key] !== null && elem[key] !== undefined) && result.push(elem[key].trim());
        }
    });
    return result;
  } else {
    return [];
  }
}

// Returns Custom Label, if not exists returns capitalized first letter
export const parseDetalleTpActivo = (text) => {
  if (text === undefined || text === null) {
    return text
  }
  const customs = {
    'FCI': 'FCI',
    'TITPUB': 'Bonos',
    'ACCION': 'Acciones',
    'CEDEAR': 'CEDEARs',
    'MONEDA': 'Moneda',
    'OBLNEG': 'Oblig. Neg',
    'FIDFIN': 'Fid. Fin.',
    'LETES': 'Letras'
  }
  let value = customs[text];
  if (value === undefined) {
    let output = text.toLowerCase();
    return output.charAt(0).toUpperCase() + output.slice(1);
  } else {
    return value;
  }
}

export const formatDate = (date) => moment(date).format('YYYY-MM-DD');

// Date range limit 
export const getMaxDateRange = (current = moment()) => (current > moment().add(1, 'month'));

// Group movements by instruments
export const groupMovementsByInstrument = (items) => _(items)
  .groupBy((e) => e.currency)
  .map((items, instrument) => ({
    instrument,
    movements: items.map((item) => ({
      fechaConcertacion: item.settlementDate, 
      fechaLiquidacion: item.agreementDate,
      detalle: item.detail,
      cantidad: item.quantity,
      precio: item.price,
      saldo: item.amount,
    })),
  }))
  .value();

  // Returns formated type of movements
  export const getMovementType = ({ codTpMovimiento, detalle }) => {

    const customs = {
      'SOLFDO': 'Bloqueo Monetario',
      'RECTM': 'Depósito',
      'COMPTM': 'Extracción',
      'CVOMER': /compra\b/gi.test(detalle) ? 'Compra' : 'Venta',  // Compra - Venta. (Agregar regex para matchear con "Detalle", si es "compra" o "venta")
      'LIQFDO': /suscripción\b/gi.test(detalle) ? 'Suscripción' : 'Rescate', //  Suscripción - Rescate (agregar regex para matchear con "Detalle", si es rescate o suscripción )
      'NCDMAN': /débito\b/gi.test(detalle) ? 'Nota de débito' : 'Nota de crédito', // Nota de Crédito - Nota de Débito (agregar regex para matchear con "Detalle". Si no dice nada, es nota de crédito, si dice al inicio "Nota de Débito", es una nota de débito)
    }
    let value = customs[codTpMovimiento];
    if (value === undefined) {
      return codTpMovimiento;
    } else {
      return value;
    }
  }

  // Returns formated categories 
  export const getCategoryNames = ({ codTpMovimiento, detalle, detalleTpActivo }) => {
    const movementType = getMovementType({ codTpMovimiento, detalle });
    const category = parseDetalleTpActivo(detalleTpActivo);
    if (movementType === undefined || category === undefined || movementType.trim() === category.trim()) {
      return '-'
    } else {
      return category
    }
  }