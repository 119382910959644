import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosCallAsync } from '../../common/common-service';
import {
  formattedInstrumentList,
} from './operate-service';

export const api_getOperateSpecieInfo = createAsyncThunk(
  'operate/getOperateSpecieInfo',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findByTicker',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const api_postOperateGetEspecies = createAsyncThunk(
  'operate/api_postOperateGetEspecies',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/ticker/findAll',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const api_getOperateInstruments = createAsyncThunk(
  'operate/getOperateInstruments',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/operate/instruments',
        method: 'GET',
        data: params,
      });
      return formattedInstrumentList(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const api_getOperateInstrumentsMarket = createAsyncThunk(
  'operate/api_getOperateInstrumentsMarket',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/instruments/market',
        method: 'GET',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const api_getOperateInstrumentsFunds = createAsyncThunk(
  'operate/api_getOperateInstrumentsFunds',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: '/instruments/funds',
        method: 'GET',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const api_getOperateExpenses = createAsyncThunk(
  'operate/api_getOperateExpenses',
  async ({ params, codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/order_expenses`,
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Confirmar Orden
export const api_confirmOperateOrder = createAsyncThunk(
  'operate/confirmOperateOrder',
  async ({ orderData, codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/order`,
        method: 'POST',
        data: orderData,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Consultar Orden
export const api_getOperateOrders = createAsyncThunk(
  'operate/getOperateOrders',
  async (codComitente, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/orders`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Cancelar Orden
export const api_cancelOperateOrder = createAsyncThunk(
  'operate/cancelOperateOrder',
  async ({ codComitente, orderId }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/order/${orderId}`,
        method: 'POST',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const api_confirmOperateOrderFdo = createAsyncThunk(
  'operate/confirmOperateOrderFdo',
  async ({ codComitente, orderData }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/order_fdo`,
        method: 'POST',
        data: orderData,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Consultar Orden
export const api_getOperateOrdersFdo = createAsyncThunk(
  'operate/getOperateOrdersFdo',
  async (codComitente, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/ordersfdo`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Cancelar Orden
export const api_cancelOperateOrderFdo = createAsyncThunk(
  'operate/cancelOperateOrderFdo',
  async ({ codComitente, orderId }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/orderfdo/${orderId}`,
        method: 'POST',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// account
export const api_getAccount = createAsyncThunk(
  'operate/api_getAccount',
  async ({ codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/account/${codComitente}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// get orders fdo
export const api_getOperateOkOrderList = createAsyncThunk(
  'operate/getordenesandsolicitudesfdo',
  async ({ codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/ordersfdo`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// get failure orders fdo
export const api_getOperateFailOrderListFdo = createAsyncThunk(
  'operate/getfailureordenesandsolicitudesfdo',
  async ({ codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/ordersfdo`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// get order market
export const api_getOperateOrderMarket = createAsyncThunk(
  'operate/getOperateOrderMarket',
  async ({ codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/orders`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// get failure orders market
export const api_getOperateFailOrderListMarket = createAsyncThunk(
  'operate/getfailureordenesandsolicitudesmarket',
  async ({ codComitente }, { rejectWithValue }) => {
    try {
      const response = await axiosCallAsync({
        base: '_PARTNERS',
        path: `/market/user/${codComitente}/orders`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const initialState = {
  loadingSpecieInfo: false,
  specieInfo: {},
  loadingOperateInstruments: false,
  operateInstruments: {
    instruments: [{ terms: [] }, { terms: [] }, { terms: [] }, { terms: [] }],
  },
  loadingInstrumentsMarket: false,
  instrumentsMarket: [],
  loadingInstrumentsFunds: false,
  instrumentsFunds: [],
  tradingOption: null,
  instrument: null,
  liquidityTerm: null,
  selectedClient: {},
  loadingConfirmOrder: false,
  confirmOrderResponse: null,
  loadingGetOrders: false,
  orders: [],
  loadingCancelOrder: false,
  cancelOrderResponse: null,
  loadingConfirmOrderFdo: false,
  confirmOrderResponseFdo: null,
  loadingGetOrdersFdo: false,
  ordersFdo: [],
  loadingCancelOrderFdo: false,
  cancelOrderResponseFdo: null,
  loadingExpenses: false,
  expenses: [],
  loadingGetAccount: false,
  account: [],
  loadingEspeciesOperate: false,
  especiesOperate: [],
  especiaByPatern:[],
  loadingOkOrderListFdo: false,
  okOrderListFdo: [],
  proccesData: null,
  loadingFailOrderListFdo: false,
  failOrderListFdo: [],
  loadingOkOrderListMarket: false,
  okOrderListMarket: [],
  loadingFailOrderListMarket: false,
  failOrderListMarket: [],
};
const operateSlice = createSlice({
  name: 'operate',
  initialState,
  reducers: {
    setSelectedClient(state, action) {
      state.selectedClient = action.payload;
    },
    a_setTradingOption(state, action) {
      state.tradingOption = action.payload;
    },
    a_setInstrument(state, action) {
      state.instrument = action.payload;
    },
    a_setLiquidityTerm(state, action) {
      state.liquidityTerm = action.payload;
    },
    a_setOperateInstruments(state, action) {
      state.operateInstruments = action.payload;
      state.loadingOperateInstruments = false;
    },
    a_setSpecieInfo(state, action) {
      state.specieInfo = action.payload;
      state.loadingSpecieInfo = false;
    },
    a_setDataModalCancel(state, action) {
      state.proccesData = action.payload;
    },
    a_resetExpensesState(state) {
      state.expenses = [];
      state.loadingExpenses = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(api_getOperateSpecieInfo.pending, (state) => {
        state.loadingSpecieInfo = true;
      })
      .addCase(api_getOperateSpecieInfo.fulfilled, (state, action) => {
        state.loadingSpecieInfo = false;
        state.specieInfo = action.payload.dataBody;
      })

      .addCase(api_getOperateSpecieInfo.rejected, (state) => {
        state.loadingSpecieInfo = false;
        state.specieInfo = {};
      });
    builder
      .addCase(api_getOperateInstruments.pending, (state) => {
        state.loadingOperateInstruments = true;
      })
      .addCase(api_getOperateInstruments.fulfilled, (state, action) => {
        state.loadingOperateInstruments = false;
        state.operateInstruments = action.payload;
      })
      .addCase(api_getOperateInstruments.rejected, (state) => {
        state.loadingOperateInstruments = false;
      });
    builder
      // Confirmar Orden
      .addCase(api_confirmOperateOrder.pending, (state) => {
        state.loadingConfirmOrder = true;
      })
      .addCase(api_confirmOperateOrder.fulfilled, (state, action) => {
        state.loadingConfirmOrder = false;
        state.confirmOrderResponse = action.payload;
      })
      .addCase(api_confirmOperateOrder.rejected, (state) => {
        state.loadingConfirmOrder = false;
      })

      // Consultar Orden
      .addCase(api_getOperateOrders.pending, (state) => {
        state.loadingGetOrders = true;
      })
      .addCase(api_getOperateOrders.fulfilled, (state, action) => {
        state.loadingGetOrders = false;
        state.orders = action.payload;
      })
      .addCase(api_getOperateOrders.rejected, (state) => {
        state.loadingGetOrders = false;
      })
      // Cancelar Orden
      .addCase(api_cancelOperateOrder.pending, (state) => {
        state.loadingCancelOrder = true;
      })
      .addCase(api_cancelOperateOrder.fulfilled, (state, action) => {
        state.loadingCancelOrder = false;
        state.cancelOrderResponse = action.payload;
      })
      .addCase(api_cancelOperateOrder.rejected, (state) => {
        state.loadingCancelOrder = false;
      });
    builder
      // Confirmar Orden FDO
      .addCase(api_confirmOperateOrderFdo.pending, (state) => {
        state.loadingConfirmOrderFdo = true;
      })
      .addCase(api_confirmOperateOrderFdo.fulfilled, (state, action) => {
        state.loadingConfirmOrderFdo = false;
        state.confirmOrderResponseFdo = action.payload;
      })
      .addCase(api_confirmOperateOrderFdo.rejected, (state) => {
        state.loadingConfirmOrderFdo = false;
      })
      // Consultar Orden FDO
      .addCase(api_getOperateOrdersFdo.pending, (state) => {
        state.loadingGetOrdersFdo = true;
      })
      .addCase(api_getOperateOrdersFdo.fulfilled, (state, action) => {
        state.loadingGetOrdersFdo = false;
        state.ordersFdo = action.payload;
      })
      .addCase(api_getOperateOrdersFdo.rejected, (state) => {
        state.loadingGetOrdersFdo = false;
      })
      // Cancelar Orden FDO
      .addCase(api_cancelOperateOrderFdo.pending, (state) => {
        state.loadingCancelOrderFdo = true;
      })
      .addCase(api_cancelOperateOrderFdo.fulfilled, (state, action) => {
        state.loadingCancelOrderFdo = false;
        state.cancelOrderResponseFdo = action.payload;
      })
      .addCase(api_cancelOperateOrderFdo.rejected, (state) => {
        state.loadingCancelOrderFdo = false;
      })
      // order expenses
      .addCase(api_getOperateExpenses.pending, (state) => {
        state.loadingExpenses = true;
      })
      .addCase(api_getOperateExpenses.fulfilled, (state, action) => {
        state.loadingExpenses = false;
        state.expenses = action.payload.dataBody;
      })
      .addCase(api_getOperateExpenses.rejected, (state) => {
        state.loadingExpenses = false;
      })
      // instruments market
      .addCase(api_getOperateInstrumentsMarket.pending, (state) => {
        state.loadingInstrumentsMarket = true;
      })
      .addCase(api_getOperateInstrumentsMarket.fulfilled, (state, action) => {
        state.loadingInstrumentsMarket = false;
        state.instrumentsMarket = action.payload.dataBody;
      })
      .addCase(api_getOperateInstrumentsMarket.rejected, (state) => {
        state.loadingInstrumentsMarket = false;
      })
      // instruments funds
      .addCase(api_getOperateInstrumentsFunds.pending, (state) => {
        state.loadingInstrumentsFunds = true;
      })
      .addCase(api_getOperateInstrumentsFunds.fulfilled, (state, action) => {
        state.loadingInstrumentsFunds = false;
        state.instrumentsFunds = action.payload.dataBody;
      })
      .addCase(api_getOperateInstrumentsFunds.rejected, (state) => {
        state.loadingInstrumentsFunds = false;
      })
      // account
      .addCase(api_getAccount.pending, (state) => {
        state.loadingGetAccount = true;
      })
      .addCase(api_getAccount.fulfilled, (state, action) => {
        state.loadingGetAccount = false;
        state.account = action.payload.dataBody;
      })
      .addCase(api_getAccount.rejected, (state) => {
        state.loadingGetAccount = false;
      })
      // especies operate
      .addCase(api_postOperateGetEspecies.pending, (state) => {
        state.loadingEspeciesOperate = true;
      })
      .addCase(api_postOperateGetEspecies.fulfilled, (state, action) => {
        state.loadingEspeciesOperate = false;
        state.especiesOperate = action.payload.dataBody;

       
        // Filtrar los bonos donde el nombre coincide con el valor de "parent"
  const filteredBonos = action.payload.dataBody.filter(bono => 
    bono.parent && bono.name.includes(bono.parent)
  );

  // Guardar los bonos filtrados en el estado
  state.especiaByPatern = filteredBonos;
 
      })
      .addCase(api_postOperateGetEspecies.rejected, (state) => {
        state.loadingEspeciesOperate = false;
      })
      // get orders fdo
      .addCase(api_getOperateOkOrderList.pending, (state) => {
        state.loadingOkOrderListFdo = true;
      })
      .addCase(api_getOperateOkOrderList.fulfilled, (state, action) => {
        state.loadingOkOrderListFdo = false;
        state.okOrderListFdo = action.payload.dataBody.map((order) => {
          return {
            ...order,
            estado: order.estado.toUpperCase(),
          };
        });
      })
      .addCase(api_getOperateOkOrderList.rejected, (state) => {
        state.loadingOkOrderListFdo = false;
      })
      // get failure orders fdo
      .addCase(api_getOperateFailOrderListFdo.pending, (state) => {
        state.loadingFailOrderListFdo = true;
      })
      .addCase(api_getOperateFailOrderListFdo.fulfilled, (state, action) => {
        state.loadingFailOrderListFdo = false;
        state.failOrderListFdo = action.payload.dataBody.map((order) => {
          return {
            ...order,
            estado: order.estado.toUpperCase(),
          };
        });
      })
      .addCase(api_getOperateFailOrderListFdo.rejected, (state) => {
        state.loadingFailOrderList = false;
      })
      // get order market
      .addCase(api_getOperateOrderMarket.pending, (state) => {
        state.loadingOkOrderListMarket = true;
      })
      .addCase(api_getOperateOrderMarket.fulfilled, (state, action) => {
        state.loadingOkOrderListMarket = false;
        state.okOrderListMarket = action.payload.dataBody.map((order) => {
          return {
            ...order,
            estado: order.estado.toUpperCase(),
          };
        });
      })
      .addCase(api_getOperateOrderMarket.rejected, (state) => {
        state.loadingOkOrderListMarket = false;
      })
      // get failure orders market
      .addCase(api_getOperateFailOrderListMarket.pending, (state) => {
        state.loadingFailOrderListMarket = true;
      })
      .addCase(api_getOperateFailOrderListMarket.fulfilled, (state, action) => {
        state.loadingFailOrderListMarket = false;
        state.failOrderListMarket = action.payload.dataBody.map((order) => {
          return {
            ...order,
            estado: order.estado.toUpperCase(),
          };
        });
      })
      .addCase(api_getOperateFailOrderListMarket.rejected, (state) => {
        state.loadingFailOrderListMarket = false;
      });
  },
});
export const {
  setSelectedClient,
  a_setTradingOption,
  a_setInstrument,
  a_setLiquidityTerm,
  a_setOperateInstruments,
  a_setSpecieInfo,
  a_setDataModalCancel,
  a_resetExpensesState
} = operateSlice.actions;
export const operateReducer = operateSlice.reducer;
