import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import iconWarning from "../../../../utils/warning-partners-24px.svg";

import { FeeChart } from "../feeChart/feeChart";
import { ProfitFee } from "../profit-fee/profit-fee";

import { api_getTeamFee } from "../../team-api";

import "./team-partners-fee.scss";

export const TeamPartnersFee = () => {
  const state = useSelector((state) => ({
    ...state["commonReducer"],
    ...state["teamReducer"],
  }));
  const { loadingTeamFee, teamFee } = state;

  // teamFee?.fees.map((item) => {
  //   console.log(item);
  // });

  const dispatch = useDispatch();

  const [period, setPeriod] = useState(2);
  const [totalFee, setTotalFee] = useState(undefined);
  const [date, setDate] = useState(null);
  const [monthSelected, setMonthSelected] = useState(undefined);

  useEffect(() => {
    let params = {
      dateFrom: "2021-01-01",
      dateTo: "2022-01-31",
    };
    dispatch(api_getTeamFee(params));
  }, [period, date]);

  const getTotalFee = () => {
    // return teamFee.reduce(
    //   (tt, o) =>
    //     tt +
    //     o?.fee.reduce((t, f) => t + parseFloat(f.total.replace(",", ".")), 0),
    //   0
    // );
  };

  useEffect(() => {
    let tF = teamFee ? getTotalFee(teamFee) : null;
    if (!loadingTeamFee) setTotalFee(tF);
  }, [loadingTeamFee, teamFee]);

  return (
    <div className="team-row-fee-main-container">
      <div className="team-partners-fee-general-container">
        <div className="team-partners-fee-sub-container">
          <div className="team-partners-fee-title">Rendimiento Productores</div>

          <div className="team-partners-fee-chart-container">
            {/* <div className="select-period-components-container">
              <div className="inactive-period-button">Anual</div>
              <div className="inactive-period-button">Mensual</div>
              <div className="selected-period-button">Personalizar</div>
              <div>[datepicker]</div>
            </div> */}
            <div className="wallet-dashboard-container-right-cards2-header">
              {/* {
                    <div data-testid="fee-title" className="total-fee-container">
                      {period === 2 ? "FEE acumulado YTD:" : "FEE del mes:"}
                      {
                        (totalFee&&totalFee!=="0,00") ? (
                          <div data-testid="wallet-total-fee" className="total-fee-result-title">
                            ${totalFee}
                          </div>
                        ) : (
                          <div className="total-fee-alert-container">
                            <span className="total-fee-alert-icon">
                              <img src={iconWarning} alt="Warning" width="auto" height="auto" />
                            </span>
                            No hay datos disponibles.
                          </div>
                        )
                      }
                    </div>
                } */}
              <div className="wallet-dashboard-container-right-cards2-header-selections">
                {/* <div className="select-period-buttons-container">
                  <button
                    className={`inactive-period-button special-fee-button-size ${
                      period === 1 ? "selected-period-button" : ""
                    } ${loadingTeamFee ? "disabled-period-button" : ""}`}
                    onClick={() => setPeriod(1)}
                    disabled={loadingTeamFee}
                  >
                    Mensual
                  </button>
                  <button
                    className={`inactive-period-button special-fee-button-size ${
                      period === 2 ? "selected-period-button" : ""
                    } ${loadingTeamFee ? "disabled-period-button" : ""}`}
                    onClick={() => setPeriod(2)}
                    disabled={loadingTeamFee}
                  >
                    Anual
                  </button>
                  </div> */}
                {/* <button
                    className={`inactive-period-button ${
                      period === 1 ? "selected-period-button" : ""
                    } ${loadingTeamFee ? "disabled-period-button" : ""}`}
                    onClick={() => setPeriod(1)}
                    disabled={loadingTeamFee}
                  >
                    Mensual
                  </button>
                  <button
                    className={`inactive-period-button ${
                      period === 2 ? "selected-period-button" : ""
                    } ${loadingTeamFee ? "disabled-period-button" : ""}`}
                    onClick={() => setPeriod(2)}
                    disabled={loadingTeamFee}
                  >
                    Anual
                  </button> */}
              </div>
            </div>
            <div className="wallet-dashboard-container-right-cards2-content">
              <FeeChart
                series={teamFee}
                width={575}
                height={225}
                period={period}
                setDate={setDate}
                setMonthSelected={setMonthSelected}
                monthSelected={monthSelected}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-md-3 profit-fee-container">
        <ProfitFee />
      </div> */}
    </div>
  );
};
