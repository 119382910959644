import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TabBar } from "../operate/operate-components/tab-bar/tab-bar";
import { PriceCoin } from "./market-components/market-price-coin/price-coin";
import { Instruments } from "./market-components/market-instruments/instruments";
import { InstrumentPrice } from './market-components/market-instrument-price/instrument-price';

import "./market-style.scss";
import { api_postBondGetEspecies, api_postCertGetEspecies, api_postEquityGetEspecies, api_postFundGetEspecies } from "./market-slice";

export const Market = () => {

  const dispatch = useDispatch();

  const [optionItem, setOptionItem] = useState("COTIZACIONES DE MERCADO");
  const [tabOptions, setTabOptions] = useState([
    {
      name: "COTIZACIONES DE MERCADO",
      onClick: () => setOptionItem("COTIZACIONES DE MERCADO"),
      visible: true,
      selected: true,
    },
    {
      name: "INSTRUMENTOS",
      onClick: () => setOptionItem("INSTRUMENTOS"),
      visible: true,
      selected: false,
    },
  ]);

  useEffect(() => {
    (optionItem === 'COTIZACIONES DE MERCADO') && instrumentPrice();
    (optionItem === 'INSTRUMENTOS') && instruments();
  }, [optionItem]);


  const instrumentPrice = async () => {
   await dispatch(
      api_postFundGetEspecies({
        type: "FUND",
        subType: "CONSERVADOR",
        // EsFisico: "True",
      })
    );

   await dispatch(
      api_postBondGetEspecies({
        type: "BOND",
        subType: "GOVERNMENT",
      })
    );

   await dispatch(
      api_postEquityGetEspecies({
        type: "EQUITY",
        subType: "BYMA.MERV",
      })
    );

   await dispatch(
      api_postCertGetEspecies({
        type: "CERTIFICATE",
      })
    );
  };

  const instruments = async () => {
   await dispatch(
      api_postFundGetEspecies({
        type: "FUND",
      })
    );

   await dispatch(
      api_postBondGetEspecies({
        type: "BOND",
      })
    );

   await dispatch(
      api_postEquityGetEspecies({
        type: "EQUITY",
      })
    );
  };

  return (
    <div className="market-view-container">
      <PriceCoin />
      {/* <><div className="item-market-separator">&nbsp;</div></> */}
      <TabBar tabOptions={tabOptions} setTabOptions={setTabOptions} />
      {optionItem === "COTIZACIONES DE MERCADO" && <InstrumentPrice />}
      {optionItem === "INSTRUMENTOS" && <Instruments />}
      <div className="item-market-separator">&nbsp;</div>
      <div className="item-market-separator">&nbsp;</div>
    </div>
  );
};