export const formatClientHoldings = (number) => {
  try {

    return number
      .toString()
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  } catch(err) {

    return 0;
    
  }
};
