import { axiosCall, getDate } from "../../common/common-service";

import {
  a_loadRequestOperateInstruments,
  a_setSuccessOperateInstruments,
  a_setFailureOperateInstruments,
  a_loadRequestOperateHolidayState,
  a_setSuccessOperateHolidayState,
  a_setFailureOperateHolidayState,
  a_loadRequestOperateArgHolidayState,
  a_setSuccessOperateArgHolidayState,
  a_setFailureOperateArgHolidayState,
  a_loadRequestOperateCancelOrder,
  a_setSuccessOperateCancelOrder,
  a_setFailureOperateCancelOrder,
  a_loadRequestOperateCancelFund,
  a_setSuccessOperateCancelFund,
  a_setFailureOperateCancelFund,
  a_loadRequestEspeciesOperate,
  a_setSuccessEspeciesOperate,
  a_setFailureEspeciesOperate,
  a_loadRequestOkOrderList,
  a_setSuccessOkOrderList,
  a_setFailureOkOrderList,
  a_loadRequestFailOrderList,
  a_setSuccessFailOrderList,
  a_setFailureFailOrderList,
  a_loadRequestOrdersLot,
  a_setSuccessOrdersLot,
  a_setFailureOrdersLot,
  a_loadRequestExpenses,
  a_setSuccessExpenses,
  a_setFailureExpenses,
  a_loadRequestSpecieInfo,
  a_setSuccessSpecieInfo,
  a_setFailureSpecieInfo,
  a_loadRequestInsertLot,
  a_setSuccessInsertLot,
  a_setFailureInsertLot,
  a_loadRequestDataDisclaimer,
  a_setSuccessDataDisclaimer,
  a_setFailureDataDisclaimer,
  a_loadRequestAuthDisclaimer,
  a_setSuccessAuthDisclaimer,
  a_setFailureAuthDisclaimer,
} from "./operate-action";

export const api_getDataDisclaimer = (params) => {
  return axiosCall({
    path: "/disclaimer/data",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestDataDisclaimer,
    a_onSuccess: a_setSuccessDataDisclaimer,
    a_onError: a_setFailureDataDisclaimer,
  });
};

export const api_postAuthDisclaimer = (params) => {
  return axiosCall({
    path: "/disclaimer/authorization",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestAuthDisclaimer,
    a_onSuccess: a_setSuccessAuthDisclaimer,
    a_onError: a_setFailureAuthDisclaimer,
  });
};

export const api_getOperateInstruments = () => {
  return axiosCall({
    path: "/operate/instruments",
    method: "GET",
    data: undefined,
    a_onLoading: a_loadRequestOperateInstruments,
    a_onSuccess: a_setSuccessOperateInstruments,
    a_onError: a_setFailureOperateInstruments,
  });
};

export const api_getOperateHolidayState = (params) => {
  return axiosCall({
    path: "/operate/holiday",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestOperateHolidayState,
    a_onSuccess: a_setSuccessOperateHolidayState,
    a_onError: a_setFailureOperateHolidayState,
  });
};

export const api_getOperateArgHolidayState = () => {
  return axiosCall({
    path: "/operate/holiday",
    method: "GET",
    data: { date: getDate(), currency: "ARS", market: "BYMA" },
    a_onLoading: a_loadRequestOperateArgHolidayState,
    a_onSuccess: a_setSuccessOperateArgHolidayState,
    a_onError: a_setFailureOperateArgHolidayState,
  });
};

export const api_postOperateCancelOrder = (params) => {
  return axiosCall({
    path: "/operate/cancelarorden",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestOperateCancelOrder,
    a_onSuccess: a_setSuccessOperateCancelOrder,
    a_onError: a_setFailureOperateCancelOrder,
  });
};

export const api_postOperateCancelFund = (params) => {
  return axiosCall({
    path: "/operate/cancelarsolicitudfondo",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestOperateCancelFund,
    a_onSuccess: a_setSuccessOperateCancelFund,
    a_onError: a_setFailureOperateCancelFund,
  });
};

export const api_postOperateGetEspecies = (params) => {
  return axiosCall({
    path: "/operate/ticker/findAll",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestEspeciesOperate,
    a_onSuccess: a_setSuccessEspeciesOperate,
    a_onError: a_setFailureEspeciesOperate,
  });
};

// 1: "PENDIENTEDEENVIO"
// 2: "ENVIADA"
// 3: "OFERTADA"
// 4: "COMPLETADA"
// 5: "CANCELADA"
// 6: "RECHAZADA"
// 7: "PARCIALMENTECOMPLETADA"
// 8: "PENDIENTEDECANCELACION"
// 9: "NOENVIADA"
//10: "EXPIRADA"
export const api_getOperateOkOrderList = (params) => {
  return axiosCall({
    path: "/operate/getordenesandsolicitudesfdo",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestOkOrderList,
    a_onSuccess: a_setSuccessOkOrderList,
    a_onError: a_setFailureOkOrderList,
  });
};

export const api_getOperateFailOrderList = (params) => {
  return axiosCall({
    path: "/operate/getordenesandsolicitudesfdo",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestFailOrderList,
    a_onSuccess: a_setSuccessFailOrderList,
    a_onError: a_setFailureFailOrderList,
  });
};

export const api_getOperateLotList = (params) => {
  return axiosCall({
    path: "/operate/getlotes",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestOrdersLot,
    a_onSuccess: a_setSuccessOrdersLot,
    a_onError: a_setFailureOrdersLot,
  });
};

export const api_getOperateExpenses = (params) => {
  return axiosCall({
    path: "/operate/expenses",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestExpenses,
    a_onSuccess: a_setSuccessExpenses,
    a_onError: a_setFailureExpenses,
  });
};

export const api_getOperateSpecieInfo = (params) => {
  return axiosCall({
    path: "/operate/ticker/findByTicker",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestSpecieInfo,
    a_onSuccess: a_setSuccessSpecieInfo,
    a_onError: a_setFailureSpecieInfo,
  });
};

export const api_postOperateInsertLot = (params) => {
  return axiosCall({
    path: "/operate/insertlot",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestInsertLot,
    a_onSuccess: a_setSuccessInsertLot,
    a_onError: a_setFailureInsertLot,
  });
};
