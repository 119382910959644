import React from 'react'

const ModalTickerTitle = ({tickerTitle,isFisic}) => {
    return (
        <div className="modal-ticker-title">
            <div className="ticker-detail-modal-person-type-title">
                {isFisic ? 'Persona Fisica' : 'Persona Juridica'}
            </div>
            <div className="ticker-detail-modal-title-text">
                {tickerTitle}
            </div>
        </div>
    )
}
export default ModalTickerTitle