import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { BalanceCard } from "../../../../components/balance-card/balance-card";
import DistributionCardPie from "./distribution-card-pie/distribution-card-pie";
import InstrumentList from "./instruments/instruments-list";
import ModalHoldingPreview from "../modals/holding-preview";
import Money from "../../../../utils/icon-hand-money.svg";

const Tenencia = (props) => {
  const { selectedClientDashboard } = props;

  const {
    funds,
    bonds,
    cedears,
    actions,
    foreignExchange,
    divisas,
    negotiableObligations,
    financialTrusts,
    lets,
    lecaps,
    quotation,
  } = props.data;

  const {
    currencyOption,
    // holding,
    // loadingHolding,
    // loadingHoldingDetail,
    loadingCustomersByPartner,
  } = useSelector((state) => ({
    ...state["home"],
    ...state["wallet"],
  }));
  const {
    holding,
    loadingHolding,
    loadingHoldingDetail,
    holdingCodComitente,
  } = useSelector((state) => ({
    ...state["wallet"],
  }))

  const [modalStateHolding, setModalHolding] = useState(false);
  const [chartImage, setChartImage] = useState();

  const setDetailTitle = () => {
    switch (currencyOption) {
      case "Pesos":
        return "Detalle Tenencia en Pesos";
      case "Dólares":
        return "Detalle Tenencia en Dólares";
    }
  };

  const chartRef = useRef(null);
  const chartImageBase64 = () => {
    const base64Image = chartRef?.current?.toBase64Image();
    base64Image && setChartImage(base64Image);
  };

  const openOrCloseModalHolding = async () => {
    await chartImageBase64();
    setModalHolding(!modalStateHolding);
  };

  return (
    <>
      <div className="wallet-dashboard-container-right-cards">
        <DistributionCardPie title="Distribución" chartRef={chartRef} selectedClientDashboard={selectedClientDashboard} />
        {modalStateHolding && (
          <ModalHoldingPreview
            closeModal={openOrCloseModalHolding}
            modalStateHolding={modalStateHolding}
            selectedClientDashboard={selectedClientDashboard}
            data={selectedClientDashboard ? holdingCodComitente : holding}
            quotation={quotation}
            chartImage={chartImage}
            holdingDetail={{
              funds,
              bonds,
              cedears,
              actions,
              foreignExchange,
            }}
          />
        )}
        <BalanceCard
          disclaimer={`La información expuesta corresponde a ${moment().format(
            "DD/MM/YYYY HH:mm"
          )}. El título que se utiliza para la operación es AL30; No se puede asegurar que la operación se perfeccione a los valores al momento de ejecutarse, debido a la fluctuación de precio determinada por el mercado.`}
          decoration={Money}
          currencyOption={currencyOption}
          selectedClientDashboard={selectedClientDashboard}
          style={undefined}
          openOrCloseModalHolding={openOrCloseModalHolding}
        />
      </div>
      <div className="item-wallet-separator">&nbsp;</div>
      <div className="wallet-dashboard-container-right-containers-desplegables">
        {/* **********************  TABLES **********************/}
        <InstrumentList
          title={setDetailTitle()}
          holding={holding}
          loadingHolding={loadingHolding}
          loadingHoldingDetail={loadingHoldingDetail}
          loadingCustomersByPartner={loadingCustomersByPartner}
          currencyOption={currencyOption}
          selectedClientDashboard={selectedClientDashboard}
          funds={funds}
          bonds={bonds}
          cedears={cedears}
          actions={actions}
          foreignExchange={foreignExchange}
          divisas={divisas}
          negotiableObligations={negotiableObligations}
          financialTrusts={financialTrusts}
          lets={lets}
          lecaps={lecaps}
          holdingCodComitente={holdingCodComitente}
        />
      </div>
    </>
  );
};

Tenencia.defaultProps = {};

export default Tenencia;
