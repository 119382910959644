import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/button/button";
import { Spinner } from "../../../../components/spinner/spinner";
import { ModalRejectedOrders } from "../../operate-components/modal-rejected-orders/modal-rejected-orders";

import iconClose from "../../../../utils/Grupo 4165.svg";
import iconSuccess from "../../../../utils/icon-mensaje-exito.svg";
import iconFailure from "../../../../utils/icon-mensaje-cancelar.svg";
import iconRequestProblem from "../../../../utils/reloj.svg";

import "./operate-modal-finish.css";

export const OperateModalFinish = ({
  setOptionItem,
  setOpenConfirmProcedure,
  resetOperateDashboard,
}) => {

  const {
    confirmOrderResponse,
    loadingConfirmOrder,
    cancelOrderResponse,
    loadingConfirmOrderFdo,
    confirmOrderResponseFdo,
  } = useSelector((state) => state.operate);
  
  const [openNow, setOpenNow] = useState(false);
  const [showRejectedOrdersModal, setShowRejectedOrdersModal] = useState(false);
  const [rejectedOrders, setRejectedOrders] = useState(null);

  const modalName = "operate-custom-modal-finish-container";
  const clickModal = (e) => e.target.id === modalName;

  useEffect(() => {
    const hasRejectedOrders = (orderResponse) => {
      const orders = Array.isArray(orderResponse) ? orderResponse : [orderResponse];
      const rejectedOrders = orders.filter(order => order.status === "REJECTED");
      return rejectedOrders.length ? rejectedOrders : null;
    };
  
    if (!loadingConfirmOrder && confirmOrderResponse) {
      const rejected = hasRejectedOrders(confirmOrderResponse.items || confirmOrderResponse);
      if (rejected) {
        setRejectedOrders(rejected);
        setOpenNow(false);
        setShowRejectedOrdersModal(true);
      }
    }
  
    if (!loadingConfirmOrderFdo && confirmOrderResponseFdo) {
      const rejectedFdo = hasRejectedOrders(confirmOrderResponseFdo.items || confirmOrderResponseFdo);
      if (rejectedFdo) {
        setRejectedOrders(rejectedFdo);
        setOpenNow(false);
        setShowRejectedOrdersModal(true);
      }
    }
  }, [
    loadingConfirmOrder,
    confirmOrderResponse,
    loadingConfirmOrderFdo,
    confirmOrderResponseFdo,
  ]);
  
  useEffect(() => {
    if (loadingConfirmOrder || loadingConfirmOrderFdo) {
      setOpenNow(true);
    }
  }, [loadingConfirmOrder, loadingConfirmOrderFdo]);
  

  return (
    <>
      <div
        id="operate-custom-modal-finish-container"
        className={`operate-custom-modal-finish-container ${
          openNow ? "open-modal" : ""
        }`}
        onClick={clickModal}
      >
        <ModalProccessWait
          iconClose={iconClose}
          setOpenNow={setOpenNow}
          setOptionItem={setOptionItem}
          setOpenConfirmProcedure={setOpenConfirmProcedure}
          resetOperateDashboard={resetOperateDashboard}
          confirmOrderResponse={confirmOrderResponse}
          cancelOrderResponse={cancelOrderResponse}
        />
      </div>
      <ModalRejectedOrders
        rejectedOrders={rejectedOrders}
        showRejectedOrdersModal={showRejectedOrdersModal}
        setShowRejectedOrdersModal={setShowRejectedOrdersModal}
        setOptionItem={setOptionItem}
        setOpenConfirmProcedure={setOpenConfirmProcedure}
        resetOperateDashboard={resetOperateDashboard}
        setRejectedOrders={setRejectedOrders}
      />
    </>
  );
};

const ModalProccessWait = ({
  iconClose,
  setOpenNow,
  setOptionItem,
  setOpenConfirmProcedure,
  resetOperateDashboard,
 confirmOrderResponse, 
 cancelOrderResponse
}) => {

  return (
    <div className="operate-custom-modal-finish-container-region-content">
      <div className="operate-custom-modal-finish-container-region-content-cancel">
        <img src={iconClose} alt="close" onClick={() => setOpenNow(false)} />
      </div>
      <div className="operate-custom-modal-finish-container-region-content-body">
        {confirmOrderResponse || cancelOrderResponse ? (
          <>
            <img
              src={confirmOrderResponse?.status || cancelOrderResponse?.status ? iconFailure : iconSuccess}
              className="icon"
              alt="icon"
            />
            <div className="operate-custom-modal-finish-container-region-content-body-title">
              {confirmOrderResponse?.status || cancelOrderResponse?.status ? "Orden fallida" : "Orden enviada"}
            </div>
            <div className="operate-custom-modal-finish-container-region-content-body-description">
              {confirmOrderResponse?.status || cancelOrderResponse?.status ? (
                <div>
                  {confirmOrderResponse?.status || cancelOrderResponse?.status}
                </div>
              ) : (
                <div>Puede visualizar el estado en la sección de órdenes.</div>
              )}
            </div>
          </>
        ) : (
          <>
            <img src={iconRequestProblem} className="icon" alt="icon" />
            <div className="operate-custom-modal-finish-container-region-content-body-title">
              Esperando respuesta del servidor...
            </div>
          </>
        )}
        <div className="operate-custom-modal-finish-container-region-content-body-buttons">
          <div className="operate-custom-modal-finish-container-region-content-body-buttons-load">
            <Button
              buttonStyle="btn--primary"
              buttonSize="btn--medium"
              type="button"
              onClick={() => {
                setOpenNow(false);
                setOpenConfirmProcedure(false);
                setOptionItem("ORDENES EN PROCESO");
                resetOperateDashboard();
              }}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
