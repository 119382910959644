import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { OptionsTipoMoneda } from '../../market-service';
import { useTableActiveData } from '../market-hooks/tables-hooks';
import { TableContainer } from '../market-table-container/table-container';
import { SelectClientsWallet } from '../../../../components/selectClientsWallet/selectClientsWallet';
import { InputTextClientsWallet } from '../../../../components/inputTextClientsWallet/inputTextClientsWallet';
import ArrowDown from '../../../../utils/arrow-down-blue-icon.svg';
import { a_setEquityTickerFiltered } from '../../market-slice';

export const TickerEquityTable = ({ termNumber }) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => ({
    ...state['marketReducer'],
    ...state['commonReducer'],
  }));

  // const { marketEquityEsp, instrumentNumber } = state;
const { marketEquityEsp, instrumentNumber } = useSelector((state) => state.market)

  const [
    handleSearchTextChange,
    search,
    handleMonedaChange,
    moneda,
    columns,
  ] = useTableActiveData('equity');
  const [currentOpened, setCurrentOpenedState] = useState(null);

  const setCurrentOpened = (title) => {
    setCurrentOpenedState(title);
  };

  useEffect(() => {
    dispatch(
      a_setEquityTickerFiltered(
        marketEquityEsp.map((equity, i) => ({
          key: equity.ticker,
          selected: i === 0 ? true : false,
          ticker: equity.name,
          last: equity?.marketData?.['Plazo' + termNumber]?.NOW_PRICE
            ? parseFloat(
                equity?.marketData?.['Plazo' + termNumber]?.NOW_PRICE
              ).toFixed(2)
            : '-',
          day_percentage: equity?.marketData?.['Plazo' + termNumber]
            ?.DAY_PERCENTAGE_CHANGE
            ? `${parseFloat(
                equity?.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE
              ).toFixed(2)}%`
            : '-',
          buy_price: equity?.marketData?.['Plazo' + termNumber]?.BID_PRICE
            ? `${parseFloat(
                equity?.marketData?.['Plazo' + termNumber]?.BID_PRICE
              ).toFixed(2)}`
            : `-`,
          sell_price: equity?.marketData?.['Plazo' + termNumber]?.ASK_PRICE
            ? `${parseFloat(
                equity?.marketData?.['Plazo' + termNumber]?.ASK_PRICE
              ).toFixed(2)}`
            : `-`,
          vol_nom: equity?.marketData?.['Plazo' + termNumber]?.VOLUME
            ? `${parseFloat(
                equity?.marketData?.['Plazo' + termNumber]?.VOLUME
              ).toFixed(2)}`
            : `-`,
          currency: equity.currency,
          last_closeure_price: equity?.marketData?.['Plazo' + termNumber]
            ?.PREV_CLOSE_PRICE
            ? equity?.marketData?.['Plazo' + termNumber]?.PREV_CLOSE_PRICE
            : '-',
        }))
      )
    );
  }, [marketEquityEsp, instrumentNumber, termNumber]);

  return (
    <div className="container-instrument-table">
      <div className="instrument-table-header-selections">
        <div className="instrument-table-header-title">Acciones</div>
        <div className="instrument-table-header-text-filter">
          <InputTextClientsWallet
            placeholder="Buscar Ticker"
            type="text"
            onChange={handleSearchTextChange}
            value={search}
          />
        </div>
        <div className="intrument-table-header-select">
          <SelectClientsWallet
            placeholder="Tipo Persona"
            options={OptionsTipoMoneda}
            selection={moneda}
            setSelection={handleMonedaChange}
            sizeList="176px"
            sizeContainer="128px"
            setCurrentOpened={setCurrentOpened}
            arrowIcon={ArrowDown}
          />
        </div>
      </div>
      <TableContainer columns={columns} scroll={{ y: '60vh' }} />
    </div>
  );
};
