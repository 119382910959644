import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, DatePicker } from 'antd';
import { Spinner } from '../../../../components/spinner/spinner';
import { NoData } from '../../../../components/no-data/no-data';
import iconReload from '../../../../utils/icon-reload-blue.svg';
import './operate-inprogress-orders.scss';

import {
  twoDecimalsFormat,
  getTermType,
  cantByInst,
  getValueWithCodMoneda,
} from '../../operate-service';

import moment from 'moment';
import {
  api_getOperateOkOrderList,
  api_getOperateOrderMarket,
  api_cancelOperateOrder,
  api_cancelOperateOrderFdo,
} from '../../operate-slice';

export const OperateInProgressOrders = ({ handleDetailProcess }) => {
  const {
    loadingOkOrderListFdo,
    loadingOkOrderListMarket,
    okOrderListFdo,
    okOrderListMarket,
    account,
    selectedClient,
  } = useSelector((state) => state.operate);
  const dispatch = useDispatch();

  let d = new Date();
  d.setMonth(d.getMonth() + 1);
  let dateEnd = d.toISOString().slice(0, 10);
  d.setMonth(d.getMonth() - 2);
  let dateInit = d.toISOString().slice(0, 10);

  const [startDate, setStartDate] = useState(
    new Date(dateInit.replace(/-/g, '/'))
  );
  const [endDate, setEndDate] = useState(new Date(dateEnd.replace(/-/g, '/')));
  const [orderState, setStateOrder] = useState(
    'PENDIENTEDEENVIO,ENVIADA,OFERTADA,PARCIALMENTECOMPLETADA,PENDIENTEDECANCELACION,EN PROCESO,PROCESADA'
  );
  const [combinedOrders, setCombinedOrders] = useState([]);
  const [isReloading, setIsReloading] = useState(false);

  useEffect(() => {
    setCombinedOrders([...okOrderListFdo, ...okOrderListMarket]);
  }, [selectedClient.codComitente, okOrderListFdo, okOrderListMarket]);

  const isLoading = loadingOkOrderListFdo && loadingOkOrderListMarket;

  useEffect(() => {
    if (account && account.info && account.info.codComitente) {
      dispatch(
        api_getOperateOkOrderList({
          codComitente: selectedClient.codComitente,
          FechaInicio: startDate.toISOString().slice(0, 10),
          FechaFin: endDate.toISOString().slice(0, 10),
          Estados: orderState.split(','),
        })
      );
      dispatch(
        api_getOperateOrderMarket({
          codComitente: selectedClient.codComitente,
          FechaInicio: startDate.toISOString().slice(0, 10),
          FechaFin: endDate.toISOString().slice(0, 10),
          Estados: orderState.split(','),
        })
      );
    }
  }, [
    dispatch,
    account,
    startDate,
    endDate,
    orderState,
    selectedClient.codComitente,
  ]);

  const reloadData = async () => {
    try {
      setIsReloading(true);
      const newStartDate = new Date(dateInit.replace(/-/g, '/'));
      const newEndDate = new Date(dateEnd.replace(/-/g, '/'));

      const ordersPromise = dispatch(
        api_getOperateOkOrderList({
          codComitente: selectedClient.codComitente,
          FechaInicio: newStartDate.toISOString().slice(0, 10),
          FechaFin: newEndDate.toISOString().slice(0, 10),
          Estados: orderState.split(','),
        })
      );
      const marketPromise = dispatch(
        api_getOperateOrderMarket({
          codComitente: selectedClient.codComitente,
          FechaInicio: newStartDate.toISOString().slice(0, 10),
          FechaFin: newEndDate.toISOString().slice(0, 10),
          Estados: orderState.split(','),
        })
      );

      await Promise.all([ordersPromise, marketPromise]);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } catch (error) {
      console.error('Error reloading data', error);
    } finally {
      setIsReloading(false);
    }
  };

  const formatDataForTable = combinedOrders.map((order) => ({
    ...order,
    index: order.codOrden === 0 ? order.codSolicitudFdo : order.codOrden,
    detalle: order.compraVenta === 'C' ? 'COMPRA' : 'VENTA',
    plazo: getTermType(order.plazo),
    precio: twoDecimalsFormat(order.precio),
    importe: twoDecimalsFormat(order.importe),
    cantByInst: cantByInst(order),
    name: selectedClient.name,
  }));

  const filteredData = formatDataForTable.filter(
    (order) =>
      orderState.split(',').includes(order.estado) &&
      new Date(order.fechaConcertacion) >= startDate &&
      new Date(order.fechaConcertacion) <= endDate
  );

  const handleRangeDates = (range) => {
    if (range && range[0] && range[1]) {
      setStartDate(moment(range[0]).toDate());
      setEndDate(moment(range[1]).toDate());
    }
  };

  const cancelProcess = (process) => {
    handleDetailProcess(process);
    if (process.instrumentType === 'InstrumentFund') {
      dispatch(
        api_cancelOperateOrderFdo({
          codComitente: process.codComitente,
          orderId: process.id,
        })
      );
    } else {
      dispatch(
        api_cancelOperateOrder({
          codComitente: process.codComitente,
          orderId: process.codOrden,
        })
      );
    }
  };

  const isCancellable = (state) => {
    switch (state) {
      case 'PENDIENTE DE ENVIO':
      case 'ENVIADA':
      case 'OFERTADA':
      case 'PARCIALMENTE COMPLETADA':
      case 'EN PROCESO':
        return true;
      default:
        return false;
    }
  };

  const columns = [
    {
      title: 'Nº',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Concertación',
      dataIndex: 'fechaConcertacion',
      key: 'fechaConcertacion',
    },
    {
      title: 'Cuenta',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Detalle',
      dataIndex: 'detalle',
      key: 'detalle',
    },
    {
      title: 'Especie',
      dataIndex: 'ticker',
      key: 'ticker',
    },
    {
      title: 'Precio',
      dataIndex: '',
      key: '',
      render: (data) => getValueWithCodMoneda(data.codMoneda, data.precio),
    },
    {
      title: 'Plazo',
      dataIndex: 'plazo',
      key: 'plazo',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Neto',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div className={data.detalle === 'COMPRA' ? 'compra' : 'venta'}>
          {getValueWithCodMoneda(data.codMoneda, data.importe)}
        </div>
      ),
    },
    {
      title: 'Lote',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: (data) =>
        isCancellable(data.estado) ? (
          <div className="acciones-active" onClick={() => cancelProcess(data)}>
            CANCELAR
          </div>
        ) : (
          <div className="acciones-inactive">CANCELAR</div>
        ),
    },
  ];

  return (
    <div className="ordenes-en-proceso">
      <div className="ordenes-en-proceso-header">
        <div className="operate-dashboard-filter-tabs">
          <div className="operate-sub-items-container">
            <div className="sub-items-left selected-sub-item">
              ORDENES EN PROCESO
            </div>
            <div className="sub-items-right"></div>
          </div>
        </div>

        <div className="ordenes-en-proceso-header-selections">
          <div className="datepickers">
            <DatePicker.RangePicker
              placeholder={['Desde', 'Hasta']}
              onChange={(range) => handleRangeDates(range)}
            />
          </div>
          <div className="operate-process-orders-reload-button-container">
            <div className="operate-process-orders-reload-button">
              <img
                onClick={reloadData}
                className={
                  isLoading || isReloading
                    ? 'operate-process-orders-reload-icon-disabled'
                    : 'operate-process-orders-reload-icon'
                }
                src={iconReload}
                alt="reload"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ordenes-en-proceso-table-pendientes">
        {isLoading || isReloading ? (
          <Spinner />
        ) : filteredData?.length === 0 ? (
          <NoData text="No hay últimos movimientos disponibles." />
        ) : (
          <Table
            dataSource={filteredData}
            columns={columns}
            key={startDate.toISOString() + endDate.toISOString()}
          />
        )}
      </div>
    </div>
  );
};
