import { axiosCall } from "../../common/common-service";

import {
  a_loadRequestTeamTree,
  a_setSuccessTeamTree,
  a_setFailureTeamTree,
  a_loadRequestTeamHoldingAccounts,
  a_setSuccessTeamHoldingAccounts,
  a_setFailureTeamHoldingAccounts,
  a_loadRequestTeamUploadBill,
  a_setSuccessTeamUploadBill,
  a_setFailureTeamUploadBill,
  a_loadRequestTeamBillsList,
  a_setSuccessTeamBillsList,
  a_setFailureTeamBillsList,
  a_loadRequestTeamFee,
  a_setSuccessTeamFee,
  a_setFailureTeamFee,
} from "./team-action";

//   /team/tree?idProducer=779

export const api_getTeamTree = (params) => {
  return axiosCall({
    path: "/team/tree",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestTeamTree,
    a_onSuccess: a_setSuccessTeamTree,
    a_onError: a_setFailureTeamTree,
  });
};

export const api_getTeamHoldingAccounts = (params) => {
  return axiosCall({
    path: "/team/data",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestTeamHoldingAccounts,
    a_onSuccess: a_setSuccessTeamHoldingAccounts,
    a_onError: a_setFailureTeamHoldingAccounts,
  });
};

export const api_postTeamUploadBill = (params) => {
  return axiosCall({
    path: "/file/uploadsend",
    method: "POST",
    data: params,
    a_onLoading: a_loadRequestTeamUploadBill,
    a_onSuccess: a_setSuccessTeamUploadBill,
    a_onError: a_setFailureTeamUploadBill,
  });
};

export const api_postTeamBillsList = (params) => {
  return axiosCall({
    path: "/file/list",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestTeamBillsList,
    a_onSuccess: a_setSuccessTeamBillsList,
    a_onError: a_setFailureTeamBillsList,
  });
};

export const api_getTeamFee = (params) => {
  return axiosCall({
    path: "/team/fee",
    method: "GET",
    data: params,
    a_onLoading: a_loadRequestTeamFee,
    a_onSuccess: a_setSuccessTeamFee,
    a_onError: a_setFailureTeamFee,
  });
};