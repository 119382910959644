import React, { useState } from "react";

import { Arrow } from "../../../../../components/arrow/arrow";

import arrowDown from "../../../../../utils/arrow-down-blue-icon.svg";

import {
  isFieldEmpty,
  twoDecimalsFormat,
} from "../../../../../common/common-service";

const Instrument = ({
  title,
  instrument,
  typeOfInstrument,
  totalInstrumentDetail,
  currencyOption,
  selectedClientDashboard,
}) => {
  const [openSubList, setOpenSubList] = useState(false);

  if (typeOfInstrument === "TITPUB") {
  }
  const filterTotal = totalInstrumentDetail?.find((instrumentDetail) => {
    return typeOfInstrument === instrumentDetail?.typeOfInstrument;
  });


  const isPositive = (value) => {
    return parseFloat(value) > 0 && true;
  };

  const calculateTotal = () => {
    if (instrument && instrument?.length !== 0) {
      let instrumentsAmountARS = [];
      let instrumentsAmountUSD = [];
  
      instrument.forEach((amount) => {
        let num = parseFloat(amount?.amount);
  
        if (amount.currency === "ARS") {
          instrumentsAmountARS.push(num);
        }
        if (amount.currency === "USD") {
          instrumentsAmountUSD.push(num);
        }
      });
  
      let totalAmountARS = instrumentsAmountARS.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      let totalAmountUSD = instrumentsAmountUSD.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
  
      switch (currencyOption) {
        case "Pesos":
          return `AR$ ${twoDecimalsFormat(totalAmountARS)}`;
        case "Dólares":
          return `U$D ${twoDecimalsFormat(totalAmountUSD)}`;
      }
    }
  };

  const getCurrencyFromOption = () => {
    switch (currencyOption) {
      case "Pesos":
        return "ARS";
      case "Dólares":
        return "USD";
    }
  };

  return (
    <>
      {instrument?.length ? (
        <div
          data-testid="wallet-instruments"
          className={`wallet-dashboard-container-right-desplegables-list-element ${
            openSubList ? "" : "visible-line"
          }`}
        >
          <div data-testid="wallet-instruments-element" className="wd-element">
            <div className="wInst-el-iconAmp">
              <button
                data-testid="wallet-arrow-btn"
                className="arrow-wrapper"
                onClick={() => setOpenSubList((x) => !x)}
              >
                <Arrow open={openSubList} icon={arrowDown} />
              </button>
            </div>
            <div
              data-testid="wallet-instruments-title"
              className="wInst-el-title"
            >
              {title}
            </div>
            <div
              data-testid="wallet-instruments-value"
              className="wInst-el-invActual"
            >
              {calculateTotal()}
            </div>
          </div>
          <div
            data-testid="wallet-instruments-element-content"
            className={`wInst-el-sublist ${
              openSubList ? "visibleSubList" : ""
            }`}
          >
            {selectedClientDashboard ? (
              <div className="reference">
                <div>Nombre</div>
                <div>Ticker</div>
                <div>Importe</div>
                <div>Precio</div>
                <div>Cantidad</div>
              </div>
            ) : (
              <div className="reference-partner">
                <div>Nombre</div>
                <div>Ticker</div>
                <div>Importe</div>
                <div>Precio</div>
                <div>Cantidad</div>
              </div>
            )}
            {instrument
            ?.filter((instrument) => instrument.currency === getCurrencyFromOption())
            .map((instrument, i) => {
              return (
                <div
                  className={
                    selectedClientDashboard
                      ? "sb-element-partner"
                      : "sb-element"
                  }
                  key={i}
                >
                  {selectedClientDashboard ? (
                    <>
                      <div data-testid="wallet-instruments-sublist-species" className="sb-desc">
                        {isFieldEmpty(instrument?.speciesDescription)}
                      </div>
                      <div data-testid="wallet-instruments-sublist-species" className="sb-desc">
                        {isFieldEmpty(instrument?.speciesAbbreviation)}
                      </div>
                      <div data-testid="wallet-instruments-sublist-amount" className="sb-invAct">
                        {currencyOption === "Pesos" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.amount), "$")}
                        {currencyOption === "Dólares" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.amount), "U$D")}
                      </div>
                      <div data-testid="wallet-instruments-sublist-price" className="sb-invAct">
                        {currencyOption === "Pesos" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.price), "$")}
                        {currencyOption === "Dólares" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.price), "U$D")}
                      </div>
                      <div data-testid="wallet-instruments-sublist-quantity" className="sb-invIni">
                        {isFieldEmpty(instrument?.quantity)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        data-testid="wallet-instruments-sublist-species"
                        className="sb-desc"
                      >{`${isFieldEmpty(
                        instrument?.speciesAbbreviation
                      )} ${isFieldEmpty(instrument?.speciesDescription)}`}</div>
                      <div
                        data-testid="wallet-instruments-sublist-amount"
                        className="sb-invIni"
                      >
                        {currencyOption === "Pesos" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.amount), "$")}
                        {currencyOption === "Dólares" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.amount), "U$D")}
                      </div>
                      <div
                        data-testid="wallet-instruments-sublist-price"
                        className="sb-invAct"
                      >
                        {currencyOption === "Pesos" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.price), "$")}
                        {currencyOption === "Dólares" &&
                          isFieldEmpty(twoDecimalsFormat(instrument?.price), "U$D")}
                      </div>
                      <div
                        data-testid="wallet-instruments-sublist-quantity"
                        className="sb-invAct"
                      >
                        {isFieldEmpty(instrument?.quantity)}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Instrument;
