import React from 'react';

import './overview.scss';

const Overview = (props) => {
    return (
        <div className="overview-container">
            <h1 className="overview-title">Mi Saldo en {props.currency} {props.shortCurrency}</h1>
            <div className="row">
                <div className="col-md-4">
                    
                </div>
                <div className="col-md-8">
                    <div className="row-between">
                        <span className="overview-data-key row">
                            <span className="overview-data-color" /> Disp. ahora
                        </span>
                        <span className="overview-data-value">{props.shortCurrency} 15.689,57</span>
                    </div>
                    <div className="row-between">
                        <span className="overview-data-key row">
                            <span className="overview-data-color" /> Disp. en 24hs
                        </span>
                        <span className="overview-data-value">{props.shortCurrency} 15.689,57</span>
                    </div>
                    <div className="row-between">
                        <span className="overview-data-key row">
                            <span className="overview-data-color" /> Disp. en 48hs
                        </span>
                        <span className="overview-data-value">{props.shortCurrency} 15.689,57</span>
                    </div>
                    <hr className="overview-hr" />
                    <div className="row-between total">
                        <span className="overview-data-key row total">
                            <span className="overview-data-color total" /> Saldo Total
                        </span>
                        <span className="overview-data-value total">{props.shortCurrency} 15.689,57</span>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}

Overview.defaultProps = {
    currency: 'Pesos',
    shortCurrency: 'ARS'
}

export default Overview