import React, { useState } from "react";
import { useDispatch } from "react-redux";

// actions and services
import {
  initCountdown,
  closeSocketConnections,
} from "../../common/common-service";
import {
  login,
  loadAuthenticatedInformation,
  setSignInData,
  setLogIn,
  setLogOut,
  setErrorMessage,
  setStatesForPasswordRecoveryNull,
} from "../../common/common-slice";
// components
import { Subtitle } from "../../components/titles/titles";
import { InputText } from "../../components/inputText/inputText";
import { Button } from "../../components/button/button";
import { RecoverUser } from "./recover-user";
import { RecoverPass } from "./recover-pass";
import { FormProblem } from "./form-problem";
import { TemsAndConditions } from "./terms-and-Conditions";

// ant design
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

// utils
import logoHomeRight from "../../utils/logo-banza-right.png";
import question from "../../utils/question.svg";

// styles
import "./login.scss";
import "./problem-login.scss";

export const Login = () => {
  const dispatch = useDispatch();

  const initialState = { username: "", password: "" };
  const [user, setUser] = useState(initialState);
  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const [errorLogin, setErrorLogin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loginLoadingStatus, setLoginLoadingStatus] = useState(false);
  const [showLogin, setshowLogin] = useState(true);
  const [recoverUser, setRecoverUser] = useState(false);
  const [recoverPass, setRecoverPass] = useState(false);
  const [formProblem, setFormProblem] = useState(false);
  const [modalVisibility, setModal] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const processLogOut = () => {
    dispatch(loadAuthenticatedInformation());
    dispatch(setSignInData(null));
    dispatch(setLogOut());
  };

  const handlerSuccess = (user) => {
    dispatch(loadAuthenticatedInformation());
    dispatch(setSignInData(user));
    dispatch(setLogIn());
    initCountdown(user, processLogOut);
  };

  const handlerError = (error) => {
    // Primero, verifica si el error viene con una estructura específica
    if (error && error.message) {
      // Si el error tiene un mensaje, úsalo directamente
      setErrorText(error.message);
    } else {
      // Si no hay un mensaje de error específico, utiliza un mensaje genérico
      setErrorText("Error de sistema. Estamos trabajando en ello");
    }
    setErrorLogin(true);
    setLoginLoadingStatus(false);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setErrorLogin(false);
    setLoginLoadingStatus(true);
    dispatch(login({ authUser: user.username, authSecret: user.password }))
      .unwrap()
      .then((response) => {
        handlerSuccess(response);
      })
      .catch((error) => {
        handlerError(error);
        
      });
  };

  const openRecoverUser = () => {
    setshowLogin(false);
    setRecoverUser(true);
    setRecoverPass(false);
    setFormProblem(false);
    dispatch(setErrorMessage(true));
  };

  const openRecoverPass = () => {
    setshowLogin(false);
    setRecoverUser(false);
    setRecoverPass(true);
    setFormProblem(false);
    dispatch(setErrorMessage(true));
  };

  const openFormProblem = () => {
    setshowLogin(false);
    setRecoverUser(false);
    setRecoverPass(false);
    setFormProblem(true);
    dispatch(setErrorMessage(true));
  };
  const backToLoginHome = () => {
    setshowLogin(true);
    setRecoverUser(false);
    setRecoverPass(false);
    setFormProblem(false);
    dispatch(setErrorMessage(true));
  };
  const backToLoginHomeReplacePassComplete = () => {
    setshowLogin(true);
    setRecoverUser(false);
    setRecoverPass(false);
    setFormProblem(false);
    dispatch(setErrorMessage(true));
    dispatch(setStatesForPasswordRecoveryNull(true));
  };
  const togglePasswordVisibility = () => {
    setPasswordVisibility((prev) => !prev);
  };
  return (
    <>
      <div className="login-general-container">
        <div className="login-left-image-container"></div>
        <div className="login-left-logo-container"></div>

        <div className="login-right-form-general-container">
          <div className="login-right-form-wrapper">
            <div className="login-header-logo-container">
              <img
                src={logoHomeRight}
                className="login-header-logo"
                alt="logo banza"
              />
            </div>
            {showLogin ? (
              <>
                <div className="login-title-container">
                  <Subtitle
                    type="main"
                    text="Ingresa el usuario y contraseña que te brindaron"
                  />
                </div>
                <div className="login-form-container">
                  <form
                    className="login-form-wrapper"
                    onSubmit={handleLoginSubmit}
                  >
                    <div className="login-form-input">
                      <InputText
                        dataTestId="username"
                        type="text"
                        name="username"
                        placeholder="Nombre de usuario*"
                        value={user.username}
                        onChange={onChange}
                      />
                    </div>
                    <div className="login-form-input">
                      <InputText
                        dataTestId="password"
                        type={passwordVisibility ? "text" : "password"}
                        togglePasswordVisibility={true}
                        onToggleVisibility={togglePasswordVisibility}
                        name="password"
                        placeholder="Contraseña*"
                        value={user.password}
                        onChange={onChange}
                        suffixIcon={
                          passwordVisibility ? (
                            <EyeOutlined onClick={togglePasswordVisibility} />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={togglePasswordVisibility}
                            />
                          )
                        }
                      />
                    </div>
                    <div
                      className={`login-form-warning ${
                        errorLogin ? "" : "message-hidden"
                      }`}
                    >
                      <p className="login-form-text">{errorText}</p>
                    </div>
                    <div className="login-form-button">
                      <Button
                        dataTestId="login-btn"
                        buttonStyle="btn--primary"
                        buttonSize="btn--medium"
                        type="submit"
                      >
                        {loginLoadingStatus ? "INGRESANDO..." : "LOGIN"}
                      </Button>
                    </div>
                  </form>
                  <div className="login-forget-links-container">
                    <div
                      className="login-recover-user"
                      onClick={openRecoverUser}
                    >
                      Olvidé mi usuario
                    </div>
                    <div className="loguin-forget-links-divider"></div>
                    <div
                      className="login-recover-password"
                      onClick={openRecoverPass}
                    >
                      Olvidé mi contraseña
                    </div>
                  </div>
                  <div className="container-form-problem-login">
                    <img src={question} alt="logo" />
                    <div className="description-login">
                      Tenés problemas para ingresar a tu cuenta?
                    </div>
                    <div className="click-form-login" onClick={openFormProblem}>
                      Escríbenos aquí
                    </div>
                    <div className="description-login">
                      Te responderemos a la brevedad
                    </div>
                  </div>
                  <div className="legals-link" onClick={() => setModal(true)}>
                    <p> Al ingresar, aceptas los términos y condiciones</p>
                  </div>
                </div>
              </>
            ) : null}
            {recoverUser ? (
              <RecoverUser
                backToLogin={backToLoginHome}
                onClickAction={openFormProblem}
                onModalTerms={() => setModal(true)}
              />
            ) : null}
            {recoverPass ? (
              <RecoverPass
                backToLogin={backToLoginHomeReplacePassComplete}
                onClickAction={openFormProblem}
                onModalTerms={() => setModal(true)}
              />
            ) : null}
            {formProblem ? <FormProblem backToLogin={backToLoginHome} /> : null}
          </div>
        </div>
      </div>
      {modalVisibility && <TemsAndConditions setModal={setModal} />}
    </>
  );
};
