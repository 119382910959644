import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedClientFromHomeToWallet } from "../../../../common/common-slice";

import { Spinner } from "../../../../components/spinner/spinner";
import InputTextSearchClean from "./input-text-search-clean/input-text-search-clean";

import iconSearch from "../../../../utils/icon-buscar.svg";
import iconQuestion from "../../../../utils/question.svg";

import menuIcon from "../../../../utils/menu-icon-blue.svg";

import "./wallet-left.scss";
import { api_getHoldingClient, setHoldingEmpty } from "../../../wallet/wallet-slice";

const WalletLeft = ({
  setOption,
  setSelectedClientDashboard,
  menuOpened,
  setMenuOpened,
  selectedClientDashboard
}) => {
  const {
    loadingCustomersByPartner,
    customersByPartner,
    selectedClientFromHomeToWallet,
    holdingCodComitente,
  } = useSelector((state) => ({
    ...state["home"],
  }));
  const { 
    loadingHolding,
    loadingHoldingDetail,
    holdingDetail,
  } = useSelector((state) => ({
    ...state['wallet'],
    ...state["common"],
  }))
  
  const dispatch = useDispatch();
  
  const [search, setSearch] = useState({
    filteredClient: customersByPartner?.name,
  });
  const [isSelected, setIsSelected] = useState(false);

  const onChange = (e) => {
    setSearch({ filteredClient: e.target.value });
  };

  const setCleanInput = () => {
    setSearch({ filteredClient: undefined });
    setSelectedClientDashboard(undefined);
    setOption("TENENCIA");
    dispatch(setSelectedClientFromHomeToWallet(false));
    setIsSelected(false);
  };

  const chooseClient = (client) => {
    dispatch(
      api_getHoldingClient(Number(client.codComitente))
    );
    setSelectedClientDashboard(client);
    setSearch({ filteredClient: client.name || client.numComitente });
    setIsSelected(true);
  };

  const filteredWalletClients = () => {
    if (!search.filteredClient) {
      return customersByPartner;
    } else {
      return customersByPartner.filter(
        (x) =>
          x.name.toLowerCase().includes(search.filteredClient.toLowerCase()) ||
          x.numComitente.includes(search.filteredClient)
      );
    }
  };

  return (
    <>
      <div
        className={
          menuOpened
            ? "show-hide-menu-button slide-in-left"
            : "show-hide-menu-button-relocate slide-in-right"
        }
      >
        <button
          onClick={() => setMenuOpened(!menuOpened)}
          className="menu-trigger-button"
        >
          <img
            src={menuIcon}
            alt="hide-teams-menu"
          />
        </button>
      </div>
      <div
        className={
          menuOpened
            ? "wallet-dashboard-container-left slide-in-left"
            : "wallet-dashboard-container-left-off-canvas slide-in-right"
        }
      >
        <div className="table-container">
          <h2 className="title-wallet">Buscador de Clientes</h2>
          <div className="item-partner-separator">&nbsp;</div>
          <div className="selections">
            <InputTextSearchClean
              type="text"
              name="name"
              placeholder="Nombre o N° Cuenta Comitente"
              value={search.filteredClient}
              onChange={onChange}
              setCleanInput={setCleanInput}
              inputSize="100%"
            />
          </div>
          <div className="item-partner-separator">&nbsp;</div>
          <div data-testid="wallet-clients-list" className="table">
            {loadingCustomersByPartner ||
            loadingHolding ||
            loadingHoldingDetail ? (
              <Spinner />
            ) : (
              <>
                {!filteredWalletClients()?.length ? (
                  <>
                    <div className="message-client-not-found">
                      <div className="iconSearch">
                        <img src={iconSearch} alt="icon Search" />
                      </div>
                      <h2 className="message-title">
                        No encontramos ese cliente...
                      </h2>
                      <p>
                        Asegurate que ese cliente exista en tu cartera y prueba
                        escribirlo nuevamente.
                      </p>
                    </div>
                    <div className="message-consult-us">
                      <div className="iconQuestion">
                        <img src={iconQuestion} alt="icon Question" />
                      </div>
                      <p>Envianos tu consulta a partners@ad-cap.com.ar</p>
                    </div>
                  </>
                ) : (
                  filteredWalletClients().map((client, i) => (
                    <Client
                      key={i}
                      client={client}
                      chooseClient={chooseClient}
                      loadingHolding={loadingHolding}
                      loadingHoldingDetail={loadingHoldingDetail}
                      isSelected={isSelected}
                      selectedClientDashboard={selectedClientDashboard}
                    />
                  ))
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Client = ({
  client,
  chooseClient,
  loadingHolding,
  loadingHoldingDetail,
  isSelected,
  selectedClientDashboard,
}) => {
  const enableWaiting = loadingHolding && loadingHoldingDetail;
  const elementClasses = `element ${enableWaiting ? "element-disabled" : ""} ${isSelected ? "elementSelected" : ""} ${selectedClientDashboard?.numComitente === client?.numComitente ? "elementSelected" : ""}`;
  return (
    <div
      className={elementClasses}
      onClick={() => (enableWaiting ? null : chooseClient(client))}
    >
      <div className="item-center">
        <div data-testid="client-name" className="name">
          {client.name}
        </div>
        <div data-testid="client-cuit" className="number">
          CUIT: {client.cuit}
        </div>
      </div>
      <div className="item">
        <p data-testid="num-comitente" className="text">
          Nº {client.numComitente}
        </p>
      </div>
    </div>
  );
};

export default WalletLeft;
