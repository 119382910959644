import React from "react";

import Input from "../forms/input";

//import BaseModal from '../../../../components/modal/base-modal';
import AlternativeModal from "../../../../components/alternativeModal/alternative-modal";
import NewButton from "../../../../components/new-button/new-button";

import "./team-modals.scss";

const SendMessage = (props) => {
  return (
    <AlternativeModal onClose={props.onClose}>
      <div className="team-modal-container">
        <div className="left-modal-title">Mensaje Nuevo</div>
        <div className="row">
          <Input
            type="text"
            placeholder="Para"
            className="col-md-12"
            required
          />
          <Input type="text" placeholder="CC" className="col-md-12" required />
          <Input
            type="text"
            placeholder="Asunto"
            className="col-md-12"
            required
          />
          <div className="col-md-12">
            <textarea
              placeholder="Escriba su mensaje aquí..."
              className="team-form-control"
              required
            />
          </div>
        </div>
        <div style={{ margin: 10 }} />
        <NewButton className="new-btn-primary">Enviar Mensaje</NewButton>
      </div>
    </AlternativeModal>
  );
};

SendMessage.defaultProps = {
  onClose: () => console.log("close action: send-message"),
};

export default SendMessage;
