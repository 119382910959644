import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TradingViewWidgetList } from './trading-view-widget-list';
import MepQuotationCard from './mep-quotation-card';
import CclQuotationCard from './ccl-quotation-card';

// import { api_getQuotation } from "../../../../common/common-api";

import './price-coin.scss';
import { api_getDollarQuotation } from '../../market-slice';
import UsdArsQuotationCard from './usdars-quotation-card';
import EurArsQuotationCard from './eurars-quotation-card';
import BrlArsQuotationCard from './brlars-quotation-card';
import UyuArsQuotationCard from './uyuars-quotation-card';

export const PriceCoin = () => {
  const dispatch = useDispatch();

  const indexList = ['BCBA:IMV'];

  const coinList = ['USDARS', 'EURARS', 'BRLARS', 'UYUARS'];

  useEffect(() => {
    // dispatch(api_getQuotation());
    dispatch(api_getDollarQuotation());
  }, []);

  return (
    <div className="market-price-container">
      {/* <div className="market-index-price">
                <div className="market-price-title">
                    ÍNDICES DE MERCADO
                </div>
                <TradingViewWidgetList listItems={indexList} width="170" height="90" />
            </div> */}
      <div className="market-index-price">
        <div className="market-price-title-container">
          <p className="market-price-title">
            COTIZACIÓN MERCADO <span>(actualiza cada 1 minuto)</span>
          </p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <MepQuotationCard />
          <CclQuotationCard />
        </div>
      </div>
      <div className="market-coin-price">
        <div className="market-price-title-container">
          <p className="market-price-title-gray">
            DIVISAS <span>(actualiza cada 1 hora)</span>
          </p>
        </div>
        {/* <TradingViewWidgetList listItems={coinList} width="170" height="90" /> */}
        <div className="quotation-container">
          <UsdArsQuotationCard />
          <EurArsQuotationCard />
          <BrlArsQuotationCard />
          <UyuArsQuotationCard />
        </div>
      </div>
    </div>
  );
};
