import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Arrow } from '../../../../components/arrow/arrow';
import '../../../../components/selectClientsWallet/selectClientsWallet.scss';

export const SelectClientOperate = ({
  title,
  options,
  selection,
  setSelection,
  disabledSelect,
  sizeContainer,
  setCurrentOpened,
  currentOpened,
  arrowIcon,
  disabled = false,
}) => {
  const {     loadingInstrumentsMarket,
    loadingInstrumentsFunds, } = useSelector(
    (state) => state.operate
  );
  const [openSelect, setOpenSelect] = useState(false);
  const [letSelect, setLetSelect] = useState(false);

  useEffect(() => {
    setLetSelect(!(loadingInstrumentsMarket || loadingInstrumentsFunds));
  }, [loadingInstrumentsMarket, loadingInstrumentsFunds]);

  useEffect(() => {
    currentOpened !== title && setOpenSelect(false);
  }, [currentOpened]);

  const onDropdownClick = () => {
    setOpenSelect((x) => !x && options.length > 0);
    setCurrentOpened(title);
  };
  const className = (() => {
    if (!letSelect || disabled) {
      return 'select-container-clients-wallet-disable-option';
    }
    return 'select-container-clients-wallet-active-option';
  })();
  return !(loadingInstrumentsMarket || loadingInstrumentsFunds) ? (
    <div
      className={className}
      style={{
        pointerEvents: disabled && 'none',
      }}
    >
      <button
        className="select-container-clients-wallet"
        onClick={letSelect ? () => onDropdownClick() : undefined}
      >
        <div>
          {selection.value === -1 || selection === '' ? (
            <div className="select-container-clients-wallet-title">{title}</div>
          ) : (
            <div className="option-select-clients-wallet-description">
              {selection.description}
            </div>
          )}
        </div>
        <div className="select-clients-wallet-arrow">
          <Arrow open={openSelect} icon={arrowIcon} />
        </div>
      </button>

      <div
        className={`select-options-clients-wallet ${
          openSelect ? 'open-clients-wallet' : ''
        }`}
      >
        {options?.map((opt, i) => {
          return (
            <div
              key={i}
              className="option-select-clients-wallet-general-container"
            >
              <div className="option-select-clients-wallet-container">
                <div
                  className="option-select-clients-wallet"
                  onClick={() => {
                    setSelection(opt);
                    setOpenSelect(false);
                  }}
                >
                  <p className="option-select-clients-wallet-description">
                    {opt.description}
                  </p>
                </div>
              </div>
              {options.length - 1 !== i ? (
                <div className="option-select-clients-wallet-divider"></div>
              ) : undefined}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div
      className={className}
      style={{
        pointerEvents: disabled && 'none',
      }}
    >
      <button className="select-container-clients-wallet">
        <div>
          <div className="select-search-loading">Cargando...</div>
        </div>
        <div className="select-clients-wallet-arrow">
          <Arrow open={openSelect} icon={arrowIcon} />
        </div>
      </button>
    </div>
  );
};
