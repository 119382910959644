import React, { useState , useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import './instruments.scss'

import {
  subTypeEquity,
  subTypeBond
} from '../../../operate/operate-service';
import { subTypeFund } from "../../market-service";

// import {
//   api_postFundGetEspecies,
//   api_postBondGetEspecies,
//   api_postEquityGetEspecies,
//   api_postCertGetEspecies
// } from '../../market-api';

import { setRadioList } from '../../market-service';
import { setCheckList } from "../../../operate/operate-service";
import { Spinner } from '../../../../components/spinner/spinner';
import { TERM_C, INSTRUMENTS_C } from '../../../../constants/constants';
import { TickerFundTable } from '../market-ticker-fund-table/ticker-fund-table';
import { TickerBondTable } from '../market-ticker-bond-table/ticker-bond-table';
import { TickerCertTable } from '../market-ticker-cert-table/ticker-cert-table';
import { TickerEquityTable } from '../market-ticker-equity-table/ticker-equity-table';
import { TickerCardContainer } from '../ticker-detail-card-container/ticker-card-container';
import { TickerDetailModal } from '../market-ticker-detail-modal/market-ticker-detail-modal';
import { FundCompareModal } from '../fund-compare-modal/fund-compare-modal';
import { LiquidityTermOptions } from '../market-liquidity-term-options/liquidity-term-options';
import { InstrumentOptions } from '../market-instrument-options/instrument-options';
import { formatBigNumbers } from '../../market-service';
import { a_setInstrumentNumber, a_setMarketTerm, api_postBondGetEspecies, api_postCertGetEspecies, api_postEquityGetEspecies, api_postFundGetEspecies } from '../../market-slice';
import { api_getOperateInstruments } from '../../../operate/operate-slice';

// import { InstrumentOptionContainer } from '../instrument-option-continer/instrument-option-container';
// import { LiquidityTermContainer } from '../../../operate/operate-components/liquidity-term-container/liquidity-term-container';

export const Instruments = () => {

  const dispatch = useDispatch();

  const [termNumber, setTermNumber] = useState(2);
  const [modalOpen, setModalOpen] = useState(false);
  const [fundCompareModal, setFundCompareModal] = useState(false);
  const [listTerms, setListTerms] = useState(TERM_C);
  const [listInstruments, setListInstruments] = useState(INSTRUMENTS_C)
  const [selectedSubTypeInstrumentFund, setSelectedSubTypeInstrumentFund] = useState({
     value: null,
     description: "Tipo de perfil" 
    });
  const [selectedSubTypeInstrumentEquity, setSelectedSubTypeInstrumentEquity] =
    useState({ value: subTypeEquity ? subTypeEquity[0].value : - 1, description: subTypeEquity ? subTypeEquity[0].description : '' });
  const [selectedSubTypeInstrumentBond, setSelectedSubTypeInstrumentBond] =
    useState({ value: subTypeBond ? subTypeBond[0].value : - 1, description: subTypeBond ? subTypeBond[0].description : '' });  

  const selectTerm = (radio) => {
    setListTerms(setCheckList(radio, listTerms));
    setTermNumber(radio["id"] - 1);
    dispatch(a_setMarketTerm(radio["id"]-1));
  };

  const selectInstrument = (radio) => {
    setListInstruments(setRadioList(radio, listInstruments));
    dispatch(a_setInstrumentNumber(radio["id"]-1));
  };

  useEffect(() => {
    dispatch(a_setInstrumentNumber(0));
    dispatch(a_setMarketTerm(2))
    dispatch(api_getOperateInstruments());
    // dispatch(
    //     api_postFundGetEspecies({
    //         type: "FUND",
    //     })
    // );
    // dispatch(
    //     api_postBondGetEspecies({
    //         type: "BOND",
    //     })
    // );
    // dispatch(
    //     api_postEquityGetEspecies({
    //         type: "EQUITY",
    //     })
    // );
}, [])

  // const state = useSelector((state) => ({
  //   ...state["marketReducer"],
  //   ...state["commonReducer"],
  // }));

  // const {
  //   loadingFundMarket,
  //   loadingBondMarket,
  //   loadingEquityMarket,
  //   loadingCertMarket,
  //   instrumentNumber,
  // } = state;
  
  const { loadingFundMarket, loadingBondMarket, loadingEquityMarket, loadingCertMarket, instrumentNumber } = useSelector((state) => state.market);

  const chooseSubTypeInstrument = (opt, instrument) => {
    switch (instrument) {
      case "Fondos":
        setSelectedSubTypeInstrumentFund(opt);
        dispatch(
          api_postFundGetEspecies({
            type: "FUND",
            subType: opt["subType"],
            // EsFisico: "True",
          })
        );
        break;
      case "Bonos":
        setSelectedSubTypeInstrumentBond(opt);
        dispatch(
          api_postBondGetEspecies({
            type: "BOND",
            // subType: opt["subType"],
          })
        );
        break;
      case "Acciones":
        setSelectedSubTypeInstrumentEquity(opt);
        dispatch(
          api_postEquityGetEspecies({
            type: "EQUITY",
            // subType: opt["subType"],
          })
        );
        break;
      case "Cedears":
        dispatch(
          api_postCertGetEspecies({
            type: "CERTIFICATE",
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    loadingFundMarket || 
    loadingBondMarket || 
    loadingCertMarket || 
    loadingEquityMarket ?
    <Spinner /> :
      <div className="instruments-tab">
        <div className="instruments-left-container">
          <div 
            className={instrumentNumber === 0 ? 
            'container-option-radio-desabled' : 
            'container-options-radio'}
          >
            Tipo de Plazo
            <LiquidityTermOptions 
              setOption={selectTerm}
              options={listTerms}
              disabled={instrumentNumber === 0 ? true : false}
            />
            {/* <LiquidityTermContainer
              listTerms={listTerms}
              selectTerm={selectTerm}
            /> */}
          </div>
          <div className="container-options-radio">
            Mostrar
            <InstrumentOptions 
              setOption={selectInstrument}
              options={listInstruments}
            />
            {/* <InstrumentOptionContainer
              listOptions={listInstruments}
              selectOption={selectInstrument}
            /> */}
          </div>
          {instrumentNumber === 0 && 
            <TickerFundTable
              subType={selectedSubTypeInstrumentFund} 
              selectSubTypeInstrument={chooseSubTypeInstrument} 
              openCompareModal={setFundCompareModal}
            />
          }
          {instrumentNumber === 1 && 
            <TickerBondTable 
              termNumber={termNumber} 
              subType={selectedSubTypeInstrumentBond} 
              selectSubTypeInstrument={chooseSubTypeInstrument} 
            />
          }
          {instrumentNumber === 2 && 
            <TickerEquityTable 
              termNumber={termNumber} 
              subType={selectedSubTypeInstrumentEquity} 
              selectSubTypeInstrument={chooseSubTypeInstrument} 
            />
          }
          {instrumentNumber === 3 && 
            <TickerCertTable 
              termNumber={termNumber} 
              subType={selectedSubTypeInstrumentEquity} 
              selectSubTypeInstrument={chooseSubTypeInstrument} 
            />
          }
          {/* {`opcion termino: ${termNumber} , opcion instrumento: ${instrumentNumber}`} */}
        </div>
        <TickerCardContainer
          setModalOpen={setModalOpen}
          subTypeInstrument={instrumentNumber}
        />
        {modalOpen &&
          <TickerDetailModal 
            closeModal={() => { setModalOpen(false) }} 
            subTypeInstrument = {instrumentNumber}
          />
        }
        { fundCompareModal && 
          <FundCompareModal 
            closeModal={()=>setFundCompareModal(false)}
          />
        }
      </div>
  )
}


