import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// ant design
import { Button, Input, Form } from 'antd';

// utils
import question from '../../utils/question.svg';

// styles
import './recover-user-pass.scss';
import './problem-login.scss';

// components actions & services
import { apiGetRecoverUser } from '../../common/common-slice';

export const RecoverUser = ({ backToLogin, onClickAction, onModalTerms }) => {
  const state = useSelector((state) => state['common']);
  const { recoverUser, errorMessage } = state;

  const dispatch = useDispatch();

  const onFinish = ({ email }) => {
    try {
      if (email) {
        dispatch(apiGetRecoverUser({ email }));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="recover-password-container">
      <div className="recover-password-wrapper">
        <h3 className="subtitle">Recuperar usuario</h3>
        <p className="recover-password-desrcription">
          Por favor, introdúzca el correo electrónico registrado en su cuenta.
          Su usuario será enviado por correo electrónico.
        </p>

        {recoverUser?.status === true && (
          <div className="msg-success">
            ¡El código de usuario fue enviado a su correo electrónico!
          </div>
        )}

        {recoverUser?.status === false && (
          <div className="msg-error">
            Ha ocurrido un error, por favor inténtelo nuevamente.
          </div>
        )}
        
        {errorMessage && (
        <div className="msg-error">
        {errorMessage}
      </div>
      )}

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            className="recover-user-form"
            rules={[
              {
                type: 'email',
                message:
                  '¡Introduzca una dirección de correo electrónico válida!',
              },
              {
                required: true,
                message: '¡Este dato es requerido!',
              },
            ]}
          >
            <Input
              placeholder="Dirección de correo electrónico *"
              className="input-text"
              block="true"
            />
          </Form.Item>
          <div className="message-container">
            <p className="message">
              * La dirección de correo electrónico ingresada deberá coincidir
              con la registrada en el usuario.
            </p>
          </div>
          <div className="btn-container">
            <Button className="btn-to-login" onClick={backToLogin} block="true">
              VOLVER A LOGIN
            </Button>
            <Button className="btn-recover-submit" htmlType="submit" block="true">
              RECUPERAR
            </Button>
          </div>
        </Form>

        <div className="container-form-problem-login recover-user-mt">
          <img src={question} alt="logo" />
          <div className="description-login">
            Tenés problemas para ingresar a tu cuenta?
          </div>
          <div className="click-form-login" onClick={onClickAction}>
            Escríbenos aquí
          </div>
          <div className="description-login">
            Te responderemos a la brevedad
          </div>
          <div className="legals-link" onClick={onModalTerms}>
            <p> Al ingresar, aceptas los términos y condiciones</p>
          </div>
        </div>
      </div>
    </div>
  );
};
