import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../../../components/spinner/spinner";
import { ProcedureContainer } from "../common/procedure-container/procedure-container";
import {
  totalsFormat,
  twoDecimalsFormat,
} from "../../operate-service";


import iconLeftArrow from "../../../../utils/Grupo 4084.svg";
import iconWarning from "../../../../utils/warning-partners-24px.svg";

import "./operate-confirm-procedure.scss";
import { api_confirmOperateOrder, api_confirmOperateOrderFdo } from "../../operate-slice";

export const OperateConfirmProcedure = ({
  cancelProcedure,
}) => {
  const dispatch = useDispatch();
  const { tradingOption,
    specieInfo,
    loadingExpenses,
    expenses,selectedClient } = useSelector((state) => state.operate);
    
  const [withoutErrors, setWithoutErrors] = useState(false);
  const [isNotFondos, setIsNotFondos] = useState(specieInfo?.type !== "FUND");
  useEffect(() => {
    const getStatusExpenseList = () => {
      if (expenses && expenses.status) {
        return expenses.status === "OPERAR_OK";
      }
      return false;
    };
    
    setWithoutErrors(getStatusExpenseList());
  }, [expenses]);
  
  
  useEffect(() => {
    if (specieInfo && typeof specieInfo.type === "string") {
      setIsNotFondos(specieInfo.type !== "FUND");
    }
  }, [specieInfo])

  const getSpecieInformation = () => {
    let specie = {
      ticker: null,
      description: specieInfo["name"],
    };
    if (specieInfo["type"] !== "FUND") {
      specie["ticker"] = specieInfo["ticker"];
    } else {
      specie["ticker"] = specieInfo["COD_CAFCI"];
    }
    return specie;
  }
  
  const validateOperation = () => {
    if (loadingExpenses || !expenses || !specieInfo || !selectedClient) {
      console.error("Faltan datos para enviar la solicitud.");
      return;
    }
  
    try {
      let orderData = {};
      if (!selectedClient.price || !expenses.gross) {
        throw new Error("Los campos 'precio' e 'importe' son necesarios.");
      }
  
      if (isNotFondos) {
        orderData = {
          compraVenta: tradingOption === "COMPRAR" ? "C" : "V",
          cantidad: expenses.quantity,
          precio:  Number(selectedClient.price.replace(',', '.')),
          importe:  twoDecimalsFormat(expenses.gross), 
          plazo: expenses.term,
          ticker: specieInfo?.ticker,
          incluyeGastosEnImporte: false,
          porImporte: false,
          aPrecioDeMercado: selectedClient.priceType === 'market' ? true : false
        };
        dispatch(api_confirmOperateOrder({ orderData, codComitente: selectedClient.codComitente }));
      } else {
        orderData = {
          ticker: specieInfo?.ticker,
          CodTpOperacionFdo: tradingOption === "SUSCRIBIR" ? "SU" : "RE", 
          Importe: expenses.gross,
          // CantCuotapartes: expenses.quantity,
          EsTotal: selectedClient.countType === "Rescate Total" ? true : false,
        };
        dispatch(api_confirmOperateOrderFdo({ codComitente: selectedClient.codComitente, orderData }));
        console.log("expenses",expenses)
      }
    } catch (error) {
      console.error("Error al confirmar la operación: ", error);
    }
  };

  console.log("expenses?.net",expenses?.net)
  
  return (
    <div className="operate-confirm-procedure-container">
      <div className="operate-confirm-procedure-important-screen-wrapper">
        <div className="operate-confirm-procedure-container-title">
          <img
            src={iconLeftArrow}
            alt="..."
            onClick={() => cancelProcedure()}
          />
          <div className="operate-confirm-procedure-title">
            Resumen de Orden
          </div>
        </div>
        <div className="operate-confirm-procedure-container-data">
          {loadingExpenses ? (
            <div className="operate-confirm-procedure-loading">
              <Spinner />
            </div>
          ) : (
            <div className="operate-confirm-procedure-table-container">
              <div className="operate-confirm-procedure-container-data-header">
                <ProcedureContainer
                  title="Operación"
                  description={tradingOption}
                />
                <ProcedureContainer
                  title="Especie"
                  description={specieInfo && specieInfo["name"]}
                />
                <ProcedureContainer
                  title="Moneda"
                  description={specieInfo?.currency === "ARS" ? "Pesos" : "Dólares"}
                />
                <ProcedureContainer
                  title="Fecha Concertación"
                  description={expenses?.fechaConcertacion}
                />
                <ProcedureContainer
                  title="Fecha Liquidación"
                  description={expenses?.fechaLiquidacion}
                  divisionHide={true}
                />
              </div>

              <div
                className={`operate-confirm-procedure-container-data-comitentes-header 
                  ${isNotFondos ? "" : "data-founds-header"}`}
              >
                <div>Denominación</div>
                {isNotFondos ? (
                  <div>Unidades</div>
                ) : (
                  <div>Cantidad estimada</div>
                )}
                <div>Importe Bruto</div>
                {isNotFondos ? <div>Gastos</div> : undefined}
                {isNotFondos ? <div>Importe Neto</div> : undefined}
              </div>
              <div className="operate-confirm-procedure-container-data-comitentes-data">
                {!expenses ? (
                  <Spinner />
                ) : (
                  <>
                  <div>
                            <div
                              className={`operate-confirm-procedure-container-data-comitentes-data-element 
                                          ${
                                            expenses.status !== "OPERAR_OK"
                                              ? "operate-confirm-procedure-data-error"
                                              : ""
                                          }`
                                        }
                            >
                              <div className="operate-confirm-procedure-comitente-info">
                                <div className="operate-confirm-procedure-comitente-name">
                                  {selectedClient.name}
                                </div>
                                {
                                  expenses.status==="OPERAR_ERROR_LIMITE"||expenses.status==="OPERAR_ERROR_SIN_SALDO" ? (
                                    <div className="operate-confirm-procedure-comitente-extra">
                                      <div className="operate-confirm-procedure-comitente-count">
                                        {expenses.message}
                                      </div>
                                    </div>
                                  ) : undefined
                                }
                                {
                                  expenses.status === "OPERAR_ERROR_SIN_CANTIDAD" ? (
                                    <div className="operate-confirm-procedure-comitente-extra">
                                      <p className="operate-confirm-procedure-comitente-value">
                                        {expenses.message}
                                      </p>
                                    </div>
                                  ) : undefined
                                }

                                {
                                  expenses.status==="OPERAR_ERROR_CANTIDAD_NO_DISPONIBLE" ? (
                                    <div className="operate-confirm-procedure-comitente-extra">
                                      <div className="operate-confirm-procedure-comitente-count">
                                        cantidad disponible: {expenses?.availablePosition}
                                      </div>
                                      <div className="operate-confirm-procedure-comitente-value">
                                        cantidad parkeada: {expenses?.parkedPosition}
                                      </div>
                                    </div>
                                  ) : undefined
                                }
                              </div>
                              <div
                                className={
                                  isNotFondos
                                    ? "operate-row-data"
                                    : "operate-row-founds"
                                }
                              >
                                {expenses?.quantity}
                              </div>
                              <div
                                className={
                                  isNotFondos
                                    ? "operate-row-data"
                                    : "operate-row-founds"
                                }
                              >
                                {expenses?.gross}
                              </div>
                              {isNotFondos ? (
                                <div className="operate-row-data">
                                  {expenses?.totalExpenses}
                                </div>
                              ) : undefined}
                              {isNotFondos ? (
                                <div className="operate-row-data">
                                  {expenses?.net}
                                </div>
                              ) : undefined}
                            </div>
                  </div>
                  </>
                  )}
              </div>
             
                
              <div
                className={`operate-confirm-procedure-container-data-comitentes-header 
                  ${isNotFondos ? "" : "data-founds-header"}`}
              >
                <div>Cantidad de clientes</div>
                {isNotFondos ? (
                  <div>Total Unidades</div>
                ) : (
                  <div>Total de cantidades estimadas</div>
                )}
                <div>Total Bruto</div>
                {isNotFondos ? <div>Total Gastos</div> : undefined}
                {isNotFondos ? <div>Total Neto</div> : undefined}
              </div>
              <div className="operate-confirm-procedure-container-footer-data">
                <div className="operate-row-specie">
                  1
                </div>
                <div className={isNotFondos ? "operate-row-data" : "operate-row-founds"}>
                  {expenses?.quantity}
                </div>
                {
                  isNotFondos ? (
                    <div className="operate-row-data">
                      {expenses?.gross}
                    </div>
                  ) : (
                    <div className="operate-row-founds">
                      {expenses?.net}
                    </div>
                  )
                }
                {
                  isNotFondos ? (
                    <div className="operate-row-data">
                      {expenses?.totalExpenses }
                    </div>
                  ) : undefined
                }
                {
                  isNotFondos ? (
                    <div className="operate-row-data">
                      {twoDecimalsFormat(expenses?.net)}
                    </div>
                  ) : undefined
                }
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="operate-confirm-procedure">
        {!withoutErrors ? (
          <div className="operate-confirm-alert-message-container">
            <div className="operate-confirm-alert-message-wrapper">
              <div className="operate-confirm-alert-message-icon-container">
                <div className="operate-confirm-alert-message-icon">
                  <img src={iconWarning} alt="..." />
                </div>
              </div>
              <div className="operate-confirm-alert-message-text-container">
                <div className="operate-confirm-alert-message-text">
                  Eliminá las ordenes que tengan error, o volvé un paso atrás
                  para corregirlas
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
        <div
          className="operate-confirm-procedure-cancel"
          onClick={() => cancelProcedure()}
        >
          No realizar por ahora
        </div>
        <div
          className={`operate-confirm-procedure-finish-ok ${
            withoutErrors ? "finish-ok-active" : ""
          }`}
          onClick={withoutErrors ? () => validateOperation() : undefined}
        >
          CONFIRMAR OPERACIÓN
        </div>
      </div>
    </div>
  );
};
