import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import {  isOrderEmptyClient } from "../../operate-service";
import "./operate-footer.scss";

export const OperateFooter = ({ totalQuantity, totalPrice, confirmProcedure, selectedInstrument }) => {
  const { selectedClient, specieInfo } = useSelector((state) => state.operate);
  const [btnActive, setBtnActive] = useState(false);

  useEffect(() => {
    let buttonActivation = specieInfo && specieInfo.ticker ? true : false;
buttonActivation &= selectedClient && !isOrderEmptyClient(selectedClient, selectedInstrument['description']);

  
    setBtnActive(buttonActivation);
  }, [specieInfo, selectedClient, totalQuantity, totalPrice]);

  return (
      <Table.Summary.Row>
          <Table.Summary.Cell>
            <div
              className={`operate-dashboard-container-footer-confirm-btn ${btnActive ? "confirm-btn-actived" : "confirm-btn-inactived"}`}
              onClick={() => btnActive && confirmProcedure()}
            >
              CONFIRMAR
            </div>
          </Table.Summary.Cell>
      </Table.Summary.Row>
  );
};
