import React, { useEffect }  from "react";
import { useDispatch } from "react-redux";

import iconClose from "../../../utils/icon-cerrar.svg";
import iconCaution from "../../../utils/errorNegro.svg";

import "./notification-error.scss";

import { a_setGenericServiceFailure } from "../../common-action";

export const NotificationError = () => {
  const dispatch = useDispatch();

  const closeMsgErr = () => {
    dispatch(a_setGenericServiceFailure(false));
  }

  useEffect(() => {
    let id;
    id = setTimeout(closeMsgErr, 5000);
    return () => {
      clearTimeout(id);
    };
  })

  return(
    <div className="container-team-notification-error">
      <div className="container-notification-error">
        <div onClick={closeMsgErr} className="container-icon-close-error">
          <img className="icon-close" src={iconClose} alt="close" />
        </div>
        <div className="container-icon-error">
            <img className="icon-error" src={iconCaution} alt="caution" />
        </div>
          <div className="container-text-error">
              <p className="title-notification-error">
                Notificación de Error
              </p>
              <a className="text-a-error">
                Ha surgido un problema al cargar los
              <br/>
                resultados. Vuelve a intentarlo!
              </a>
          </div>
      </div>
    </div>
  );
};