import React, { useState } from 'react';
import { amountDirectionColor, statusColor, useFilteredData } from './utils';

import mockData from './mockData';
import './table.scss';

const Table = () => {
    const { items, requestFilter, requestSort, sortConfig } = useFilteredData(mockData);
    const [activeFilter, setFilter] = useState('');

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return 'neutral';
        }
        return sortConfig.key === name ? sortConfig.direction : 'neutral';
    };

    const onClickOption = (option) => {
        setFilter('none');
        requestFilter(option);
    }

    const changeVisibility = (title) => {
        setFilter((title === activeFilter) ? '' : title);
    }

    const TH = ({ title, list }) => (
        <div className={'filter-div ' + ((activeFilter === title) ? ' active' : '')}>
            <button type="button" onClick={() => changeVisibility(title)} className="filter-active">
                <div className="filter-dropdown-container">
                    {/* <a className="icon-filter" /> */}
                    {title.toUpperCase()}
                    {
                        (list && (activeFilter === title)) && (
                            <div className="filter-dropdown">
                                <ul>
                                    {list.map((listItem, index) => <li key={index} onClick={() => onClickOption(listItem)}>{listItem.value}</li>)}
                                </ul>
                            </div>
                        )
                    }
                </div>
            </button>
        </div>
    )

    return (
        <div className="container-table-treasury">

            <div className='container-data-table-treasury'>
                <div className='width10'>
                    <button type="button" onClick={() => requestSort('date')} className={` boton-filtro-treasury ${getClassNamesFor('date')}`}>
                        FECHA
                    </button>
                </div>
                <div className='width20'>CLIENTE</div>
                <div className='width10'>CUENTA</div>
                <div className='width15'>T. LÍQUIDA</div>
                <div className='width10'>
                    <TH title="DETALLE" list={['Venta AY24', 'Depósito', 'Extracción'].map((value) => ({ key: 'detail', value }))} />
                </div>
                <div className='width5'>
                    <TH className="width5" title="MONEDA" list={['Pesos', 'Dolar'].map((value) => ({ key: 'currency', value }))} />
                </div>
                <div className='width10'>MONTO/NETO</div>
                <div className='width15'>
                    <TH className="width15" title="ESTADO" list={['ACEPTADO', 'PENDIENTE', 'CANCELADO', 'RECHAZADO'].map((value) => ({ key: 'status', value }))} />
                </div>
                <div className='width5'></div>
            </div>

            <div className='container-information-treasury' onClick={() => changeVisibility()}>
                {
                    items.map((item, index) => (
                        <div className='container-individual-data' key={index}>
                            <div className="width10 date">{item.date}</div>
                            <div className='width20'>{item.client}</div>
                            <div className='width10'>{item.account}</div>
                            <div className="width15 bold-blue">{item.tenencia} <span className="underline-btn">OPERAR</span></div>
                            <div className='width10'>{item.detail}</div>
                            <div className='width5'>{item.currency}</div>
                            <div className={`width10 ${amountDirectionColor[item?.neto?.direction]}`}>{item.neto.direction}{item.neto.amount}</div>
                            <div className={`width15 ${statusColor[item?.status]}`} >{item.status}</div>
                            <div className='width5'>
                                <div className="btn-row">
                                    <i className="icon-ticket active-icon" />
                                    <i className={"icon-send " + ((item.status === 'ACEPTADO') ? 'active-icon' : 'inactive-active')} />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

Table.defaultProps = {
}

export default Table