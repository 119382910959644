import React from "react";

import "./procedure-container.scss";

export const ProcedureContainer = ({ title, description, divisionHide }) => {
  return (
    <div className="operate-confirm-procedure-container-data-header-field">
      <div className="operate-confirm-procedure-container-data-header-field-title">
        {title}
      </div>
      <div className="operate-confirm-procedure-container-data-header-field-description">
        {description}
      </div>
      {!divisionHide ? (
        <div className="operate-confirm-procedure-container-data-header-field-resalter" />
      ) : undefined}
    </div>
  );
};
