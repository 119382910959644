import React from "react";

import icon from "../../../../utils/business-and-finance.svg";
import "./buttons.scss";

const NormalButton = (props) => {
  return (
    <button className="base-button normal-button" onClick={props.onClick}>
      <img src={props.icon} alt="..." />
      <h1>{props.title.toUpperCase()}</h1>
    </button>
  );
};

NormalButton.defaultProps = {
    title: 'Ingresar Comrpobantes',
    icon,
    onClick: () => console.log('click action')
}

export default NormalButton;