import React from 'react';

import './forms.scss';

const InputFile = ({ onChange, className, inputProps, inputIcon, inputText }) => {
    return (
        <div className={"team-form-container " + className}>
            <label className="custom-file-upload team-form-control">
                <input name="file" type="file" {...inputProps} onChange={onChange} />
                <div><i className={inputIcon} />{inputText}</div>
            </label>
        </div> 
    )
}

InputFile.defaultProps = {
    className: '',
    inputProps: {},
    inputIcon: 'icon-input icon-upload',
    inputText: 'Subir Archivo',
    onChange: () => console.log('onChange input')
}

export default InputFile