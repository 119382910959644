import React from 'react';

import './forms.scss';

const Input = ({ label, name, placeholder, onChange, type, value, children, className }) => {
    return (
        <div className={"form-container " + className}>
            {(label) && <label>{label}</label>}
            <input
                name={name}
                className="form-control"
                placeholder={placeholder}
                onChange={onChange}
                type={type}
                value={value}
            >
            {children}
            </input>
        </div> 
    )
}

Input.defaultProps = {
    name: 'inputname',
    className: '',
    placeholder: '',
    value: null,
    type: 'text',
    onChange: () => console.log('onChange input')
}

export default Input