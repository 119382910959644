import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import { Home } from "../home/home-view";
import { Market } from "../market/market-view";
import { Operate } from "../operate/operate-view";
import { Treasury } from "../treasury/treasury-view";
import { Wallet } from "../wallet/wallet-view";
import { Team } from "../team/team-view";
import { Analytics } from "../analytics/analytics-view";
import { Files } from "../files/files-view";

import { TemporalyView } from "../../common/common-components/temporaly-view/temporaly-view";
import { Disclaimer } from "../operate/operate-components/disclaimer/disclaimer";
import { Spinner } from "../../components/spinner/spinner";

import { viewInformation, checkDisclaimer } from "../../libs/libsUserProfile";

import "./main-view.scss";
import { StyleGuide } from "../../scss/styleguide";

export const MainViews = () => {
  const state = useSelector((state) => ({
    ...state["commonReducer"],
    ...state["operateReducer"],
  }));

  const { discAuth } = state;
  const { routes } = useSelector((state) => state.common);
  const { partnerInfo } = useSelector((state) => state.home);

  const [viewAttrs, setViewAttrs] = useState(
    new Array(9).fill({
      lockLevel: "NOT_VISIBLE",
      visibility: false,
    })
  );

  useEffect(() => {
    if (partnerInfo?.partnerId) {
      viewAttrs.forEach((item, i) => {
        viewAttrs[i] = viewInformation(routes[i], partnerInfo);
      });
      setViewAttrs(viewAttrs);
    }
  }, [partnerInfo, routes]);

  return (
    <div
      className={`main-views-container ${
        process.env.REACT_APP_ENV_ENVIRONMENT === "STG" ? "stg" : ""
      }`}
    >
      {!partnerInfo?.partnerId ? (
        <Spinner />
      ) : (
        <Routes>
          <Route
            path="/home"
            element={
              viewAttrs[0]?.visibility ? (
                viewAttrs[0]?.lockLevel === "VISIBLE" ? (
                  <Home />
                ) : (
                  <TemporalyView viewAttr={viewAttrs[0]} />
                )
              ) : null /* Deberia mostrar una ruta alternativa que especifique el tipo de error */
            }
          />
          <Route
            path="/market"
            element={
              viewAttrs[1]?.visibility ? (
                viewAttrs[1]?.lockLevel === "VISIBLE" ? (
                  <Market />
                ) : (
                  <TemporalyView viewAttr={viewAttrs[1]} />
                )
              ) : null /* Deberia mostrar una ruta alternativa que especifique el tipo de error */
            }
          />
          {viewAttrs[2]?.visibility ? (
            <Route
              path="/operate"
              element={
                viewAttrs[2]?.lockLevel === "VISIBLE" ? (
                  checkDisclaimer(discAuth, partnerInfo) ? (
                    <Operate />
                  ) : (
                    <Disclaimer
                      disclaimerID={1}
                      partnerID={partnerInfo["partnerId"]}
                    />
                  )
                ) : (
                  <TemporalyView viewAttr={viewAttrs[2]} />
                )
              }
            />
          ) : null /* Deberia mostrar una ruta alternativa que especifique el tipo de error */}
          <Route
            path="/treasury"
            element={
              viewAttrs[3]?.visibility ? (
                viewAttrs[3]?.lockLevel === "VISIBLE" ? (
                  <Treasury />
                ) : (
                  <TemporalyView viewAttr={viewAttrs[3]} />
                )
              ) : null /* Deberia mostrar una ruta alternativa que especifique el tipo de error */
            }
          />
          <Route
            path="/wallet"
            element={
              viewAttrs[4]?.visibility ? (
                viewAttrs[4]?.lockLevel === "VISIBLE" ? (
                  <Wallet />
                ) : (
                  <TemporalyView viewAttr={viewAttrs[4]} />
                )
              ) : null /* Deberia mostrar una ruta alternativa que especifique el tipo de error */
            }
          />
          <Route
            path="/team"
            element={
              viewAttrs[5]?.visibility ? (
                viewAttrs[5]?.lockLevel === "VISIBLE" ? (
                  <Team />
                ) : (
                  <TemporalyView viewAttr={viewAttrs[5]} />
                )
              ) : null /* Deberia mostrar una ruta alternativa que especifique el tipo de error */
            }
          />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/files" element={<Files />} />
          <Route path="/styleguide" element={<StyleGuide />} />

          <Route index element={<Navigate to="home" />} />
        </Routes>
      )}
    </div>
  );
};
