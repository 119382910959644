// store.js
import { configureStore } from '@reduxjs/toolkit';

import { commonReducer } from './common-slice';
import { homeReducer } from '../views/home/home-slice';
import { walletReducer } from '../views/wallet/wallet-slice';
// import { homeReducer } from '../views/home/home-slice';

import { marketReducer } from '../views/market/market-slice';
import { operateReducer } from '../views/operate/operate-slice';
// import homeReducer from '../views/home/home-reducer';
// import operateReducer from '../views/operate/operate-reducer';
// import walletReducer from '../views/wallet/wallet-reducer';
// import teamReducer from '../views/team/team-reducer';
// import marketReducer from '../views/market/market-reducer';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    home: homeReducer,
    operate: operateReducer,
    wallet: walletReducer,
    // home: homeReducer,
    // operate: operateReducer,
    // wallet: walletReducer,
    // team: teamReducer,
    market: marketReducer,
  },
});
