import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// utils
import iconCopy from '../../../../utils/copy-icon.svg';

// styles
import './section-title.scss';

const SectionTitle = (props) => {
  const state = useSelector((state) => state['commonReducer']);
  const { userLogged } = state;

  const [copy, setCopy] = useState(false);

  const copyToClipboard = (code) => {
    const textarea = document.createElement('textarea');
    textarea.value = code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    showCopyText();
  };

  const showCopyText = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  return (
    <div className="section-title">
      <div className="container-header">
        <h1 className="container-header-title">{props.title}</h1>
        {userLogged?.referredCode && (
          <>
            <div className="container-header-referCode">
              Cód. Referido
              <button onClick={() => copyToClipboard(userLogged?.referredCode)}>
                {userLogged?.referredCode}{' '}
                <img className="icon-copy" src={iconCopy} alt="icon copy" />
              </button>
              {copy && <div className="copy-text">¡Copiado!</div>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SectionTitle.defaultProps = {
  title: 'Section Title',
};

export default SectionTitle;
