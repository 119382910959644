import React from "react";
import "./titles.scss";

const TYPES = ["text", "main", "link", "danger", "success", "light", "purple"];

export const Title = ({ text, type, children }) => {
  const messageType = TYPES.includes(type) ? type : TYPES[0];

  // return <h1 className={`title ${messageType}`}>{text}</h1>;
  return <h1 className={`title ${messageType}`}>{children}</h1>;
};

export const Subtitle = ({ text, type }) => {
  const messageType = TYPES.includes(type) ? type : TYPES[0];
  return <h2 className={`subtitle ${messageType}`}>{text}</h2>;
};

export const Paragraph = ({ text, type }) => {
  const messageType = TYPES.includes(type) ? type : TYPES[0];
  return <p className={`paragraph ${messageType}`}>{text}</p>;
};

export const Text = ({ text, type }) => {
  const messageType = TYPES.includes(type) ? type : TYPES[0];
  return <p className={`text ${messageType}`}>{text}</p>;
};
