import React, { useState } from "react";
import Factura from "../../../../utils/icon-botton-team-top.svg";
import AddReceipt from '../modals/add-receipt';

import "./team-top.scss";

export const TeamTop = () => {
  const [modalVisibility, setModal] = useState('none');
  
  const weeklyReport = () => {
    setModal('add-receipt');
  };

  return (
    <>
      <div className="button-report-bill" onClick={weeklyReport}>
        Informe su Factura
      </div>
      {/* <div className="button-team-top-container" onClick={weeklyReport}>
        <div className="button-team-top-img-container">
          <img className="button-team-top-img" src={Factura} alt="..." />
        </div>
        <div className="button-team-top-title">
          INFORME
          <br/>
          SU FACTURA
        </div>
      </div> */}
      {
        (modalVisibility === 'add-receipt') && (
          <AddReceipt onClose={() => setModal('none')} />
        )
      }
    </>
  );
};

