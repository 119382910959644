import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const MarketTime = ({ instrumentNumber, termNumber }) => {
  const [time, setTime] = useState(null);
  const instrumentsData = useSelector((state) => state.operate.operateInstruments.instruments);
  useEffect(() => {
    const instrument = instrumentsData.find(
      (instr) => instr.id === instrumentNumber + 1
    );
    if (instrument && instrument.terms) {
      const term = instrument.terms.find((t) => t.term === termNumber);
      if (term) {
        setTime(`${term.openingHour}hs a ${term.closingHour}hs`);
      }
    }
  }, [instrumentNumber, termNumber, instrumentsData]);

  return (
    <>
      {time && (
        <div className="market-time">
          <span>HORARIO&nbsp;Lunes a Viernes de {time}</span>
        </div>
      )}
    </>
  );
};
