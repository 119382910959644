import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 * @description function to get specie info and liquidity term for detail specie cards.
 * @returns specieInfo from the reducer, and states with data from the same reducer.
 */
export const useFetchSpecieInfo = () => {
  const { specieInfo, liquidityTerm } = useSelector((state) => state.operate);

  const [termData, setTermData] = useState("");
  const [refData, setRefData] = useState("");

  useEffect(() => {
    try {
      setTermData(specieInfo["marketData"]["Plazo" + liquidityTerm["term"]]);
      setRefData(specieInfo);
    } catch (error) {
      throw new Error(error);
    }
  }, [specieInfo, liquidityTerm]);

  return {
    specieInfo,
    termData,
    refData,
  };
};

/******************************************************************************************************/

