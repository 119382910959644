import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FundsDetailEspecie } from "../funds/funds-detail-especie";
import { CommonDetailEspecie } from "../common-detail-especie/common-detail-especie";
import { OperateScheduleView } from "../operate-schedule-view/operate-schedule-view";
import "./operate-detail-especie.scss";

export const OperateDetailEspecie = ({}) => {
  const { specieInfo, loadingSpecieInfo, liquidityTerm } = useSelector((state) => state.operate);

  const [activeCard, setActiveCard] = useState(false);

  useEffect(()=>{
    setActiveCard((!loadingSpecieInfo&&Object.entries(specieInfo)?.length) ? true : false);
  },[loadingSpecieInfo, specieInfo])

  return (
    <div className={`operate-detail-especie-container ${activeCard ? "active-detail-card" : ""}`}>
      {
        (!loadingSpecieInfo) ? (
          <OperateScheduleView />
        ) : undefined
      }
      <div className="container-results-instruments-filters-selections">
        {
          specieInfo ? (
            loadingSpecieInfo ? (
              "Cargando"
            ) : (specieInfo["type"] === "FUND") ? (
              <FundsDetailEspecie />
            ) : (liquidityTerm&&(liquidityTerm["term"]<4)&&["BOND", "EQUITY", "CERTIFICATE"].includes(specieInfo["type"])) ? (
              <div style={{ width: "100%" }}>
                <CommonDetailEspecie />
              </div>
            ) : undefined
          ) : undefined
        }
      </div>
    </div>
  );
};

/* ****************************************************** */
export const PiePercentageStatics = ({ dataList }) => {
  const initialPosPercentage = (id) => {
    const percArray = dataList.filter((element) => element.id < id);
    const sum = percArray.reduce((acc, x) => acc + x.percentage, 0);
    const result = (sum * 180) / 50;
    return { sum, result };
  };

  return (
    <div className="operate-pie-main-container">
      <div className="operate-my-circle-container">
        {dataList.map(({ id, color, name, percentage }) => {
          return (
            <div
              key={id}
              className={`perc-element `}
              style={{
                zIndex: id === dataList.length ? 0 : id,
                transform: `rotate(${initialPosPercentage(id).result}deg)`,
                background: `conic-gradient(${color} ${percentage}%, transparent ${percentage}% 100%)`,
              }}
            ></div>
          );
        })}
        <div className={`operate-corona-interna`}></div>
      </div>
    </div>
  );
};