import React from "react";

import Input from '../forms/input';
import InputFile from '../forms/input-file';
import Select from '../forms/select';

import BaseModal from '../../../../components/modal/base-modal';
import { Button } from '../../../../components/button/button';

import './treasury-modals.scss';

const AddReceipt = (props) => {
  return (
    <BaseModal onClose={props.onClose} title="Ingresar comprobantes">
        <form className="d-flex flex-column">
            <Input className="col-md-12" name="client" label="Nombre del cliente" placeholder="Apellido y Nombre del Cliente" />
            <div className="d-flex flex-row">
                <Select className="col-md-4" name="moneda" label="Moneda" options={[{label: 'Pesos', value: '-'}, {label: 'USD', value: '-'}]} />
                <Select className="col-md-8" name="moneda" label="Seleccione cuenta de origen" options={[{label: 'CBU 0025 4589 4523 2569', value: '-'}]} />
            </div>

            <div className="amount-container">
                <div className="f-row-center col-md-6">
                    <span className="currency-symbol-xl">$</span>
                    <input
                        name="amount"
                        className="input-number-xl"
                        placeholder="0.00"
                        type="number"
                    />
                </div>
                <div className="amount-footer">
                    <hr className="hr-underline" />
                    <span className="help-text">Ingrese valor exacto</span>
                </div>
            </div>

            <InputFile />
            
            <div className="f-row-center">
                <div className="buttons-footer-container">
                    <Button buttonSize="btn--large">SUBIR COMPROBANTE</Button>
                    <span className="skip-upload-button" onClick={props.onClose}>No cargar por ahora</span>
                </div>
            </div>
     
        </form>
    </BaseModal>
  );
};

AddReceipt.defaultProps = {
  onClose: () => console.log('close action: add-receipt')
}

export default AddReceipt;
