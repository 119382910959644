import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { OptionsTipoMoneda } from '../../market-service';
import { useTableActiveData } from '../market-hooks/tables-hooks';
import { TableContainer } from '../market-table-container/table-container';
import { SelectClientsWallet } from '../../../../components/selectClientsWallet/selectClientsWallet';
import { InputTextClientsWallet } from '../../../../components/inputTextClientsWallet/inputTextClientsWallet';
import ArrowDown from "../../../../utils/arrow-down-blue-icon.svg";
import { a_setBondTickerFiltered } from '../../market-slice';

export const TickerBondTable = ({ termNumber }) => {


    const dispatch = useDispatch();

    // const state = useSelector((state) => ({
    //     ...state["marketReducer"],
    //     ...state["commonReducer"],
    // }));

    // const {
    //     marketBondEsp,
    //     instrumentNumber,
    // } = state;

    const { marketBondEsp, instrumentNumber } = useSelector((state) => state.market)
    const [
        handleSearchTextChange,
        search,
        handleMonedaChange,
        moneda,
        columns,
    ] = useTableActiveData('bond')
    
    const [currentOpened, setCurrentOpenedState] = useState(null);

    const filterMarketBond = async () => {
       await dispatch(a_setBondTickerFiltered(
            marketBondEsp.map((bond, i) => ({
                key: bond.ticker,
                selected: (i === 0) ? true : false,
                ticker: bond.name,
                last: bond?.marketData?.['Plazo' + termNumber]?.NOW_PRICE ? parseFloat(bond?.marketData?.['Plazo' + termNumber]?.NOW_PRICE).toFixed(2) : '-',
                day_percentage: bond.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE ? `${parseFloat(bond.marketData?.['Plazo' + termNumber]?.DAY_PERCENTAGE_CHANGE).toFixed(2)}%` : '-',
                buy_price: bond.marketData?.['Plazo' + termNumber]?.BID_PRICE ? `${parseFloat(bond.marketData?.['Plazo' + termNumber]?.BID_PRICE).toFixed(2)}` : `-`,
                sell_price: bond.marketData?.['Plazo' + termNumber]?.ASK_PRICE ? `${parseFloat(bond.marketData?.['Plazo' + termNumber]?.ASK_PRICE).toFixed(2)}` : `-`,
                vol_nom: bond.marketData?.['Plazo' + termNumber]?.VOLUME ? `${parseFloat(bond.marketData?.['Plazo' + termNumber]?.VOLUME).toFixed(2)}` : `-`,
                currency: bond.currency,
                last_closeure_price: bond.marketData?.['Plazo' + termNumber]?.PREV_CLOSE_PRICE ? bond.marketData?.['Plazo' + termNumber]?.PREV_CLOSE_PRICE : '-',
            }))
        )
        )
    }
    useEffect(() => {
        filterMarketBond()
    }, [marketBondEsp, instrumentNumber,termNumber]);

  const setCurrentOpened = (title) => {
    setCurrentOpenedState(title);
  };
    return (
        <div className="container-instrument-table">
            <div className="instrument-table-header-selections">
                <div className="instrument-table-header-title">
                    Bonos
                </div>
                <div className="instrument-table-header-text-filter">
                    <InputTextClientsWallet
                        placeholder="Buscar Ticker"
                        type="text"
                        onChange={handleSearchTextChange}
                        value={search}
                    />
                </div>
                <div className="intrument-table-header-select">
                    <SelectClientsWallet
                        placeholder="Tipo Persona"
                        options={OptionsTipoMoneda}
                        selection={moneda}
                        setSelection={handleMonedaChange}
                        sizeList="176px"
                        sizeContainer="128px"
                        setCurrentOpened={setCurrentOpened}
                        arrowIcon={ArrowDown}
                    />
                </div>
            </div>
            <TableContainer
                columns={columns}
                scroll={{ y: '60vh' }}
            />
        </div>
    );
};
