import React, { useEffect, useState } from "react";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import WalletLeft from "./wallet-components/wallet-left/wallet-left";
import WalletRight from "./wallet-components/wallet-right/wallet-right";

import { 
  api_getWalletFee, 
  api_getQuotation 
} from "../../common/common-api";

import {
  api_getDollarQuotation,
} from "../market/market-slice";

import {
  api_getFeeDetail,
} from "./wallet-api";
import {
  api_getLastMovements,
} from "./../home/home-slice";
import {
  api_getHolding,
  api_getHoldingDetail,
  api_getHoldingClientDetail,
} from "./wallet-slice";
import { auth } from "../../common/common-service";

import "./wallet.scss";

export const Wallet = () => {

  const {
    signInData,
    selectedClientFromHomeToWallet,
    holdingDetail,
    holdingCodComitenteDetail,
    customersByPartner,
  } = useSelector((state) => ({
    ...state["common"],
    ...state["wallet"],
    ...state["home"],
  }));

  const {
    quotationDollar
  } = useSelector((state) =>({
    ...state["market"]
  }))

  const [menuOpened, setMenuOpened] = useState(true);
  const dispatch = useDispatch();
  const [option, setOption] = useState("TENENCIA");
  const [selectedClientDashboard, setSelectedClientDashboard] = useState();

  const [period, setPeriod] = useState(2);
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [funds, setFunds] = useState([]);
  const [cedears, setCedears] = useState([]);
  const [bonds, setBonds] = useState([]);
  const [actions, setActions] = useState([]);
  const [foreignExchange, setForeignExchange] = useState([]);
  const [divisas, setDivisas] = useState([]);
  const [negotiableObligations, setNegotiableObligations] = useState([]);
  const [financialTrusts, setFinancialTrusts] = useState([]);
  const [lets, setLets] = useState([]);
  const [lecaps, setLecaps] = useState([]);

  const limitFeeDate = (date) => {
    let requestDate = moment(date ? date : new Date());
    let limitDate = moment().month(8).year(2021);
    if (requestDate >= limitDate) {
      return requestDate.format("YYYY-MM-DD");
    } else {
      return limitDate.startOf('month').format("YYYY-MM-DD");
    }
  }

  useEffect(() => {
    try {
      if (option === "FEE") {
        let params = {
          period: period,
          date: limitFeeDate(date),
        };
        if (selectedClientDashboard) {
          params["codComitente"] = selectedClientDashboard["codComitente"];
        }
        dispatch(api_getWalletFee(params));
      }
    } catch (error) {
      throw new Error(error);
    }
  }, [selectedClientDashboard, option, period, date, signInData, dispatch]);

  useEffect(() => {
    try {
      if (option === "FEE") {
        let params = {
          period: period,
          date: limitFeeDate(date)
        };
        if (selectedClientDashboard) {
          params["codComitente"] = selectedClientDashboard["codComitente"];
        }
        dispatch(api_getFeeDetail(params));
      }
    } catch (error) {
      throw new Error(error);
    }
  }, [selectedClientDashboard, option, period, date, signInData, dispatch]);

  useEffect(() => {
    try {
      if (option === "TENENCIA") {
        const params = {};
        
        if (selectedClientDashboard) {
          params["codComitente"] = selectedClientDashboard["codComitente"];
          dispatch(api_getHoldingClientDetail(params));
        } else {
          dispatch(api_getHoldingDetail());
        }
        
        dispatch(api_getHolding());
        dispatch(api_getDollarQuotation());
      }
    } catch (error) {
      throw new Error(error);
    }
  }, [selectedClientFromHomeToWallet, selectedClientDashboard, signInData, option, dispatch]);

  useEffect(() => {
    try {
      if (option === "CUENTA_CORRIENTE") {
        let isBefore =
          startDate && moment(startDate).unix() < moment(date).unix();
        let params = {
          codComitente: selectedClientDashboard["codComitente"],
          porConcertacion: 1,
          fechaDesde: moment(isBefore ? startDate : date).format("YYYY-MM-DD"),
          fechaHasta: moment(date).format("YYYY-MM-DD"),
        };

        startDate !== null &&
          date !== null &&
          dispatch(api_getLastMovements(params));
      }
    } catch (error) {
      throw new Error(error);
    }
  }, [option, date, startDate, selectedClientDashboard, dispatch]);

  useEffect(() => {
    const selectedDetail = selectedClientDashboard ? holdingCodComitenteDetail : holdingDetail;
    const fundsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "Fondo Común de Inversión";
    });
    setFunds(fundsFiltered);

    const actionsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "Acciones";
    });
    setActions(actionsFiltered);

    const bondsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "Títulos Públicos";
    });
    setBonds(bondsFiltered);

    const cedearsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "Certificados de depósitos Argentinos";
    });
    setCedears(cedearsFiltered);

    const foreignExchangeFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "Monedas";
    });
    setForeignExchange(foreignExchangeFiltered);

    const divisasFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "Divisas";
    });
    setDivisas(divisasFiltered);

    const negotiableObligationsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "OBLNEG";
    });
    setNegotiableObligations(negotiableObligationsFiltered);

    const financialTrustsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "FIDFIN";
    });
    setFinancialTrusts(financialTrustsFiltered);

    const letsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "LETES";
    });
    setLets(letsFiltered);

    const lecapsFiltered = selectedDetail?.dataBody?.filter((inst) => {
      return inst.instrumentType === "LECAP";
    });
    setLecaps(lecapsFiltered);
  }, [selectedClientDashboard, holdingDetail, holdingCodComitenteDetail]);

  return (
    <div className="render-container">
    <div className="wallet-dashboard-container">
        <WalletLeft
          setOption={setOption}
          setSelectedClientDashboard={setSelectedClientDashboard}
          menuOpened={menuOpened} setMenuOpened={setMenuOpened}
          selectedClientDashboard={selectedClientDashboard}
        />
      <WalletRight
        option={option}
        setOption={setOption}
        selectedClientDashboard={selectedClientDashboard}
        setPeriod={setPeriod}
        period={period}
        setDate={setDate}
        selectedDate={date}
        setStartDate={setStartDate}
        startDate={startDate}
        funds={funds}
        bonds={bonds}
        cedears={cedears}
        actions={actions}
        foreignExchange={foreignExchange}
        divisas={divisas}
        negotiableObligations={negotiableObligations}
        financialTrusts={financialTrusts}
        lets={lets}
        lecaps={lecaps}
        quotation={quotationDollar}
        menuOpened={menuOpened}
      />
    </div>
    <><div className="item-wallet-separator">&nbsp;</div></>
    </div>
  );
};
