import React, { useState } from 'react';

// components common and services
import { Arrow } from '../arrow/arrow';

// utils
import arrowDown from '../../utils/arrow-dark-blue-down.svg';

// styles
import './selectProfileCoin.scss';

export const SelectProfileCoin = ({
  options,
  selection,
  setSelectionCoin2,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  return (
    <div
      className="selectProfileCoin-wrapper"
      onMouseLeave={() => setOpenSelect(false)}
    >
      <button
        className="selectProfileCoin-container"
        onClick={() => setOpenSelect((x) => !x)}
      >
        <div className="element-wrapper">
          {selection.value === -1 ? 'Selección' : selection.description}
        </div>
        <Arrow open={openSelect} icon={arrowDown} />
      </button>

      <div
        className={`selectProfileCoin-options ${
          openSelect ? 'open-selectProfileCoin' : ''
        }`}
      >
        {options.map((opt, i) => {
          return (
            <div
              className={`option-selectProfileCoin ${
                selection.value === opt.value ? 'selected' : ''
              }`}
              key={i}
              onClick={() => {
                setSelectionCoin2(opt);
                setOpenSelect(false);
              }}
            >
              {opt.description}
            </div>
          );
        })}
      </div>
    </div>
  );
};
