import React from "react";

import icon from "../../../../utils/money.svg";
import "./buttons.scss";

const OutlineButton = (props) => {
  return (
    <button className="base-button outline-button" onClick={props.onClick}>
      <img src={props.icon} alt="..." />
      <h1>{props.title.toUpperCase()}</h1>
    </button>
  );
};

OutlineButton.defaultProps = {
    title: 'Extracción de dinero',
    icon,
    onClick: () => console.log('click action')
}

export default OutlineButton;